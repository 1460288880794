import { useState, useEffect } from 'react';
import { PEAP_CONTENT } from '../../../utils/constants';
import ManagementTab from './navigationTabs/management';
import { Helmet } from 'react-helmet';
import Complainant from './Complaint';
import Peapapproval from './Approvals';
import Insights from './insights';
import Messaging from './Messaging';
import PendingSpecialist from './pendingSpecialist';
import ActiveSpecialist from './activeSpecialist';
import Topbar from '../../PulseSurvey/Topbar';

function Specialist() {
  const [selectedContentType, setSelectedContentType] = useState(1);

  return (
    <div className="relative lg:pl-5 lg:mb-[45px]">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Peap Management</title>
      </Helmet>
      <Topbar title={'Peap Management'} />
      <div className="rounded-b-[10px] bg-shoorah-ggg dark:bg-shoorah-darkBgColor dark:text-white">
        <div className="overflow-auto custom-scrollbar">
          <div className="w-full dark:border-shoorah-darkBgColor">
            <ManagementTab
              PEAP_CONTENT={PEAP_CONTENT}
              selectedContentType={selectedContentType}
              setSelectedContentType={setSelectedContentType}
            />
            {selectedContentType === 1 && <ActiveSpecialist />}
            {selectedContentType === 2 && <PendingSpecialist />}
            {selectedContentType === 3 && <Insights />}
            {selectedContentType === 4 && <Complainant />}
            {selectedContentType === 5 && <Messaging />}
            {selectedContentType === 6 && <Peapapproval />}
          </div>
        </div>

        <div></div>
      </div>
    </div>
  );
}

export default Specialist;
