import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import slack from '../../assets/images/slack.png';
import { getPathForSurvey } from '../../utils/helper';
import Draftbase from '../../assets/images/Draftbase.png';

const DraftCard = ({ template }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option) => {
    console.log(`Selected option: ${option}`);
    setIsDropdownOpen(false);
  };

  return (
    <div className="w-full sm:w-[32%] pb-5 mt-5 bg-[#FFFFFF] border-[1.5px]  border-[#E7E8EA] rounded-[18px]  ">
      <div className="flex flex-col ">
        <div className="flex h-[162px]  bg-[#F4F4F4] rounded-t-[18px]">
          <img
            src={Draftbase}
            alt="Draftbase"
            className="object-cover object-center  rounded-t-[16.5px] w-full h-auto"
          />
        </div>
        <div className="h-fit flex flex-col gap-2 mx-4 mt-[15px]  ">
          <div className="flex flex-row justify-between items-center bg-[#FFFFFF] relative">
            <p className="text-[20px] text-[#0B1D2E] font-medium">{template.title}</p>
            <button onClick={toggleDropdown}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                  stroke="#0B1D2E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                  stroke="#0B1D2E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                  stroke="#0B1D2E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {isDropdownOpen && (
              <div className="absolute right-0  w-[155px] text-[16px] bg-[#FFFFFF] rounded-md  z-40 top-full">
                <div className="py-[2px] border border-[#DDDDDD] rounded-[8px]">
                  {['Open', 'Edit', 'Delete'].map((option) => (
                    <button
                      key={option}
                      className={`block w-full text-left px-4 py-2 text-sm z-50 
                        ${option === 'Delete' ? 'text-[#F05289]' : ''} ${
                        option === 'Edit' ? 'border-b border-[#E7E8EA] pb-3' : ''
                      } ${
                        option === 'Delete' ? 'border-t border-[#E7E8EA] pt-3' : ''
                      } hover:bg-gray-100`}
                      onClick={() => handleOptionClick(option)}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="flex ">
            <p className="text-[16px] text-[#0B1D2E] font-light h-[24px]">
              {/* {template.responses} response{template.responses !== 1 ? 's' : ''} */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex sm:justify-between justify-center w-full  items-center m-4  gap-4 ">
      <div className="hidden sm:flex">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="w-[200px] h-[45px] border border-black rounded-full text-black bg-white hover:bg-gray-100 disabled:opacity-50 transition-all"
        >
          Previous page
        </button>
      </div>
      <div className="flex min-w-[254px] w-[25%] justify-evenly  ">
        {/* {currentPage > 1 && <span className="flex items-center text-gray-500">...</span>} */}
        <span className="flex items-center text-gray-500">...</span>
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => onPageChange(number)}
            className={`px-[11.5px] py-2 rounded-full ${
              currentPage === number
                ? 'bg-[#4A57DA]/20 text-[#4A57DA] scale-125  text-[20px] transition-all' // Active page
                : 'text-gray-500 text-[20px] hover:bg-gray-200 hover:text-black transition-all scale-100' // Inactive pages
            }`}
          >
            {number < 10 ? `0${number}` : number}
          </button>
        ))}
        <span className="flex items-center text-gray-500">...</span>
        {/* {currentPage < pageNumbers.length && (
          <span className="flex items-center text-gray-500">...</span>
        )} */}
      </div>
      <div className="hidden sm:flex">
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === pageNumbers.length}
          className="w-[200px] h-[45px] bg-[#4A57DA] text-white rounded-full hover:bg-[#3A45B0] disabled:opacity-50 transition-all"
        >
          Next Page
        </button>
      </div>
    </div>
  );
};

const MytemplatesDrafts = () => {
  const [templates, setTemplates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [templatesPerPage] = useState(9);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Simulating API call to fetch templates
    const fetchTemplates = () => {
      const dummyTemplates = Array(21)
        .fill()
        .map((_, index) => ({
          id: index + 1,
          title: `Template ${index + 1}`,
          responses: Math.floor(Math.random() * 10)
        }));
      setTemplates(dummyTemplates);
    };

    fetchTemplates();
  }, []);

  // Filter templates based on search term
  const filteredTemplates = templates.filter(
    (template) =>
      template.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      template.responses.toString().includes(searchTerm)
  );

  // Get current templates
  const indexOfLastTemplate = currentPage * templatesPerPage;
  const indexOfFirstTemplate = indexOfLastTemplate - templatesPerPage;
  const currentTemplates = filteredTemplates.slice(indexOfFirstTemplate, indexOfLastTemplate);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  return (
    <div className="mx-auto max-w-[1640px]">
      {/* Search bar and button  */}
      <div className="flex justify-between mx-4 ml-5 mt-10">
        <div className="relative w-[370px] h-[40px] border-[1.5px] bg-[#FFFFFF] border-[#E7E8EA] rounded-[100px] px-4 flex items-center ">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.1441 12.5351C11.8739 11.3749 13.1464 8.22148 11.9863 5.49164C10.8261 2.76181 7.67265 1.48933 4.94281 2.64948C2.21297 3.80963 0.94049 6.96309 2.10064 9.69293C3.2608 12.4228 6.41426 13.6952 9.1441 12.5351Z"
                stroke="#54616D"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.8408 11.3895L15.0062 15.5555"
                stroke="#54616D"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
          <input
            className="pl-2 flex justify-start w-full h-full bg-transparent focus:outline-none"
            type="text"
            placeholder="Search your drafts"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <div className="flex justify-around items-center w-full sm:w-[195px] h-[40px] bg-[#4A57DA] text-[16px] rounded-[100px] text-[#F4F4F4]">
          <Link to={getPathForSurvey('/pulse-survey/add-edit-survey')} className="truncate">
            Make New Survey
          </Link>
        </div>
      </div>
      <div className="ml-3 mt-[40px] font-serif">
        <div>
          <div className="bg-[#FFFFFF] border-[1.5px] border-[#E7E8EA] rounded-[16px] mx-2 mt-[15px] mb-8">
            <div className="flex flex-col h-fit">
              <div className="h-[60px] flex flex-row justify-between mb-[20px] mt-[15px] mx-8 items-center ">
                <p className="text-[24px] text-[#0B1D2E] font-medium">My Drafts</p>

                <button className="text-[14px] text-[#4A57DA]">
                  <Link to={getPathForSurvey('/pulse-survey/')} className="truncate">
                    View Published Surveys
                  </Link>
                </button>
              </div>

              <div className="flex flex-col gap-4 justify-evenly bg-[#FFFFFF] border-[#E7E8EA] rounded-[18px] mx-6 mb-8">
                <div className="flex flex-wrap justify-between z-0">
                  {currentTemplates.map((template) => (
                    <DraftCard key={template.id} template={template} />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-[-100px] sticky w-[97%] rounded-[16px] align-middle overflow-hidden flex justify-center mx-5 bg-[#FFFFFF]">
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(filteredTemplates.length / templatesPerPage)}
              onPageChange={paginate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MytemplatesDrafts;
