import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import DonutChartCard from './DonutChartCard';

const ValueCards = ({resultData, isDashboard = false}) => {
  console.log("🚀 ~ ValueCards ~ isDashboard:", Number(resultData?.assessment?.incompletedPercentage))
  const incompletePercentage = isDashboard ? Number(resultData?.assessment?.incompletedPercentage) : ((resultData?.assessment?.inCompletedCount * 100) / resultData?.assessment?.total)?.toFixed(2);
  console.log("🚀 ~ ValueCards ~ incompletePercentage:", incompletePercentage)
  const completePercentage = isDashboard ? Number(resultData?.assessment?.completedPercentage) : (100 - incompletePercentage)?.toFixed(2);
  console.log("🚀 ~ ValueCards ~ completePercentage:", completePercentage)
  const dataD1 = {
    labels: ['Complete', 'Incomplete'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [completePercentage, incompletePercentage],
        backgroundColor: ['#D97F56', '#ECC09E'],
        hoverBackgroundColor: ['#D97F56', '#ECC09E'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };

  const optionsD = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: '90%',
    radius: '70%'
  };

  const dataD2 = {
    labels: ['18-25', '26-32'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [resultData?.ageRangeEngagement?.[0].count, resultData?.ageRangeEngagement?.[1].count],
        backgroundColor: ['#67A14A', '#C2EC97'],
        hoverBackgroundColor: ['#67A14A', '#C2EC97'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };

  const dataD4 = {
    labels: ['12:00 AM - 11:59 AM', '12:00 PM - 11:59 PM'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [resultData?.engagementRates?.[0]?.totalEngagements, resultData?.engagementRates?.[1]?.totalEngagements],
        backgroundColor: ['#21BDAD', '#6BE0BD'],
        hoverBackgroundColor: ['#21BDAD', '#6BE0BD'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };
  
  const dataD = {
    labels: ['Attachment', 'Percentage'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [resultData?.outComeType?.[0].types?.[1]?.count, resultData?.outComeType?.[0].types?.[0].count],
        backgroundColor: ['#ec4899', '#f9a8d4'],
        hoverBackgroundColor: ['#db2777', '#f472b6'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };

  const dataD5 = {
    labels: ['0-40', '41-80'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [resultData?.scoreRanges?.[0].totalUsersInRange + resultData?.scoreRanges?.[1].totalUsersInRange, resultData?.scoreRanges?.[2].totalUsersInRange + resultData?.scoreRanges?.[3].totalUsersInRange],
        backgroundColor: ['#EDCC12', '#FFE873'],
        hoverBackgroundColor: ['#EDCC12', '#FFE873'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };

  const CompletedData = [
    {
      label: 'Complete',
      value: resultData?.assessment?.completedCount,
      percentage: ((resultData?.assessment?.completedCount * 100) / resultData?.assessment?.total)?.toFixed(2),
      color: '#D97F56'
    },
    {
      label: 'Incomplete',
      value: resultData?.assessment?.inCompletedCount,
      percentage: ((resultData?.assessment?.inCompletedCount * 100) / resultData?.assessment?.total)?.toFixed(2),
      color: '#ECC09E'
    },
  ]

  console.log('resultData?.ageRangeEngagement?.[0].count + resultData?.ageRangeEngagement?.[1].count', ((resultData?.ageRangeEngagement?.[1].count * 100)));
  
const ageEngagements = resultData?.ageRangeEngagement
const totalAgeEngagements = ageEngagements?.[0].count + ageEngagements?.[1].count
  const UserAgeRangeData = [
    {
      label: 'Attachment',
      value: ageEngagements?.[0].count,
      percentage: ((ageEngagements?.[0].count * 100) / (totalAgeEngagements !== 0 ? totalAgeEngagements : 1))?.toFixed(2),
      color: '#67A14A'
    },
    {
      label: 'Age 26-32',
      value: ageEngagements?.[1].count,
      percentage: ((ageEngagements?.[1].count * 100) / (totalAgeEngagements !== 0 ? totalAgeEngagements : 1))?.toFixed(2),
      color: '#C2EC97'
    },
  ]

  const CommonOutComeData = [
    {
      label: 'Attachment',
      value: ageEngagements?.[0].count,
      percentage: ((ageEngagements?.[0].count * 100) / (totalAgeEngagements !== 0 ? totalAgeEngagements : 1))?.toFixed(2),
      color: '#67A14A'
    },
    {
      label: 'Percentage',
      value: ageEngagements?.[1].count,
      percentage: ((ageEngagements?.[1].count * 100) / (totalAgeEngagements !== 0 ? totalAgeEngagements : 1))?.toFixed(2),
      color: '#C2EC97'
    },
  ]

  const selfAssesmentDashboard = [
    {
      component: <DonutChartCard data={CompletedData} title={'Completed Assessments'} />
    },
    {
      component: <DonutChartCard data={UserAgeRangeData} title={'User Age Range'} />
    },
    {
      component: <DonutChartCard data={UserAgeRangeData} title={'Common Outcome'} />
    }
  ]





  return (
    <div className="flex flex-col sm:flex-row gap-4 w-full">
      <div className="flex flex-col lg:flex-row md:flex-col gap-10 w-full overflow-x-auto horizontal-card-scrollbar pb-2">
        <div className="flex flex-col md:flex md:flex-row gap-4 md:gap-10 w-full">
          {selfAssesmentDashboard.map((item, index) => (
            <React.Fragment key={index}>
              {item.component}
            </React.Fragment>
          ))}
          {/* <DonutChartCard data={CompletedData} title={'Completed Assessments'} totalCount={1} /> */}
           {/* <div className="px-3 py-6 w-full sm:mb-0 md:w-1/2 lg:w-1/5 min-w-full sm:min-w-[250px] bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
            <div className="flex flex-row gap-2 px-1 justify-between">
              <div>
                <h2 className="text-[20px] tracking-tight flex items-start font-medium text-[#0B0F18] text-left pl-[0px] md:pl-[0px]">
                  {!isDashboard ? 'Users Completed' : 'Complete Assessment'}
                </h2>
              </div>
              <div className="flex items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="relative flex max-h-[180px] justify-center items-center">
              {!isDashboard && <div className="absolute text-center">
                <p className="text-sm text-[#606060]">Total</p>
                <p className="text-2xl text-[#0B0F18]">{resultData?.assessment?.total}</p>
              </div>}
              <Doughnut data={dataD1} options={optionsD} />
            </div>
            <div className="flex flex-col md:flex-col lg:flex-col md:justify-between md:gap-5 lg:gap-3 gap-4 lg:pl-5 lg:pr-10 md:pl-2 md:pr-2">
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#D97F56] rounded-full mr-2"></span>
                <p className=" flex flex-row items-center gap-2 text-[16px] text-[#606060]">
                  Complete: <p className="text-[#0B0F18] text-[20px]">{completePercentage}%</p>
                </p>
              </div>
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#ECC09E] rounded-full mr-2"></span>
                <p className="flex flex-row items-center gap-2 text-[16px] text-[#606060]">
                  Incomplete: <p className="text-[#0B0F18] text-[20px]">{incompletePercentage}%</p>
                </p>
              </div>
            </div>
          </div> */}
          {/* <div className="px-3 py-6 w-full sm:mb-0  md:w-1/2 lg:w-1/5 min-w-full sm:min-w-[250px] bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
            <div className="flex flex-row gap-2 px-1 justify-between">
              <div>
                <h2 className="text-[20px] tracking-tight flex items-start font-medium text-[#0B0F18] text-left pl-[0px] md:pl-[0px] ">
                  User Age Range
                </h2>
              </div>
              <div className="flex items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="relative flex max-h-[180px] justify-center items-center">
              <div className="absolute text-center">
                <p className="text-sm text-[#606060]">Total</p>
                <p className="text-2xl text-[#0B0F18] ">{resultData?.ageRangeEngagement?.[0].count + resultData?.ageRangeEngagement?.[1].count}</p>
              </div>
              <Doughnut data={dataD2} options={optionsD} />
            </div>
            <div className="flex flex-col md:flex-col lg:flex-col md:justify-between md:gap-5 lg:gap-3 gap-4 lg:pl-5 lg:pr-10 md:pl-2 md:pr-2">
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#67A14A] rounded-full mr-2"></span>
                <p className="flex flex-row items-center gap-2 text-[16px] text-[#606060]">
                  Age 18-25: <p className="text-[#0B0F18] text-[20px] ">{resultData?.ageRangeEngagement?.[0].count}</p>
                </p>
              </div>
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#C2EC97] rounded-full mr-2"></span>
                <p className="flex flex-row items-center gap-2 text-[16px] text-[#606060]">
                  Age 26-32: <p className="text-[#0B0F18] text-[20px]">{resultData?.ageRangeEngagement?.[1].count}</p>
                </p>
              </div>
            </div>
          </div> */}
          <div className="px-3 py-6 w-full sm:mb-0 md:w-1/2 lg:w-1/5 min-w-full sm:min-w-[250px] bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
            <div className="flex flex-row gap-2 justify-between px-1">
              <div>
                <h2 className="text-[20px] tracking-tight flex items-start font-medium text-[#0B0F18] text-left pl-[0px] md:pl-[0px]">
                Common
                Outcome
                </h2>
              </div>
              <div className="flex items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="relative flex max-h-[180px] justify-center items-center">
              <div className="absolute text-center">
                <p className="text-sm text-[#606060]">Total</p>
                <p className="text-2xl text-[#0B0F18] ">{resultData?.outComeType?.[0].types?.[1]?.count + resultData?.outComeType?.[0].types?.[0]?.count}</p>
              </div>
              <Doughnut data={dataD} options={optionsD} />
            </div>
            <div className="flex flex-col md:flex-col lg:flex-col md:justify-between md:gap-5 lg:gap-3 gap-4 lg:pl-5 lg:pr-10 md:pl-2 md:pr-2">
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-pink-500 rounded-full mr-2"></span>
                <p className="flex flex-row gap-2 items-center text-[16px] text-[#606060]">
                Attachment: <p className="text-[#0B0F18] text-[20px]">{(resultData?.outComeType?.[0].types?.[1]?.percentage)?.toFixed(2)}%</p>
                </p>
              </div>
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-pink-300 rounded-full mr-2"></span>
                <p className="flex flex-row gap-2 text-[16px] text-[#606060]">
                Percentage: <p className="text-[#0B0F18] items-center text-[20px]">{(resultData?.outComeType?.[0].types?.[0]?.percentage)?.toFixed(2)}%</p>
                </p>
              </div>
            </div>
          </div>
          {/*<div className="px-3 py-6 w-full sm:mb-0 md:w-1/2 lg:w-1/5 min-w-full sm:min-w-[250px] bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
            <div className="flex flex-row gap-2 px-1 justify-between">
              <div>
                <h2 className="text-[20px] tracking-tight flex items-start font-medium text-[#0B0F18] text-left pl-[0px] md:pl-[0px]">
                  Top Usage Times
                </h2>
              </div>
              <div className="flex items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="relative flex max-h-[180px] justify-center items-center">
              <div className="absolute text-center">
                <p className="text-sm text-[#606060]">Total</p>
                <p className="text-2xl text-[#0B0F18] ">{resultData?.engagementRates?.[0]?.totalEngagements + resultData?.engagementRates?.[1]?.totalEngagements}</p>
              </div>
              <Doughnut data={dataD4} options={optionsD} />
            </div>
            <div className="flex flex-col md:flex-col lg:flex-col md:justify-between md:gap-5 lg:gap-3 gap-4 lg:pl-5 lg:pr-10 md:pl-2 md:pr-2">
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#21BDAD] rounded-full mr-2"></span>
                <p className=" flex flex-row gap-2 items-center text-[16px] text-[#606060]">
                12-11:59am: <p className="text-[#0B0F18] text-[20px]">{(resultData?.engagementRates?.[0]?.engagementRatePercentage)?.toFixed(2)}%</p>
                </p>
              </div>
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#6BE0BD] rounded-full mr-2"></span>
                <p className=" flex flex-row gap-2 text-[16px] items-center text-[#606060]">
                12-11:59pm: <p className="text-[#0B0F18] text-[20px]">{(resultData?.engagementRates?.[1]?.engagementRatePercentage)?.toFixed(2)}%</p>
                </p>
              </div>
            </div>
          </div>
          <div className="px-3 py-6 w-full sm:mb-0 md:w-1/2 lg:w-1/5 min-w-full sm:min-w-[250px] bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
            <div className="flex flex-row gap-2 justify-between px-1">
              <div>
                <h2 className="text-[20px] tracking-tight flex items-start font-medium text-[#0B0F18] text-left pl-[0px] md:pl-[0px]">
                  Average Scores
                </h2>
              </div>
              <div className="flex items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="relative flex max-h-[180px] justify-center items-center">
              <div className="absolute text-center">
                <p className="text-sm text-[#606060]">Total</p>
                <p className="text-2xl text-[#0B0F18] ">{resultData?.scoreRanges?.[0].totalUsersInRange + resultData?.scoreRanges?.[1].totalUsersInRange + resultData?.scoreRanges?.[2].totalUsersInRange + resultData?.scoreRanges?.[3].totalUsersInRange}</p>
              </div>
              <Doughnut data={dataD5} options={optionsD} />
            </div>
            <div className="flex flex-col md:flex-col lg:flex-col md:justify-between md:gap-5 lg:gap-3 gap-4 lg:pl-5 lg:pr-10 md:pl-2 md:pr-2">
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#EDCC12] rounded-full mr-2"></span>
                <p className="flex flex-row gap-2 items-center text-[16px] text-[#606060]">
                  0-40%: <p className="text-[#0B0F18] text-[20px]">{resultData?.scoreRanges?.[0].percentage + resultData?.scoreRanges?.[1].percentage}%</p>
                </p>
              </div>
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#FFE873] rounded-full mr-2"></span>
                <p className="flex flex-row gap-2 text-[16px] text-[#606060]">
                  41-60%: <p className="text-[#0B0F18] items-center text-[20px]">{resultData?.scoreRanges?.[2].percentage + resultData?.scoreRanges?.[3].percentage}%</p>
                </p>
              </div>
            </div>
          </div> */}
          </div>
      </div>
    </div>
  );
};

export default ValueCards;
