import React from 'react';
import { getPathForSurvey } from '../../../utils/helper';
import { Link } from 'react-router-dom';
import Dots from '../../../assets/images/Dots.svg';
import gallery from '../../../assets/images/gallery.png';

const SurveyCrad = ({
  item,
  handleQuestionAction,
  Id,
  handleDeletePulseSurve,
  isAdmin = true,
  handleDeactivatePulseSurvey
}) => {
  return (
    <div className="w-full bg-white border-[1.5px] border-[#E7E8EA] rounded-2xl shadow-sm">
      {/* Image Section */}
      <div className="relative bg-[#F4F4F4] rounded-t-2xl overflow-hidden">
        {item.logo ? (
          <img src={item.logo} alt="survey" className="object-cover w-full h-[160px]" />
        ) : (
          <div className="flex justify-center items-center h-[160px]">
            <img src={gallery} alt="survey placeholder" className="w-[48px] h-[48px]" />
          </div>
        )}
      </div>

      {/* Content Section */}
      <div className="h-fit flex flex-col lg:gap-4 gap-2 lg:px-4 px-3 lg:py-6 py-4">
        {/* Title and Action Menu */}
        <div className="flex justify-between items-center">
          <p className="lg:text-[20px] text-[16px] text-gray-900 font-bold leading-6 truncate max-w-[80%]">
            {item.title}
          </p>
          <button className="relative" onClick={() => handleQuestionAction(item.id)}>
            <img src={Dots} alt="Dots icon" className="w-6 h-6 text-shoorah-lightBlack" />
            {item.id === Id && isAdmin && (
              <div className="w-40 z-50 bg-white absolute top-10 right-0 mt-2 border-[1.5px] border-[#DDDDDD] rounded-lg shadow-lg">
                <div className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer font-medium leading-6 text-[16px]">
                  Open
                </div>
                {item.status !== 5 && (
                  <div className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer font-medium leading-6 text-[16px]">
                    <Link
                      to={getPathForSurvey(
                        `/pulse-survey/add-edit-survey?id=${item.id}&type=${item.type}`
                      )}
                    >
                      Edit
                    </Link>
                  </div>
                )}
                <div className="border-b border-gray-400 w-full my-2"></div>
                {item.status === 5 && <div className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer font-medium leading-6 text-[16px]">
                  <Link
                      to={getPathForSurvey(
                        `/pulse-survey/add-edit-survey?id=${item.id}&type=${item.type}&page=results`
                      )}
                    >
                      Results
                    </Link>
                </div>}
                <div className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer font-medium leading-6 text-[16px]">
                  Rename
                </div>
                <div className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer font-medium leading-6 text-[16px]">
                  Duplicate
                </div>
                <div className="border-b border-gray-400 w-full my-2"></div>
                <div
                  onClick={() => handleDeletePulseSurve(item.id)}
                  className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer text-red-700 font-medium leading-6 text-[16px]"
                >
                  Delete
                </div>
                {item.status === 5 && (
                  <div
                    onClick={() => handleDeactivatePulseSurvey(item.id)}
                    className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer text-red-700 font-medium leading-6 text-[16px]"
                  >
                    Deactivate
                  </div>
                )}
              </div>
            )}
            {item.id === Id && !isAdmin && (
              <div className="w-40 z-50 bg-white absolute top-10 right-0 mt-2 border-[1.5px] border-[#DDDDDD] rounded-lg shadow-lg">
                <div className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer font-medium leading-6 text-[16px]">
                  <Link to={getPathForSurvey(`/pulse-survey/add-edit-survey?id=${item.id}&type=2`)}>
                    Share
                  </Link>
                </div>
              </div>
            )}
          </button>
        </div>
        {/* Description */}
        <p className="lg:text-[16px] text-[14px] text-gray-600 font-normal leading-5">
          {item.description}
        </p>
      </div>
    </div>
  );
};

export default SurveyCrad;
