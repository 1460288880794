import { Fragment, useState, useContext, useEffect } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { SidebarContext } from '../context/SidebarContext';
import { getLocalStorageItem } from '../utils/helper';
import { errorToast } from '../utils/helper';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const subMenu = [
  {
    name: 'Focus',
    href: '/content-management/focus',
    icon: '',
    access: true,
    show: true
  },
  {
    name: 'Focus',
    href: '/content-management/focus/add-edit',
    icon: '',
    access: true,
    show: false
  },
  {
    name: 'Focus',
    href: '/content-management/focus/view',
    icon: '',
    access: true,
    show: false
  },
  {
    name: 'Affirmation',
    href: '/content-management/affirmation',
    icon: '',
    access: true,
    show: true
  },
  {
    name: 'Affirmation',
    href: '/content-management/affirmation/add-edit',
    icon: '',
    access: true,
    show: false
  },
  {
    name: 'Affirmation',
    href: '/content-management/affirmation/view',
    icon: '',
    access: true,
    show: false
  },
  {
    name: 'Meditation',
    href: '/content-management/meditation',
    icon: '',
    access: true,
    show: true
  },
  {
    name: 'Meditation',
    href: '/content-management/meditation/add-edit',
    icon: '',
    access: true,
    show: false
  },
  {
    name: 'Gratitude',
    href: '/content-management/gratitude',
    icon: '',
    access: true,
    show: true
  },
  {
    name: 'Gratitude',
    href: '/content-management/gratitude/add-edit',
    icon: '',
    access: true,
    show: false
  },
  {
    name: 'Rituals',
    href: '/content-management/rituals',
    icon: '',
    access: true,
    show: true
  },
  {
    name: 'Rituals',
    href: '/content-management/rituals/add-edit',
    icon: '',
    access: true,
    show: false
  },
  // {
  //   name: 'BedTime Stories',
  //   href: '/content-management/bedtime-stories',
  //   icon: '',
  //   access: true,
  //   show: true,
  // },
  {
    name: 'Sounds',
    href: '/content-management/sounds',
    icon: '',
    access: true,
    show: true
  },
  {
    name: 'Sounds',
    href: '/content-management/sounds/add-edit',
    icon: '',
    access: true,
    show: false
  },
  {
    name: 'Top Picks',
    href: '/content-management/top-picks',
    icon: '',
    access: true,
    show: true
  },
  {
    name: 'Top Picks',
    href: '/content-management/top-picks/add-edit',
    icon: '',
    access: true,
    show: false
  }
  // {
  //   name: 'Manifestation',
  //   href: '/content-management/manifestation',
  //   icon: '',
  //   access: true,
  //   show: true,
  // },
];

const Menu = ({
  item,
  setActiveIndex,
  index,
  openedSubMenuIndex,
  setOpenedSubMenuIndex,
  slide
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setShow } = useContext(SidebarContext);
  const userData = getLocalStorageItem('userData') && JSON.parse(getLocalStorageItem('userData'));

  const getStatus = () => {
    return !!subMenu?.map((item) => item.href)?.includes(location.pathname);
  };

  const [showSubMenu, setShowSubMenu] = useState(getStatus());

  useEffect(() => {
    setShowSubMenu(openedSubMenuIndex === index);
  }, [openedSubMenuIndex, index]);

  const handleRedirect = (link) => {
    if (item?.isDisabled) {
      return errorToast('Access denied..');
    }
    if (window.innerWidth < 1024) {
      setShow(false);
    }
    if (!item.remove) {
      navigate(link);
    } else {
      navigate(`/${userData?.slug}/myCompany`);
    }
  };

  const toggleSubMenu = () => {
    if (showSubMenu) {
      setActiveIndex(index);
      setOpenedSubMenuIndex(null);
    } else {
      setActiveIndex(index);
      setOpenedSubMenuIndex(index);
    }
  };

  return (
    <>
      <div
        key={item.name}
        onClick={() => {
          if (item.dropDownHref === 'isDropDown') {
            toggleSubMenu();
            handleRedirect(item.href);
          } else {
            handleRedirect(item.href);
            setActiveIndex(index);
          }
        }}
        className={classNames(
          location?.pathname?.includes(item.href) ||
            item?.subMenu?.map((item) => item.href)?.includes(location.pathname)
            ? 'text-shoorah-active dark:text-white cursor-pointer'
            : 'text-shoorah-lightBlack dark:text-white/60 cursor-pointer',
          'group outline-none flex items-center lg:h-[60px] h-[81px] lg:py-[18px] lg:ps-6 text-sm lg:border-none border-b border-shoorah-borderGray dark:border-shoorah-darkBg lg:rounded-md relative font-medium',
          slide ? 'lg:ps-6 ps-[22px] lg:justify-start' : 'lg:justify-start justify-center'
        )}
      >
        {location?.pathname?.includes(item.href) ||
        item?.subMenu?.map((item) => item.href)?.includes(location.pathname) ? (
          <>
            <div
              className={`w-2 lg:h-[60px] h-[81px] bg-shoorah-active dark:bg-shoorah-darkBg absolute top-0 left-0 transition-all`}
            ></div>
            <img
              src={item?.activeIcon}
              alt="sidebar_icon"
              className={`h-6 w-6 dark:invert flex-shrink-0 ${
                slide ? 'mr-5 ml-2' : 'lg:mr-5 lg:ml-2 m-0'
              }`}
            />
          </>
        ) : (
          <img
            loading="lazy"
            src={item?.icon}
            alt="sidebar_icon"
            className={`h-6 w-6 flex-shrink-0 dark:opacity-60 ${
              slide ? 'mr-5 ml-2' : 'lg:mr-5 lg:ml-2 m-0'
            }`}
          />
        )}
        <span className={`lg:block hidden`}>{item.name}</span>
        {slide && <span className={`lg:hidden block text-nowrap`}>{item.name}</span>}
        {item.isDropDown &&
          (showSubMenu ? (
            <ChevronUpIcon className="w-[20px] ml-2 lg:block hidden" />
          ) : (
            <ChevronDownIcon className="w-[20px] ml-2 lg:block hidden" />
          ))}
      </div>
      {item.isDropDown && showSubMenu && (
        <Transition
          show={showSubMenu}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className="text-shoorah-newDashboardBlue dark:text-white py-2 space-y-2 px-2 bg-shoorah-offWhite dark:bg-shoorah-darkBgColor">
            {item.subMenu?.map((innerItem, subIndex) => {
              return !innerItem.show ? (
                ''
              ) : (
                <div
                  key={subIndex}
                  onClick={() => handleRedirect(innerItem.href)}
                  className={classNames(
                    location?.pathname?.includes(innerItem.href)
                      ? 'bg-shoorah-active text-white cursor-pointer'
                      : 'cursor-pointer text-shoorah-gray2',
                    'lg:mx-4 mx-1 group outline-none flex items-center py-3 text-sm font-normal rounded-md',
                    slide ? 'justify-start px-2' : 'lg:justify-start justify-center px-2'
                  )}
                >
                  {location?.pathname?.includes(innerItem.href) ? (
                    <>
                      <img
                        loading="lazy"
                        src={innerItem?.activeIcon}
                        alt="sidebar_icon"
                        className={`h-6 w-6 flex-shrink-0 dark:opacity-60 ${
                          slide ? 'mr-3 ml-2' : 'lg:ml-2 lg:mr-3'
                        }`}
                      />
                    </>
                  ) : (
                    <img
                      loading="lazy"
                      src={innerItem?.icon}
                      alt="sidebar_icon"
                      className={`h-6 w-6 flex-shrink-0 dark:opacity-60 ${
                        slide ? 'mr-3 ml-2' : 'lg:ml-2 lg:mr-3'
                      }`}
                    />
                  )}
                  <span className={`lg:block hidden`}>{innerItem.name}</span>
                  {slide && <span className={`lg:hidden block text-nowrap`}>{innerItem.name}</span>}
                </div>
              );
            })}
          </div>
        </Transition>
      )}
    </>
  );
};

Menu.propTypes = {
  item: PropTypes.any,
  setActiveIndex: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  openedSubMenuIndex: PropTypes.number,
  setOpenedSubMenuIndex: PropTypes.func.isRequired
};

export default Menu;
