export const Pagination = ({ currentPage, totalCount, pageSize, onPageChange }) => {
  const totalPages = Math.ceil(totalCount / pageSize);
  const getVisiblePageNumbers = () => {
    if (totalPages <= 5) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    if (currentPage <= 3) {
      return [1, 2, 3, 4, 5, '...'];
    }

    if (currentPage >= totalPages - 2) {
      return ['...', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    }

    return [
      '...',
      currentPage - 2,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      currentPage + 2,
      '...'
    ];
  };

  const visiblePageNumbers = getVisiblePageNumbers();

  return (
    <div className="px-4 py-4 bg-white dark:bg-shoorah-darkBgTabColor dark:border-none border border-gray-200 rounded-[15px] flex justify-between mb-0 items-center">
      <div className="hidden sm:flex">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="h-[40px] px-6 border text-sm border-black rounded-full text-black bg-white hover:bg-gray-100 disabled:opacity-50 transition-all"
        >
          Previous page
        </button>
      </div>
      <div className="flex min-w-[254px] items-center gap-2 justify-center">
        {visiblePageNumbers.map((number, index) => (
          <button
            key={index}
            onClick={() => typeof number === 'number' && onPageChange(number)}
            className={`w-[45px] h-[45px] font-medium rounded-full text-sm ${
              currentPage === number
                ? 'bg-[#4A57DA]/20 text-[#4A57DA]  transition-all '
                : 'text-gray-500 hover:bg-gray-200 hover:text-black transition-all scale-100'
            }`}
            disabled={typeof number !== 'number'}
          >
            {typeof number === 'number' ? (number < 10 ? `0${number}` : number) : '...'}
          </button>
        ))}
      </div>
      <div className="hidden sm:flex">
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="h-[40px] px-6 text-sm bg-[#4A57DA] text-white rounded-full hover:bg-[#3A45B0] disabled:opacity-50 transition-all"
        >
          Next Page
        </button>
      </div>
    </div>
  );
};
