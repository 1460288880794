import Topbar from './Topbar';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Dots from '../../assets/images/Dots.svg';
import slack from '../../assets/images/slack.png';
import { errorToast, getPathForSurvey, isCopanySuperOrSubAdmin, isSuperAndSubAdmin, successToast } from '../../utils/helper';
import SearchInput from '../../component/common/Input/SearchInput';
import templatesurvey from '../../assets/images/templatesurvey.svg';
import templatesurveyA from '../../assets/images/templatesurveyA.svg';
import templatesurveyD from '../../assets/images/templatesurveyD.png';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import AddSurveyPopup from '../../component/common/modals/CreatePopup';
import { surveyApi } from '../../api/surveyApi';
import SurveyCrad from './addSurvey/surveyCrad';
import Loader from '../../component/common/Loader';
import AdminDashboardInsight from './addSurvey/AdminDashboardInsight';
import DashboardInfoCard from '../../component/common/DashboardInfoCard';

ChartJS.register(ArcElement, Tooltip, Legend);

const PulseSurveyDashboard = () => {
  const [isOpen, setIsOpen] = useState(false); //For Date created dropdown

  const [Id, setId] = useState('');

  const [isPopupOpen, setIsPopupOpen] = useState(false); // For Survey popup

  const [survey, setSurvey] = useState([]);

  const [templateData, setTemplateData] = useState([]);

  const [selectedOption, setSelectedOption] = useState('Date Created'); // Manage selected option for Date created dropdown

  const [loading, setLoading] = useState(false);

  const popupOption = ['Survey', 'Template']; // Options for popup

  const [resultData, setResultData] = useState();

  const { userType } = JSON.parse(localStorage.getItem('userData'));

  const navigate = useNavigate();

  let isAdmin;
  isAdmin =
    userType === 0 || userType === 1 ? true : userType === 3 || userType === 4 ? false : true;

  // Function to toggle dropdown For Date created
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev); // Toggle isOpen state
  };

  // Function to toggle popup
  const togglePopUp = () => {
    if (isAdmin) {
      setIsPopupOpen((prev) => !prev);
    } else {
      navigate(getPathForSurvey('/pulse-survey/add-edit-survey'));
      localStorage.setItem('surveyType', 'Survey');
    }
    // setIsPopupOpen((prev) => !prev); // Toggle isOpen state
  };

  // Function to handle option click For Date created button
  const handleOptionClick = (option) => {
    setSelectedOption(option); // Set the clicked option as selected
    setIsOpen(false); // Close the dropdown
  };

  // Function to handle option click For Survey popup
  const handlePopuopOptionClick = (option) => {
    setIsPopupOpen(false); // Close the dropdown
    localStorage.setItem('surveyType', option);
    localStorage.removeItem('surveyId');
  };

  const handleQuestionAction = (id) => {
    if (Id === '') {
      setId(id);
    } else {
      setId('');
    }
  };

  const fetchSurvey = async () => {
    try {
      const userType = JSON.parse(localStorage.getItem('userData'))?.userType;

      let response;

      if (userType === 0 || userType === 1) {
        response = await surveyApi.getSurveys(1, 6, '', '', '', 'createdAt', undefined, 2);
      } else if (userType === 3 || userType === 4) {
        response = await surveyApi.getSurveyAll(1, 6, '', '', '', 'createdAt', undefined, 2);
      } else {
        console.log('Invalid userType');
        return;
      }

      setSurvey(response?.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchSurveyStatus = async () => {
    if(isCopanySuperOrSubAdmin()) {
      try {
        await surveyApi.getSurveyStatus(false).then((response) => {
          setResultData(response?.data);
        }).catch((error) => {
          console.error('Error fetching data:', error);
        })
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else if (isSuperAndSubAdmin()) {
      try {
        await surveyApi.getSurveyStatus(true).then((response) => {
          console.log("🚀 ~ awaitsurveyApi.getSurveyStatus ~ response:", response)
          setResultData(response?.data);
        }).catch((error) => {
          console.error('Error fetching data:', error);
        })
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    
  };

  const fetchTemplate = async () => {
    setLoading(true);
    try {
      const userType = JSON.parse(localStorage.getItem('userData'))?.userType;

      let response;

      if (userType === 0 || userType === 1) {
        response = await surveyApi.getSurveys(1, 6, '', '', '', 'createdAt', undefined, 1);
      } else if (userType === 3 || userType === 4) {
        response = await surveyApi.getSurveyAll(1, 6, '', '', '', 'createdAt', undefined, 1);
      } else {
        console.log('Invalid userType');
        return;
      }

      setTemplateData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSurvey();
    fetchTemplate();
    fetchSurveyStatus();
  }, []);

  const handleDeletePulseSurve = (assessmentId, type) => {
    surveyApi
      .deleteSurvey(assessmentId, isAdmin)
      .then((response) => {
        console.log(response);
        if (response?.data?.meta?.code === 200) {
          successToast(response?.data?.meta?.message);
          type === 'survey' ? fetchSurvey() : fetchTemplate();
        }
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.response?.data?.message ?? 'Unknown Error Occurred');
      });
  };

  const handleDeactivatePulseSurvey = (assessmentId, type) => {
    surveyApi
      .inactiveAdminSurvey(assessmentId, isAdmin)
      .then((response) => {
        if (response?.data?.meta?.code === 200) {
          successToast(response?.data?.meta?.message);
          type === 'survey' ? fetchSurvey() : fetchTemplate();
        }
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.response?.data?.message ?? 'Unknown Error Occurred');
      });
  };

  const AdminStatusCards = [
    <DashboardInfoCard 
      icon={<svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="56" rx="28" fill="#4A57DA" />
        <path
          d="M28 38C33.5 38 38 33.5 38 28C38 22.5 33.5 18 28 18C22.5 18 18 22.5 18 28C18 33.5 22.5 38 28 38Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.75 28L26.58 30.83L32.25 25.17"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>}
      title={'Created Surveys'}
      value={resultData?.totalSurveys ? resultData.totalSurveys : 0}
    />,
    <DashboardInfoCard 
      icon={<svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="56" rx="28" fill="#F05289" />
        <path
          d="M28 38C33.5 38 38 33.5 38 28C38 22.5 33.5 18 28 18C22.5 18 18 22.5 18 28C18 33.5 22.5 38 28 38Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.1699 30.83L30.8299 25.17"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.8299 30.83L25.1699 25.17"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>}
      title={'Active Surveys'}
      value={0}
    />,
    <DashboardInfoCard 
      icon={<svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="56" rx="28" fill="#0B1D2E" />
        <path
          d="M23.3999 22.32L31.8899 19.49C35.6999 18.22 37.7699 20.3 36.5099 24.11L33.6799 32.6C31.7799 38.31 28.6599 38.31 26.7599 32.6L25.9199 30.08L23.3999 29.24C17.6899 27.34 17.6899 24.23 23.3999 22.32Z"
          stroke="#F4F4F4"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.1101 29.6501L29.6901 26.0601"
          stroke="#F4F4F4"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>}
      title={'Completed Surveys'}
      value={resultData?.completedSurveys ? resultData.completedSurveys : 0}
    />,
    <DashboardInfoCard 
      icon={<svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="56" rx="28" fill="#20BDAD" />
        <path
          d="M21.1499 18V38"
          stroke="white"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.1499 20H32.3499C35.0499 20 35.6499 21.5 33.7499 23.4L32.5499 24.6C31.7499 25.4 31.7499 26.7 32.5499 27.4L33.7499 28.6C35.6499 30.5 34.9499 32 32.3499 32H21.1499"
          stroke="white"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>}
      title={'Response Rate'}
      value={resultData?.completionRate ? resultData.completionRate : 0}
    />
  ]

  const subAdminStatusCards = [
    <DashboardInfoCard 
      icon={<svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="56" rx="28" fill="#4A57DA" />
        <path
          d="M28 38C33.5 38 38 33.5 38 28C38 22.5 33.5 18 28 18C22.5 18 18 22.5 18 28C18 33.5 22.5 38 28 38Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.75 28L26.58 30.83L32.25 25.17"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>}
      title={'Complete Surveys'}
      value={resultData?.completedSurveys ? resultData.completedSurveys : 0}
    />,
    <DashboardInfoCard 
      icon={<svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="56" rx="28" fill="#F05289" />
        <path
          d="M28 38C33.5 38 38 33.5 38 28C38 22.5 33.5 18 28 18C22.5 18 18 22.5 18 28C18 33.5 22.5 38 28 38Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.1699 30.83L30.8299 25.17"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.8299 30.83L25.1699 25.17"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>}
      title={'Incomplete Surveys'}
      value={resultData?.notAttemptedSurveys ? resultData.notAttemptedSurveys : 0}
    />,
    <DashboardInfoCard 
      icon={<svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="56" rx="28" fill="#0B1D2E" />
        <path
          d="M23.3999 22.32L31.8899 19.49C35.6999 18.22 37.7699 20.3 36.5099 24.11L33.6799 32.6C31.7799 38.31 28.6599 38.31 26.7599 32.6L25.9199 30.08L23.3999 29.24C17.6899 27.34 17.6899 24.23 23.3999 22.32Z"
          stroke="#F4F4F4"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.1101 29.6501L29.6901 26.0601"
          stroke="#F4F4F4"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>}
      title={'Open Surveys'}
      value={resultData?.openSurveys ? resultData.openSurveys : 0}
    />,
    <DashboardInfoCard 
      icon={<svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="56" rx="28" fill="#20BDAD" />
        <path
          d="M21.1499 18V38"
          stroke="white"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.1499 20H32.3499C35.0499 20 35.6499 21.5 33.7499 23.4L32.5499 24.6C31.7499 25.4 31.7499 26.7 32.5499 27.4L33.7499 28.6C35.6499 30.5 34.9499 32 32.3499 32H21.1499"
          stroke="white"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>}
      title={'Delivered Surveys'}
      value={resultData?.totalSurveys ? resultData.totalSurveys : 0}
    />
  ]

  const dashborStatusCardList = isSuperAndSubAdmin() ? AdminStatusCards : subAdminStatusCards

  if (loading) {
    return <Loader compact />;
  }
  return (
    <div className="mx-auto max-w-[1640px] overflow-x-hidden hide-scrollbar font-work-sans pt-24 lg:p-0">
      <div className="font-work-sans lg:ps-5">
        <Topbar title="Surveys" />
        {/* Top buttons */}
        <div className="flex sm:justify-end  items-center mt-3">
          <div className="flex items-center gap-3">
            <div className="flex justify-center items-center w-full sm:w-[170px] h-[40px] text-[14px] border border-[#0B1D2E] rounded-[100px]">
              <div className="relative inline-block text-left">
                {/* Button */}
                <button
                  type="button"
                  onClick={toggleDropdown} // Toggle dropdown on button click
                  className="inline-flex justify-between items-center w-full px-3 py-2 text-sm font-medium text-gray-700 focus:outline-none"
                >
                  {selectedOption} {/* Show selected option */}
                  <svg
                    className={`transform transtion-transform ml-0 sm:ml-3 ${
                      isOpen ? 'rotate-180' : 'rotate-0'
                    }`}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.28 9.9668L12.9333 14.3135C12.42 14.8268 11.58 14.8268 11.0666 14.3135L6.71997 9.9668"
                      stroke="#0B1D2E"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>

                {/* Dropdown */}
                {isOpen && (
                  <div className="absolute left-[-12px] z-10 mt-1 w-[170px] bg-white divide-y divide-gray-100 rounded-md ring-1 ring-[#E7EAE8] ring-opacity-1">
                    <ul className="py-1 text-sm text-gray-700">
                      <li>
                        <button
                          onClick={() => handleOptionClick('Date Created')} // Handle option click
                          className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                        >
                          Date Created
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleOptionClick('Last Updated')} // Handle option click
                          className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                        >
                          Last Updated
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleOptionClick('Alphabetical')} // Handle option click
                          className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                        >
                          Alphabetical
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-row relative justify-center items-center w-full sm:w-[195px] h-[40px] bg-[#4A57DA] text-[16px] rounded-[100px] text-[#F4F4F4]">
              <button className="flex flex-row items-center" type="button" onClick={togglePopUp}>
                Create Survey
              </button>
              <AddSurveyPopup
                open={isPopupOpen}
                setOpen={togglePopUp}
                popupOption={popupOption}
                path1={getPathForSurvey('/pulse-survey/add-edit-survey')}
                path2={getPathForSurvey('/pulse-survey/add-edit-survey')}
                handlePopuopOptionClick={(option) => handlePopuopOptionClick(option)}
                firstTitle={' Start from scratch'}
                firstSubtitle={'Begin with a blank survey of form, then add your questions'}
                secondTitle={'Use a template'}
                secondSubTitle={'Save time with a template built for your situation.'}
              />
            </div>
          </div>
        </div>

        {/* Slide */}
        <div className="flex sm:flex-row flex-col h-auto gap-4 mt-5">
            {dashborStatusCardList.map((item, index) => (
              <React.Fragment key={index}>
                {item}
              </React.Fragment>
            ))}
        </div>
        {/* My Recent Surveys */}
        <div className="flex flex-col h-fit bg-[#FFFFFF] border-[1.5px] border-[#E7E8EA] rounded-2xl my-5 lg:p-6 p-3 pb-8">
          <div className="flex flex-row justify-between mb-8 items-center">
            <p className="lg:text-[24px] text-[18px] text-shoorah-lightBlack leading-9 font-medium">
              My Recent Surveys
            </p>
            <Link
              to={getPathForSurvey('/pulse-survey/my-survey?type=2')}
              className="lg:text-[14px] text-[10px] leading-9 font-medium text-[#4A57DA]"
            >
              View All
            </Link>
          </div>
          <div className="h-fit gap-4 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 bg-[#FFFFFF] border-[#E7E8EA] rounded-2xl">
            {survey?.slice(0, 6)?.map((item, index) => (
              <SurveyCrad
                key={index}
                item={item}
                handleQuestionAction={(id) => handleQuestionAction(id)}
                Id={Id}
                handleDeletePulseSurve={(deleteId) => handleDeletePulseSurve(deleteId, 'survey')}
                handleDeactivatePulseSurvey={(deactivateId) =>
                  handleDeactivatePulseSurvey(deactivateId, 'survey')
                }
              />
            ))}
          </div>
        </div>
        {/* My Template */}
        <div className="flex flex-col h-full bg-[#FFFFFF] border-[1.5px] border-[#E7E8EA] rounded-2xl mb-5 mt-5 lg:p-6 p-3 pb-8">
          <div className="flex flex-row justify-between mb-8 items-center">
            <p className="lg:text-[24px] text-[18px] text-shoorah-lightBlack leading-9 font-medium">
              Manage Templates
            </p>
            <Link
              to={getPathForSurvey('/pulse-survey/my-survey?type=1')}
              className="lg:text-[14px] text-[10px] leading-9 font-medium text-[#4A57DA]"
            >
              View All
            </Link>
          </div>
          <div className="md:overflow-y-scroll hide-scrollbar h-fit gap-4 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 bg-[#FFFFFF] border-[#E7E8EA] rounded-2xl">
            {templateData?.slice(0, 6)?.map((item, index) => (
              <SurveyCrad
                key={index}
                item={item}
                handleQuestionAction={(id) => handleQuestionAction(id)}
                Id={Id}
                handleDeletePulseSurve={(deleteId) => handleDeletePulseSurve(deleteId, 'template')}
                isAdmin={isAdmin}
              />
            ))}
            {/* {templateData.map((item, index) => (
              <div
                className="w-full bg-[#FFFFFF] border-[1.5px] border-[#E7E8EA] rounded-2xl"
                key={index}
              >
                <div>
                  <div className="bg-[#F4F4F4]">
                    <img src={item.img} alt="survey" className="object-cover w-full h-[160px]" />
                  </div>
                  <div className="h-fit flex flex-col lg:gap-4 gap-2 lg:px-4 px-3 lg:py-6 py-4">
                    <p className="lg:text-[20px] text-[16px] text-shoorah-lightBlack font-bold leading-6">
                      {item.title}
                    </p>
                    <div className="flex">
                      <p className="lg:text-[16px] text-[14px] text-shoorah-lightBlack font-normal leading-5">
                        {item.desc}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))} */}
          </div>

          {/* admin chart */}
        </div>
          {isSuperAndSubAdmin() &&<div className="flex flex-col h-full mb-10 mt-5">
              <AdminDashboardInsight resultData={resultData} />
          </div>}
      </div>
    </div>
  );
};
export default PulseSurveyDashboard;
