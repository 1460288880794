import React, { useState, useEffect, useContext } from 'react';
import { getPathForSurvey } from '../../utils/helper';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
// import Insights from '../Peap/Insight/insights';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const InsightsB2bSurvey = () => {
  const dataD = {
    labels: ['Responses', 'No Response'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [1135, 365],
        backgroundColor: ['#ec4899', '#f9a8d4'],
        hoverBackgroundColor: ['#db2777', '#f472b6'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };

  // Options for the Donut chart
  const optionsD = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: '90%',
    radius: '70%'
  };
  const optionsD1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: '90%',
    radius: '70%'
  };

  const dataD1 = {
    labels: ['Responses', 'No Response'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [25, 35],
        backgroundColor: ['#D97F56', '#ECC09E'],
        hoverBackgroundColor: ['#D97F56', '#ECC09E'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };

  // Options for the Donut chart
  const optionsD2 = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: '90%',
    radius: '70%'
  };

  const dataD2 = {
    labels: ['Responses', 'No Response'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [25, 25],
        backgroundColor: ['#67A14A', '#C2EC97'],
        hoverBackgroundColor: ['#67A14A', '#C2EC97'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };

  const optionsD3 = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: '90%',
    radius: '70%'
  };

  const dataD3 = {
    labels: ['Responses', 'No Response'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [65, 35],
        backgroundColor: ['#F05289', '#FF9BCB'],
        hoverBackgroundColor: ['#F05289', '#FF9BCB'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };

  const optionsD4 = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: '90%',
    radius: '70%'
  };

  const dataD4 = {
    labels: ['Responses', 'No Response'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [65, 35],
        backgroundColor: ['#21BDAD', '#6BE0BD'],
        hoverBackgroundColor: ['#21BDAD', '#6BE0BD'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };
  const optionsD5 = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: '90%',
    radius: '70%'
  };

  const dataD5 = {
    labels: ['Responses', 'No Response'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [45, 75],
        backgroundColor: ['#EDCC12', '#FFE873'],
        hoverBackgroundColor: ['#EDCC12', '#FFE873'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };

  // Options for the Donut chart

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Survey',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: 'rgba(74, 87, 218, 1)',
        borderColor: 'rgba(74, 87, 218, 1)',
        borderWidth: 1
      }
    ]
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: 'Survey Data'
      }
    }
  };
  const [isOpen, setIsOpen] = useState(false); // Manage dropdown open/close state
  const [isOpenNew, setIsOpenNew] = useState(false); // Manage dropdown open/close state

  const [selectedOption, setSelectedOption] = useState('Date Created'); // Manage selected option

  const [selectedOptionNew, setSelectedOptionNew] = useState('New Assessment'); // Manage selected option
  const [subscribeDetailsData, setSubscribeDetailsData] = useState([]);
  const navigate = useNavigate();

  const total = 1200;
  const responses = 1135;
  const noResponse = 365;
  const percentage = (responses / total) * 100;

  const optionArray = (flag) => {
    let chartOptions = {
      chart: {
        type: 'donut'
      },
      dataLabels: {
        enabled: false
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            }
          },
          dataLabels: {
            enabled: false
          }
        }
      ],
      colors:
        flag === 1
          ? ['#D97F56', '#ECC09E']
          : flag === 2
          ? ['#67A14A', '#C2EC97']
          : flag === 3
          ? ['#F05289', '#FF9BCB']
          : flag === 4
          ? ['#21BDAD', '#6BE0BD']
          : flag === 5
          ? ['#F7E895', '#FFE873']
          : ['#ffffff', '#ffffff'],
      labels: flag === 1 ? ['Subscribed', 'Unsubscribed'] : ['Playing Now', 'Playing Past'],
      toolTip: {
        style: {
          fontSize: '20px'
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '88',
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: 'Total',
                color: '#808080'
              },
              value: {
                show: true,
                color: '#000000'
                // formatter: function () {
                //   let checkInsideData =
                //     flag === 1
                //       ? insideData.meditationIn
                //       : flag === 2
                //       ? insideData.soundIn
                //       : flag === 3
                //       ? insideData.ritualIn
                //       : flag === 4
                //       ? insideData.affirmationIn
                //       : flag === 5
                //       ? insideData.podIn
                //       : 0;
                //   const sum = checkInsideData.reduce(
                //     (partialSum, a) => partialSum + a,
                //     0
                //   );
                //   return sum;
                // },
              }
            }
          }
        }
      }
    };
    return chartOptions;
  };

  // Function to toggle dropdown
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev); // Toggle isOpen state
  };
  const toggleDropdownNew = () => {
    setIsOpenNew((prev) => !prev); // Toggle isOpen state
  };

  // Function to handle option click
  const handleOptionClick = (option) => {
    setSelectedOption(option); // Set the clicked option as selected
    setIsOpen(false); // Close the dropdown
  };
  const handleOptionClickNew = (option) => {
    setSelectedOptionNew(option); // Set the clicked option as selected
    setIsOpenNew(false); // Close the dropdown
  };
  const ProgressBar = ({ label, percentage }) => (
    <div className="mb-4">
      <div className="flex justify-between items-center mb-1">
        <span className="text-lg font-normal">{label}</span>
        <span className="text-sm text-green-600">{percentage}%</span>
      </div>
      <div className="w-full bg-green-100 rounded-full h-2">
        <div className="bg-green-500 h-2 rounded-full" style={{ width: `${percentage}%` }}></div>
      </div>
    </div>
  );

  return (
    <div className=" mx-auto max-w-[1640px]">
      {/* <div className=" w-full flex justify-end sm:hidden relative top-[-32px] left-[-10px]">
        <div className=" flex justify-center items-center p-5 w-[9px]  h-[9px] rounded-full shadow-md  bg-shoorah-secondary">
          <Link to={getPathForSurvey('/pulse-survey/add-edit-survey')} className=" text-white">
            +
          </Link>
        </div>
      </div> */}
      <div className="ml-5 mr-4 mt-[40px] font-serif">
        {/* Top buttons */}
        <div className="flex flex-row gap-3 sm:justify-end items-center mt-[60px] sm:m-0">
          <div className="flex justify-center items-center w-full sm:w-[170px] h-[40px] text-[14px] border border-[#0B1D2E] rounded-[100px] ">
            <div className="relative inline-block text-left">
              {/* Button */}
              <button
                type="button"
                le
                dropdown
                on
                button
                click
                className="inline-flex justify-between items-center w-full px-3 py-2 text-sm font-medium text-gray-700  focus:outline-none"
              >
                <Link
                  to={getPathForSurvey('/pulse-survey/add-edit-survey')}
                  //   onClick={() => setShowPage('preview')}
                  className="text-[#0B1D2E]"
                >
                  Preview Survey
                </Link>
              </button>
            </div>
          </div>
        </div>
        {/* Slide */}
        <div className="flex sm:flex-row flex-col h-auto gap-5 mt-[40px]">
          <div className="w-full sm:w-3/12 md:w-full h-[96px] bg-[#FFFFFF] rounded-[8px] border-[1.5px] border-[#E7E8EA] flex items-center pl-3">
            <div className="flex flex-row items-center w-full ">
              <div className="flex justify-center w-1/4 items-center ">
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="56" height="56" rx="28" fill="#C2EC97" />
                  <path
                    d="M28 38C33.5 38 38 33.5 38 28C38 22.5 33.5 18 28 18C22.5 18 18 22.5 18 28C18 33.5 22.5 38 28 38Z"
                    stroke="#0B1D2E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M23.75 27.9999L26.58 30.8299L32.25 25.1699"
                    stroke="#0B1D2E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className=" flex flex-col w-3/4 text-start ml-2">
                <p className="text-[14px] tracking-tight">Total Responses</p>
                <p className="text-[24px] font-medium">00</p>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-3/12    md:w-full h-[96px] bg-[#FFFFFF]  rounded-[8px] border-[1.5px] flex items-center pl-3   border-[#E7E8EA]">
            <div className="flex flex-row items-center w-full">
              <div className="flex justify-center w-1/4 items-center">
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="56" height="56" rx="28" fill="#F05289" />
                  <path
                    d="M27.97 38C33.4928 38 37.97 33.5228 37.97 28C37.97 22.4772 33.4928 18 27.97 18C22.4471 18 17.97 22.4772 17.97 28C17.97 33.5228 22.4471 38 27.97 38Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M23.21 32C24.26 33.51 26.02 34.5 28 34.5C29.98 34.5 31.73 33.51 32.79 32"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className=" flex flex-col w-3/4 text-start ml-2">
                <p className="text-[14px] tracking-tight">Completion rate</p>
                <p className="text-[24px] font-medium">00</p>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-3/12   md:w-full h-[96px] bg-[#FFFFFF]  rounded-[8px] border-[1.5px]  flex  items-center pl-3 border-[#E7E8EA]">
            <div className="flex flex-row items-center w-full ">
              <div className="flex justify-center w-1/4 items-center ">
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="56" height="56" rx="28" fill="#EDCC12" />
                  <path
                    d="M36.75 29.25C36.75 34.08 32.83 38 28 38C23.17 38 19.25 34.08 19.25 29.25C19.25 24.42 23.17 20.5 28 20.5C32.83 20.5 36.75 24.42 36.75 29.25Z"
                    stroke="#0B1D2E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M28 24V29"
                    stroke="#0B1D2E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M25 18H31"
                    stroke="#0B1D2E"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className=" flex flex-col w-3/4 text-start ml-2">
                <p className="text-[14px] tracking-tight"> Time Spent</p>
                <p className="text-[24px] font-medium">00</p>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-3/12   md:w-full  h-[96px] bg-[#FFFFFF]  rounded-[8px] border-[1.5px]  flex items-center pl-3  border-[#E7E8EA]">
            <div className="flex flex-row items-center w-full ">
              <div className="flex justify-center w-1/4 items-center ">
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="56" height="56" rx="28" fill="#20BDAD" />
                  <path
                    d="M34.32 28.0002C36.92 28.0002 38 27.0002 37.04 23.7202C36.39 21.5102 34.49 19.6102 32.28 18.9602C29 18.0002 28 19.0802 28 21.6802V24.5602C28 27.0002 29 28.0002 31 28.0002H34.32Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M35.9999 30.6998C35.0699 35.3298 30.6299 38.6898 25.5799 37.8698C21.7899 37.2598 18.7399 34.2098 18.1199 30.4198C17.3099 25.3898 20.6499 20.9498 25.2599 20.0098"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className=" flex flex-col w-3/4 text-start ml-2">
                <p className="text-[14px] tracking-tight"> Skipped Questions</p>
                <p className="text-[24px] font-medium">00</p>
              </div>
            </div>
          </div>
        </div>
        {/* My Template */}
        {/* <div className=" flex flex-col h-fit  bg-[#FFFFFF] border-[1.5px] border-[#E7E8EA] rounded-[16px]  mt-5 mb-4">
          <div className="h-[60px] flex flex-row  justify-between mb-[18px] mt-[15px] mx-8 items-center ">
            <p className="text-[24px] text-[#0B1D2E]">Manage Assessments</p>
            <button
              className="text-[14px] text-[#4A57DA]"
              onClick={() => navigate('/self-assessment/view-all-assessment')}
            >
              View All
            </button>
          </div>
          <div className=" flex flex-col sm:flex-row h-fit  overflow-x-auto  gap-5 justify-evenly bg-[#FFFFFF]  border-[#E7E8EA] rounded-[18px]  ml-6 mr-6  mb-8">
            <div className="max-w-md bg-white border border-gray-300 rounded-lg shadow-md overflow-hidden flex flex-col md:flex-row">
              <div className="w-full md:w-3/5 p-4">
                <div className="h-48 md:h-full overflow-hidden rounded-lg">
                  <img src={assessmentImg} alt="Sample" className="w-full h-full object-cover" />
                </div>
              </div>
              <div className="flex-grow p-4 relative flex flex-col" style={{ paddingTop: '40px' }}>
                <div className="absolute top-2 right-2">
                  <button>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <p className="text-[20px] text-[#0B1D2E] font-bold">Social Anxiety Assessment</p>
                <p className="text-[16px] text-[#0B1D2E] font-medium">Used 1,345 times</p>
                <button className="bg-[#4A57DA] text-white rounded px-2 py-1 hover:bg-[#3b46c9] mt-2 self-start">
                  Anxiety
                </button>
              </div>
            </div>
            <div className="max-w-md bg-white border border-gray-300 rounded-lg shadow-md overflow-hidden flex flex-col md:flex-row">
              <div className="w-full md:w-3/5 p-4">
                <div className="h-48 md:h-full overflow-hidden rounded-lg">
                  <img src={assessmentImg} alt="Sample" className="w-full h-full object-cover" />
                </div>
              </div>
              <div className="flex-grow p-4 relative flex flex-col" style={{ paddingTop: '40px' }}>
                <div className="absolute top-2 right-2">
                  <button>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <p className="text-[20px] text-[#0B1D2E] font-bold">Social Anxiety Assessment</p>
                <p className="text-[16px] text-[#0B1D2E] font-medium">Used 1,345 times</p>
                <button className="bg-[#4A57DA] text-white rounded px-2 py-1 hover:bg-[#3b46c9] mt-2 self-start">
                  Anxiety
                </button>
              </div>
            </div>
            <div className="max-w-md bg-white border border-gray-300 rounded-lg shadow-md overflow-hidden flex flex-col md:flex-row">
              <div className="w-full md:w-3/5 p-4">
                <div className="h-48 md:h-full overflow-hidden rounded-lg">
                  <img src={assessmentImg} alt="Sample" className="w-full h-full object-cover" />
                </div>
              </div>
              <div className="flex-grow p-4 relative flex flex-col" style={{ paddingTop: '40px' }}>
                <div className="absolute top-2 right-2">
                  <button>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <p className="text-[20px] text-[#0B1D2E] font-bold">Social Anxiety Assessment</p>
                <p className="text-[16px] text-[#0B1D2E] font-medium">Used 1,345 times</p>
                <button className="bg-[#4A57DA] text-white rounded px-2 py-1 hover:bg-[#3b46c9] mt-2 self-start">
                  Anxiety
                </button>
              </div>
            </div>
            <div className="max-w-md bg-white border border-gray-300 rounded-lg shadow-md overflow-hidden flex flex-col md:flex-row">
              <div className="w-full md:w-3/5 p-4">
                <div className="h-48 md:h-full overflow-hidden rounded-lg">
                  <img src={assessmentImg} alt="Sample" className="w-full h-full object-cover" />
                </div>
              </div>
              <div className="flex-grow p-4 relative flex flex-col" style={{ paddingTop: '40px' }}>
                <div className="absolute top-2 right-2">
                  <button>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <p className="text-[20px] text-[#0B1D2E] font-bold">Social Anxiety Assessment</p>
                <p className="text-[16px] text-[#0B1D2E] font-medium">Used 1,345 times</p>
                <button className="bg-[#4A57DA] text-white rounded px-2 py-1 hover:bg-[#3b46c9] mt-2 self-start">
                  Anxiety
                </button>
              </div>
            </div>

            <div className="max-w-md bg-white border border-gray-300 rounded-lg shadow-md overflow-hidden flex flex-col md:flex-row">
              <div className="w-full md:w-3/5 p-4">
                <div className="h-48 md:h-full overflow-hidden rounded-lg">
                  <img src={assessmentImg} alt="Sample" className="w-full h-full object-cover" />
                </div>
              </div>
              <div className="flex-grow p-4 relative flex flex-col" style={{ paddingTop: '40px' }}>
                <div className="absolute top-2 right-2">
                  <button>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <p className="text-[20px] text-[#0B1D2E] font-bold">Social Anxiety Assessment</p>
                <p className="text-[16px] text-[#0B1D2E] font-medium">Used 1,345 times</p>
                <button className="bg-[#4A57DA] text-white rounded px-2 py-1 hover:bg-[#3b46c9] mt-2 self-start">
                  Anxiety
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="p-4 ml-1 w-full h-fit mb-10  font-serif  ">
        <div className="flex flex-col sm:flex-row gap-4 w-full">
          <div className=" flex flex-col  lg:flex-row md:flex-col gap-10 w-full overflow-x-auto">
            <div className="md:flex md:flex-row gap-10">
              <div className=" px-3 py-6 w-full  mb-5 sm:mb-0  md:w-1/2 lg:w-[250px]  bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
                <div className="flex flex-row gap-2 px-1 justify-between">
                  <div>
                    <h2 className="text-[20px] tracking-tight flex items-start  font-medium text-[#0B0F18] text-left pl-[0px]  md:pl-[0px] ">
                      Complete Assesment
                    </h2>
                  </div>
                  <div className="flex items-center">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                <div className="relative flex max-h-[180px] justify-center items-center">
                  <div className="absolute text-center">
                    <p className="text-sm font-thin text-[#606060]">Total</p>
                    <p className="text-2xl text-[#0B0F18] ">25</p>
                  </div>
                  <Doughnut data={dataD1} options={optionsD1} />
                </div>
                <div className="flex flex-row  md:flex-row lg:flex-col  md:justify-between  md:gap-5 lg:gap-3 gap-8   lg:pl-5 lg:pr-10  md:pl-2 md:pr-2 ">
                  <div className="flex   items-center justify-left  ">
                    <span className="w-3 h-3 bg-[#D97F56] rounded-full mr-2"></span>
                    <p className=" flex flex-row items-center gap-2 text-[16px] text-[#606060] font-thin">
                      Complete: <p className="text-[#0B0F18] text-[20px]">1,135 </p>
                    </p>
                  </div>
                  <div className="flex items-center justify-left">
                    <span className="w-3 h-3 bg-[#ECC09E] rounded-full mr-2"></span>
                    <p className=" flex flex-row  items-center gap-2 text-[16px] text-[#606060] font-thin">
                      Incomplete: <p className="text-[#0B0F18] text-[20px]">365</p>
                    </p>
                  </div>
                </div>
              </div>
              <div className=" px-3 py-6 w-full  mb-5 sm:mb-0  md:w-1/2 lg:w-[250px]  bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
                <div className="flex flex-row gap-2  px-1 justify-between">
                  <div>
                    <h2 className="text-[20px] tracking-tight flex items-start  font-medium text-[#0B0F18] text-left pl-[0px]  md:pl-[0px] ">
                      User Age Range
                    </h2>
                  </div>
                  <div className="flex items-center">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                <div className="relative flex max-h-[180px] justify-center items-center">
                  <div className="absolute text-center">
                    <p className="text-sm font-thin text-[#606060]">Total</p>
                    <p className="text-2xl text-[#0B0F18] ">25</p>
                  </div>
                  <Doughnut data={dataD2} options={optionsD2} />
                </div>
                <div className="flex flex-row  md:flex-row lg:flex-col  md:justify-between  md:gap-5 lg:gap-3 gap-8   lg:pl-5 lg:pr-10  md:pl-2 md:pr-2 ">
                  <div className="flex   items-center justify-left  ">
                    <span className="w-3 h-3 bg-[#67A14A] rounded-full mr-2"></span>
                    <p className=" flex flex-row items-center gap-2 text-[16px] text-[#606060] font-thin">
                      Age 18-25: <p className="text-[#0B0F18] text-[20px] ">65% </p>
                    </p>
                  </div>
                  <div className="flex items-center justify-left">
                    <span className="w-3 h-3 bg-[#C2EC97] rounded-full mr-2"></span>
                    <p className=" flex flex-row items-center gap-2 text-[16px] text-[#606060] font-thin">
                      Age 26-32: <p className="text-[#0B0F18] text-[20px]">35%</p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex md:flex-row gap-10">
              <div className=" px-3 py-6 w-full  mb-5 sm:mb-0  md:w-1/2 lg:w-[250px]  bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
                <div className="flex flex-row gap-2 px-1 justify-between">
                  <div>
                    <h2 className="text-[20px] tracking-tight flex items-start  font-medium text-[#0B0F18] text-left pl-[0px]  md:pl-[0px] ">
                      Common Outcome
                    </h2>
                  </div>
                  <div className="flex items-center">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                <div className="relative flex max-h-[180px] justify-center items-center">
                  <div className="absolute text-center">
                    <p className="text-sm font-thin text-[#606060]">Total</p>
                    <p className="text-2xl text-[#0B0F18] ">25</p>
                  </div>
                  <Doughnut data={dataD4} options={optionsD4} />
                </div>
                <div className="flex flex-row  md:flex-row lg:flex-col  md:justify-between  md:gap-5 lg:gap-3 gap-8   lg:pl-5 lg:pr-10  md:pl-2 md:pr-2 ">
                  <div className="flex   items-center justify-left  ">
                    <span className="w-3 h-3 bg-[#21BDAD] rounded-full mr-2"></span>
                    <p className=" flex flex-row gap-2 items-center text-[16px] text-[#606060] font-thin">
                      Anxiety: <p className="text-[#0B0F18] text-[20px]">35% </p>
                    </p>
                  </div>
                  <div className="flex items-center justify-left">
                    <span className="w-3 h-3 bg-[#6BE0BD] rounded-full mr-2"></span>
                    <p className=" flex flex-row gap-2 items-center text-[16px] text-[#606060] font-thin">
                      Depression: <p className="text-[#0B0F18] text-[20px]">65%</p>
                    </p>
                  </div>
                </div>
              </div>
              <div className=" px-3 py-6 w-full  mb-5 sm:mb-0  md:w-1/2 lg:w-[250px]  bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
                <div className="flex flex-row gap-2 px-1 justify-between">
                  <div>
                    <h2 className="text-[20px] tracking-tight flex items-start  font-medium text-[#0B0F18] text-left pl-[0px]  md:pl-[0px] ">
                      Top Usage Times
                    </h2>
                  </div>
                  <div className="flex items-center">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                <div className="relative flex max-h-[180px] justify-center items-center">
                  <div className="absolute text-center">
                    <p className="text-sm font-thin text-[#606060]">Total</p>
                    <p className="text-2xl text-[#0B0F18] ">25</p>
                  </div>
                  <Doughnut data={dataD5} options={optionsD5} />
                </div>
                <div className="flex flex-row  md:flex-row lg:flex-col  md:justify-between  md:gap-5 lg:gap-3 gap-8   lg:pl-5 lg:pr-10  md:pl-2 md:pr-2 ">
                  <div className="flex   items-center justify-left  ">
                    <span className="w-3 h-3 bg-[#EDCC12] rounded-full mr-2"></span>
                    <p className=" flex flex-row gap-2 items-center text-[16px] text-[#606060] font-thin">
                      9-10pm: <p className="text-[#0B0F18] text-[20px]">65%</p>
                    </p>
                  </div>
                  <div className="flex items-center justify-left">
                    <span className="w-3 h-3 bg-[#FFE873] rounded-full mr-2"></span>
                    <p className=" flex flex-row gap-2 text-[16px] items-center text-[#606060] font-thin">
                      7-8am: <p className="text-[#0B0F18] text-[20px]">35%</p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex md:flex-row gap-10">
              <div className=" px-3 py-6 w-full  mb-5 sm:mb-0  md:w-1/2 lg:w-[250px]  bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
                <div className="flex flex-row gap-2 justify-between px-1">
                  <div>
                    <h2 className="text-[20px] tracking-tight flex items-start  font-medium text-[#0B0F18] text-left pl-[0px]  md:pl-[0px] ">
                      Average Scores
                    </h2>
                  </div>
                  <div className="flex items-center">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                <div className="relative flex max-h-[180px] justify-center items-center">
                  <div className="absolute text-center">
                    <p className="text-sm font-thin text-[#606060]">Total</p>
                    <p className="text-2xl text-[#0B0F18] ">25</p>
                  </div>
                  <Doughnut data={dataD} options={optionsD} />
                </div>
                <div className="flex flex-row  md:flex-row lg:flex-col  md:justify-between  md:gap-5 lg:gap-3 gap-8   lg:pl-5 lg:pr-10  md:pl-2 md:pr-2 ">
                  <div className="flex   items-center justify-left  ">
                    <span className="w-3 h-3 bg-pink-500 rounded-full mr-2"></span>
                    <p className=" flex flex-row gap-2 items-center text-[16px] text-[#606060] font-thin">
                      40-50%: <p className="text-[#0B0F18] text-[20px]">35% </p>
                    </p>
                  </div>
                  <div className="flex items-center justify-left">
                    <span className="w-3 h-3 bg-pink-300 rounded-full mr-2"></span>
                    <p className=" flex flex-row gap-2 text-[16px] text-[#606060] font-thin">
                      20-30%: <p className="text-[#0B0F18]  items-center text-[20px]">65%</p>
                    </p>
                  </div>
                </div>
              </div>
              <div className=" px-3 py-6 w-full  mb-5 sm:mb-0  md:w-1/2 lg:w-[250px]  bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
                <div className="flex flex-row gap-2 justify-between">
                  <div>
                    <h2 className="text-[20px] tracking-tight flex items-start  font-medium text-[#0B0F18] text-left pl-[0px] lg:pl-[10px] md:pl-[0px] ">
                      Complete Assesment
                    </h2>
                  </div>
                  <div className="flex items-center">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
                <div className="relative flex max-h-[180px] justify-center items-center">
                  <div className="absolute text-center">
                    <p className="text-2xl text-[#0B0F18] ">1,200</p>
                    <p className="text-sm font-thin text-[#606060]">Total</p>
                  </div>
                  <Doughnut data={dataD} options={optionsD} />
                </div>
                <div className="flex flex-row  md:flex-row lg:flex-col  md:justify-between  md:gap-5 lg:gap-3 gap-8   lg:pl-5 lg:pr-10  md:pl-2 md:pr-2 ">
                  <div className="flex   items-center justify-left  ">
                    <span className="w-3 h-3 bg-pink-500 rounded-full mr-2"></span>
                    <p className=" flex flex-row gap-2 text-[16px] text-[#606060] font-thin">
                      Responses: <p className="text-[#0B0F18]">1,135 </p>
                    </p>
                  </div>
                  <div className="flex items-center justify-left">
                    <span className="w-3 h-3 bg-pink-300 rounded-full mr-2"></span>
                    <p className=" flex flex-row gap-2 text-[16px] text-[#606060] font-thin">
                      No Response: <p className="text-[#0B0F18]">365</p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col  lg:flex-row md:flex-col gap-6 sm:w-full mt-[20px]">
          <div className=" flex flex-col justify-between w-full md:w-full  lg:max-w-[265px] h-[200px] md:h-[200px] lg:h-[344px] bg-white rounded-xl border-[1.5px] border-[#E7E8EA] p-5">
            <p className=" text-[20px] lg:text-[20px]  md:text-[25px] leading-tight h-[66px]  font-medium  w-[80%] justify-start text-[#0B0F18]">
              Percentage of incomplete Assessments
            </p>
            <p className=" text-[64px] text-[#0B0F18] font-serif">35 %</p>
          </div>
          <div className="  flex flex-col justify-between  w-full md:w-full  lg:max-w-[265px]  h-[344px] bg-white rounded-xl border-[1.5px] border-[#E7E8EA] p-5">
            <p className="text-[#0B0F18] text-[20px]   lg:text-[20px]  md:text-[25px]">
              Drop-off point
            </p>
            <div>
              <div className=" max-w-md lg:max-w-md  mx-auto p-6 ">
                <ProgressBar label="Intro" percentage={7} />
                <ProgressBar label="About" percentage={55} />
                <ProgressBar label="Questions" percentage={89} />
              </div>
            </div>
          </div>
          <div className=" h-[344px]  w-full  bg-white rounded-xl border-[1.5px] border-[#E7E8EA] p-4">
            <Bar
              data={data}
              options={{
                ...options,
                responsive: true,
                maintainAspectRatio: false
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default InsightsB2bSurvey;
