import React, { useEffect, useState } from 'react';
import { Api } from '../../../api';

const Specification = ({ data }) => {
  const [focuses, setFocuses] = useState([]);

  const getSpecialization = async () => {
    try {
      const res = await Api.getFocusNamesList(3);
      if (res?.data?.meta?.code === 1) {
        setFocuses(res.data.data);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  useEffect(() => {
    getSpecialization();
  }, []);

  const selectedSpecialisties =
    typeof data?.specialities === 'string'
      ? [data?.specialities?.toLowerCase()]
      : data?.specialities.map((item) => item?.toLowerCase());

  return (
    <div className="border-2 relative  w-full border-gray-300 rounded-3xl px-6 lg:px-10 pt-3 lg:pt-6 pb-0 lg:pb-0 bg-white dark:bg-shoorah-darkBgTabColor dark:border-none dark:text-white">
      <div className="text-black dark:text-white h-full text-lg font-medium w-full flex flex-col gap-[1rem] justify-between">
        <h4>Specialisations</h4>
        <p className="text-sm font-medium text-shoorah-gray2">
          Gain valuable insights into each specialist's unique skill sets, experience and areas of
          specialisation.
        </p>

        {/* custom-scrollbar  : Removed this*/}
        <div className="overflow-auto min-h-[200px] max-h-[520px] h-full w-full no-scrollbar">
          <div className="mt-4 flex flex-wrap justify-start gap-x-4 gap-y-3 pb-4 w-full">
            {focuses.map((focus, index) => (
              <p
                key={focus.id}
                className={`w-[80%] sm:w-[40%] text-center leading-none tracking-tighter cursor-pointer p-2  text-base border-gray-300 rounded-3xl border-2 text-gray-400
                ${index % 4 === 2 ? 'ml-14 ' : ''}  ${index % 4 === 1 ? 'mr-14' : ''} ${
                  selectedSpecialisties.includes(focus.focusName.toLowerCase())
                    ? 'bg-shoorah-primary text-white'
                    : ''
                }`}
              >
                {focus.focusName}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Specification;
