import { motion, AnimatePresence } from 'framer-motion';
import React, { lazy, memo, Suspense, useEffect, useContext, useRef } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import allRoutes from '../routes/RouteFile';
import b2bRoutes from './adminRoutes';
import partnerRoutes from './partnerRoutes';
import expertRoutes from './expertRoutes';

import Loader from '../component/common/Loader';
import PropTypes from 'prop-types';
import Sidebar from '../layout/Sidebar';
import useWindowDimensions, {
  cleanLocalStorage,
  getDeviceToken,
  getLocalStorageItem
} from '../utils/helper';
import { SidebarContext } from '../context/SidebarContext';
import RightSidebar from '../layout/RightSidebar';

import { useState } from 'react';
import PartnerSidebar from '../layout/PartnerSidebar';
import { Api } from '../api';
import ExpertSidebar from '../layout/expertSidebar';

const Login = lazy(() => import('../container/Login'));

const LoadSideBar = () => {
  const location = useLocation();
  const [slide, setSlide] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setSlide(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [ref]);

  return (
    <>
      {!['/login', '/reset-password', '/forgot-password']?.includes(location?.pathname) && (
        <div className="relative h-full">
          <button
            className={`w-[26px] lg:hidden h-[26px] rounded-full border-[1.5px] border-shoorah-borderGray dark:border-shoorah-darkBg flex items-center justify-center absolute top-[75px] z-[999] bg-white transition-transform duration-300 ease-in-out ${
              slide ? 'translate-x-[286px]' : 'translate-x-[67px]'
            }`}
            onClick={() => setSlide(!slide)}
          >
            {slide ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M1.19629 6.99902H12.8038"
                  stroke="black"
                  stroke-width="0.9996"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.99083 3.20508C3.47301 3.964 1.9552 5.48181 1.19629 6.99957C1.9552 8.51744 3.47301 10.0352 4.99083 10.7942"
                  stroke="black"
                  stroke-width="0.9996"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M12.8037 6.99902H1.19621"
                  stroke="black"
                  stroke-width="0.9996"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.00917 3.20508C10.527 3.964 12.0448 5.48181 12.8037 6.99957C12.0448 8.51744 10.527 10.0352 9.00917 10.7942"
                  stroke="black"
                  stroke-width="0.9996"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </button>
          <Sidebar slide={slide} />
        </div>
      )}
    </>
  );
};

const RoutesList = memo(() => {
  const { width } = useWindowDimensions();
  const { setShow } = useContext(SidebarContext);

  useEffect(() => {
    if (width < 1024) {
      setShow(false);
    }
  }, [width]);

  return (
    <Router>
      <ConditionalRoute />
    </Router>
  );
});

export const ConditionalRoute = () => {
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const userData = getLocalStorageItem('userData') && JSON.parse(getLocalStorageItem('userData'));
  const userType = userData?.userType;
  const [RoutesFile, setRoutesFile] = useState([]);

  useEffect(() => {
    if (window.location.pathname !== '/login')
      if (userData?.userType === 0 || userData?.userType === 1) {
        setRoutesFile(allRoutes);
      } else if (userData?.userType === 3 || userData?.userType === 4) {
        setRoutesFile(b2bRoutes);
      } else if (userData?.userType === 5) {
        setRoutesFile(partnerRoutes);
      } else if (userData?.userType === 6) {
        setRoutesFile(expertRoutes);
      } else {
        setRoutesFile(allRoutes);
      }

    if (userData?.userType === 2) {
      cleanLocalStorage();
      window.location.href = '/login';
    }
  }, [window.location.pathname]);

  const logoutUser = async () => {
    await Api.logoutUser().then((res) => {
      cleanLocalStorage();
      window.location.href = '/login';
    });
  };

  if (!['/login', '/reset-password', '/forgot-password']?.includes(window.location?.pathname)) {
    if (!getLocalStorageItem('token')) {
      if (getDeviceToken()) {
        logoutUser();
      } else {
        cleanLocalStorage();
        window.location.href = '/login';
      }
      return <></>;
    }
  }
  return (
    <>
      {!['/login', '/reset-password', '/forgot-password']?.includes(location?.pathname) ? (
        <>
          <div className="h-screen lg:p-[18px] flex overflow-hidden">
            <motion.div
              initial={{
                x: -100,
                opacity: 0
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  duration: 0.5
                }
              }}
              className="lg:col-span-1 pointer-events-none h-[90%] lg:h-[95vh] lg:relative fixed z-[39]"
            >
              <div
                className={`h-full ${
                  isDrawerOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'
                } transition-transform relative pointer-events-auto`}
              >
                <LoadSideBar />

                {isDrawerOpen && (
                  <div
                    onClick={() => {
                      setIsDrawerOpen(false);
                    }}
                    className="absolute w-8 h-8 p-1 bg-white shadow-md dark:bg-shoorah-darkBgTabColor dark:text-white rounded-full -right-10 top-6"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-full h-full"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                  </div>
                )}
              </div>
            </motion.div>
            <AnimatePresence exitBeforeEnter={true}>
              <div
                key={location?.pathname}
                className={`hide-scrollbar h-full lg:h-screen relative overflow-y-auto lg:p-0 ps-[97px] pt-[105px] pb-5 pr-4 ${
                  location?.pathname.includes('/dashboard') ||
                  location?.pathname.includes('/partner-dashboard')
                    ? 'lg:w-[calc(100%-500px)] w-full'
                    : 'w-full'
                }`}
              >
                {/* <Topbar /> */}
                <motion.div
                // initial={{
                //   y: 100,
                //   opacity: 0
                // }}
                // animate={{
                //   y: 0,
                //   opacity: 1,
                //   transition: {
                //     duration: 0.5
                //   }
                // }}
                >
                  <Suspense fallback={<Loader />}>
                    <Routes>
                      {RoutesFile.map((itm, key) =>
                        itm.private ? (
                          <Route
                            key={key}
                            exact={itm.exact}
                            path={itm.path}
                            name={itm.name}
                            Component={itm.component}
                          />
                        ) : (
                          <Route
                            key={key}
                            exact={itm.exact}
                            path={itm.path}
                            name={itm.name}
                            Component={itm.component}
                          />
                        )
                      )}
                      <Route exact path="/login" element={<Login />}>
                        {/* <Navigate to="/login" component={Login} /> */}
                      </Route>
                      <Route exact path="/" element={<Navigate to="/login" />}>
                        {/* <Navigate to="/login" component={Login} /> */}
                      </Route>
                      {/* <Route component={NotFound} /> */}
                    </Routes>
                  </Suspense>
                </motion.div>
              </div>
            </AnimatePresence>

            {location.pathname.includes('/dashboard') && (
              <motion.div
                initial={{
                  x: 100,
                  opacity: 0
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.5
                  }
                }}
                className="col-span-1 lg:block hidden absolute lg:relative right-4 top-5 lg:right-auto lg:top-auto lg:h-[95vh] w-[250px]"
              >
                <RightSidebar />
              </motion.div>
            )}

            {userType === 5 && (
              <motion.div
                initial={{
                  x: 100,
                  opacity: 0
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.5
                  }
                }}
                className="col-span-1 lg:block absolute lg:relative right-4 top-5 lg:right-auto lg:top-auto lg:h-[95vh] w-[250px]"
              >
                <PartnerSidebar />
              </motion.div>
            )}

            {userType === 6 && (
              <motion.div
                initial={{
                  x: 100,
                  opacity: 0
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.5
                  }
                }}
                className="col-span-1 lg:block absolute lg:relative right-4 top-5 lg:right-auto lg:top-auto lg:h-[95vh] w-[250px]"
              >
                <ExpertSidebar />
              </motion.div>
            )}
          </div>
        </>
      ) : (
        <>
          <LoadSideBar />
          <Suspense fallback={<Loader />}>
            <Routes>
              {RoutesFile.map((itm, key) =>
                itm.private ? (
                  <Route
                    key={key}
                    exact={itm.exact}
                    path={itm.path}
                    name={itm.name}
                    Component={itm.component}
                  />
                ) : (
                  <Route
                    key={key}
                    exact={itm.exact}
                    path={itm.path}
                    name={itm.name}
                    Component={itm.component}
                  />
                )
              )}
              <Route exact path="/login" element={<Login />}></Route>
              <Route exact path="/" element={<Navigate to="/login" />}></Route>
            </Routes>
          </Suspense>{' '}
        </>
      )}
    </>
  );
};

RoutesList.propTypes = {
  isShow: PropTypes.any,
  setShow: PropTypes.any
};

export default RoutesList;
