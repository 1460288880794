import React from 'react';
import Like from '../../../assets/images/SurveyThumbUp.svg';
import Select from 'react-select';

function SurveyApprovalModal({ adminList, handleChange, handleSendSurveyWithApproval }) {
  const superAdminListOptions = adminList.map((admin) => {
    return {
      value: admin?.id,
      label: admin?.name?.charAt(0)?.toUpperCase() + admin?.name?.slice(1)
    };
  });

  return (
    <div className="flex justify-center items-center z-50 fixed h-screen w-screen top-0 left-0 bg-black/15 px-10">
      <div className="h-fit w-[692px] rounded-[24px] px-24 py-16 bg-shoorah-normanGray border-[1.5px] border-shoorah-borderGray">
        <div className="flex justify-center">
          <img src={Like} alt="Like" />
        </div>
        <h1 className="text-2xl font-medium text-center mt-6">
          You're currently unable to share this survey
        </h1>
        <p className="text-base font-normal text-center mt-4">
          Send the survey to an admin to gain approval
        </p>
        <Select
          options={superAdminListOptions}
          className="mt-4 w-96 mx-auto"
          placeholder="Select Admin..."
          onChange={(e) => handleChange(e)}
        />
        <button
          className="bg-[#4A57DA] rounded-full px-10 py-3 text-white text-lg block mx-auto mt-8"
          onClick={() => handleSendSurveyWithApproval()}
        >
          My Surveys
        </button>
      </div>
    </div>
  );
}

export default SurveyApprovalModal;
