import React from 'react';
import Add from '../../../assets/images/SurveyBulkAdd.svg';

const BulkAnswerModal = ({
  bulkAnswerInput,
  handleGetBulkAnswerData,
  maxLength,
  handleSaveBulkAnswer
}) => {
  return (
    <div className="flex justify-center items-center z-50 fixed h-screen w-screen top-0 left-0 bg-black/15 px-10">
      <div className=" h-fit w-[702px] rounded-[24px] px-16 py-4 bg-shoorah-normanGray">
        <div className="text-4xl mt-[16px]">Add Answers in Bulk</div>
        <div className="text-shoorah-lightBlack text-[18px] font-thin mt-[10px] flex">
          Enter each answer choice on a seperate line
        </div>
        <div className="flex justify-center mt-[30px]">
          <div className="relative w-full">
            <div className="absolute left-4 top-4">
              <image src={Add} alt="Add" />
            </div>
            <textarea
              value={bulkAnswerInput}
              onChange={(e) => handleGetBulkAnswerData(e)}
              maxLength={maxLength}
              placeholder="Enter reason here..."
              className="w-full h-[240px] pt-3 pl-12 border rounded-2xl resize-none text-shoorah-lightBlack border-shoorah-borderGray focus:outline-none focus:ring-2 focus:ring-gray-200"
            />
            <div className="absolute bottom-2 right-4 py-5 px-6 text-gray-400 text-sm">
              *{maxLength} Character limit
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-[40px] mb-[10px] gap-6">
          <button
            className="text-black border border-black px-10  rounded-full p-3"
            onClick={() => handleSaveBulkAnswer('cancel')}
          >
            Cancel
          </button>
          <button
            className="border bg-shoorah-primary text-white border-shoorah-primary px-14 py-2 rounded-full p-5"
            onClick={() => handleSaveBulkAnswer('save')}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default BulkAnswerModal;
