import { Transition } from '@headlessui/react';
import React, { Fragment, useRef, useState } from 'react';
import { useOutsideClick } from '../../utils/helper';

function CommonPopover({ label, children, contentClassName, className, containerClassName }) {
  const wrapperRef = useRef();
  const [openPopover, setOpenPopover] = useState(false);

  useOutsideClick(wrapperRef, () => {
    if (openPopover) setOpenPopover((prevState) => !prevState);
  });

  return (
    <div className={`relative ${containerClassName}`}>
      <button
        className={`inline-flex items-center justify-center rounded-3xl border px-2 py-1 text-sm font-medium shadow-sm hover:shoorah-primary focus:outline-none ${className}`}
        onClick={() => setOpenPopover((state) => !state)}
      >
        {label}
      </button>
      <Transition
        show={openPopover}
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          ref={wrapperRef}
          className={`absolute p-2 right-0 z-50 mt-2 origin-top-right text-left rounded-md dark:bg-shoorah-darkBgTabColor dark:text-white bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none  ${contentClassName}`}
        >
          {children}
        </div>
      </Transition>
    </div>
  );
}

export default CommonPopover;
