import { Api } from '../../api';
// import Pagination from '../../component/common/Pagination/Pagination';
import {
  errorToast,
  useOutsideClick,
  getFilterKey,
  isSuperAdmin,
  isCopanySuperAdmin,
  contentApprovalStatusColors
} from '../../utils/helper';

import Table from '../../component/common/Table';
import Breadcrumb from '../../component/common/Breadcrumb';
import { Fragment } from 'react';
import { useRef, useState, useEffect } from 'react';

import SearchInput from '../../component/common/Input/SearchInput';
import { Helmet } from 'react-helmet';
import SelectMenu from '../../component/common/SelectMenu';
import {
  APPROVAL_STATUS_DROPDOWN,
  CONTENT_APPROVAL_STATUS,
  CONTENT_TYPE,
  PER_PAGE
} from '../../utils/constants';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { Transition } from '@headlessui/react';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { CompanyApi } from '../../api/companyApi';
import moment from 'moment';
import CommonPopover from '../../component/common/popover';
import { Link, useNavigate } from 'react-router-dom';
import ConfirmPopup from '../../component/common/modals/ConfirmPopup';
import RejectPopup from '../../component/common/modals/RejectPopup';
import { Pagination } from '../../component/common/Paginate';
import Loader from '../../component/common/Loader';
import Topbar from '../PulseSurvey/Topbar';

const pages = [{ name: 'Content Approval', href: '/content-approval', current: true }];

const columns = [
  {
    title: 'Name',
    key: 'displayName',
    type: 'text',
    align: 'left',
    extend: true,
    style: {
      opacity: 'opacity-100'
    }
  },
  {
    title: 'Content Area',
    key: 'contentType',
    type: 'contentType',
    align: 'left',
    extend: true,
    style: {
      opacity: 'opacity-70'
    }
  },
  {
    title: 'Expert Name',
    key: 'expertName',
    type: 'text',
    longText: false,
    align: 'left',
    extend: true,
    style: {
      opacity: 'opacity-100'
    }
  },
  {
    title: 'Date Created',
    key: 'createdOn',
    type: 'text',
    extend: true,
    align: 'left',
    style: {
      opacity: 'opacity-100'
    }
  },
  {
    title: 'Created By',
    key: 'createdBy',
    extend: true,
    type: 'text',
    align: 'left',
    style: {
      opacity: 'opacity-100'
    }
  },
  {
    title: 'Date Approved',
    key: 'dateApproved',
    extend: true,
    type: 'text',
    align: 'left',
    style: {
      opacity: 'opacity-100'
    }
  },
  {
    title: 'Approved By',
    key: 'approvedBy',
    extend: true,
    type: 'text',
    align: 'left',
    style: {
      opacity: 'opacity-100'
    }
  },
  {
    title: 'Status',
    extend: true,
    key: 'contentStatus',
    align: 'left',
    type: 'text',
    style: {
      opacity: 'opacity-100'
    }
  },
  {
    title: 'Actions',
    key: 'action',
    type: 'text',
    align: 'left',
    style: {
      opacity: 'opacity-100'
    }
  }
];

function ContentApproval() {
  const wrapperRef = useRef(null);
  const [loader, setLoader] = useState(true);
  const [contentList, setContentListList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortBy, setSortBy] = useState('');
  const [selectedPerPage, setSelectedPerPage] = useState(PER_PAGE[0]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterQuery, setFilterQuery] = useState('');
  const [adminNameList, setAdminNameList] = useState([]);
  const [subAdminNameList, setSubAdminNameList] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    contentType: '',
    contentStatus: '',
    updatedBy: '',
    createdBy: ''
  });
  const [selectedType, setSelectedType] = useState('');
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);

  const navigate = useNavigate();
  const userData = localStorage.getItem('userData') && JSON.parse(localStorage.getItem('userData'));

  const handlePagination = async (pageNumber, pageSize, searchKey, query, sortBy, sortOrder) => {
    setLoader(true);
    const fetchContentApproval = isSuperAdmin()
      ? Api.getContentApprovalList.bind(
          null,
          pageNumber,
          pageSize,
          searchKey,
          query,
          sortBy,
          sortOrder
        )
      : isCopanySuperAdmin()
      ? CompanyApi.getContentApprovalListB2b.bind(
          null,
          pageNumber,
          pageSize,
          searchKey,
          query,
          sortBy,
          sortOrder
        )
      : null;
    const response = await fetchContentApproval?.();
    if (response?.data?.meta?.code === 1) {
      setCurrentPage(pageNumber);
      setContentListList(response?.data?.data);
      setTotalCount(response?.data?.meta?.totalRecords);
      setLoader(false);
    } else if (response?.code === 401) {
      setLoader(false);
      errorToast(response?.message);
    } else if (response?.data?.meta?.code === 0) {
      setCurrentPage(1);
      setContentListList([]);
      setTotalCount(0);
      setLoader(false);
      errorToast(response?.data?.meta?.message);
    } else {
      setLoader(false);
    }
  };

  const getAdminList = () => {
    const promise = isSuperAdmin()
      ? Api.adminList()
      : isCopanySuperAdmin()
      ? CompanyApi.getB2BAllAdminList()
      : null;

    promise?.then((response) => {
      const superAdmins = response?.data?.data?.filter(
        (admin) => admin.user_type === (isSuperAdmin() ? 0 : isCopanySuperAdmin() ? 3 : '')
      );
      const subAdmins = response?.data?.data?.filter(
        (admin) => admin.user_type === (isSuperAdmin() ? 1 : isCopanySuperAdmin() ? 4 : '')
      );
      const superAdminsList = superAdmins.map((admin) => {
        return {
          value: admin.id,
          name: admin.name
        };
      });
      const subAdminsList = subAdmins.map((admin) => {
        return {
          value: admin.id,
          name: admin.name
        };
      });
      setAdminNameList([{ name: 'Select Admin', value: '' }, ...superAdminsList]);
      setSubAdminNameList([{ name: 'Select Sub Admin', value: '' }, ...subAdminsList]);
    });
  };

  const handleSortBy = (sortByValue) => {
    setSortBy(sortByValue);
    if (sortByValue === sortBy) {
      let tempSortOrder = sortOrder === 1 ? -1 : 1;
      setSortOrder(tempSortOrder);
      handlePagination(
        currentPage,
        selectedPerPage?.value,
        searchTerm,
        filterQuery,
        sortByValue,
        tempSortOrder
      );
    } else {
      setSortOrder(1);
      handlePagination(
        currentPage,
        selectedPerPage?.value,
        searchTerm,
        filterQuery,
        sortByValue,
        1
      );
    }
  };

  useEffect(() => {
    if (searchTerm) {
      let searchParam = searchTerm.trim();
      const delayDebounceFn = setTimeout(() => {
        handlePagination(1, selectedPerPage?.value, searchParam, filterQuery, sortBy, sortOrder);
      }, 800);
      return () => clearTimeout(delayDebounceFn);
    } else {
      handlePagination(1, 10, '', filterQuery, sortBy, sortOrder);
    }
  }, [searchTerm]);

  useEffect(() => {
    getAdminList();
  }, []);

  const handlePerPage = (perPage) => {
    setSelectedPerPage(perPage);
    handlePagination(1, perPage.value, searchTerm, filterQuery, sortBy, sortOrder);
  };

  useOutsideClick(wrapperRef, () => {
    if (showFilterModal) setShowFilterModal(!showFilterModal);
  });

  const filterHandler = (data, key) => {
    setFilterCriteria((prevData) => ({
      ...prevData,
      [key]: data
    }));
    let clone = JSON.parse(JSON.stringify(filterCriteria));
    const newData = { ...clone, [key]: data };
    let tempFilterQuery = [];
    Object.keys(newData).forEach(function (key) {
      if (newData[key]?.value?.toString()) {
        tempFilterQuery.push(`&${key}=${newData[key]?.value}`);
      }
    });
    let query = tempFilterQuery?.toString()?.replaceAll(',', '');
    setFilterQuery(query);
    handlePagination(1, selectedPerPage.value, searchTerm, query, sortBy, sortOrder);
  };

  const resetHandler = () => {
    setFilterCriteria({
      accountType: '',
      accountStatus: ''
    });
    setFilterQuery('');
    setShowFilterModal(false);
    handlePagination(1, selectedPerPage.value, searchTerm, '', sortBy, sortOrder);
  };

  const handleChangeType = (val) => {
    setSelectedType(val);
    const query = val === '' ? '' : `&contentStatus=${val}`;
    handlePagination(1, selectedPerPage.value, searchTerm, query, sortBy, sortOrder);
  };

  const handleStatusChange = (id, status) => {
    const content = {
      contentStatus: status,
      id: id
    };
    setSelectedContent(content);
    if (status === 1) {
      setOpenConfirmation(true);
    } else {
      setOpenReject(true);
    }
  };

  const handleClose = () => {
    setSelectedContent(null);
    setOpenConfirmation(false);
    setOpenReject(false);
  };

  const handleAcceptReject = (reason) => {
    const payload = !reason
      ? selectedContent
      : {
          ...selectedContent,
          comment: reason
        };
    Api.updateApproval(payload)
      .then((res) => {
        if (res?.data?.meta?.code === 1) {
          handlePagination(
            currentPage,
            selectedPerPage.value,
            searchTerm,
            filterQuery,
            sortBy,
            sortOrder
          );
          handleClose();
        }
      })
      .catch((err) => {
        console.log('Error: ', err);
        errorToast(err.message);
      });
  };

  const handleClickView = (content) => {
    let url =
      content.contentType === 12
        ? `/self-assessment/create-self-assessment?view=4&id=${content.content_type_id}&contentApproval=1`
        : '/content-approval/view';

    navigate(url, {
      state: { ...content, action: 'view' }
    });
  };

  const tableData = contentList.map((item) => {
    return {
      displayName: item?.displayName,
      contentType: item?.contentType,
      expertName: item.expertName,
      createdOn: moment(item?.createdOn).format('MMM d, YYYY'),
      createdBy: item?.createdBy?.name,
      dateApproved: item.updatedOn ? moment(item.updatedOn).format('MMM d, YYYY') : 'N/A',
      approvedBy: item.updatedBy?.name,
      contentStatus: (
        <div
          className={`px-2 py-1 text-xs w-[104px] rounded-full border text-center ${contentApprovalStatusColors(
            item.contentStatus
          )}`}
        >
          {item.contentStatus === 0 ? 'Draft' : item.contentStatus === 1 ? 'Approved' : 'Rejected'}
        </div>
      ),
      action: (
        <div>
          <CommonPopover
            label={
              <>
                <span>View</span>
                <ChevronDownIcon className="w-5 h-5" />
              </>
            }
            className={'w-[100px] gap-3'}
            contentClassName="w-[155px] p-5"
          >
            <ul
              className={` text-base space-y-2 ${
                item.contentStatus !== 2 && item.contentType !== 12 && 'border-b pb-3'
              }`}
            >
              <li>
                <button onClick={() => handleClickView(item)}>View Content</button>
              </li>
              {item.contentStatus !== 1 && item.contentType !== 12 && (
                <li>
                  <button onClick={() => handleStatusChange(item.id, 1)}>Approve</button>
                </li>
              )}
            </ul>
            {item.contentStatus !== 2 && item.contentType !== 12 && (
              <ul className="text-base pt-3 space-y-2">
                <li>
                  <button onClick={() => handleStatusChange(item.id, 2)}>Reject</button>
                </li>
              </ul>
            )}
          </CommonPopover>
        </div>
      )
    };
  });

  return (
    <div className="relative lg:ps-5">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Content Approval | Shoorah Admin</title>
      </Helmet>
      {/* <Breadcrumb pageList={pages} /> */}
      <Topbar title="Content Approval" />
      {loader && <Loader />}
      <div className="mt-6">
        <div className="flex flex-col gap-3 md:flex-row md:items-center justify-between">
          <div className="flex items-center h-full font-medium gap-6 text-shoorah-gray9 ">
            <button
              className={selectedType === '' ? 'text-black dark:text-white' : ''}
              onClick={() => handleChangeType('')}
            >
              All
            </button>
            <button
              className={selectedType === 0 ? 'text-black dark:text-white' : ''}
              onClick={() => handleChangeType(CONTENT_APPROVAL_STATUS[0].value)}
            >
              Awaiting Approval
            </button>
            <button
              className={selectedType === 1 ? 'text-black dark:text-white' : ''}
              onClick={() => handleChangeType(CONTENT_APPROVAL_STATUS[1].value)}
            >
              Approved
            </button>
          </div>
          <div className="flex items-center gap-2">
            <SearchInput
              id="searchKey"
              name="searchKey"
              type="text"
              ACCOUNT_TYPE
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by name"
            />
            <div className="ml-3 self-center">
              <button
                className="inline-flex items-center justify-center rounded-3xl border border-transparent bg-gradient-to-r from-shoorah-primary to-shoorah-secondary px-5 py-2 text-sm font-medium text-white shadow-sm hover:shoorah-primary focus:outline-none sm:w-auto"
                onClick={() => setShowFilterModal((state) => !state)}
              >
                <FunnelIcon className="text-white w-[18px] h-[20px] inline" />
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={showFilterModal}
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            ref={wrapperRef}
            className="absolute p-5 right-0 z-[2] mt-2 w-[100%] sm:w-[600px] lg:w-[700px] mx-auto origin-top-right rounded-md dark:bg-shoorah-darkBgTabColor dark:text-white bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none  "
          >
            <div className="flex items-center justify-between">
              <p className="font-medium">Filters</p>
              <div className="flex space-x-3">
                <button
                  onClick={resetHandler}
                  className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 dark:bg-shoorah-darkBgColor dark:text-white dark:hover:bg-shoorah-darkBgColor text-sm font-medium rounded-md"
                >
                  Reset Filter
                </button>
                <button
                  onClick={() => setShowFilterModal(false)}
                  className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 dark:bg-shoorah-darkBgColor dark:text-white dark:hover:bg-shoorah-darkBgColor text-sm font-medium rounded-md"
                >
                  Close
                </button>
              </div>
            </div>
            <div className="grid sm:grid-cols-2 gap-4 mt-4">
              <div>
                <SelectMenu
                  menuList={CONTENT_TYPE}
                  defaultSelected={filterCriteria.contentType}
                  label="Content type"
                  isRequired={false}
                  setSelectedMenu={(data) => filterHandler(data, 'contentType')}
                  disabled={filterCriteria.contentType === ''}
                />
              </div>
              <div>
                <SelectMenu
                  menuList={APPROVAL_STATUS_DROPDOWN}
                  label="Approval status"
                  isRequired={false}
                  defaultSelected={filterCriteria.contentStatus}
                  setSelectedMenu={(data) => filterHandler(data, 'contentStatus')}
                  disabled={filterCriteria.contentStatus === ''}
                />
              </div>
              <div>
                <SelectMenu
                  menuList={subAdminNameList}
                  defaultSelected={filterCriteria.createdBy}
                  label="Created by"
                  isRequired={false}
                  setSelectedMenu={(data) => filterHandler(data, 'createdBy')}
                />
              </div>
              <div>
                <SelectMenu
                  menuList={adminNameList}
                  defaultSelected={filterCriteria.updatedBy}
                  label="Updated By"
                  isRequired={false}
                  setSelectedMenu={(data) => filterHandler(data, 'updatedBy')}
                />
              </div>
            </div>
          </div>
        </Transition>
        {!Object.values(filterCriteria).every((x) => x === '') && (
          <div className="border py-1 px-2 mt-3">
            <div className="flex justify-between">
              <div className="self-center overflow-auto filter-container mr-2">
                <div className="flex">
                  {Object.keys(filterCriteria).map((keyName, i) => (
                    <span key={i}>
                      {filterCriteria?.[keyName]?.value?.toString() ? (
                        <div className="flex dark:bg-shoorah-darkBgTabColor dark:text-white bg-gray-200 px-2 py-1 mr-2 whitespace-nowrap">
                          <p className="text-[14px] object-contain m-0 whitespace-nowrap">
                            {getFilterKey(keyName === 'contentStatus' ? 'approvalStatus' : keyName)}
                          </p>
                          <p className="text-[14px] m-0 whitespace-nowrap">&nbsp;: &nbsp;</p>
                          <p className="text-[14px] m-0 whitespace-nowrap ">
                            {filterCriteria?.[keyName].name}
                          </p>
                          <p
                            className="border cursor-pointer m-0 ml-2 self-center whitespace-nowrap"
                            onClick={() => filterHandler('', keyName)}
                          >
                            <XMarkIcon className="w-[16px] border border-shoorah-primary text-white bg-shoorah-secondary" />
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                    </span>
                  ))}
                </div>
              </div>
              <div className="flex self-center justify-end w-[50px]">
                <button className="px-2 py-1 bg-shoorah-secondary" onClick={resetHandler}>
                  <XMarkIcon className="w-[18px] border-shoorah-primary text-white bg-shoorah-secondary" />
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="mt-4">
          <Table
            columns={columns}
            data={tableData}
            name={'content_approval'}
            bottomBorder={totalCount > selectedPerPage?.value}
            setSortBy={(sort) => handleSortBy(sort)}
            // loader={loader}
          />
        </div>
      </div>
      <div className="mt-6">
        {contentList.length > 0 && !loader ? (
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={selectedPerPage?.value}
            onPageChange={(page) =>
              handlePagination(
                page,
                selectedPerPage?.value,
                searchTerm,
                filterQuery,
                sortBy,
                sortOrder
              )
            }
          />
        ) : (
          // <Pagination
          //   currentPage={currentPage}
          //   totalCount={totalCount}
          //   pageSize={selectedPerPage?.value}
          //   onPageChange={(page) =>
          //     handlePagination(
          //       page,
          //       selectedPerPage?.value,
          //       searchTerm,
          //       filterQuery,
          //       sortBy,
          //       sortOrder
          //     )
          //   }
          // />
          <span />
        )}
      </div>
      <ConfirmPopup
        open={openConfirmation}
        setOpen={handleClose}
        message={'Are you sure you want to approve?'}
        setAccepted={() => handleAcceptReject()}
      />
      <RejectPopup
        message={'Are you sure you want to reject?'}
        open={openReject}
        onClose={handleClose}
        onSubmit={(reason) => handleAcceptReject(reason)}
      />
    </div>
  );
}

export default ContentApproval;

// import { useEffect, useState } from 'react';
// import Tablenew from '../../component/common/newTable';
// import ActionDropDownnew from '../../component/common/ActionDropdownnew';
// import StatusIndicator from '../../component/common/StatusIndicator';
// import SearchInput from '../../component/common/Input/SearchInput';
// import { all } from 'axios';

// const RejectionModal = ({ isOpen, onClose, onConfirm }) => {
//   const [reason, setReason] = useState('');
//   const [error, setError] = useState('');
//   const maxLength = 250;

//   if (!isOpen) return null;

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (!reason.trim()) {
//       setError('*Text box cannot be empty');
//       return;
//     }

//     setError('');
//     onConfirm(reason);
//     setReason('');
//   };

//   return (
//     <div className="fixed inset-0 bg-[#414141] bg-opacity-50 flex items-center justify-center z-50">
//       <div className="bg-[#F4F4F4] rounded-[24px] flex flex-col items-center p-6 w-full ml-5 mr-5 sm:ml-0 sm:mr-0 sm:w-[702px] h-[490px] shadow-xl">
//         <h3 className=" text-[28px] mt-[18px] sm:mt-0  sm:text-[32px] mb-[10px] text-[#0B1D2E] flex justify-center font-serif">
//           Rejection resoning
//         </h3>
//         <h2 className=" text-[14px] sm:text-[20px] text-[##0B1D2E] font-light flex justify-center font-serif mb-[20px]">
//           Enter a rejection reason to the creator
//         </h2>
//         <form onSubmit={handleSubmit} className="w-full flex flex-col items-center">
//           <div className="relative flex justify-center items-center w-full sm:w-[596px]">
//             <div className="absolute left-4 top-4">
//               <svg
//                 width="20"
//                 height="20"
//                 viewBox="0 0 20 20"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//                 className="opacity-50"
//               >
//                 <g clipPath="url(#clip0_302_19223)">
//                   <path
//                     d="M16.4384 7.76362C16.4027 6.95987 16.3344 6.16644 16.2547 5.38522C16.0529 3.40836 14.456 1.81088 12.4801 1.60011C11.3187 1.47622 10.1303 1.37729 8.92123 1.37729C7.71212 1.37729 6.52378 1.47622 5.36234 1.60011C3.38644 1.81088 1.78957 3.40836 1.58781 5.38522C1.47002 6.53927 1.37732 7.71996 1.37732 8.9212C1.37732 10.1224 1.47002 11.3031 1.58781 12.4572C1.78957 14.4341 3.38656 16.0315 5.36247 16.2423C6.32421 16.3449 7.3044 16.4303 8.29954 16.4567"
//                     stroke="#0B1D2E"
//                     strokeWidth="1.428"
//                     strokeLinecap="round"
//                   />
//                   <path
//                     d="M5.20227 6.73435V6.18918C5.20227 5.54864 5.65954 4.99484 6.29462 4.91143C8.05762 4.67983 9.77632 4.67983 11.5393 4.91143C12.1744 4.99484 12.6317 5.54864 12.6317 6.18918V6.73435"
//                     stroke="#0B1D2E"
//                     strokeWidth="1.428"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                   />
//                   <path
//                     d="M8.91711 4.73773V11.2177"
//                     stroke="#0B1D2E"
//                     strokeWidth="1.428"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                   />
//                   <path
//                     d="M15.4455 11.1897L11.6435 15.5036L11.1227 17.9533C11.0389 18.3475 11.4498 18.7204 11.8345 18.5995L14.2724 17.8331L18.1993 13.701C18.8516 13.0147 18.7378 11.8669 17.9482 11.1686C17.1773 10.4866 16.0568 10.4961 15.4455 11.1897Z"
//                     stroke="#0B1D2E"
//                     strokeWidth="1.428"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                   />
//                 </g>
//                 <defs>
//                   <clipPath id="clip0_302_19223">
//                     <rect width="20" height="20" fill="white" />
//                   </clipPath>
//                 </defs>
//               </svg>
//             </div>
//             <textarea
//               value={reason}
//               onChange={(e) => {
//                 setReason(e.target.value);
//                 setError('');
//               }}
//               maxLength={maxLength}
//               placeholder="Enter reason here..."
//               className="w-full h-[240px] pt-3 pl-12 rounded-2xl resize-none text-[#0B1D2E] border-[#E7E8EA] focus:outline-none focus:ring-2 focus:ring-gray-200"
//             />
//             <div className="absolute bottom-2 right-2 py-2 px-6 text-gray-400 text-sm">
//               *{maxLength} Character limit
//             </div>
//           </div>
//           {error && (
//             <p className="absolute top-[650px] text-[12px] font-medium font-serif text-[#FF0606] mt-2">
//               {error}
//             </p>
//           )}
//           <div className="flex justify-center space-x-3">
//             <button
//               type="submit"
//               className="w-[194px] h-[40px] bg-[#4A57DA] text-[#FFFFFF] rounded-3xl mt-[48px] hover:bg-red-700"
//             >
//               Submit
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// const Pagination = ({ currentPage, totalPages, onPageChange }) => {
//   return (
//     <div className="flex ml-10 mt-5 items-center rounded-[16px] align-middle w-[95%]  justify-center bg-[#FFFFFF] h-[90px] pl-5 pr-5 font-serif">
//       <div className="flex items-center w-full justify-between  gap-2 font-serif">
//         <div className="hidden sm:flex">
//           <button
//             onClick={() => onPageChange(currentPage - 1)}
//             disabled={currentPage === 1}
//             className={` w-[200px] h-[45px] border border-black rounded-full text-black bg-white hover:bg-gray-100 disabled:opacity-50 transition-all ${
//               currentPage === 1
//                 ? 'text-gray-400 cursor-not-allowed'
//                 : 'text-[#0B1D2E] hover:bg-gray-100'
//             }`}
//           >
//             Previous
//           </button>
//         </div>

//         <div className="flex gap-2">
//           <span className="flex items-center text-gray-500 text-[20px]">...</span>
//           {[...Array(totalPages)].map((_, index) => (
//             <button
//               key={index + 1}
//               onClick={() => onPageChange(index + 1)}
//               className={`px-[23px] py-[12px] rounded-full ${
//                 currentPage === index + 1
//                   ? 'bg-[#E5EAF9] mx-3 text-[20px] text-[#4A57DA] transition-all scale-110'
//                   : 'transition-all scale-95 text-[20px] text-[#0B1D2E]/50'
//               }`}
//             >
//               {index + 1}
//             </button>
//           ))}
//           <span className="flex items-center text-gray-500 text-[20px]">...</span>
//         </div>
//         <div className="hidden sm:flex">
//           <button
//             onClick={() => onPageChange(currentPage + 1)}
//             disabled={currentPage === totalPages}
//             className={`  w-[200px] h-[45px] bg-[#4A57DA] text-white rounded-full hover:bg-[#3A45B0] disabled:opacity-50 transition-all ${
//               currentPage === totalPages
//                 ? 'bg-[#4A57DA]/20 text-[#4A57DA] cursor-not-allowed text-[18px] transition-all'
//                 : 'text-gray-500 text-[18px] hover:bg-gray-200 hover:text-black transition-all '
//             }`}
//           >
//             Next
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// const ContentApproval = () => {
//   const headers = [
//     {
//       label: 'Name',
//       style: {
//         width: '200px',
//         paddingLeft: '40px'
//       }
//     },
//     {
//       label: 'Content Area',
//       style: { width: '150px' }
//     },
//     {
//       label: 'Expert Name',
//       style: {
//         width: '180px'
//         // textAlign: 'center'
//       }
//     },
//     {
//       label: 'Date Created',
//       style: { width: '120px' }
//     },
//     {
//       label: 'Created By',
//       style: { width: '150px' }
//     },
//     {
//       label: 'Approved By',
//       style: { width: '150px' }
//     },
//     {
//       label: 'Status',
//       style: { width: '100px' }
//     },
//     {
//       label: 'Actions',
//       style: { width: '100px' }
//     }
//   ];

//   const columnConfig = [
//     {
//       field: 'name',
//       type: 'text',
//       style: {
//         width: '400px',
//         paddingLeft: '40px'
//       }
//     },

//     { field: 'contentArea', type: 'light' },
//     { field: 'expertName', type: 'text' },
//     { field: 'dateCreated', type: 'date' },
//     { field: 'createdBy', type: 'text' },
//     { field: 'assignedTo', type: 'text' },
//     { field: 'status', type: 'status' },
//     { field: 'actions', type: 'action' }
//   ];

//   const initialData = [
//     {
//       name: ' Workload & wellbeing check',
//       contentArea: 'Survey A',
//       expertName: 'DR.MeA',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 1
//     },
//     {
//       name: 'Workload B',
//       contentArea: 'Survey template B',
//       expertName: 'DR.MeB',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 2
//     },
//     {
//       name: 'Workload C',
//       contentArea: 'Survey template C',
//       expertName: 'DR.MeC',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 3
//     },
//     {
//       name: 'Workload D',
//       contentArea: 'Survey template D',
//       expertName: 'DR.MeD',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 4
//     },
//     {
//       name: 'Workload E',
//       contentArea: 'Survey template E',
//       expertName: 'DR.MeE',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 5
//     },
//     {
//       name: 'Workload F',
//       contentArea: 'Survey template F',
//       expertName: 'DR.MeF',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 6
//     },
//     {
//       name: 'Workload G',
//       contentArea: 'Survey template G',
//       expertName: 'DR.MeG',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 7
//     },
//     {
//       name: 'Workload H',
//       contentArea: 'Survey template H',
//       expertName: 'DR.MeH',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 8
//     },
//     {
//       name: 'Workload I',
//       contentArea: 'Survey template I',
//       expertName: 'DR.MeI',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 9
//     },
//     {
//       name: 'Workload J',
//       contentArea: 'Survey template J',
//       expertName: 'DR.MeJ',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 10
//     },
//     {
//       name: 'Workload K',
//       contentArea: 'Survey template K',
//       expertName: 'DR.MeK',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 11
//     },
//     {
//       name: 'Workload L',
//       contentArea: 'Survey template L',
//       expertName: 'DR.MeL',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 12
//     },
//     {
//       name: 'Workload M',
//       contentArea: 'Survey template M',
//       expertName: 'DR.MeM',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 13
//     },
//     {
//       name: 'Workload N',
//       contentArea: 'Survey template N',
//       expertName: 'DR.MeN',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 14
//     },
//     {
//       name: 'Workload O',
//       contentArea: 'Survey template O',
//       expertName: 'DR.MeO',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 15
//     },
//     {
//       name: 'Workload P',
//       contentArea: 'Survey template P',
//       expertName: 'DR.MeP',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 16
//     },
//     {
//       name: 'Workload Q',
//       contentArea: 'Survey template Q',
//       expertName: 'DR.MeQ',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 17
//     },
//     {
//       name: 'Workload R',
//       contentArea: 'Survey template R',
//       expertName: 'DR.MeR',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 18
//     },
//     {
//       name: 'Workload S',
//       contentArea: 'Survey template S',
//       expertName: 'DR.MeS',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 19
//     },
//     {
//       name: 'Workload T',
//       contentArea: 'Survey template T',
//       expertName: 'DR.MeT',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 20
//     },
//     {
//       name: 'Workload U',
//       contentArea: 'Survey template U',
//       expertName: 'DR.MeU',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 21
//     },
//     {
//       name: 'Workload V',
//       contentArea: 'Survey template V',
//       expertName: 'DR.MeV',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 22
//     },
//     {
//       name: 'Workload W',
//       contentArea: 'Survey template W',
//       expertName: 'DR.MeW',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 23
//     },
//     {
//       name: 'Workload X',
//       contentArea: 'Survey template X',
//       expertName: 'DR.MeX',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 24
//     },
//     {
//       name: 'Workload Y',
//       contentArea: 'Survey template Y',
//       expertName: 'DR.MeY',
//       dateCreated: 'Oct 27, 2024',
//       createdBy: 'Reuben Hale',
//       assignedTo: 'Admin',
//       status: 'Awaiting',
//       id: 25
//     }
//   ];

//   const [filter, setFilter] = useState('all');
//   const [searchTerm, setSearchTerm] = useState('');
//   const [allContent, setAllContent] = useState();
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedItem, setSelectedItem] = useState(null);

//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 12;

//   useEffect(() => {
//     const savedData = JSON.parse(localStorage.getItem('userTableData'));
//     if (savedData) {
//       setAllContent(savedData);
//     } else {
//       setAllContent(initialData);
//       localStorage.setItem('userTableData', JSON.stringify(initialData));
//     }
//   }, []);

//   const handleStatusChange = (id, newStatus) => {
//     if (newStatus === 'rejected') {
//       setSelectedItem(id);
//       setIsModalOpen(true);
//       return;
//     }
//     updateStatus(id, newStatus);
//   };

//   const updateStatus = (id, newStatus, rejectionReason = '') => {
//     const savedData = JSON.parse(localStorage.getItem('userTableData'));
//     const updatedContent = savedData.map((row) =>
//       row.id === id ? { ...row, status: newStatus, rejectionReason: rejectionReason } : row
//     );

//     setAllContent(updatedContent);
//     localStorage.setItem('userTableData', JSON.stringify(updatedContent));
//   };

//   const handleRejectionConfirm = (reason) => {
//     updateStatus(selectedItem, 'rejected', reason);
//     setIsModalOpen(false);
//     setSelectedItem(null);
//   };

//   const filteredData = allContent?.filter((item) => {
//     const statusMatch =
//       filter === 'all' ||
//       (filter === 'approved' && item.status === 'approved') ||
//       (filter === 'rejected' && item.status === 'rejected');

//     if (!statusMatch) return false;
//     if (!searchTerm.trim()) return true;

//     const searchFields = [
//       item.name,
//       item.contentArea,
//       item.expertName,
//       item.dateCreated,
//       item.createdBy,
//       item.assignedTo
//     ];

//     const searchLower = searchTerm.toLowerCase();
//     return searchFields.some((field) => field?.toLowerCase().includes(searchLower));
//   });

//   const totalPages = Math.ceil((filteredData?.length || 0) / itemsPerPage);
//   const paginatedData = filteredData?.slice(
//     (currentPage - 1) * itemsPerPage,
//     currentPage * itemsPerPage
//   );

//   useEffect(() => {
//     setCurrentPage(1);
//   }, [filter, searchTerm]);

//   return (
//     <div>
//       <div className="flex flex-col sm:flex-row justify-between gap-3 mx-8">
//         <div className="flex  space-x-2 font-serif mt-[100px] gap-5  mb-2 sm:mb-4">
//           <button
//             onClick={() => setFilter('all')}
//             className={`btn text-[20px] ${filter === 'all' ? 'opacity-100' : 'opacity-20'}`}
//           >
//             All
//           </button>
//           <button
//             onClick={() => setFilter('rejected')}
//             className={`btn text-[20px] ${filter === 'rejected' ? 'opacity-100' : 'opacity-20'}`}
//           >
//             Awaiting Approved
//           </button>
//           <button
//             className={`btn text-[20px] ${filter === 'approved' ? 'opacity-100' : 'opacity-20'}`}
//             onClick={() => setFilter('approved')}
//           >
//             Approved
//           </button>
//         </div>

//         <div className="flex w-full  sm:w-fit h-fit mt-2 sm:mt-[100px]">
//           <SearchInput
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//             placeholder="Search"
//           />
//         </div>
//       </div>
//       <div
//         className="mt-4 rounded-xl border-[0.25px] border-[#E7E8EA] mx-3 ml-10 overflow-x-auto"
//         style={{
//           scrollbarWidth: 'thin',
//           scrollbarColor: '#4A57DA transparent',
//           WebkitOverflowScrolling: 'touch'
//         }}
//       >
//         <style jsx>{`
//           div::-webkit-scrollbar {
//             height: 9px;
//             opacity: 0;
//             transition: opacity 0.3s;
//           }
//           div::-webkit-scrollbar-track {
//             background: transparent;
//           }
//           div::-webkit-scrollbar-thumb {
//             background: #4a57da;
//             border-radius: 24px;
//           }
//           div:hover::-webkit-scrollbar {
//             opacity: 1;
//           }
//           div:not(:hover)::-webkit-scrollbar {
//             opacity: 0;
//           }
//         `}</style>
//         <div className=" min-w-[1700px]">
//           <Tablenew
//             headers={headers}
//             data={paginatedData}
//             columnConfig={columnConfig}
//             onStatusChange={handleStatusChange}
//           />
//         </div>
//       </div>

//       {filteredData?.length > 0 && (
//         <Pagination
//           currentPage={currentPage}
//           totalPages={totalPages}
//           onPageChange={setCurrentPage}
//         />
//       )}

//       <RejectionModal
//         isOpen={isModalOpen}
//         onClose={() => {
//           setIsModalOpen(false);
//           setSelectedItem(null);
//         }}
//         onConfirm={handleRejectionConfirm}
//       />
//     </div>
//   );
// };

// export default ContentApproval;
