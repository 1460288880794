import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const DonutChartCard = ({ title, isTotal = true, data, }) => {
  const dataD = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        label: 'Overall Responses',
        data: data.map((item) => item.value),
        backgroundColor: data.map((item) => item.color),
        hoverBackgroundColor: data.map((item) => item.color),
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };

  const optionsD = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: '90%',
    radius: '70%'
  };

  return (
    <div className="px-3 py-6 w-full sm:mb-0 md:w-1/2 lg:w-1/5 min-w-full sm:min-w-[250px] bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
      <div className="flex flex-row gap-2 px-1 justify-between">
        <div>
          <h2 className="text-[20px] tracking-tight flex items-start font-medium text-[#0B0F18] text-left pl-[0px] md:pl-[0px]">
            {title}
          </h2>
        </div>
        <div className="flex items-center">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
      <div className="relative flex max-h-[180px] justify-center items-center">
        {isTotal && (
          <div className="absolute text-center">
            <p className="text-sm text-[#606060]">Total</p>
            <p className="text-2xl text-[#0B0F18]">{data.map((item) => item.value).reduce((a, b) => a + b, 0)}</p>
          </div>
        )}
        <Doughnut data={dataD} options={optionsD} />
      </div>
      <div className="flex flex-col md:flex-col lg:flex-col md:justify-between md:gap-5 lg:gap-3 gap-4 lg:pl-5 lg:pr-10 md:pl-2 md:pr-2">
        {data.map((item, index) => (
          <div className="flex items-center justify-left" key={index}>
            <span className="w-3 h-3 rounded-full mr-2" style={{ backgroundColor: `${item.color}` }}></span>
            <p className=" flex flex-row items-center gap-2 text-[16px] text-[#606060]">
              {item.label}: <p className="text-[#0B0F18] text-[20px]">{item.percentage}%</p>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DonutChartCard;
