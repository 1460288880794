import React, { Fragment, useEffect, useRef, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SurveyContext } from '../../context/PreviewSurveyContext';
import { Calendar } from 'react-calendar';
import {
  errorToast,
  isSuperAdmin,
  MaxCharlimit,
  successToast,
  isSuperAndSubAdmin
} from '../../utils/helper';
import { Api } from '../../api';
import { CompanyApi } from '../../api/companyApi';
import assessmentImg from '../../assets/images/assessmentImg.svg';
import Chart from 'react-apexcharts';

const ViewAssessment = () => {
  const optionArray = (flag) => {
    let chartOptions = {
      chart: {
        type: 'donut'
      },
      dataLabels: {
        enabled: false
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            }
          },
          dataLabels: {
            enabled: false
          }
        }
      ],
      colors:
        flag === 1
          ? ['#D97F56', '#ECC09E']
          : flag === 2
          ? ['#67A14A', '#C2EC97']
          : flag === 3
          ? ['#F05289', '#FF9BCB']
          : flag === 4
          ? ['#21BDAD', '#6BE0BD']
          : flag === 5
          ? ['#F7E895', '#FFE873']
          : ['#ffffff', '#ffffff'],
      labels: flag === 1 ? ['Subscribed', 'Unsubscribed'] : ['Playing Now', 'Playing Past'],
      toolTip: {
        style: {
          fontSize: '20px'
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '88',
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: 'Total',
                color: '#808080'
              },
              value: {
                show: true,
                color: '#000000'
                // formatter: function () {
                //   let checkInsideData =
                //     flag === 1
                //       ? insideData.meditationIn
                //       : flag === 2
                //       ? insideData.soundIn
                //       : flag === 3
                //       ? insideData.ritualIn
                //       : flag === 4
                //       ? insideData.affirmationIn
                //       : flag === 5
                //       ? insideData.podIn
                //       : 0;
                //   const sum = checkInsideData.reduce(
                //     (partialSum, a) => partialSum + a,
                //     0
                //   );
                //   return sum;
                // },
              }
            }
          }
        }
      }
    };
    return chartOptions;
  };
  const { surveyMeta, setSurveyMeta } = useContext(SurveyContext);
  const navigate = useNavigate();
  const [error, setError] = useState({
    surveyTitle: '',
    surveyTime: ''
  });
  const [addUserPopUpOpen, setAddUserPopUpOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [bulkAnswer, setBulkAnswer] = useState();
  const [currentQuestion, setCurrentQuestion] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [openCategory, setOpenCategory] = useState(false);
  const [surveyType, setSurveyType] = useState('save');
  const [answers, setAnswers] = useState(['']);
  const [newCategory, setNewCategory] = useState({
    name: 'surveyCategory',
    value: '',
    disabled: true
  });
  const [fileData, setFileData] = useState({
    logo: null,
    image: null
  });
  const [form, setForm] = useState({
    surveyTitle: '',
    surveyTime: '',
    surveyCategory: ''
  });
  const [otherDetails, setOtherDetails] = useState({
    area: [],
    time: '',
    audience: [],
    duration: 0,
    template_category: 3
  });
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  //tempId, surveyFromTemplate, surveyId, preview, null to create new survey
  const tempId = searchParams.get('tempId');
  const type = searchParams.get('type');
  console.log('🚀 ~ AddEditPulseSurvey ~ type:', type);
  const surveyFromTemplate = searchParams.get('surveyFromTemplate');
  const surveyId = searchParams.get('id');
  const isPreview = searchParams.get('preview');
  const isDraft = searchParams.get('isDraft');
  // const templateSurveyId = searchParams.get('template');
  // const isTemplateEdit = searchParams.get('editTemplate');
  // const templateId  = searchParams.get('templateId');
  const [subscribeDetailsData, setSubscribeDetailsData] = useState([]);
  const logoFile = useRef(null);
  const [selectedLogo, setSelectedLogo] = useState('');
  const [previewLogo, setPreviewLogo] = useState('');
  const imageFile = useRef(null);
  const [selectedImage, setSelectedImage] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [saveType, setSaveType] = useState('');
  const [isLogoUpdated, setIsLogoUpdated] = useState(false);
  const [isImageUpdated, setIsImageUpdated] = useState(false);
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [image, setImage] = useState(null);
  //if there is no survey Id or template Id then it is a new survey
  const isEdit = Boolean(surveyId || tempId);
  const [loading, setLoading] = useState(isEdit);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [text, setText] = useState('');
  const [questionsList, setQuestionsList] = useState(
    isEdit
      ? []
      : [
          {
            id: new Date().getTime(),
            quid: '',
            question: '',
            question_error: false,
            question_type: 'mcq',
            options: [
              {
                id: new Date().getTime(),
                value: '',
                error: false,
                options_status: 0
              }
            ],
            isOtherOption: false,
            isNoneOfTheAbove: false,
            skipable: false
          }
        ]
  );

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const addAnswer = () => {
    setAnswers([...answers, '']); // Add a new empty answer field
  };

  const removeAnswer = () => {
    if (answers.length > 1) {
      // Ensure at least one field remains
      setAnswers(answers.slice(0, -1)); // Remove the last answer field
    }
  };

  const handleChangeUpdate = (index, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value; // Update the specific answer field
    setAnswers(updatedAnswers);
  };

  const handleBulkSave = () => {
    const newAnswers = text
      .split('\n')
      .map((answer) => answer.trim())
      .filter((answer) => answer !== '');
    setAnswers(newAnswers);
    setAddUserPopUpOpen(false);
    setText('');
  };

  const cancelBulkSave = () => {
    setAddUserPopUpOpen(false);
    setText('');
  };

  console.log('🚀 ~ AddEditPulseSurvey ~ questionsList:', questionsList);
  useEffect(() => {
    let getCategories;
    //load template data and add this to form
    if (tempId !== null) {
      setLoading(true);
      const apiResponse = isSuperAndSubAdmin() ? Api.getPulseSurveyById(tempId) : null;
      apiResponse
        .then((res) => res.data)
        .then((response) => onLoadDataToForm(response))
        .finally(() => setLoading(false));
    }
    //load template data and add this to form
    if (surveyId !== null) {
      setLoading(true);
      const apiResponse = isSuperAndSubAdmin()
        ? Api.getPulseSurveyById(surveyId)
        : CompanyApi.getPulseSurveyByIdB2b(surveyId);
      apiResponse
        .then((res) => res.data)
        .then((response) => onLoadDataToForm(response))
        .finally(() => setLoading(false));
    }
    //need to only fetch template data and add this to form without any id
    if (surveyFromTemplate !== null) {
      setLoading(true);
      const apiResponse = isSuperAndSubAdmin()
        ? Api.getPulseSurveyById(surveyFromTemplate)
            .then((res) => res.data)
            .then((response) => onLoadDataToForm(response))
            .finally(() => setLoading(false))
        : null;
    }

    //fetching Surveys categories
    if (userData?.userType === 0 || userData?.userType === 1) {
      getCategories = Api.getPulseSurveyCategories();
    } else if (userData?.userType === 3 || userData?.userType === 4) {
      getCategories = CompanyApi.getPulseSurveyCategoriesB2b();
    }
    getCategories.then((res) => setCategoryList(res?.data?.data));
  }, [surveyFromTemplate, surveyId, tempId, userData?.userType]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Save the image data
      };
      reader.readAsDataURL(file); // Read the file as a Data URL
    }
  };
  const total = 1200;
  const responses = 1135;
  const noResponse = 365;
  const percentage = (responses / total) * 100;

  const handleBackgroundImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setErrorMessage('File size must be less than 5 MB.');
        return;
      }
      setErrorMessage('');

      const reader = new FileReader();
      reader.onloadend = () => {
        setBackgroundImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (surveyMeta) {
      surveyMeta._id
        ? onLoadDataToForm({ data: surveyMeta })
        : onLoadDataToFromForPreview(surveyMeta);
    }
  }, [isPreview, surveyMeta]);

  // useEffect(() => {
  //   if (isEdit) {

  //   }
  // }, [isEdit, surveyId]);
  const onLoadDataToFromForPreview = (data) => {
    const { questionsList, type, surveyData, images, other } = data;
    const removeOptions = (questions) => {
      return questions.map((item) => {
        const options = item.options.filter(
          (opt) => opt.value !== 'other' && opt.value !== 'None of the above'
        );
        return { ...item, options };
      });
    };

    // Remove "other" and "none of the above" options from questionsList
    const updatedQuestionsList = removeOptions(questionsList);
    setQuestionsList(updatedQuestionsList);
    setOtherDetails(other);
    setForm(surveyData);
    setPreviewLogo(data?.previewLogo);
    setPreviewImage(data?.previewImage);
    setSurveyType(type);
    setSelectedLogo(data?.logo);
    setSelectedImage(data?.image);
  };

  const onLoadDataToForm = (response) => {
    const { data: element } = response || {};

    setOtherDetails({
      time: element?.notifyTime || '',
      area: element?.surveyArea || [],
      duration: element?.surveyDuration || 0,
      departments: element?.departments || [],
      template_category: element?.templateCategory || 'B2B'
    });

    setForm({
      surveyTitle: element?.surveyTitle || '',
      surveyCategory: element?.surveyCategory || ''
    });

    setPreviewLogo(element?.surveyLogo);
    setPreviewImage(element?.surveyImage);

    const initialQuestionsList = element?.questions?.map((question) => {
      const questionKey = 'question-id-' + new Date().getTime() + '-' + question?._id;
      const optionKey = questionKey + '-';
      let isOtherOption, isNoneOfTheAbove;
      const options = question?.options
        ?.map((option) => {
          return {
            error: false,
            value: option,
            options_status: '',
            id: optionKey + '-' + new Date().getTime() + '-' + option?._id
          };
        })
        .filter(Boolean);
      return {
        id: questionKey,
        quid: question?._id,
        question: question?.title,
        question_type: question?.question_type,
        options,
        isOtherOption,
        isNoneOfTheAbove,
        skipable: question?.skipable
      };
    });

    setQuestionsList(initialQuestionsList);
  };

  const handleValidation = (type = 'save') => {
    setSaveType(type);
    const isSurveyTitleValid = form?.surveyTitle?.trim() !== '';
    if (!isSurveyTitleValid) {
      setError((prevError) => ({ ...prevError, surveyTitle: 'Please Enter Pulse Survey Title' }));
    } else {
      setError((prevError) => ({ ...prevError, surveyTitle: '' }));
    }

    const questionsValidation = questionsList.map((item) => {
      item.question_error = item.question?.trim() === '';
      if (item.question_error) {
        return false;
      }
      item.options.forEach((opt) => {
        if (opt.value?.trim() === '') opt.error = true;
      });
      const optionsValid = item.options.every((opt) => opt.value?.trim() !== '');
      return optionsValid;
    });

    const isAllQuestionsValid = questionsValidation.every((res) => res === true);
    // Final validation check
    const isAllFieldOk = isSurveyTitleValid && isAllQuestionsValid;

    if (!isAllFieldOk) {
      errorToast('Please enter required details!');
      return;
    }
  };
  const determineStatus = (type) => {
    if (type === 'template') {
      return isSuperAdmin() ? 1 : 0;
    } else if (type === 'draft') {
      return 0;
    } else {
      return userData?.userType === 0 || userData?.userType === 3 ? 1 : 0;
    }
  };

  const saveAsDraft = () => {
    handleValidation('draft');
  };

  const handleSaveAsTemplate = () => {
    handleValidation('template');
  };

  const createSurveyButton = () => {
    handleValidation('save');
  };

  const handleCancelQuestions = (Id) => {
    if (questionsList?.length > 1) {
      setQuestionsList((prevList) => prevList.filter((_, index) => index !== Id));
    }
  };
  const handleBulkAnswer = () => {
    setOpen(false);

    if (bulkAnswer) {
      const lines = bulkAnswer
        ?.trim()
        .split('\n')
        .filter((line) => line.trim() !== '');

      // setBulkAnswer(lines);

      setQuestionsList((prevList) =>
        prevList?.map((question) =>
          question.id === currentQuestion.id
            ? {
                ...question,
                options: [
                  ...question.options,
                  ...lines.map((line, i) => ({
                    id: new Date().getTime() + i,
                    value: line?.trim(),
                    options_status: 0
                  }))
                ]
              }
            : question
        )
      );
    }
  };

  const onChangePositive = (toggle, itemId, id) => {
    const array = questionsList.map((item) => {
      if (item.id === itemId) {
        item.options = item.options.map((item) => {
          if (item.id === id) {
            item.positive = toggle;
          }
          return item;
        });
      }
      return item;
    });
    setQuestionsList(array);
  };
  const handleBulkModal = (e) => {
    setCurrentQuestion(e);
    setOpen(true);
  };
  const handleRemoveImg = (type) => {
    setFileData((prev) => ({
      ...prev,
      [type]: null
    }));
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;

    setNewCategory({
      ...newCategory,
      value: value,
      disabled: value?.trim() === ''
    });
  };

  const handleLogoImage = (e) => {
    if (e?.target?.files[0]?.size > 1048576) {
      errorToast(`File size should be less than 1MB`);
    } else {
      if (e?.target?.files[0]) {
        if (!e?.target?.files[0].name.match(/\.(jpg|jpeg|png|gif)$/i)) {
          errorToast(
            `The specified file ${e?.target?.files[0].name} could not be uploaded. Please upload valid image.`
          );
        } else {
          setSelectedLogo(e.target.files[0]);
          const objectUrl = URL.createObjectURL(e.target.files[0]);
          setPreviewLogo(objectUrl);
          if (surveyId) {
            setIsLogoUpdated(false);
          }

          return () => URL.revokeObjectURL(objectUrl);
        }
      } else {
        setPreviewLogo(undefined);
      }
    }
  };

  // const [selectedOption, setSelectedOption] = useState('Multiple Choice');

  // const toggleDropdown = () => setIsOpen(!isOpen);

  const [isOpen, setIsOpen] = useState(false);
  // const [selectedOption, setSelectedOption] = useState('Multiple Choice');

  // const toggleDropdown = () => setIsOpen(!isOpen);

  const maxLength = 250;
  const [selectedOptiona, setSelectedOptionb] = useState('');
  const [isOpenA, setIsOpenB] = useState(false); // State for dropdown visibility

  const [selectedOption, setSelectedOption] = useState('Multiple Choice');

  const toggleDropdown = () => setIsOpen(!isOpen);

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const tileContent = ({ date, view }) => {
    if (view === 'month' && isDateMarked(date)) {
      return <span className="mark" />;
    }
    return null;
  };
  const tileClassName = ({ date }) => {
    return isDateMarked(date) ? 'marked-tile' : null;
  };

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const markedDates = [new Date('2024-05-05'), new Date('2024-05-10'), new Date('2024-05-15')];

  const isDateMarked = (date) => {
    return markedDates.some((markedDate) => isSameDay(date, markedDate));
  };

  const [showCalendar, setShowCalendar] = useState(false);

  const handleToggleCalendar = () => {
    setShowCalendar((prevShowCalendar) => !prevShowCalendar);
  };

  const handleChangeC = (e) => {
    const date = new Date(e); // Convert the input to a Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    setStartDate(formattedDate);
  };
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [showPage, setShowPage] = useState('edit');

  const [isOpenT, setIsOpenT] = useState(false);
  const [selectedTime, setSelectedTime] = useState('');

  // Function to generate times from 12:00 AM to 12:00 PM
  const generateTimes = () => {
    const times = [];
    const start = 0; // 12:00 am in minutes
    const end = 12 * 60; // 12:00 pm in minutes
    for (let time = start; time <= end; time += 30) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      const period = hours >= 12 ? 'pm' : 'am';
      const displayTime = `${hours % 12 || 12}:${minutes === 0 ? '00' : minutes} ${period}`;
      times.push(displayTime);
    }
    return times;
  };

  // Handle the dropdown selection
  const handleSelectT = (time) => {
    setSelectedTime(time);
    setIsOpenT(false);
  };

  // Share Survey Category Handeling
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState('Dr Mark Bouiji');

  const categories = ['Category 1', 'Category 2', 'Category 3', 'Category 4'];

  const handleCategorySelect = (category) => {
    setCurrentCategory(category);
    setDropdownOpen(false);
  };

  // Survey Category Handeling
  const [surveyDropdownOpen, setSurveyDropdownOpen] = useState(false); // renamed from dropdownOpen
  const [selectedSurveyCategory, setSelectedSurveyCategory] = useState('Dr Mark Bouiji'); // renamed from currentCategory

  const surveyCategories = ['Category 1', 'Category 2', 'Category 3', 'Category 4']; // renamed from categories

  const handleSurveyCategorySelect = (category) => {
    setSelectedSurveyCategory(category); // renamed for clarity
    setSurveyDropdownOpen(false);
  };

  const [addUserPopUpOpenSurvey, setAddUserPopUpOpenSurvey] = useState(false);
  return (
    <div className=" gird font-serif m-5 gap-10 ">
      <div className="grid grid-cols-12 justify-around items-center mx-auto max-w-[1440px] ">
        {/* Top Bar  */}
        <div className="col-span-6 grid gap-5 sm:flex  sm:gap-16 ">
          <div
            className={`text-[20px] ${showPage === 'edit' ? 'opacity-100' : 'opacity-50'}`}
            onClick={() => setShowPage('edit')}
          >
            <button>View</button>
          </div>
          <div
            className={`text-[20px] ${showPage === 'share' ? 'opacity-100' : 'opacity-50'}`}
            onClick={() => setShowPage('share')}
          >
            <button>Results</button>
          </div>
        </div>
        <div className="col-span-6 flex justify-end text-[1rem]">
          <button
            className="hover:bg-opacity-90 mx-3 inline-flex items-center justify-center rounded-2xl border border-transparent bg-gradient-to-r from-shoorah-primary to-shoorah-secondary px-9 py-2 text-sm font-normal text-white shadow-sm focus:outline-none sm:w-auto"
            onClick={() => navigate('/self-assessment/create-self-assessment')}
          >
            Create New Assessment
          </button>
        </div>
      </div>

      {showPage === 'edit' && (
        <div>
          <div className="flex flex-col bg-white rounded-2xl  border px-5 sm:pl-[25px]  mt-[41px] mb-[22px] border-[#E7E8EA]300">
            <h1 className="mt-[40px] mb-[15px] text-[16px] font-medium ">Assesment Title</h1>
            <div className="grid grid-cols-12 w-full sm:w-[68.5%] h-[40px]  mb-[36px]">
              <input
                className="col-span-12  bg-[#F4F4F4] rounded-xl pl-[15px] border-2 border-[#E7E8EA]300 truncate"
                placeholder=" Enter title: e.g Social Anxiety Assessment"
                value="Anxiety Symptom Assessment"
              ></input>
            </div>
            <h1 className=" mb-[15px] text-[16px] font-medium ">Assesment Description</h1>
            <div className="grid grid-cols-12 w-full sm:w-[68.5%] h-[100px]  mb-[36px]">
              <textarea
                className="col-span-12 bg-[#F4F4F4] border-2 h-[100px]  border-[#E7E8EA]300 rounded-xl  pl-[18px] pb-[30px] pt-[10px] mb-[35px]  relative resize-none"
                placeholder="Thank you for taking time to answer this survey."
                value="Take this assessment to find out if you may have symptoms of anxiety."
              ></textarea>
            </div>
          </div>
          <div className="bg-white  rounded-2xl w-full p-5">
            <div className=" flex flex-col sm:grid sm:grid-cols-12 gap-5 mt-[35px]">
              <div className="col-span-6 flex flex-col ">
                <h1>About Assesment</h1>
                <textarea
                  className="dropdown-menu w-full text-[14px] bg-[#F4F4F4] border-2 resize-none border-[#E7E8EA]300 rounded-xl pl-[18px] pb-[30px] pt-[10px] mb-[35px]  relative"
                  placeholder="Enter some copy covering what this assessment is  about:"
                  value="This brief, time-saving questionnaire is designed for anyone who thinks they may be having symptoms of social anxiety disorder."
                ></textarea>
              </div>
              <div className="col-span-6 flex flex-col ">
                <h1>Who is this for?</h1>
                <textarea
                  className="dropdown-menu w-full text-[14px] bg-[#F4F4F4] border-2 resize-none border-[#E7E8EA]300 rounded-xl pl-[18px] pb-[30px] pt-[10px] mb-[35px]  relative"
                  placeholder="Enter some details of who may take this assessment:"
                  value="This brief, time-saving questionnaire is designed for anyone who thinks they may be having symptoms of social anxiety disorder."
                ></textarea>
              </div>
            </div>

            <div className="">
              <h1>Is it accurate ?</h1>
              <textarea
                className="dropdown-menu  w-full sm:w-1/2 max-w-[580px] bg-[#F4F4F4] border-2  resize-none border-[#E7E8EA]300 rounded-xl pl-[18px] pb-[30px] pt-[10px] mb-[15px]  relative"
                placeholder="Enter some copy covering the accuracy of this assessment:"
                value="This brief, time-saving questionnaire is designed for anyone who thinks they may be having symptoms of social anxiety disorder."
              ></textarea>
            </div>
          </div>
          <div className="mt-[25px] bg-white rounded-xl   p-[1px] ">
            <h1 className="mt-[20px] mb-[15px] text-[16px] font-medium  ml-6 ">Reviewed By</h1>

            <div className="flex flex-col sm:flex-row sm:ml-[25px]  align-middle m-[30px]">
              <div className="flex flex-col items-center justify-center">
                <div className="flex justify-center items-center rounded-3xl border border-[#E7E8EA] w-[80px] h-[80px] relative">
                  {assessmentImg ? (
                    <img
                      src={assessmentImg}
                      alt="Uploaded"
                      className="rounded-3xl w-full h-full object-cover"
                    />
                  ) : (
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.85 45.5H31.15C41.4 45.5 45.5 41.4 45.5 31.15V18.85C45.5 8.6 41.4 4.5 31.15 4.5H18.85C8.6 4.5 4.5 8.6 4.5 18.85V31.15C4.5 41.4 8.6 45.5 18.85 45.5Z"
                        stroke="#0B1D2E"
                        strokeWidth="3.075"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.85 20.9002C21.1144 20.9002 22.95 19.0646 22.95 16.8002C22.95 14.5358 21.1144 12.7002 18.85 12.7002C16.5856 12.7002 14.75 14.5358 14.75 16.8002C14.75 19.0646 16.5856 20.9002 18.85 20.9002Z"
                        stroke="#0B1D2E"
                        strokeWidth="3.075"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.87366 39.2468L15.9802 32.4613C17.5997 31.3748 19.9367 31.4978 21.3922 32.7483L22.0687 33.3428C23.6677 34.7163 26.2507 34.7163 27.8497 33.3428L36.3777 26.0243C37.9767 24.6508 40.5597 24.6508 42.1587 26.0243L45.5002 28.8943"
                        stroke="#0B1D2E"
                        strokeWidth="3.075"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                  {/* <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="absolute inset-0 opacity-0 cursor-pointer"
                  /> */}
                </div>
              </div>

              <div className=" w-full xl:w-[42%]  flex flex-col sm:flex-row  gap-[25px] sm:mt-[0px] mt-[20px]  ml-[0px] sm:ml-[25px]">
                <div className="w-full sm:w-1/2 gap-8">
                  <div className="flex flex-col">
                    <h1 className="text-[16px] mb-[16px]">Medical Professional</h1>
                    <div
                      className="relative flex flex-col justify-between items-center px-7 border border-[#E7E8EA] h-[40px] bg-[#F4F4F4] rounded-xl  cursor-pointer"
                      onClick={() => setDropdownOpen(!dropdownOpen)}
                    >
                      <div className="flex flex-row justify-between w-full h-full items-center">
                        <span>{currentCategory}</span>
                        <svg
                          className={`w-4 h-4 transform ${dropdownOpen ? 'rotate-180' : ''}`}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>

                      {dropdownOpen && (
                        <ul className="absolute top-full z-40 left-0 w-full bg-white border border-[#E7E8EA] rounded-xl mt-2">
                          {categories.map((category, index) => (
                            <li
                              key={index}
                              className="px-4 py-2 hover:bg-[#E7E8EA] cursor-pointer"
                              onClick={() => handleCategorySelect(category)}
                            >
                              {category}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full sm:w-1/2 gap-8">
                  <div className="flex flex-col">
                    <h1 className="text-[16px] mb-[16px]">Published By</h1>
                    <div
                      className="relative flex flex-col justify-between items-center px-7 border border-[#E7E8EA] h-[40px] bg-[#F4F4F4] rounded-xl  cursor-pointer"
                      onClick={() => setDropdownOpen(!dropdownOpen)}
                    >
                      <div className="flex flex-row justify-between w-full h-full items-center">
                        <span>{currentCategory}</span>
                        <svg
                          className={`w-4 h-4 transform ${dropdownOpen ? 'rotate-180' : ''}`}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>

                      {dropdownOpen && (
                        <ul className="absolute top-full z-40 left-0 w-full bg-white border border-[#E7E8EA] rounded-xl mt-2">
                          {categories.map((category, index) => (
                            <li
                              key={index}
                              className="px-4 py-2 hover:bg-[#E7E8EA] cursor-pointer"
                              onClick={() => handleCategorySelect(category)}
                            >
                              {category}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[30px] rounded-3xl border-[1.5px] border-[#E7E8EA]  px-6 py-8 bg-[#FFFFFF]">
            <h1 className="text-2xl">Fear of Losing Control or Going Crazy?</h1>
            <div className="p-4">
              <div className="flex flex-col">
                <label className="flex items-center mb-4">
                  <input
                    type="radio"
                    value="option1"
                    checked={selectedOption === 'option1'}
                    onChange={handleOptionChange}
                    className="mr-2"
                  />
                  Never
                </label>
                <label className="flex items-center mb-4">
                  <input
                    type="radio"
                    value="option2"
                    checked={selectedOption === 'option2'}
                    onChange={handleOptionChange}
                    className="mr-2"
                  />
                  Rairly
                </label>
                <label className="flex items-center mb-4">
                  <input
                    type="radio"
                    value="option3"
                    checked={selectedOption === 'option3'}
                    onChange={handleOptionChange}
                    className="mr-2"
                  />
                  Sometime
                </label>
                <label className="flex items-center mb-4">
                  <input
                    type="radio"
                    value="option3"
                    checked={selectedOption === 'option3'}
                    onChange={handleOptionChange}
                    className="mr-2"
                  />
                  Often
                </label>
              </div>
            </div>
          </div>
        </div>
      )}

      {isOpen && (
        <div
          className="dropdown-menu w-64 z-10 bg-white overflow-hidden absolute mt-1 border-2 py-4 border-[#E7E8EA]300 rounded-xl"
          style={{
            top: '23px',
            zIndex: 274,
            left: '-189px'
          }}
        >
          <div
            className="dropdown-item p-2 hover:bg-gray-300 cursor-pointer"
            onClick={() => selectOption('Single Choice')}
          >
            Edit
          </div>
          <div
            className="dropdown-item p-2 hover:bg-gray-300 cursor-pointer"
            onClick={() => selectOption('Multiple Choice')}
          >
            Duplicate
          </div>
          <div className="border-b border-[#E7E8EA]400 w-full"></div>
          <div
            className="dropdown-item p-2 hover:bg-gray-300 cursor-pointer text-red-700"
            onClick={() => selectOption('Multiple Choice')}
          >
            Delete
          </div>
        </div>
      )}
      {addUserPopUpOpen && (
        <div className="flex justify-center items-center z-50 fixed h-screen w-screen top-0 left-0 bg-black/15  px-10">
          <div className=" h-fit w-[702px] rounded-3xl px-16 py-4 bg-gray-100">
            <div className="text-4xl mt-[20px]">Add Answers in Bulk</div>
            <div className="text-gray-400 mt-[20px]">
              Enter each answer choice on a seperate line
            </div>
            <div className="flex justify-center mt-[30px]">
              <div className="relative w-full">
                <textarea
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  maxLength={maxLength}
                  placeholder="Enter reason here..."
                  className="w-full h-[240px] p-4 border rounded-xl text-[#0B1D2E] border-[#E7E8EA]300 focus:outline-none focus:ring-2 focus:ring-gray-200"
                />
                <div className="absolute bottom-2 right-4  py-5 px-6 text-gray-400 text-sm">
                  *{maxLength} Character limit
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-[40px] mb-[10px] gap-6">
              <button
                className="text-black border border-black px-10  rounded-full p-3"
                onClick={cancelBulkSave}
              >
                Cancel
              </button>
              <button
                className=" border bg-shoorah-primary text-white border-shoorah-primary px-14 py-2 rounded-full p-5"
                onClick={handleBulkSave}
              >
                Save
              </button>
              <button className=" "></button>
            </div>
          </div>
        </div>
      )}

      {/* Share Page  */}
      {showPage === 'share' && (
        <div className="max-w-[1440px] mx-auto mt-4">
          <div className=" w-full min-w-[240px] h-[300px] border-[1.5px] border-[#E7E8EA] bg-white rounded-2xl  p-4">
            <div className="max-w-md p-2">
              <h2 className="text-[20px] font-medium mb-2">Overall Responses</h2>
              <Chart
                options={optionArray(1)}
                series={[44, 50, 51]}
                type="donut"
                width={200}
                height={200}
              />

              {/* Statistics */}
              <div className="space-y-3">
                <div className="flex items-center">
                  <div className="w-3 h-3 rounded-full bg-pink-400 mr-2"></div>
                  <span className="text-gray-600">Responses:</span>
                  <span className="ml-2 font-normal">{responses.toLocaleString()}</span>
                </div>

                <div className="flex items-center">
                  <div className="w-3 h-3 rounded-full bg-pink-200 mr-2"></div>
                  <span className="text-gray-600">No Response:</span>
                  <span className="ml-2 font-norma;">{noResponse.toLocaleString()}</span>
                </div>
              </div>
            </div>
          </div>

          <div className=" bg-[#FFFFFF] pt-[30px] mt-[20px] rounded-3xl p-5  sm:pl-[40px]">
            <h1 className=" text-[20px] mb-[45px] ">End Date</h1>
            <h1 className="mb-[15px] text-[1rem]">Heading Text</h1>
            <input
              className="dropdown-menu w-full sm:w-1/2 max-w-[580px] h-[40px] bg-[#F4F4F4] border-[1.5px]  text-[14px] border-[#E7E8EA] rounded-xl pl-[18px] mb-[35px] flex flex-col justify-center items-start relative"
              placeholder="You have completed this survey."
            ></input>
            <h1 className="text-[1rem] mb-[15px]"> Body Text</h1>
            <textarea
              className="dropdown-menu w-full sm:w-1/2 max-w-[580px] bg-[#F4F4F4] border-[1.5px] text-[14px] resize-none border-[#E7E8EA] rounded-xl pl-[18px] pb-[30px] pt-[10px] mb-[px]  relative"
              placeholder="Thank you for taking time to answer this survey."
            ></textarea>
          </div>
          <div className="grid grid-cols-12 sm:flex justify-end mt-[30px] p-5 gap-6">
            <div className="col-span-6 border border-black rounded-3xl w-full  sm:max-w-[102px] h-[40px] flex justify-center items-center">
              <button>Preview</button>
            </div>
            <div className="col-span-6 rounded-3xl p-3 w-full  sm:max-w-[145px] h-[40px] bg-shoorah-secondary flex justify-center items-center">
              <button
                className="text-[14px] text-white w-full"
                onClick={() => {
                  setAddUserPopUpOpenSurvey(true);
                }}
              >
                Send Survey
              </button>
            </div>
          </div>
          {addUserPopUpOpenSurvey && (
            <div
              className="flex justify-center items-center z-50 fixed h-screen w-screen top-0 left-0 bg-black/15  px-10"
              onClick={() => {
                setAddUserPopUpOpenSurvey(false);
              }}
            >
              <div className=" h-fit w-[695px] rounded-[24px] px-12 py-4 bg-[#FFFFFF] sm:m-0 m-2">
                <div className="text-4xl mt-[45px] flex justify-center">
                  <svg
                    width="96"
                    height="96"
                    viewBox="0 0 96 96"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="48" cy="48" r="48" fill="#E5EAFA" />
                    <path
                      d="M44.6667 22.5345C46.5067 20.9612 49.52 20.9612 51.3866 22.5345L55.6 26.1614C56.4 26.8548 57.8933 27.4147 58.96 27.4147H63.4933C66.32 27.4147 68.64 29.7345 68.64 32.5612V37.0947C68.64 38.1347 69.2 39.6547 69.8933 40.4547L73.52 44.668C75.0934 46.508 75.0934 49.5213 73.52 51.388L69.8933 55.6012C69.2 56.4012 68.64 57.8946 68.64 58.9612V63.4948C68.64 66.3214 66.32 68.6413 63.4933 68.6413H58.96C57.92 68.6413 56.4 69.2012 55.6 69.8945L51.3866 73.5211C49.5466 75.0945 46.5333 75.0945 44.6667 73.5211L40.4534 69.8945C39.6534 69.2012 38.16 68.6413 37.0933 68.6413H32.48C29.6533 68.6413 27.3333 66.3214 27.3333 63.4948V58.9346C27.3333 57.8946 26.7734 56.4012 26.1067 55.6012L22.5067 51.3613C20.96 49.5213 20.96 46.5346 22.5067 44.6946L26.1067 40.4547C26.7734 39.6547 27.3333 38.1614 27.3333 37.1214V32.5612C27.3333 29.7345 29.6533 27.4147 32.48 27.4147H37.0933C38.1334 27.4147 39.6534 26.8548 40.4534 26.1614L44.6667 22.5345Z"
                      fill="#B6C0F3"
                      stroke="#4A57DA"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M42.3737 55.5193L46.2403 58.506C46.747 59.0126 47.867 59.2527 48.6137 59.2527H53.3337C54.827 59.2527 56.4537 58.1328 56.827 56.6394L59.8136 47.5193C60.427 45.786 59.307 44.2661 57.4403 44.2661H52.4537C51.707 44.2661 51.0937 43.6529 51.2004 42.7729L51.8136 38.7729C52.0536 37.6529 51.307 36.3995 50.187 36.0261C49.2003 35.6528 47.947 36.1594 47.4403 36.906L42.3203 44.506"
                      stroke="#4A57DA"
                      stroke-width="3"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M36 55.5182V43.305C36 41.545 36.7467 40.9316 38.5067 40.9316H39.76C41.4933 40.9316 42.2667 41.545 42.2667 43.305V55.5182C42.2667 57.2516 41.52 57.8916 39.76 57.8916H38.5067C36.7467 57.8916 36 57.2782 36 55.5182Z"
                      stroke="#4A57DA"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="text-[#0B1D2E] text-[24px]  mt-[30px] flex justify-center ">
                  <div className="w-[75%] text-center">
                    Congratulations on successfully creating your first survey template.
                  </div>
                </div>
                <div className="flex justify-center mt-[18px]">
                  <div className="relative w-[50%] text-[16px]  text-[#0B1D2E] flex justify-center text-center ">
                    <p className="font-thin">
                      {' '}
                      You can now manage this survey and access valuable insights
                    </p>
                  </div>
                </div>
                <div className="flex justify-center mt-[40px] mb-[50px] gap-6">
                  <button
                    className="text-[#FFFFFF] bg-shoorah-secondary  text-[16px] border border-black px-10  rounded-full p-3"
                    onClick={() => {
                      setAddUserPopUpOpen(false);
                    }}
                  >
                    Template
                  </button>

                  <button className=" "></button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {/* Preview Page */}
      {showPage === 'preview' && (
        <div className="max-w-[1440px] mx-auto">
          <div className="mt-[30px] rounded-xl border-[1.5px] border-[#E7E8EA] bg-[#FFFFFF] px-6 py-8 mb-[35px]">
            <div className="flex flex-row w-full max-w-[122px]  gap-3 h-full  rounded-3xl px-6 py-[7px] justify-center bg-[#FFDC43] ">
              <div className="relative left-[-4px]">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="#0B1D2E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 12H16"
                    stroke="#0B1D2E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 16V8"
                    stroke="#0B1D2E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>
                <button className="">Logo</button>
              </div>
            </div>

            <div className=" sm:grid sm:grid-cols-12 gap-5 mt-[40px] sm:w-[80%] ">
              <div className="col-span-7 grid gap-3">
                <h1 className="text-xl">Survey Title</h1>
                <input
                  className=" w-full rounded-xl p-3 bg-[#F4F4F4] border-[1.5px] border-[#E7E8EA]"
                  type="text"
                  placeholder=" "
                ></input>
              </div>
              <div className="col-span-5 grid gap-3">
                <h1 className="text-xl">Survey Category</h1>

                <div
                  className="relative w-full rounded-xl p-3 bg-[#F4F4F4] border-[1.5px]  border-[#E7E8EA] cursor-pointer"
                  onClick={() => setSurveyDropdownOpen(!surveyDropdownOpen)}
                >
                  <div className="flex flex-row justify-between items-center">
                    <span>{selectedSurveyCategory}</span>
                    <svg
                      className={`w-4 h-4 transform ${surveyDropdownOpen ? 'rotate-180' : ''}`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>

                  {surveyDropdownOpen && (
                    <ul className="absolute top-full left-0 w-full  border  bg-[#F4F4F4]  border-[#E7E8EA] rounded-xl mt-2 z-10">
                      {surveyCategories.map((category, index) => (
                        <li
                          key={index}
                          className="px-4 py-2 hover:bg-[#E7E8EA] cursor-pointer"
                          onClick={() => handleSurveyCategorySelect(category)}
                        >
                          {category}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className=" grid gap-3 mt-[2rem] sm:w-[80%] ">
              <h1 className="text-xl">Survey Category</h1>
              <textarea
                className="w-full rounded-xl p-3 pt-[15px] pl-[15px] bg-[#F4F4F4] border-[1.5px] border-[#E7E8EA] min-h-[100px] resize-none"
                type="text"
                placeholder=" "
              ></textarea>
            </div>
          </div>
          <div className=" px-3 py-10 sm:px-7 sm:py-10 border border-[#E7E8EA] bg-[#FFFFFF] rounded-xl ">
            <div className="flex justify-center  mb-9">
              <div className="flex w-full z-0 ">
                <div className="  h-fit  rounded-xl px-3  py-1  sm:px-6 sm:py-3 mr-5 sm:mr-[1.5%] text-black bg-gray-200">
                  Q1
                </div>
                <h2 className="   sm:leading-normal tracking-none sm:tracking-normal h-fit w-full  md:w-full lg:max-w-[46%] leading-9 text-[18.5px] sm:text-[24px]  mb-5 sm:mb-0  flex  items-center">
                  How often would you prefer to work remotely?
                </h2>
              </div>

              <div className="relative inline-block">
                <div
                  className="dropdown-toggle   justify-end  flex  items-center  rounded-xl cursor-pointer "
                  onClick={toggleDropdown}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 12H9m6 6H9m6-12H9"
                    />
                  </svg>
                </div>

                {isOpen && (
                  <div
                    className="dropdown-menu w-64 z-10 bg-[#F4F4F4] overflow-hidden absolute mt-1 border-[1.5px] py-4 border-[#E7E8EA] rounded-xl"
                    style={{
                      top: '23px',
                      zIndex: 274,
                      left: '-189px'
                    }}
                  >
                    <div
                      className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer"
                      onClick={() => selectOption('Single Choice')}
                    >
                      Edit
                    </div>
                    <div
                      className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer"
                      onClick={() => selectOption('Multiple Choice')}
                    >
                      Duplicate
                    </div>
                    <div className="border-b border-gray-400 w-full"></div>
                    <div
                      className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer text-red-700"
                      onClick={() => selectOption('Multiple Choice')}
                    >
                      Delete
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full  flex flex-col items-center">
              <div className="flex flex-col space-y-7 mb-4 w-[87%] justify-center">
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="Very satisfied"
                    checked={selectedOptiona === 'Very satisfied'}
                    onChange={() => setSelectedOptionb('Very satisfied')}
                    className="mr-2  "
                    style={{ transform: 'scale(1.75)' }}
                  />
                  <p className="ml-6"> Very satisfied</p>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="Somewhat satisfied"
                    checked={selectedOptiona === 'Somewhat satisfied'}
                    onChange={() => setSelectedOptionb('Somewhat satisfied')}
                    className="mr-2"
                    style={{ transform: 'scale(1.75)' }}
                  />
                  <p className="ml-6"> Somewhat satisfied </p>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="Not too satisfied"
                    checked={selectedOptiona === 'Not too satisfied'}
                    onChange={() => setSelectedOptionb('Not too satisfied')}
                    className="mr-2"
                    style={{ transform: 'scale(1.75)' }}
                  />
                  <p className="ml-6"> Not too satisfied </p>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="Not at all satisfied"
                    checked={selectedOptiona === 'Not at all satisfied'}
                    onChange={() => setSelectedOptionb('Not at all satisfied')}
                    className="mr-2"
                    style={{ transform: 'scale(1.75)' }}
                  />

                  <p className="ml-6"> Not at all satisfied</p>
                </label>
              </div>
            </div>
          </div>
          <div className=" px-3 py-10 sm:px-7 sm:py-10 border border-[#E7E8EA] bg-[#FFFFFF] rounded-xl  mt-[21px]">
            <div className="flex justify-center  mb-9">
              <div className="flex w-full z-0 ">
                <div className=" col-span-1 h-fit  rounded-xl px-3  py-1  sm:px-6 sm:py-3 mr-5 sm:mr-[1.5%] text-black bg-gray-200">
                  Q2
                </div>
                <h2 className="   sm:leading-normal tracking-none sm:tracking-normal h-fit  w-full  md:w-full lg:max-w-[46%] leading-9 text-[18.5px] sm:text-[24px]  mb-5 sm:mb-0  flex  items-center">
                  Thinking about the colleagues you work with most closely, do most of them Work:
                </h2>
              </div>

              <div className="relative inline-block">
                <div
                  className="dropdown-toggle   justify-end  flex  items-center  rounded-xl cursor-pointer "
                  onClick={toggleDropdown}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 12H9m6 6H9m6-12H9"
                    />
                  </svg>
                </div>

                {isOpen && (
                  <div
                    className="dropdown-menu w-64 z-10 bg-[#F4F4F4] overflow-hidden absolute mt-1 border-[1.5px] py-4 border-[#E7E8EA] rounded-xl"
                    style={{
                      top: '23px',
                      zIndex: 274,
                      left: '-189px'
                    }}
                  >
                    <div
                      className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer"
                      onClick={() => selectOption('Single Choice')}
                    >
                      Edit
                    </div>
                    <div
                      className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer"
                      onClick={() => selectOption('Multiple Choice')}
                    >
                      Duplicate
                    </div>
                    <div className="border-b border-gray-400 w-full"></div>
                    <div
                      className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer text-red-700"
                      onClick={() => selectOption('Multiple Choice')}
                    >
                      Delete
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full  flex flex-col items-center">
              <div className="flex flex-col space-y-7 mb-4 w-[87%] justify-center">
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="Very satisfied"
                    checked={selectedOptiona === 'Very satisfied'}
                    onChange={() => setSelectedOptionb('Very satisfied')}
                    className="mr-2  "
                    style={{ transform: 'scale(1.75)' }}
                  />
                  <p className="ml-6">Fully from the office or workspace</p>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="Somewhat satisfied"
                    checked={selectedOptiona === 'Somewhat satisfied'}
                    onChange={() => setSelectedOptionb('Somewhat satisfied')}
                    className="mr-2"
                    style={{ transform: 'scale(1.75)' }}
                  />
                  <p className="ml-6"> Mostly from the office or workspace </p>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="Not too satisfied"
                    checked={selectedOptiona === 'Not too satisfied'}
                    onChange={() => setSelectedOptionb('Not too satisfied')}
                    className="mr-2"
                    style={{ transform: 'scale(1.75)' }}
                  />
                  <p className="ml-6">Hybrid from the office and from home </p>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="Not at all satisfied"
                    checked={selectedOptiona === 'Not at all satisfied'}
                    onChange={() => setSelectedOptionb('Not at all satisfied')}
                    className="mr-2"
                    style={{ transform: 'scale(1.75)' }}
                  />

                  <p className="ml-6"> Mostly from home or remotely</p>
                </label>
              </div>
            </div>
          </div>

          <div className=" px-3 py-10 sm:px-7 sm:py-10 border border-[#E7E8EA] bg-[#FFFFFF] rounded-xl  mt-[21px]">
            <div className="flex justify-center  mb-9">
              <div className="flex w-full z-0 ">
                <div className="  h-fit  rounded-xl px-3  py-1  sm:px-6 sm:py-3 mr-5 sm:mr-[1.5%] text-black bg-gray-200">
                  Q3
                </div>
                <h2 className="   sm:leading-normal tracking-none sm:tracking-normal h-fit w-full  md:w-full lg:max-w-[46%] leading-9 text-[18.5px] sm:text-[24px]  mb-5 sm:mb-0  flex  items-center">
                  Do you agree or disagree with the following statement: Those who work remotely at
                  our organization have the same professional opportunities as colleagues who work
                  from the office.
                </h2>
              </div>

              <div className="relative inline-block">
                <div
                  className="dropdown-toggle   justify-end  flex  items-center  rounded-xl cursor-pointer "
                  onClick={toggleDropdown}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 12H9m6 6H9m6-12H9"
                    />
                  </svg>
                </div>

                {isOpen && (
                  <div
                    className="dropdown-menu w-64 z-10 bg-[#F4F4F4] overflow-hidden absolute mt-1 border-[1.5px] py-4 border-[#E7E8EA] rounded-xl"
                    style={{
                      top: '23px',
                      zIndex: 274,
                      left: '-189px'
                    }}
                  >
                    <div
                      className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer"
                      onClick={() => selectOption('Single Choice')}
                    >
                      Edit
                    </div>
                    <div
                      className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer"
                      onClick={() => selectOption('Multiple Choice')}
                    >
                      Duplicate
                    </div>
                    <div className="border-b border-gray-400 w-full"></div>
                    <div
                      className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer text-red-700"
                      onClick={() => selectOption('Multiple Choice')}
                    >
                      Delete
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex w-full  md:w-full lg:w-[50%] justify-center mb-[32px]">
              {/* <img className="  w-[80%] rounded-xl" src={surveytemplate} /> */}
            </div>
            <div className="w-full  flex flex-col items-center">
              <div className="flex flex-col space-y-7 mb-4 w-[87%] justify-center">
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="Very satisfied"
                    checked={selectedOptiona === 'Very satisfied'}
                    onChange={() => setSelectedOptionb('Very satisfied')}
                    className="mr-2  "
                    style={{ transform: 'scale(1.75)' }}
                  />
                  <p className="ml-6">Fully from the office or workspace</p>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="Somewhat satisfied"
                    checked={selectedOptiona === 'Somewhat satisfied'}
                    onChange={() => setSelectedOptionb('Somewhat satisfied')}
                    className="mr-2"
                    style={{ transform: 'scale(1.75)' }}
                  />
                  <p className="ml-6"> Mostly from the office or workspace </p>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="Not too satisfied"
                    checked={selectedOptiona === 'Not too satisfied'}
                    onChange={() => setSelectedOptionb('Not too satisfied')}
                    className="mr-2"
                    style={{ transform: 'scale(1.75)' }}
                  />
                  <p className="ml-6">Hybrid from the office and from home </p>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="Not at all satisfied"
                    checked={selectedOptiona === 'Not at all satisfied'}
                    onChange={() => setSelectedOptionb('Not at all satisfied')}
                    className="mr-2"
                    style={{ transform: 'scale(1.75)' }}
                  />

                  <p className="ml-6"> Mostly from home or remotely</p>
                </label>
              </div>
            </div>
          </div>
          <div className=" px-3 py-10 sm:px-7 sm:py-10 border border-[#E7E8EA] bg-[#FFFFFF] rounded-xl  mt-[21px]">
            <div className="flex justify-center  mb-9">
              <div className="flex w-full z-0 ">
                <div className=" col-span-1 h-fit  rounded-xl px-3  py-1  sm:px-6 sm:py-3 mr-5 sm:mr-[1.5%] text-black bg-gray-200">
                  Q5
                </div>
                <h2 className="   sm:leading-normal tracking-none sm:tracking-normal h-fit  w-full  md:w-full lg:max-w-[46%] leading-9 text-[18.5px] sm:text-[24px]  mb-5 sm:mb-0  flex  items-center">
                  Which of the following challenges do you face while working remotely? (Select all
                  that apply)
                </h2>
              </div>

              <div className="relative inline-block">
                <div
                  className="dropdown-toggle   justify-end  flex  items-center  rounded-xl cursor-pointer "
                  onClick={toggleDropdown}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 12H9m6 6H9m6-12H9"
                    />
                  </svg>
                </div>

                {isOpen && (
                  <div
                    className="dropdown-menu w-64 z-10 bg-[#F4F4F4] overflow-hidden absolute mt-1 border-[1.5px] py-4 border-[#E7E8EA] rounded-xl"
                    style={{
                      top: '23px',
                      zIndex: 274,
                      left: '-189px'
                    }}
                  >
                    <div
                      className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer"
                      onClick={() => selectOption('Single Choice')}
                    >
                      Edit
                    </div>
                    <div
                      className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer"
                      onClick={() => selectOption('Multiple Choice')}
                    >
                      Duplicate
                    </div>
                    <div className="border-b border-gray-400 w-full"></div>
                    <div
                      className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer text-red-700"
                      onClick={() => selectOption('Multiple Choice')}
                    >
                      Delete
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full  flex flex-col items-center">
              <div className="flex flex-col space-y-7 mb-4 w-[87%] justify-center">
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="Very satisfied"
                    checked={selectedOptiona === 'Very satisfied'}
                    onChange={() => setSelectedOptionb('Very satisfied')}
                    className="mr-2  "
                    style={{ transform: 'scale(1.75)' }}
                  />
                  <p className="ml-6">Too many distractions</p>
                </label>

                <label className="flex items-center">
                  <input
                    type="radio"
                    value="Somewhat satisfied"
                    checked={selectedOptiona === 'Somewhat satisfied'}
                    onChange={() => setSelectedOptionb('Somewhat satisfied')}
                    className="mr-2"
                    style={{ transform: 'scale(1.75)' }}
                  />
                  <p className="ml-6"> Technical issues</p>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="Somewhat satisfied"
                    checked={selectedOptiona === 'Somewhat satisfied'}
                    onChange={() => setSelectedOptionb('Somewhat satisfied')}
                    className="mr-2"
                    style={{ transform: 'scale(1.75)' }}
                  />
                  <p className="ml-6"> Internet connectivity issues</p>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="Not too satisfied"
                    checked={selectedOptiona === 'Not too satisfied'}
                    onChange={() => setSelectedOptionb('Not too satisfied')}
                    className="mr-2"
                    style={{ transform: 'scale(1.75)' }}
                  />
                  <p className="ml-6">Lack of appropriate equipment to work remotely </p>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value="Not at all satisfied"
                    checked={selectedOptiona === 'Not at all satisfied'}
                    onChange={() => setSelectedOptionb('Not at all satisfied')}
                    className="mr-2"
                    style={{ transform: 'scale(1.75)' }}
                  />

                  <p className="ml-6"> Lack of social interaction</p>
                </label>
                <label className="flex  items-center">
                  <input
                    type="radio"
                    value="Not at all satisfied"
                    checked={selectedOptiona === 'Not at all satisfied'}
                    onChange={() => setSelectedOptionb('Not at all satisfied')}
                    className="mr-2"
                    style={{ transform: 'scale(1.75)' }}
                  />
                  <p className="ml-6"> Other (please specify)</p>
                </label>
                <input className="  p-2 w-full md:w-[50%] lg:w-[32%] h-[40px] min-w-[360px] border-[1.5px] bg-[#FAFAFA] border-[#E7E8EA] rounded-xl"></input>
              </div>
            </div>
          </div>
          <div className="mt-[30px] rounded-3xl border-[1.5px] border-[#E7E8EA]  px-6 py-8 bg-[#FFFFFF]">
            <div className="block w-full h-full">
              <h1 className="text-2xl">Add Question</h1>
              <div className="sm:grid sm:grid-cols-12 gap-4  mt-[20px] sm:w-[80%] w-full">
                <input
                  className=" col-span-1 sm:col-span-8 w-full  mb-10 sm:mb-0 rounded-xl px-5 py-3  bg-[#FAFAFA] border-[1.5px] border-[#E7E8EA]"
                  type="text"
                  placeholder="Enter your question"
                />
                <div className="col-span-1 sm:col-span-1  grid justify-center items-center  gap-3 border-[1.5px] bg-[#F4F4F4]  border-[#E7E8EA]  rounded-2xl ">
                  <button>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                        stroke="#0B1D2E"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"
                        stroke="#0B1D2E"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.66998 18.9501L7.59998 15.6401C8.38998 15.1101 9.52998 15.1701 10.24 15.7801L10.57 16.0701C11.35 16.7401 12.61 16.7401 13.39 16.0701L17.55 12.5001C18.33 11.8301 19.59 11.8301 20.37 12.5001L22 13.9001"
                        stroke="#0B1D2E"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="relative inline-block w-64">
                  <div
                    className="dropdown-toggle border border-[#E7E8EA] flex justify-between items-center p-4 bg-[#FAFAFA] rounded-xl cursor-pointer "
                    onClick={toggleDropdown}
                  >
                    <span>{selectedOption}</span>
                    {/* <svg
                      className={`transform transition-transform ${
                        isOpen ? 'rotate-180' : 'rotate-0'
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 10.6l3.71-3.37a.75.75 0 111.06 1.06l-4 3.75a.75.75 0 01-1.06 0l-4-3.75a.75.75 0 01.02-1.06z"
                        clipRule="evenodd"
                      />
                    </svg> */}
                    <svg
                      className={`w-4 h-4 transform ${isOpen ? 'rotate-180' : ''}`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>

                  {isOpen && (
                    <div className="dropdown-menu overflow-hidden absolute w-full mt-1 border border-[#E7E8EA] bg-[#FAFAFA] rounded-xl  z-10">
                      <div
                        className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer"
                        onClick={() => selectOption('Single Choice')}
                      >
                        Single Choice
                      </div>
                      <div
                        className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer"
                        onClick={() => selectOption('Multiple Choice')}
                      >
                        Multiple Choice
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <h1 className=" text-xl mt-[45px] mb-[10px]">Answer Choice</h1>

            <div className="grid grid-cols-12 gap-6 sm:w-[80%] w-full mb-[20px]">
              <div className=" col-span-12 sm:col-span-9 mb-3 sm:mb-0 rounded-xl px-5 py-3 bg-[#FAFAFA] border-[1.5px] border-[#E7E8EA]">
                <input
                  className=" bg-[#FAFAFA] border-none w-full outline-none rounded-xl px-4 "
                  type="text"
                  placeholder="Enter your answer choice"
                />
              </div>
              <div className="grid grid-cols-12 sm:grid-cols-4 col-span-3 gap-5 sm:gap-4 sm:justify-items-end">
                <div className=" col-span-6 sm:col-span-1 justify-center items-center flex text-gray-400">
                  <button className="  flex justify-center items-center">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                        stroke="#A1A1A1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 12H16"
                        stroke="#A1A1A1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 16V8"
                        stroke="#A1A1A1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className=" col-span-6 sm:col-span-1 justify-center items-center flex text-gray-400">
                  <button className="  flex justify-center items-center">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.92 22C17.42 22 21.92 17.5 21.92 12C21.92 6.5 17.42 2 11.92 2C6.41998 2 1.91998 6.5 1.91998 12C1.91998 17.5 6.41998 22 11.92 22Z"
                        stroke="#A1A1A1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.91998 12H15.92"
                        stroke="#A1A1A1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-6 sm:w-[80%] w-full mb-[20px]">
              <div className=" col-span-12 sm:col-span-9 mb-3 sm:mb-0 rounded-xl px-5 py-3 bg-[#FAFAFA] border-[1.5px] border-[#E7E8EA]">
                <input
                  className=" bg-[#FAFAFA] border-none w-full outline-none rounded-xl px-4 truncate "
                  type="text"
                  placeholder="Enter your answer choice"
                />
              </div>
              <div className="grid grid-cols-12 sm:grid-cols-4 col-span-3 gap-5 sm:gap-4 sm:justify-items-end">
                <div className=" col-span-6 sm:col-span-1 justify-center items-center flex text-gray-400">
                  <button className="  flex justify-center items-center">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                        stroke="#A1A1A1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 12H16"
                        stroke="#A1A1A1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 16V8"
                        stroke="#A1A1A1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className=" col-span-6 sm:col-span-1 justify-center items-center flex text-gray-400">
                  <button className="  flex justify-center items-center">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.92 22C17.42 22 21.92 17.5 21.92 12C21.92 6.5 17.42 2 11.92 2C6.41998 2 1.91998 6.5 1.91998 12C1.91998 17.5 6.41998 22 11.92 22Z"
                        stroke="#A1A1A1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.91998 12H15.92"
                        stroke="#A1A1A1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-6 sm:w-[80%] w-full mb-[20px]">
              <div className=" col-span-12 sm:col-span-9 mb-3 sm:mb-0 rounded-xl px-5 py-3 bg-[#FAFAFA] border-[1.5px] border-[#E7E8EA]">
                <input
                  className=" bg-[#FAFAFA] border-none w-full outline-none rounded-xl px-4 "
                  type="text"
                  placeholder="Enter your answer choice"
                />
              </div>
              <div className="grid grid-cols-12 sm:grid-cols-4 col-span-3 gap-5 sm:gap-4 sm:justify-items-end">
                <div className=" col-span-6 sm:col-span-1 justify-center items-center flex text-gray-400">
                  <button className="  flex justify-center items-center">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                        stroke="#A1A1A1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 12H16"
                        stroke="#A1A1A1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 16V8"
                        stroke="#A1A1A1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className=" col-span-6 sm:col-span-1 justify-center items-center flex text-gray-400">
                  <button className="  flex justify-center items-center">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.92 22C17.42 22 21.92 17.5 21.92 12C21.92 6.5 17.42 2 11.92 2C6.41998 2 1.91998 6.5 1.91998 12C1.91998 17.5 6.41998 22 11.92 22Z"
                        stroke="#A1A1A1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.91998 12H15.92"
                        stroke="#A1A1A1"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className=" flex  sm:justify-start  rounded-xl px-0 py-3 justify-start ">
              <button
                className=" flex gap-5 justify-center items-center rounded-[25px] border border-[#A1A1A1] px-11 py-2"
                onClick={() => {
                  setAddUserPopUpOpen(true);
                }}
              >
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 18 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.50004 14.6666C12.1667 14.6666 15.1667 11.6666 15.1667 7.99998C15.1667 4.33331 12.1667 1.33331 8.50004 1.33331C4.83337 1.33331 1.83337 4.33331 1.83337 7.99998C1.83337 11.6666 4.83337 14.6666 8.50004 14.6666Z"
                    stroke="#0B1D2E"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.83337 8H11.1667"
                    stroke="#0B1D2E"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 10.6666V5.33331"
                    stroke="#0B1D2E"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Bulk Answers</p>
              </button>
            </div>

            <h1 className=" text-xl mt-[20px]">Answers Choices</h1>

            <div className="grid grid-cols-12 w-full sm:w-[70%] lg:w-[70%] gap-4 sm:gap-6 lg:gap-10 mt-5 sm:mt-[20px]">
              <div className="col-span-12 sm:col-span-4 px-3 py-4  border border-[#CED2D5] rounded-[25px]  items-center">
                <div className="flex items-center">
                  <div className="p-[8px] border border-[#CED2D5] rounded-full"></div>
                  <p className="ml-5 truncate">Add "Other" Answers choice</p>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-4 px-3 py-4  border border-[#CED2D5] rounded-[25px]  items-center">
                <div className="flex items-center">
                  <div className="p-[8px] border border-[#CED2D5] rounded-full"></div>
                  <p className="ml-5 truncate">"Skipable" Question</p>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-4 px-3 py-4  border border-[#CED2D5] rounded-[25px]  items-center">
                <div className="flex items-center">
                  <div className="p-[8px] border border-[#CED2D5] rounded-full"></div>
                  <p className="ml-5 truncate">"None of the Above" Options</p>
                </div>
              </div>
            </div>
            <div className=" mt-[20px] flex justify-end">
              <button className="bg-shoorah-secondary text-white px-10 py-3 rounded-full p-5">
                Save Question
              </button>
            </div>
          </div>

          {/* <div className="w-full sm:w-[45%] md:w-[60%] lg:w-[40%]">
            <div className="grid grid-cols-12 mt-[20px] gap-2 sm:gap-5">
              <div className="col-span-12 sm:col-span-6 flex flex-row px-3  py-3 sm:py-0 gap-4 sm:gap-4 md:gap-8 lg:gap-6  justify-center items-center bg-shoorah-secondary text-white  rounded-full">
                <p className="flex truncate">Public Template</p>
                <div>
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.44006 13.2801L10.7867 8.9334C11.3001 8.42006 11.3001 7.58006 10.7867 7.06673L6.44006 2.72006"
                      stroke="white"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-4">
                <button className="w-full text-black border border-black  py-3 rounded-full">
                  Save as draft
                </button>
              </div>
            </div>
          </div> */}
          <div className="w-full sm:w-[45%] md:w-[60%] lg:w-[40%] max-w-[400px]">
            <div className="grid grid-cols-12 mt-[20px] gap-2 sm:gap-5">
              <div className="col-span-12 sm:col-span-6 flex flex-row truncate px-3  py-3 sm:py-0 gap-4 sm:gap-4 md:gap-8 lg:gap-6  justify-center items-center bg-shoorah-secondary text-white  rounded-full">
                <p className="flex truncate">Save Assesment</p>
                <div>
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.44006 13.2801L10.7867 8.9334C11.3001 8.42006 11.3001 7.58006 10.7867 7.06673L6.44006 2.72006"
                      stroke="white"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-4">
                <button className="w-full text-black border border-black  py-3 rounded-full">
                  Save as draft
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {addUserPopUpOpen && (
        <div className="flex justify-center items-center z-50 fixed h-screen w-screen top-0 left-0 bg-black/15  px-10">
          <div className=" h-fit w-[702px] rounded-[24px] px-16 py-4 bg-[#F4F4F4]">
            <div className="text-4xl mt-[16px]">Add Answers in Bulk</div>
            <div className="text-[#0B1D2E] text-[18px] font-thin mt-[10px] flex">
              Enter each answer choice on a seperate line
            </div>

            <div className="flex justify-center mt-[30px]">
              <div className="relative w-full">
                <div className="absolute left-4 top-4">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="opacity-50"
                  >
                    <g clipPath="url(#clip0_302_19223)">
                      <path
                        d="M16.4384 7.76362C16.4027 6.95987 16.3344 6.16644 16.2547 5.38522C16.0529 3.40836 14.456 1.81088 12.4801 1.60011C11.3187 1.47622 10.1303 1.37729 8.92123 1.37729C7.71212 1.37729 6.52378 1.47622 5.36234 1.60011C3.38644 1.81088 1.78957 3.40836 1.58781 5.38522C1.47002 6.53927 1.37732 7.71996 1.37732 8.9212C1.37732 10.1224 1.47002 11.3031 1.58781 12.4572C1.78957 14.4341 3.38656 16.0315 5.36247 16.2423C6.32421 16.3449 7.3044 16.4303 8.29954 16.4567"
                        stroke="#0B1D2E"
                        strokeWidth="1.428"
                        strokeLinecap="round"
                      />
                      <path
                        d="M5.20227 6.73435V6.18918C5.20227 5.54864 5.65954 4.99484 6.29462 4.91143C8.05762 4.67983 9.77632 4.67983 11.5393 4.91143C12.1744 4.99484 12.6317 5.54864 12.6317 6.18918V6.73435"
                        stroke="#0B1D2E"
                        strokeWidth="1.428"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.91711 4.73773V11.2177"
                        stroke="#0B1D2E"
                        strokeWidth="1.428"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.4455 11.1897L11.6435 15.5036L11.1227 17.9533C11.0389 18.3475 11.4498 18.7204 11.8345 18.5995L14.2724 17.8331L18.1993 13.701C18.8516 13.0147 18.7378 11.8669 17.9482 11.1686C17.1773 10.4866 16.0568 10.4961 15.4455 11.1897Z"
                        stroke="#0B1D2E"
                        strokeWidth="1.428"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_302_19223">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <textarea
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  maxLength={maxLength}
                  placeholder="Enter reason here..."
                  className="w-full h-[240px] pt-3 pl-12 border rounded-2xl resize-none text-[#0B1D2E] border-[#E7E8EA] focus:outline-none focus:ring-2 focus:ring-gray-200"
                />
                <div className="absolute bottom-2 right-4 py-5 px-6 text-gray-400 text-sm">
                  *{maxLength} Character limit
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-[40px] mb-[10px] gap-6">
              <button
                className="text-black border border-black px-10  rounded-full p-3"
                onClick={() => {
                  setAddUserPopUpOpen(false);
                }}
              >
                Cancel
              </button>
              <button className=" border bg-shoorah-primary text-white border-shoorah-primary px-14 py-2 rounded-full p-5">
                Save
              </button>
              <button className=" "></button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewAssessment;
