import validator from 'validator';

// Publish assessment validation
export const AddSelfAssessmentValidation = (data, assessmentType) => {
  const errors = {};
  if (validator.isEmpty(data.title.trim())) {
    errors.title = '*Title is required';
  }

  if (validator.isEmpty(data.description.trim())) {
    errors.description = '*Description is required';
  }

  if (
    validator.isEmpty(data.aboutAssessment.trim()) ||
    validator.isEmpty(data.whoIsThisFor.trim()) ||
    validator.isEmpty(data.isItAccurate.trim())
  ) {
    errors.aboutAssessment = '*This field is required';
  }
  if (assessmentType === 0) {
    console.log(data);
    if (data.resultMessage && validator.isEmpty(data.resultMessage[0]?.trim())) {
      errors.message0To25 = '*Message required';
    }
    if (data.resultMessage && validator.isEmpty(data.resultMessage[1]?.trim())) {
      errors.message26To50 = '*Message required';
    }
    if (data.resultMessage && validator.isEmpty(data.resultMessage[2]?.trim())) {
      errors.message51To75 = '*Message required';
    }
    if (data.resultMessage && validator.isEmpty(data.resultMessage[3]?.trim())) {
      errors.message76To100 = '*Message required';
    }
  }

  if (validator.isEmpty(data.medicalExpert?.trim())) {
    errors.medicalExpert = '*Expert required';
  }

  if (validator.isEmpty(data.publishedBy)) {
    errors.publishedBy = '*Name required';
  }

  // if (validator.isEmpty(data.questionTitle.trim())) {
  //   errors.questionTitle = '*Question title is required';
  // }

  // data?.answers?.forEach((answer, index) => {
  //   if (validator.isEmpty(answer?.value.trim())) {
  //     errors[`answerValue_${index}`] = `*Answer ${index + 1} is required`;
  //   }
  //   if (answer?.weight === '' || answer?.weight === null) {
  //     errors[`answerWeight_${index}`] = `*Weight for answer ${index + 1} is required`;
  //   }
  //   if (!answer?.hasOwnProperty('weight')) {
  //     errors[`answerWeight_${index}`] = `*Weight for answer ${index + 1} is required`;
  //   }
  // });

  return { errors, isValid: Object.keys(errors).length <= 0 };
};
// Share assessment validation
export const EditSelfAssessmentValidation = (data) => {
  const errors = {};

  if (validator.isEmpty(data?.endHeading?.trim())) {
    errors.endHeading = '*Assessment end heading is required';
  }

  if (validator.isEmpty(data?.endBody?.trim())) {
    errors.endBody = '*Assessment end body is required';
  }

  return { errors, isValid: Object.keys(errors).length <= 0 };
};

// Draft assessment validation
export const DraftSelfAssessmentValidation = (data) => {
  const errors = {};

  if (validator.isEmpty(data.title.trim())) {
    errors.title = '*Title is required';
  }

  if (validator.isEmpty(data.description.trim())) {
    errors.description = '*Description is required';
  }

  return { errors, isValid: Object.keys(errors).length <= 0 };
};

// Add/Edit question validation
export const EditQuestionValidation = (data) => {
  const errors = {};

  if (validator.isEmpty(data.title.trim())) {
    errors.questionTitle = '*Question field is required';
  }
  data.options.forEach((option, index) => {
    if (validator.isEmpty(option.value.trim())) {
      errors[`option${index}`] = `*Answer field is required`;
    }
    if (option.weight === '' || option.weight === null) {
      errors[`weight${index}`] = `*Weight is required`;
    }
    if (!option.hasOwnProperty('weight')) {
      errors[`weight${index}`] = `*Weight is required`;
    }
  });

  return { errors, isValid: Object.keys(errors).length <= 0 };
};
