import moment from 'moment';
import React from 'react';

const ProfileCard = ({ data }) => {
  const fName = data?.name?.split(' ')?.[0];
  const lName = data?.name?.split(' ')?.[1];
  return (
    <div className="flex flex-col md:flex-row gap-[2rem] flex-wrap 2xl:flex-nowrap ">
      <div className="flex flex-col md:flex-row gap-[2rem]">
        <div className="rounded-xl bg-shoorah-primary w-full max-h-[200px] h-full md:w-[300px] overflow-hidden flex justify-center items-center ">
          {data?.userProfile ? (
            <img
              src={data?.userProfile}
              alt="Profile Picture"
              className="w-full h-full object-cover"
            />
          ) : (
            <p className="text-white">No profile image available</p>
          )}
        </div>
        <div className="rounded-xl bg-shoorah-primary w-full max-h-[200px] h-full md:w-[300px] flex justify-center overflow-hidden items-center">
          {data?.introVideo ? (
            <video width="100%" height="100%" controls autoPlay muted loop>
              <source src={data.introVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <p className="text-white">No video available</p>
          )}
        </div>
      </div>

      <div className="rounded-xl md:pl-3 w-full">
        <p className="text-xl dark:text-white text-black">Personal Details</p>

        <div className="rounded-xl mt-3 md:mt-6 flex flex-wrap lg:flex-nowrap justify-around gap-3 lg:gap-10 items-end w-full">
          <div className="rounded-xl text-shoorah-gray2 flex flex-col justify-around gap-3 w-full">
            <div className="rounded-xl flex items-center gap-[1rem]  w-full">
              <label className="w-[6rem]  text-shoorah-gray2 pt-1">Title</label>
              <p className="w-[5rem] capitalize bg-white text-shoorah-gray3  border border-gray-200 h-fit appearance-none outline-none py-2 rounded-xl px-3">
                {data?.title || 'N/A'}
              </p>
            </div>

            <div className="rounded-xl  flex items-center gap-[1rem]  w-full">
              <label className="w-[6rem] shrink-0  text-shoorah-gray2 leading-tight pt-1">
                First Name
              </label>
              <p className="bg-white w-full  text-shoorah-gray3 border border-gray-200 h-fit outline-none py-2 rounded-xl px-3">
                {fName || 'N/A'}
              </p>
            </div>

            <div className="rounded-xl flex items-center gap-[1rem]  w-full">
              <label className="w-[6rem] shrink-0  leading-tight text-shoorah-gray2 pt-1">
                Last Name
              </label>
              <p className="bg-white w-full  text-shoorah-gray3 border border-gray-200 h-fit outline-none py-2 rounded-xl px-3">
                {lName || 'N/A'}
              </p>
            </div>
          </div>
          <div className="rounded-xl flex flex-col  md:justify-end gap-3 w-full">
            <div className="rounded-xl flex  items-center gap-[1rem]  w-full">
              <label className="w-[6rem]  lg:w-[4rem] shrink-0  text-shoorah-gray2 pt-1">
                Location
              </label>
              <p className="bg-white w-full  text-shoorah-gray3 border border-gray-200 h-fit outline-none py-2 rounded-xl px-3">
                {data?.country || 'N/A'}
              </p>
            </div>

            <div className="rounded-xl flex items-center gap-[1rem]  w-full">
              <label className="w-[6rem]  lg:w-[4rem] shrink-0  text-shoorah-gray2 pt-1">DOB</label>
              <p className="bg-white w-full  text-shoorah-gray3 border border-gray-200 h-fit outline-none py-2 rounded-xl px-3">
                {moment(data?.dob).format('DD.MM.YYYY') || 'N/A'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
