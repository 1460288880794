import UserIdIcon from '../../../assets/images/userIdIcon.svg';
import EducationIcon from '../../../assets/images/EducationIcon.svg';
import SheildIcon from '../../../assets/images/SheildIcon.svg';
import LinkedInIcon from '../../../assets/images/LinkedInIcon.svg';
import BagIcon from '../../../assets/images/BagRoundIcon.svg';

const SpecialistInformation = ({ data }) => {
  const informations = [
    {
      icons: UserIdIcon,
      label: 'Medical Id no.',
      value: data?.medicalId
    },
    {
      icons: EducationIcon,
      label: 'Education',
      value: data?.highestCertification
    },
    {
      icons: SheildIcon,
      label: 'Current Place of Practice',
      value: data?.locationOfPractice
    },
    {
      icons: LinkedInIcon,
      label: 'Linkedln URL',
      value: data?.socialId
    },
    {
      icons: BagIcon,
      label: 'Years of Practical Experience',
      value: data?.yearOfExperience
    }
  ];
  return (
    <div className="border-2 relative border-gray-300 rounded-3xl px-6 lg:px-10 pt-3 lg:pt-6 pb-6 lg:pb-12 bg-white dark:bg-shoorah-darkBgTabColor dark:border-none dark:text-white ">
      <div className="text-black space-y-6 lg:space-y-10 dark:text-white text-lg  font-medium w-full ">
        <div>
          <h4 className="mb-3">Specialist Information</h4>
          <p className="text-sm font-medium text-shoorah-gray2">
            Specialist provide in-depth information on their profiles, offering clients of clear
            understanding of there expertise.
          </p>
        </div>
        <div className="space-y-4">
          {informations.map((item) => {
            return (
              <div
                key={item.label}
                className="w-full px-4 py-2 flex gap-3 md:gap-5 items-center rounded-2xl border-2 border-gray-300 "
              >
                <div className="flex shrink-0 w-8 h-8  justify-center items-center text-shoorah-primary">
                  <img src={item.icons} alt="UserId Icon" />
                  {/* {item.icons} */}
                </div>
                <div className="w-full flex flex-col justify-between max-w-full">
                  <p className="text-sm text-shoorah-gray2">{item.label}</p>
                  <div className="pr-3 text-shoorah-primary dark:text-shoorah-gray1 h-fit max-w-full w-full line-clamp-1 md:text-xl">
                    {item.value}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SpecialistInformation;
