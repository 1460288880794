import React from 'react';

const DashboardInfoCard = ({ icon, title, value }) => {
  return (
    <div className="w-full sm:w-3/12 md:w-full h-[96px] bg-[#FFFFFF] rounded-[8px] border-[1.5px] border-[#E7E8EA] flex items-center p-5">
      <div className="flex flex-row items-center w-full">
        <div className="flex justify-center items-center">{icon}</div>
        <div className="flex flex-col text-start ml-4">
          <p className="text-[14px] tracking-tight">{title}</p>
          <p className="text-[24px] font-medium">{value}</p>
        </div>
      </div>
    </div>
  );
};

export default DashboardInfoCard;
