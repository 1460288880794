export const DEADOMAINS = [
  '@0-mail.com',
  '@001.igg.biz',
  '@0815.ru',
  '@0815.su',
  '@027168.com',
  '@0box.eu',
  '@0clickemail.com',
  '@0wnd.net',
  '@0wnd.org',
  '@0x01.gq',
  '@0x01.tk',
  '@10mail.com',
  '@10mail.org',
  '@10minut.xyz',
  '@10minut.com.pl',
  '@10minutemail.co.uk',
  '@10minutemail.ga',
  '@10minutemail.co.za',
  '@10minutemail.com',
  '@10minutemail.ml',
  '@10minutemail.net',
  '@10minutemail.nl',
  '@10minutemail.us',
  '@10minutemail.pro',
  '@10minutemailbox.com',
  '@10minutemail.gq',
  '@10minutemails.in',
  '@10minutesmail.fr',
  '@10minutenemail.de',
  '@10minutemail.be',
  '@10minutemail.cf',
  '@10x9.com',
  '@11163.com',
  '@12hosting.net',
  '@12houremail.com',
  '@10minutesmail.com',
  '@12minutemail.com',
  '@12storage.com',
  '@14n.co.uk',
  '@15qm.com',
  '@1ce.us',
  '@1chuan.com',
  '@1clck2.com',
  '@10minutemail.de',
  '@1pad.de',
  '@1usemail.com',
  '@10minutmail.pl',
  '@1zhuan.com',
  '@20mail.eu',
  '@20email.eu',
  '@20mail.it',
  '@2120001.net',
  '@20mm.eu',
  '@123-m.com',
  '@21cn.com',
  '@2ether.net',
  '@2prong.com',
  '@2odem.com',
  '@2wc.info',
  '@30wave.com',
  '@3202.com',
  '@33mail.com',
  '@36ru.com',
  '@3d-painting.com',
  '@3l6.com',
  '@3mail.ga',
  '@4057.com',
  '@418.dk',
  '@4mail.cf',
  '@42o.org',
  '@4mail.ga',
  '@2anom.com',
  '@55hosting.net',
  '@4warding.com',
  '@5july.org',
  '@5oz.ru',
  '@5mail.cf',
  '@5x25.com',
  '@5gramos.com',
  '@2nd-mail.xyz',
  '@675hosting.com',
  '@6ip.us',
  '@5ymail.com',
  '@60minutemail.com',
  '@20minutemail.com',
  '@6mail.ga',
  '@6mail.ml',
  '@6paq.com',
  '@6url.com',
  '@80665.com',
  '@4warding.net',
  '@7tags.com',
  '@8mail.ml',
  '@99.com',
  '@7mail.ml',
  '@99experts.com',
  '@9ox.net',
  '@a45.in',
  '@a7996.com',
  '@a-bc.net',
  '@aa5zy64.com',
  '@abakiss.com',
  '@abyssmail.com',
  '@abcmail.email',
  '@abuser.eu',
  '@academiccommunity.com',
  '@academywe.us',
  '@acentri.com',
  '@adiq.eu',
  '@achievewe.us',
  '@aditus.info',
  '@add3000.pp.ua',
  '@ado888.biz',
  '@adsd.org',
  '@advantimo.com',
  '@adwaterandstir.com',
  '@aegia.net',
  '@aegde.com',
  '@afrobacon.com',
  '@agtx.net',
  '@aheadwe.us',
  '@9mail.cf',
  '@ahk.jp',
  '@air2token.com',
  '@airsi.de',
  '@al-qaeda.us',
  '@akapost.com',
  '@alienware13.com',
  '@akgq701.com',
  '@agger.ro',
  '@aligamel.com',
  '@alisongamel.com',
  '@alivance.com',
  '@allamericanwe.us',
  '@alldirectbuy.com',
  '@alliancewe.us',
  '@alph.wtf',
  '@alphaomegawe.us',
  '@ama-trade.de',
  '@amail.club',
  '@amail4.me',
  '@amazon-aws.org',
  '@amelabs.com',
  '@amiri.net',
  '@ampsylike.com',
  '@amail.com',
  '@analogwe.us',
  '@andthen.us',
  '@andreihusanu.ro',
  '@anappthat.com',
  '@anappfor.com',
  '@allowed.org',
  '@animesos.com',
  '@ano-mail.net',
  '@anon-mail.de',
  '@anit.ro',
  '@anonbox.net',
  '@anonmail.top',
  '@anonymail.dk',
  '@anonmails.de',
  '@anonymbox.com',
  '@anonymousness.com',
  '@anthony-junkmail.com',
  '@antireg.com',
  '@antireg.ru',
  '@antispam.de',
  '@anyalias.com',
  '@aoeuhtns.com',
  '@appc.se',
  '@apkmd.com',
  '@appixie.com',
  '@ama-trans.de',
  '@aphlog.com',
  '@apfelkorps.de',
  '@apps.dj',
  '@appinventor.nl',
  '@apssdc.ml',
  '@akerd.com',
  '@ariaz.jetzt',
  '@arduino.hk',
  '@armyspy.com',
  '@art-en-ligne.pro',
  '@aron.us',
  '@asdasd.nl',
  '@asdasd.ru',
  '@arvato-community.de',
  '@ashleyandrew.com',
  '@asdhgsad.com',
  '@asorent.com',
  '@astroempires.info',
  '@asu.mx',
  '@asu.su',
  '@averdov.com',
  '@aver.com',
  '@autorobotica.com',
  '@autotwollow.com',
  '@avia-tonic.fr',
  '@ass.pp.ua',
  '@avls.pt',
  '@auti.st',
  '@awatum.de',
  '@awiki.org',
  '@axiz.org',
  '@antispam24.de',
  '@axsup.net',
  '@azcomputerworks.com',
  '@b2bx.net',
  '@badpotato.tk',
  '@banit.club',
  '@bareed.ws',
  '@barryogorman.com',
  '@badgerland.eu',
  '@basscode.org',
  '@bauwerke-online.com',
  '@banit.me',
  '@badoop.com',
  '@bcast.ws',
  '@bccto.me',
  '@bcb.ro',
  '@beddly.com',
  '@beefmilk.com',
  '@bearsarefuzzy.com',
  '@betr.co',
  '@bgtmail.com',
  '@bgx.ro',
  '@binkmail.com',
  '@bigstring.com',
  '@bigprofessor.so',
  '@bij.pl',
  '@bio-muesli.net',
  '@binka.me',
  '@blogmyway.org',
  '@binnary.com',
  '@bladesmail.net',
  '@bitwhites.top',
  '@blogspam.ro',
  '@blip.ch',
  '@bluewerks.com',
  '@bartdevos.be',
  '@bluedumpling.info',
  '@bobmail.info',
  '@bootybay.de',
  '@bofthew.com',
  '@borged.com',
  '@boun.cr',
  '@bot.nu',
  '@bouncr.com',
  '@boximail.com',
  '@boxformail.in',
  '@boxtemp.com.br',
  '@brasx.org',
  '@breakthru.com',
  '@brefmail.com',
  '@broadbandninja.com',
  '@bsnow.net',
  '@bspamfree.org',
  '@bst-72.com',
  '@brennendesreich.de',
  '@btcmail.pw',
  '@btizet.pl',
  '@bugmenot.com',
  '@bulrushpress.com',
  '@bundes-li.ga',
  '@bund.us',
  '@bumpymail.com',
  '@bunchofidiots.com',
  '@bunsenhoneydew.com',
  '@burnthespam.info',
  '@burstmail.info',
  '@businesssuccessislifesuccess.com',
  '@buspad.org',
  '@businessbackend.com',
  '@buymoreplays.com',
  '@buzzcluby.com',
  '@byebyemail.com',
  '@byom.de',
  '@cam4you.cc',
  '@bum.net',
  '@car101.pro',
  '@cars2.club',
  '@carbtc.net',
  '@cbair.com',
  '@carsencyclopedia.com',
  '@candymail.de',
  '@casualdx.com',
  '@cane.pw',
  '@cavi.mx',
  '@ceed.se',
  '@cellurl.com',
  '@ch.tc',
  '@cek.pm',
  '@centermail.com',
  '@chacuo.net',
  '@chaichuang.com',
  '@centermail.net',
  '@chalupaurybnicku.cz',
  '@cdpa.cc',
  '@cetpass.com',
  '@chammy.info',
  '@cheaphub.net',
  '@choco.la',
  '@chilkat.com',
  '@choicemail1.com',
  '@chong-mail.com',
  '@chong-mail.net',
  '@chibakenma.ml',
  '@chickenkiller.com',
  '@chielo.com',
  '@chumpstakingdumps.com',
  '@cigar-auctions.com',
  '@cheatmail.de',
  '@civx.org',
  '@cjpeg.com',
  '@clandest.in',
  '@ckiso.com',
  '@clickdeal.co',
  '@clixser.com',
  '@cmail.net',
  '@cmail.club',
  '@cmail.com',
  '@clrmail.com',
  '@cmail.org',
  '@cnamed.com',
  '@cnmsg.net',
  '@clipmail.eu',
  '@cocovpn.com',
  '@cnew.ir',
  '@coldemail.info',
  '@coieo.com',
  '@completegolfswing.com',
  '@comwest.de',
  '@consumerriot.com',
  '@contbay.com',
  '@coolandwacky.us',
  '@coreclip.com',
  '@coolimpool.org',
  '@coza.ro',
  '@courrieltemporaire.com',
  '@crapmail.org',
  '@crazymailing.com',
  '@crastination.de',
  '@crazespaces.pw',
  '@cross-law.gq',
  '@crossroadsmail.com',
  '@cross-law.ga',
  '@cream.pink',
  '@csh.ro',
  '@crusthost.com',
  '@cu.cc',
  '@cuirushi.org',
  '@curryworld.de',
  '@cust.in',
  '@cubiclink.com',
  '@cuvox.de',
  '@cyber-innovation.club',
  '@cyber-phone.eu',
  '@cylab.org',
  '@d1yun.com',
  '@dab.ro',
  '@d3p.dk',
  '@daemsteam.com',
  '@daintly.com',
  '@damai.webcam',
  '@daryxfox.net',
  '@dataarca.com',
  '@dammexe.net',
  '@daibond.info',
  '@damnthespam.com',
  '@davidkoh.net',
  '@dbunker.com',
  '@datum2.com',
  '@dayrep.com',
  '@dawin.com',
  '@dbz5mchild.com',
  '@deadchildren.org',
  '@dcemail.com',
  '@de-a.org',
  '@ddcrew.com',
  '@deadfake.cf',
  '@deagot.com',
  '@dealja.com',
  '@defomail.com',
  '@deekayen.us',
  '@delayload.net',
  '@degradedfun.net',
  '@demen.ml',
  '@datazo.ca',
  '@dengekibunko.gq',
  '@delayload.com',
  '@derkombi.de',
  '@dev-null.cf',
  '@derluxuswagen.de',
  '@dev-null.gq',
  '@dev-null.ga',
  '@dev-null.ml',
  '@devnullmail.com',
  '@deyom.com',
  '@der-kombi.de',
  '@dhy.cc',
  '@digitalsanctuary.com',
  '@digitalmariachis.com',
  '@diapaulpainting.com',
  '@dildosfromspace.com',
  '@dhm.ro',
  '@dingbone.com',
  '@disbox.org',
  '@discard.cf',
  '@disaq.com',
  '@discard.email',
  '@dialogus.com',
  '@discard.gq',
  '@discardmail.com',
  '@discard.ga',
  '@discardmail.de',
  '@discard.ml',
  '@disign-concept.eu',
  '@disposable.cf',
  '@disign-revelation.com',
  '@disposableemailaddresses.com',
  '@disposableinbox.com',
  '@disposablemails.com',
  '@disposeamail.com',
  '@dispose.it',
  '@dispomail.eu',
  '@dispostable.com',
  '@disposemail.com',
  '@divermail.com',
  '@disposableaddress.com',
  '@diwaq.com',
  '@dndent.com',
  '@divismail.ru',
  '@dnses.ro',
  '@doanart.com',
  '@dob.jp',
  '@dlemail.ru',
  '@dodgeit.com',
  '@dodgit.com',
  '@dodgit.org',
  '@donemail.ru',
  '@divad.ga',
  '@doiea.com',
  '@domforfb9.tk',
  '@dodsi.com',
  '@domozmail.com',
  '@dongqing365.com',
  '@dontsendmespam.de',
  '@dotman.de',
  '@doquier.tk',
  '@dontreg.com',
  '@dotmsg.com',
  '@dp76.com',
  '@dr69.site',
  '@dred.ru',
  '@dodgemail.de',
  '@drdrb.com',
  '@drivetagdev.com',
  '@drdrb.net',
  '@drevo.si',
  '@dropmail.me',
  '@dropcake.de',
  '@dolphinnet.net',
  '@dsiay.com',
  '@droplar.com',
  '@dspwebservices.com',
  '@dumpandjunk.com',
  '@dumpyemail.com',
  '@dumpmail.de',
  '@durandinterstellar.com',
  '@dqnwara.com',
  '@duam.net',
  '@duskmail.com',
  '@e-mailbox.ga',
  '@e-mail.com',
  '@e4ward.com',
  '@duck2.club',
  '@e-mail.org',
  '@e3z.de',
  '@e-tomarigi.com',
  '@easy-trash-mail.com',
  '@eay.jp',
  '@easytrashmail.com',
  '@ebeschlussbuch.de',
  '@eatmea2z.club',
  '@easynetwork.info',
  '@edgex.ru',
  '@einrot.com',
  '@eelmail.com',
  '@eintagsmail.de',
  '@efxs.ca',
  '@ecolo-online.fr',
  '@electro.mn',
  '@email-fake.com',
  '@email-fake.cf',
  '@email-fake.ga',
  '@email-fake.gq',
  '@email-fake.ml',
  '@email-temp.com',
  '@email-jetable.fr',
  '@email.net',
  '@emailage.cf',
  '@emailfake.ml',
  '@emaildienst.de',
  '@emailgo.de',
  '@emailfreedom.ml',
  '@emailigo.de',
  '@emailias.com',
  '@emailinfive.com',
  '@dz17.net',
  '@emailna.co',
  '@emails.ga',
  '@emailresort.com',
  '@emailsecurer.com',
  '@emailsingularity.net',
  '@emailsensei.com',
  '@emailisvalid.com',
  '@emailo.pro',
  '@emaillime.com',
  '@emailspam.ml',
  '@emailtemporanea.net',
  '@emailtemporanea.com',
  '@emailthe.net',
  '@emailtemporario.com.br',
  '@emailtech.info',
  '@emailtmp.com',
  '@emailspam.cf',
  '@emailto.de',
  '@emailz.ml',
  '@emailz.gq',
  '@emailure.net',
  '@emil.com',
  '@emailwarden.com',
  '@emeil.ir',
  '@emailxfer.com',
  '@eml.pp.ua',
  '@emz.net',
  '@emlpro.com',
  '@empireanime.ga',
  '@emlhub.com',
  '@emltmp.com',
  '@esprity.com',
  '@esc.la',
  '@ero-tube.org',
  '@ephemail.net',
  '@envy17.com',
  '@esemay.com',
  '@ethersportz.info',
  '@ether123.net',
  '@estate-invest.fr',
  '@ethereum1.top',
  '@ethersports.org',
  '@epb.ro',
  '@ephemeral.email',
  '@etlgr.com',
  '@evilcomputer.com',
  '@evyush.com',
  '@emeil.in',
  '@euaqa.com',
  '@exitstageleft.net',
  '@evopo.com',
  '@explodemail.com',
  '@express.net.ua',
  '@eyepaste.com',
  '@extremail.ru',
  '@ezfill.com',
  '@ez.lv',
  '@ezstest.com',
  '@f4k.es',
  '@f5.si',
  '@fackme.gq',
  '@fadingemail.com',
  '@fake-mail.cf',
  '@faithkills.com',
  '@fake-email.pp.ua',
  '@fakedemail.com',
  '@fakeinbox.info',
  '@fammix.com',
  '@fakemail.fr',
  '@fakemailgenerator.com',
  '@fakemailz.com',
  '@fantasymail.de',
  '@fangoh.com',
  '@fastchevy.com',
  '@fakeinformation.com',
  '@fakeinbox.com',
  '@fast-mail.fr',
  '@fastacura.com',
  '@fastkawasaki.com',
  '@fastchrysler.com',
  '@fastmazda.com',
  '@fastsubaru.com',
  '@fastmitsubishi.com',
  '@fastnissan.com',
  '@fastsuzuki.com',
  '@fasttoyota.com',
  '@fastyamaha.com',
  '@fatflap.com',
  '@fightallspam.com',
  '@fbma.tk',
  '@fictionsite.com',
  '@fddns.ml',
  '@fettometern.com',
  '@figjs.com',
  '@fir.hk',
  '@film-blog.biz',
  '@filzmail.com',
  '@fivemail.de',
  '@findu.pl',
  '@figshot.com',
  '@flowu.com',
  '@fluidsoft.us',
  '@flemail.ru',
  '@fleckens.hu',
  '@fizmail.com',
  '@flurred.com',
  '@flyinggeek.net',
  '@flyspam.com',
  '@foobarbot.net',
  '@footard.com',
  '@forecastertests.com',
  '@foreskin.cf',
  '@foreskin.gq',
  '@foreskin.ga',
  '@foreskin.ml',
  '@foreskin.tk',
  '@fornow.eu',
  '@forspam.net',
  '@forward.cat',
  '@forgetmail.com',
  '@foxja.com',
  '@fr33mail.info',
  '@foxtrotter.info',
  '@fr.nf',
  '@frapmail.com',
  '@free-email.cf',
  '@freedompop.us',
  '@freecat.net',
  '@freelance-france.eu',
  '@freeletter.me',
  '@freemails.ml',
  '@freemail.tweakly.net',
  '@freeplumpervideos.com',
  '@freesistercam.com',
  '@freemailzone.com',
  '@freundin.ru',
  '@friendlymail.co.uk',
  '@front14.org',
  '@fuckme69.club',
  '@ftpinc.ca',
  '@ftp.sh',
  '@fuckedupload.com',
  '@fudgerub.com',
  '@fucknloveme.top',
  '@fulvie.com',
  '@fun64.com',
  '@fuckxxme.top',
  '@fuirio.com',
  '@funnymail.de',
  '@furusato.tokyo',
  '@fyii.de',
  '@fuwamofu.com',
  '@furzauflunge.de',
  '@g2xmail.top',
  '@g3xmail.top',
  '@gafy.net',
  '@g1xmail.top',
  '@fxnxs.com',
  '@gaggle.net',
  '@galaxy.tv',
  '@gally.jp',
  '@garasikita.pw',
  '@gamgling.com',
  '@gardenscape.ca',
  '@gamail.top',
  '@garbagemail.org',
  '@garizo.com',
  '@garliclife.com',
  '@garrymccooey.com',
  '@gbcmail.win',
  '@gav0.com',
  '@gbmail.top',
  '@gdmail.top',
  '@geekforex.com',
  '@gelitik.in',
  '@gcmail.top',
  '@geew.ru',
  '@gedmail.win',
  '@geronra.com',
  '@genderfuck.net',
  '@get1mail.com',
  '@get2mail.fr',
  '@get-mail.ml',
  '@get.pp.ua',
  '@getfun.men',
  '@getairmail.com',
  '@ghosttexter.de',
  '@geteit.com',
  '@getmails.eu',
  '@giantmail.de',
  '@gifto12.com',
  '@getonemail.com',
  '@ginzi.net',
  '@giacmosuaviet.info',
  '@girlmail.win',
  '@getonemail.net',
  '@gishpuppy.com',
  '@ginzi.be',
  '@glucosegrin.com',
  '@givmail.com',
  '@glubex.com',
  '@giyam.com',
  '@gmal.com',
  '@ginzy.eu',
  '@gmial.com',
  '@getnada.com',
  '@gmx.fr.nf',
  '@gmx1mail.top',
  '@gmxmail.top',
  '@go2vpn.net',
  '@gmxmail.win',
  '@golemico.com',
  '@gmatch.org',
  '@googlemail.com',
  '@gorillaswithdirtyarmpits.com',
  '@gotimes.xyz',
  '@gotmail.com',
  '@gotmail.net',
  '@gotmail.org',
  '@grandmasmail.com',
  '@greenhousemail.com',
  '@gowikitv.com',
  '@grandmamail.com',
  '@greggamel.com',
  '@greensloth.com',
  '@greggamel.net',
  '@gregorygamel.com',
  '@gregorygamel.net',
  '@grish.de',
  '@grr.la',
  '@griuc.schule',
  '@gsrv.co.uk',
  '@gudanglowongan.com',
  '@guerillamail.info',
  '@guerillamail.de',
  '@guerillamail.com',
  '@guerillamail.net',
  '@guerillamail.org',
  '@guerrillamail.info',
  '@guerrillamail.de',
  '@guerrillamail.net',
  '@guerrillamail.org',
  '@guerrillamailblock.com',
  '@gxemail.men',
  '@guerrillamail.biz',
  '@gustr.com',
  '@guerrillamail.com',
  '@gynzy.at',
  '@gynzy.es',
  '@gynzy.gr',
  '@gynzy.info',
  '@gynzy.lt',
  '@gynzy.mobi',
  '@gynzy.eu',
  '@gynzy.sk',
  '@gynzy.ro',
  '@gynzy.pl',
  '@gzb.ro',
  '@gomail.in',
  '@h8s.org',
  '@habitue.net',
  '@hacccc.com',
  '@haida-edu.cn',
  '@hackersquad.tk',
  '@haltospam.com',
  '@hackthatbit.ch',
  '@harakirimail.com',
  '@haribu.com',
  '@hartbot.de',
  '@hatespam.org',
  '@hccmail.win',
  '@haydoo.com',
  '@headstrong.de',
  '@heathenhammer.com',
  '@hecat.es',
  '@hezll.com',
  '@herp.in',
  '@hi5.si',
  '@herpderp.nl',
  '@hidebox.org',
  '@hmamail.com',
  '@hmh.ro',
  '@hoanggiaanh.com',
  '@hoanglong.tech',
  '@hochsitze.com',
  '@hola.org',
  '@holl.ga',
  '@hidzz.com',
  '@hmail.us',
  '@hidemail.de',
  '@honor-8.com',
  '@hoo.com',
  '@hopemail.biz',
  '@hotmial.com',
  '@hostlaba.com',
  '@hotmai.com',
  '@hotpop.com',
  '@hotprice.co',
  '@hs.vc',
  '@hpc.tw',
  '@housat.com',
  '@huangniu8.com',
  '@ht.cx',
  '@i6.cloudns.cc',
  '@hulapla.de',
  '@hungpackage.com',
  '@humaility.com',
  '@hvastudiesucces.nl',
  '@i6.cloudns.cx',
  '@iaoss.com',
  '@ichigo.me',
  '@icx.in',
  '@ieatspam.info',
  '@ige.es',
  '@ieh-mail.de',
  '@ihazspam.ca',
  '@ignoremail.com',
  '@icx.ro',
  '@iheartspam.org',
  '@ilovespam.com',
  '@ikbenspamvrij.nl',
  '@imgof.com',
  '@imails.info',
  '@imul.info',
  '@imstations.com',
  '@imgv.de',
  '@inbax.tk',
  '@inbound.plus',
  '@inboxalias.com',
  '@inbox.si',
  '@inboxbear.com',
  '@inboxclean.com',
  '@inboxed.pw',
  '@inclusiveprogress.com',
  '@inboxstore.me',
  '@inboxclean.org',
  '@inboxdesign.me',
  '@inboxproxy.com',
  '@indomina.cf',
  '@incognitomail.org',
  '@indomaed.pw',
  '@incq.com',
  '@incognitomail.com',
  '@ind.st',
  '@inggo.org',
  '@indosukses.press',
  '@inoutmail.de',
  '@infocom.zp.ua',
  '@insorg-mail.info',
  '@incognitomail.net',
  '@instance-email.com',
  '@instantemailaddress.com',
  '@instant-mail.de',
  '@investore.co',
  '@iozak.com',
  '@ip4.pp.ua',
  '@ip6.li',
  '@instantmail.fr',
  '@intersteller.com',
  '@interstats.org',
  '@internetoftags.com',
  '@ipoo.org',
  '@ippandansei.tk',
  '@ip6.pp.ua',
  '@irabops.com',
  '@irish2me.com',
  '@ipsur.org',
  '@ironiebehindert.de',
  '@isdaq.com',
  '@iroid.com',
  '@iuemail.men',
  '@italy-mail.com',
  '@iwi.net',
  '@ixx.io',
  '@j-p.us',
  '@jafps.com',
  '@isosq.com',
  '@j7.cloudns.cx',
  '@jajxz.com',
  '@jdmadventures.com',
  '@jellyrolls.com',
  '@jdz.ro',
  '@janproz.com',
  '@jetable.com',
  '@jet-renovation.fr',
  '@jetable.net',
  '@jnxjn.com',
  '@jetable.org',
  '@jetable.pp.ua',
  '@jmail.ro',
  '@jmail.ovh',
  '@jobposts.net',
  '@jobs-to-be-done.net',
  '@joetestalot.com',
  '@jopho.com',
  '@joseihorumon.info',
  '@joelpet.com',
  '@jourrapide.com',
  '@josse.ltd',
  '@jsrsolutions.com',
  '@jpco.org',
  '@justemail.ml',
  '@jumonji.tk',
  '@junk.to',
  '@kademen.com',
  '@jwork.ru',
  '@kariplan.com',
  '@juyouxi.com',
  '@kakadua.net',
  '@karatraman.ml',
  '@kasmail.com',
  '@katztube.com',
  '@keepmymail.com',
  '@kcrw.de',
  '@kazelink.ml',
  '@kaspop.com',
  '@kekita.com',
  '@kiois.com',
  '@keipino.de',
  '@kennedy808.com',
  '@killmail.net',
  '@kismail.ru',
  '@kir.ch.tc',
  '@kiani.com',
  '@killmail.com',
  '@klassmaster.com',
  '@kludgemush.com',
  '@kloap.com',
  '@klipschx12.com',
  '@klick-tipp.us',
  '@knol-power.nl',
  '@klzlk.com',
  '@kosmetik-obatkuat.com',
  '@kommunity.biz',
  '@kmhow.com',
  '@kostenlosemailadresse.de',
  '@kopagas.com',
  '@koszmail.pl',
  '@krypton.tk',
  '@krsw.tk',
  '@ksmtrck.tk',
  '@kwilco.net',
  '@kyal.pl',
  '@kyois.com',
  '@l-c-a.us',
  '@lacedmail.com',
  '@lackmail.net',
  '@kulturbetrieb.info',
  '@kurzepost.de',
  '@lain.ch',
  '@lags.us',
  '@kwift.net',
  '@lak.pp.ua',
  '@lackmail.ru',
  '@landmail.co',
  '@lakelivingstonrealestate.com',
  '@last-chance.pro',
  '@laoeq.com',
  '@lawlita.com',
  '@lastmail.co',
  '@lazyinbox.com',
  '@ldaho.net',
  '@lazyinbox.us',
  '@ldop.com',
  '@leeching.net',
  '@lee.mx',
  '@ldtp.com',
  '@lenovog4.com',
  '@letthemeatspam.com',
  '@letmeinonthis.com',
  '@lillemap.net',
  '@lhsdv.com',
  '@lifetotech.com',
  '@lifebyfood.com',
  '@lgxscreen.com',
  '@lez.se',
  '@lilo.me',
  '@lkgn.se',
  '@loadby.us',
  '@link2mail.net',
  '@loan101.pro',
  '@litedrop.com',
  '@llogin.ru',
  '@linkedintuts2016.pw',
  '@loaoa.com',
  '@loh.pp.ua',
  '@loapq.com',
  '@logular.com',
  '@loin.in',
  '@lolfreak.net',
  '@losemymail.com',
  '@lovemeet.faith',
  '@lookugly.com',
  '@lortemail.dk',
  '@lovemeleaveme.com',
  '@lr78.com',
  '@lroid.com',
  '@lzoaq.com',
  '@luckymail.org',
  '@lukop.dk',
  '@lru.me',
  '@m21.cc',
  '@magamail.com',
  '@macr2.com',
  '@lukecarriere.com',
  '@macromice.info',
  '@maboard.com',
  '@maggotymeat.ga',
  '@mail-filter.com',
  '@magicbox.ro',
  '@maidlow.info',
  '@mail-temporaire.com',
  '@mail-temporaire.fr',
  '@mail.by',
  '@m4ilweb.info',
  '@mail114.net',
  '@mail1a.de',
  '@mail22.club',
  '@mail333.com',
  '@mail4-us.org',
  '@mail.wtf',
  '@mail-easy.fr',
  '@mail2rss.org',
  '@mail666.ru',
  '@mail707.com',
  '@mail4trash.com',
  '@mailback.com',
  '@mail72.com',
  '@mailbidon.com',
  '@mailbiz.biz',
  '@mailbox52.ga',
  '@mailblocks.com',
  '@mailbucket.org',
  '@mailbox92.biz',
  '@mailcat.biz',
  '@mailcatch.com',
  '@mailcker.com',
  '@mailchop.com',
  '@maildrop.cc',
  '@mailde.de',
  '@mailde.info',
  '@maildrop.cf',
  '@maildu.de',
  '@maileater.com',
  '@maildrop.ml',
  '@mailed.in',
  '@maileme101.com',
  '@maildx.com',
  '@mailexpire.com',
  '@maileimer.de',
  '@mailfall.com',
  '@mailfa.tk',
  '@mailfree.ga',
  '@mailf5.com',
  '@mailforspam.com',
  '@mailfree.gq',
  '@mailfs.com',
  '@mailfree.ml',
  '@mailfreeonline.com',
  '@mailguard.me',
  '@mailinatar.com',
  '@mailhz.me',
  '@mailgutter.com',
  '@mailhazard.com',
  '@mailinator.net',
  '@mailhazard.us',
  '@mailimate.com',
  '@mailinator.co.uk',
  '@mailinater.com',
  '@mailinator.info',
  '@mailinator.com',
  '@mailinator2.com',
  '@mailinator.org',
  '@mailincubator.com',
  '@mailinator.us',
  '@mailjunk.gq',
  '@mailin8r.com',
  '@mailismagic.com',
  '@mailjunk.cf',
  '@mailjunk.ml',
  '@mailmate.com',
  '@mailme.ir',
  '@mailme24.com',
  '@mailmoat.com',
  '@mailna.biz',
  '@mailmoth.com',
  '@mailmetrash.com',
  '@mailms.com',
  '@mailme.gq',
  '@mailna.co',
  '@mailna.me',
  '@mailnesia.com',
  '@mailnator.com',
  '@mailme.lv',
  '@mailnull.com',
  '@mailna.in',
  '@mailorg.org',
  '@mailpick.biz',
  '@mailrock.biz',
  '@mailproxsy.com',
  '@mailsac.com',
  '@mailshell.com',
  '@mailsiphon.com',
  '@mailscrap.com',
  '@mailsucker.net',
  '@mailslite.com',
  '@mailslapping.com',
  '@mailtechx.com',
  '@mailtemp.info',
  '@mailtemporaire.fr',
  '@mailquack.com',
  '@mailtome.de',
  '@mailtemporaire.com',
  '@mailtothis.com',
  '@mailtv.tv',
  '@mailtv.net',
  '@mailtrash.net',
  '@mailzi.ru',
  '@mailtrix.net',
  '@mailtraps.com',
  '@makemenaughty.club',
  '@mailzilla.org',
  '@makemetheking.com',
  '@malayalamdtp.com',
  '@malahov.de',
  '@mansiondev.com',
  '@malove.site',
  '@mankyrecords.com',
  '@manifestgenerator.com',
  '@manybrain.com',
  '@markmurfin.com',
  '@mailzilla.com',
  '@maswae.world',
  '@mbx.cc',
  '@matamuasu.ga',
  '@matchpol.net',
  '@mciek.com',
  '@mcache.net',
  '@messagebeamer.de',
  '@mechanicalresumes.com',
  '@meinspamschutz.de',
  '@merry.pink',
  '@mebelnu.info',
  '@meltmail.com',
  '@messwiththebestdielikethe.rest',
  '@mezimages.net',
  '@mfsa.info',
  '@miaferrari.com',
  '@mfsa.ru',
  '@midcoastcustoms.com',
  '@midcoastcustoms.net',
  '@midlertidig.com',
  '@midcoastsolutions.net',
  '@midlertidig.net',
  '@midcoastsolutions.com',
  '@midlertidig.org',
  '@miauj.com',
  '@migmail.net',
  '@mierdamail.com',
  '@mdhc.tk',
  '@mihep.com',
  '@migmail.pl',
  '@mijnhva.nl',
  '@ministry-of-silly-walks.de',
  '@minsmail.com',
  '@mintemail.com',
  '@mkpfilm.com',
  '@ml8.ca',
  '@misterpinball.de',
  '@mji.ro',
  '@mnode.me',
  '@moakt.ws',
  '@moakt.co',
  '@moakt.com',
  '@mobileninja.co.uk',
  '@mm.my',
  '@moburl.com',
  '@mobilevpn.top',
  '@mockmyid.com',
  '@mm5.se',
  '@moeri.org',
  '@mohmal.com',
  '@mohmal.im',
  '@momentics.ru',
  '@monachat.tk',
  '@monadi.ml',
  '@moneypipe.net',
  '@monumentmail.com',
  '@moot.es',
  '@moreawesomethanyou.com',
  '@mohmal.tech',
  '@moreorcs.com',
  '@mohmal.in',
  '@morsin.com',
  '@motique.de',
  '@moza.pl',
  '@mozej.com',
  '@mox.pp.ua',
  '@mr24.co',
  '@msgos.com',
  '@mswork.ru',
  '@mspeciosa.com',
  '@mt2009.com',
  '@mt2015.com',
  '@mtmdev.com',
  '@muathegame.com',
  '@muchomail.com',
  '@munoubengoshi.gq',
  '@msxd.com',
  '@mucincanon.com',
  '@mwarner.org',
  '@mxfuel.com',
  '@mvrht.com',
  '@mvrht.net',
  '@mutant.me',
  '@my10minutemail.com',
  '@mybitti.de',
  '@mountainregionallibrary.net',
  '@mydemo.equipment',
  '@mycleaninbox.net',
  '@mycorneroftheinter.net',
  '@myemailboxy.com',
  '@myindohome.services',
  '@mymail-in.net',
  '@mynetstore.de',
  '@mypacks.net',
  '@myspaceinc.com',
  '@mypartyclip.de',
  '@myphantomemail.com',
  '@myspamless.com',
  '@mystvpn.com',
  '@mytemp.email',
  '@mytempmail.com',
  '@mytempemail.com',
  '@mytrashmail.com',
  '@myzx.com',
  '@mziqo.com',
  '@nabuma.com',
  '@n1nja.org',
  '@nada.ltd',
  '@nada.email',
  '@nakedtruth.biz',
  '@nanonym.ch',
  '@negated.com',
  '@neko2.net',
  '@nbox.notif.me',
  '@neomailbox.com',
  '@nervmich.net',
  '@nepwk.com',
  '@nervtmich.net',
  '@netmails.com',
  '@netmails.net',
  '@netricity.nl',
  '@netris.net',
  '@netzidiot.de',
  '@nevermail.de',
  '@nezdiro.org',
  '@nezzart.com',
  '@nfast.net',
  '@nguyenusedcars.com',
  '@nh3.ro',
  '@nice-4u.com',
  '@nincsmail.com',
  '@nincsmail.hu',
  '@niwl.net',
  '@nm7.cc',
  '@next.ovh',
  '@newbpotato.tk',
  '@nnh.com',
  '@nnoway.ru',
  '@nnot.net',
  '@nobulk.com',
  '@nobuma.com',
  '@no-ux.com',
  '@noclickemail.com',
  '@nokiamail.com',
  '@noicd.com',
  '@nolemail.ga',
  '@nomail.cf',
  '@nomail.pw',
  '@nomail.ga',
  '@nomail2me.com',
  '@nomorespamemails.com',
  '@nonspam.eu',
  '@nonspammer.de',
  '@norseforce.com',
  '@nonze.ro',
  '@nospam4.us',
  '@nospamfor.us',
  '@no-spam.ws',
  '@notmailinator.com',
  '@nospamthanks.info',
  '@notrnailinator.com',
  '@notsharingmy.info',
  '@now.im',
  '@nowhere.org',
  '@nowmymail.com',
  '@nubescontrol.com',
  '@ntlhelp.net',
  '@nullbox.info',
  '@nutpa.net',
  '@nurfuerspam.de',
  '@nothingtoseehere.ca',
  '@ny7.me',
  '@nypato.com',
  '@o2stk.org',
  '@obfusko.com',
  '@o7i.net',
  '@nwytg.com',
  '@nwldx.com',
  '@objectmail.com',
  '@obxpestcontrol.com',
  '@odaymail.com',
  '@obobbo.com',
  '@odem.com',
  '@odnorazovoe.ru',
  '@oepia.com',
  '@ohaaa.de',
  '@offshore-proxies.net',
  '@oalsp.com',
  '@ohi.tw',
  '@okrent.us',
  '@okzk.com',
  '@olypmall.ru',
  '@omail.pro',
  '@oerpub.org',
  '@oneoffemail.com',
  '@omnievents.org',
  '@onewaymail.com',
  '@onlatedotcom.info',
  '@onlineidea.info',
  '@oopi.org',
  '@onlysext.com',
  '@oneoffmail.com',
  '@oolus.com',
  '@online.ms',
  '@opmmedia.ga',
  '@oroki.de',
  '@ordinaryamerican.net',
  '@opayq.com',
  '@opendns.ro',
  '@oreidresume.com',
  '@otherinbox.com',
  '@outlawspam.com',
  '@ourpreviewdomain.com',
  '@ourawesome.life',
  '@outmail.win',
  '@ovpn.to',
  '@owlpic.com',
  '@p71ce1m.com',
  '@p33.org',
  '@ozyl.de',
  '@pagamenti.tk',
  '@pakadebu.ga',
  '@pancakemail.com',
  '@pastebitch.com',
  '@paplease.com',
  '@pepbot.com',
  '@peterdethier.com',
  '@payperex2.com',
  '@pfui.ru',
  '@piaa.me',
  '@pig.pp.ua',
  '@photomark.net',
  '@photo-impact.eu',
  '@pii.at',
  '@piki.si',
  '@pingir.com',
  '@pa9e.com',
  '@pisls.com',
  '@pjjkp.com',
  '@plhk.ru',
  '@ploae.com',
  '@plw.me',
  '@pojok.ml',
  '@pavilionx2.com',
  '@pokemail.net',
  '@pi.vu',
  '@polarkingxx.ml',
  '@pooae.com',
  '@politikerclub.de',
  '@pookmail.com',
  '@posta.store',
  '@porsh.net',
  '@popesodomy.com',
  '@posdz.com',
  '@postonline.me',
  '@poutineyourface.com',
  '@powlearn.com',
  '@ppetw.com',
  '@predatorrat.cf',
  '@predatorrat.ga',
  '@predatorrat.gq',
  '@predatorrat.ml',
  '@predatorrat.tk',
  '@premium-mail.fr',
  '@privacy.net',
  '@primabananen.net',
  '@privatdemail.net',
  '@privymail.de',
  '@privy-mail.com',
  '@projectcl.com',
  '@privy-mail.de',
  '@procrackers.com',
  '@propscore.com',
  '@protempmail.com',
  '@proxymail.eu',
  '@pro-tag.org',
  '@psles.com',
  '@powered.name',
  '@psh.me',
  '@prtnx.com',
  '@psoxs.com',
  '@puglieisi.com',
  '@punkass.com',
  '@purcell.email',
  '@putthisinyourspamdatabase.com',
  '@qasti.com',
  '@qisdo.com',
  '@qc.to',
  '@qibl.at',
  '@qisoa.com',
  '@qiq.us',
  '@qoika.com',
  '@put2.net',
  '@qsl.ro',
  '@qtum-ico.com',
  '@ququb.com',
  '@quickmail.nl',
  '@quickinbox.com',
  '@pwrby.com',
  '@ra3.us',
  '@qvy.me',
  '@r4nd0m.de',
  '@rabin.ca',
  '@rabiot.reisen',
  '@raetp9.com',
  '@raketenmann.de',
  '@randomail.net',
  '@purelogistics.org',
  '@raqid.com',
  '@razemail.com',
  '@rbb.org',
  '@rdklcrv.xyz',
  '@rcasd.com',
  '@rcpt.at',
  '@reality-concept.club',
  '@reallymymail.com',
  '@receiveee.com',
  '@recyclemail.dk',
  '@recode.me',
  '@recipeforfailure.com',
  '@re-gister.com',
  '@reconmail.com',
  '@reftoken.net',
  '@reliable-mail.com',
  '@remarkable.rocks',
  '@remote.li',
  '@realtyalerts.ca',
  '@redfeathercrow.com',
  '@reptilegenetics.com',
  '@rejo.technology',
  '@remail.cf',
  '@remail.ga',
  '@riddermark.de',
  '@rhyta.com',
  '@rmqkr.net',
  '@risingsuntouch.com',
  '@rkomo.com',
  '@richfinances.pw',
  '@rma.ec',
  '@rollindo.agency',
  '@ro.lt',
  '@rnailinator.com',
  '@rootfest.net',
  '@ronnierage.net',
  '@rowe-solutions.com',
  '@rotaniliam.com',
  '@rtrtr.com',
  '@rppkn.com',
  '@royal.net',
  '@ruffrey.com',
  '@rtskiya.xyz',
  '@rumgel.com',
  '@rustydoor.com',
  '@ruru.be',
  '@runi.ca',
  '@rvb.ro',
  '@s0ny.net',
  '@safersignup.de',
  '@s33db0x.com',
  '@sabrestlouis.com',
  '@safermail.info',
  '@safetymail.info',
  '@safetypost.de',
  '@sanstr.com',
  '@salmeow.tk',
  '@sanfinder.com',
  '@samsclass.info',
  '@sandelf.de',
  '@sausen.com',
  '@sawoe.com',
  '@scatmail.com',
  '@schachrol.com',
  '@satisfyme.club',
  '@scay.net',
  '@schafmail.de',
  '@schmid.cf',
  '@schrott-email.de',
  '@secmail.pw',
  '@sd3.in',
  '@secretemail.de',
  '@secure-box.info',
  '@revolvingdoorhoax.org',
  '@secure-mail.biz',
  '@secure-mail.cc',
  '@seekjobs4u.com',
  '@seekapps.com',
  '@sendingspecialflyers.com',
  '@sendfree.org',
  '@selfdestructingmail.com',
  '@server.ms',
  '@shalar.net',
  '@sendspamhere.com',
  '@senseless-entertainment.com',
  '@sexical.com',
  '@secured-link.net',
  '@sharklasers.com',
  '@shieldedmail.com',
  '@sharedmailbox.org',
  '@shieldemail.com',
  '@shiftmail.com',
  '@shitmail.org',
  '@shipfromto.com',
  '@shitmail.de',
  '@shitmail.me',
  '@shitware.nl',
  '@shotmail.ru',
  '@shortmail.net',
  '@showslow.de',
  '@shut.name',
  '@sify.com',
  '@shrib.com',
  '@sikux.com',
  '@sizzlemctwizzle.com',
  '@siteposter.net',
  '@skeefmail.com',
  '@sin.cl',
  '@sinfiltro.cl',
  '@sinnlos-mail.de',
  '@sino.tw',
  '@slaskpost.se',
  '@slippery.email',
  '@slipry.net',
  '@slopsbox.com',
  '@slothmail.net',
  '@skypaluten.de',
  '@sluteen.com',
  '@slushmail.com',
  '@sly.io',
  '@smashmail.de',
  '@smarttalent.pw',
  '@smtp99.com',
  '@smellypotato.tk',
  '@slave-auctions.net',
  '@smellfear.com',
  '@smwg.info',
  '@snapwet.com',
  '@sneakmail.de',
  '@snakemail.com',
  '@sofortmail.de',
  '@sofort-mail.de',
  '@sogetthis.com',
  '@socrazy.club',
  '@sofimail.com',
  '@sohu.com',
  '@sohus.cn',
  '@sohai.ml',
  '@socrazy.online',
  '@solvemail.info',
  '@solar-impact.pro',
  '@soisz.com',
  '@soioa.com',
  '@soodmail.com',
  '@spam.su',
  '@soon.it',
  '@spam-be-gone.com',
  '@soodomail.com',
  '@spamarrest.com',
  '@soodonims.com',
  '@spamavert.com',
  '@spambog.com',
  '@spambob.com',
  '@spambog.de',
  '@spambog.ru',
  '@spambob.net',
  '@spambooger.com',
  '@spambox.org',
  '@spambox.irishspringrealty.com',
  '@spambox.us',
  '@spam4.me',
  '@spamcero.com',
  '@spamcorptastic.com',
  '@spamcon.org',
  '@spamcowboy.net',
  '@spamcowboy.com',
  '@spamdecoy.net',
  '@spamday.com',
  '@spamex.com',
  '@spamfighter.gq',
  '@spamfighter.cf',
  '@spamfighter.ml',
  '@spamfree24.de',
  '@spamfree.eu',
  '@spamfree24.com',
  '@spamfree24.eu',
  '@spamcowboy.org',
  '@spamhereplease.com',
  '@spamfree24.info',
  '@spamhole.com',
  '@spamify.com',
  '@spaminator.de',
  '@spaml.com',
  '@spaml.de',
  '@spamslicer.com',
  '@spammotel.com',
  '@spamoff.de',
  '@spamtrap.ro',
  '@spamthis.co.uk',
  '@spamwc.cf',
  '@spamwc.ga',
  '@spamwc.ml',
  '@spamwc.gq',
  '@spoofmail.de',
  '@spikio.com',
  '@spybox.de',
  '@spr.io',
  '@ssoia.com',
  '@spamobox.com',
  '@sroff.com',
  '@squizzy.de',
  '@startfu.com',
  '@squizzy.net',
  '@statdvr.com',
  '@stanfordujjain.com',
  '@startkeys.com',
  '@starlight-breaker.net',
  '@stathost.net',
  '@stop-my-spam.cf',
  '@steambot.net',
  '@stelliteop.info',
  '@stinkefinger.net',
  '@stop-my-spam.com',
  '@stop-my-spam.ml',
  '@storiqax.top',
  '@stexsy.com',
  '@storiqax.com',
  '@stop-my-spam.pp.ua',
  '@storj99.com',
  '@storj99.top',
  '@streetwisemail.com',
  '@suckmyd.com',
  '@stuffmail.de',
  '@suburbanthug.com',
  '@stuckmail.com',
  '@sudomail.com',
  '@sudolife.me',
  '@sudolife.net',
  '@sudomail.biz',
  '@sudoverse.com',
  '@sudomail.net',
  '@sudoweb.net',
  '@sudoverse.net',
  '@sudoworld.com',
  '@superplatyna.com',
  '@sudoworld.net',
  '@suioe.com',
  '@supermailer.jp',
  '@suremail.info',
  '@stop-my-spam.ga',
  '@superrito.com',
  '@superstachel.de',
  '@svip520.cn',
  '@sweetpotato.ml',
  '@svxr.org',
  '@sweetxxx.de',
  '@svk.jp',
  '@super-auswahl.de',
  '@swift10minutemail.com',
  '@tafmail.com',
  '@symphonyresume.com',
  '@tafoi.gr',
  '@szerz.com',
  '@syujob.accountants',
  '@taglead.com',
  '@tagyourself.com',
  '@talkinator.com',
  '@tagmymedia.com',
  '@tapchicuoihoi.com',
  '@tanukis.org',
  '@taphear.com',
  '@tb-on-line.net',
  '@techemail.com',
  '@tech69.com',
  '@techgroup.me',
  '@teerest.com',
  '@teewars.org',
  '@tefl.ro',
  '@telecomix.pl',
  '@tellos.xyz',
  '@teleworm.com',
  '@teleworm.us',
  '@temp-mail.de',
  '@temp-mails.com',
  '@temp-mail.org',
  '@temp-mail.pp.ua',
  '@temp-mail.ru',
  '@tempemail.com',
  '@tempail.com',
  '@tempemail.biz',
  '@temp-mail.com',
  '@tempe-mail.com',
  '@tempalias.com',
  '@tempinbox.com',
  '@tempinbox.co.uk',
  '@tempemail.net',
  '@tempmail.eu',
  '@tempmail.de',
  '@tempmail2.com',
  '@tempmaildemo.com',
  '@tempmail.pp.ua',
  '@tempmailer.com',
  '@tempmail.us',
  '@tempmail.it',
  '@tempmailer.de',
  '@tempomail.fr',
  '@temporarily.de',
  '@tempsky.com',
  '@tempymail.com',
  '@ternaklele.ga',
  '@testore.co',
  '@temporarymailaddress.com',
  '@temporarioemail.com.br',
  '@tempthe.net',
  '@testudine.com',
  '@thankyou2010.com',
  '@thanksnospam.info',
  '@thelimestones.com',
  '@theaviors.com',
  '@thatim.info',
  '@thedirhq.info',
  '@thediamants.org',
  '@thex.ro',
  '@thembones.com.au',
  '@thismail.net',
  '@thrma.com',
  '@thisisnotmyrealemail.com',
  '@thietbivanphong.asia',
  '@thrott.com',
  '@thraml.com',
  '@thnikka.com',
  '@throwawaymail.pp.ua',
  '@throwam.com',
  '@throwawayemailaddress.com',
  '@thunkinator.org',
  '@throya.com',
  '@tic.ec',
  '@tinoza.org',
  '@throam.com',
  '@tittbit.in',
  '@tipsb.com',
  '@tiv.cc',
  '@throwawaymail.com',
  '@tilien.com',
  '@tkitc.de',
  '@tizi.com',
  '@tm2mail.com',
  '@tlpn.org',
  '@thxmate.com',
  '@tmail.com',
  '@tmail.ws',
  '@tmpjr.me',
  '@tmpmail.net',
  '@tmails.net',
  '@tmpeml.info',
  '@tokem.co',
  '@toiea.com',
  '@toddsbighug.com',
  '@tmpmail.org',
  '@tokenmail.de',
  '@tonymanso.com',
  '@toomail.biz',
  '@top1post.ru',
  '@tmailinator.com',
  '@toon.ml',
  '@tosunkaya.com',
  '@tp-qa-mail.com',
  '@totalvista.com',
  '@totesmail.com',
  '@toss.pw',
  '@topinrock.cf',
  '@topranklist.de',
  '@tormail.org',
  '@toprumours.com',
  '@tradermail.info',
  '@tqosi.com',
  '@tqoai.com',
  '@tranceversal.com',
  '@trash-amil.com',
  '@trash-mail.at',
  '@trash-mail.de',
  '@trash-mail.cf',
  '@trash-mail.com',
  '@trash-mail.gq',
  '@trashcanmail.com',
  '@trashdevil.com',
  '@trash-me.com',
  '@trashdevil.de',
  '@trash2009.com',
  '@trashemail.de',
  '@trashemails.de',
  '@trashmail.at',
  '@trashinbox.com',
  '@trashmail.com',
  '@trashmail.gq',
  '@trashmail.io',
  '@trashmail.me',
  '@trashmail.net',
  '@trashmail.org',
  '@trashmails.com',
  '@trashmailer.com',
  '@trashmail.ws',
  '@trashymail.net',
  '@trashymail.com',
  '@trasz.com',
  '@trayna.com',
  '@trbvm.com',
  '@trickmail.net',
  '@trbvn.com',
  '@trbvo.com',
  '@trollproject.com',
  '@trillianpro.com',
  '@trialmail.de',
  '@trungtamtoeic.com',
  '@tryalert.com',
  '@turoid.com',
  '@tvchd.com',
  '@tualias.com',
  '@trashmail.de',
  '@twocowmail.net',
  '@twoweirdtricks.com',
  '@twinmail.de',
  '@txtadvertise.com',
  '@tverya.com',
  '@tyldd.com',
  '@uacro.com',
  '@ubm.md',
  '@tyhe.ro',
  '@ubismail.net',
  '@ugimail.net',
  '@uemail99.com',
  '@ujijima1129.gq',
  '@uiu.us',
  '@uk.to',
  '@undo.it',
  '@uhhu.ru',
  '@unids.com',
  '@umail.net',
  '@unimark.org',
  '@upliftnow.com',
  '@urfunktion.se',
  '@uggsrock.com',
  '@uroid.com',
  '@us.to',
  '@ushijima1129.cf',
  '@usako.net',
  '@us.af',
  '@ushijima1129.ga',
  '@used-product.fr',
  '@ushijima1129.gq',
  '@ushijima1129.tk',
  '@uwork4.us',
  '@ushijima1129.ml',
  '@uu.gl',
  '@uyhip.com',
  '@valemail.net',
  '@valhalladev.com',
  '@venompen.com',
  '@uu2.ovh',
  '@veo.kr',
  '@ver0.cf',
  '@vankin.de',
  '@vctel.com',
  '@vda.ro',
  '@vemomail.win',
  '@ver0.ml',
  '@ver0.tk',
  '@ver0.ga',
  '@verdejo.com',
  '@ver0.gq',
  '@veryday.ch',
  '@veryrealemail.com',
  '@via.tokyo.jp',
  '@veryday.info',
  '@vidchart.com',
  '@vfemail.net',
  '@vikingsonly.com',
  '@viditag.com',
  '@vipepe.com',
  '@vinernet.com',
  '@vipmail.pw',
  '@veryday.eu',
  '@visal168.ga',
  '@visal007.tk',
  '@viralplays.com',
  '@visal168.tk',
  '@vmani.com',
  '@vixletdev.com',
  '@vmpanda.com',
  '@vomoto.com',
  '@votiputox.org',
  '@voxelcore.com',
  '@vpn.st',
  '@vorga.org',
  '@vps30.com',
  '@vkcode.ru',
  '@vps911.net',
  '@vssms.com',
  '@vrmtr.com',
  '@vubby.com',
  '@vuiy.pw',
  '@vztc.com',
  '@w3internet.co.uk',
  '@walkmail.ru',
  '@walkmail.net',
  '@wallm.com',
  '@wazabi.club',
  '@wbml.net',
  '@web-mail.pp.ua',
  '@web-ideal.fr',
  '@web2mailco.com',
  '@webcontact-france.eu',
  '@webtrip.ch',
  '@wegwerf-email.at',
  '@weg-werf-email.de',
  '@wegwerf-email.de',
  '@wee.my',
  '@wegwerf-emails.de',
  '@wegwerfemail.com',
  '@wegwerfadresse.de',
  '@wegwerfemail.de',
  '@wegwerfemail.net',
  '@vsimcard.com',
  '@wegwerfemailadresse.com',
  '@wegwerf-email.net',
  '@wegwerfmail.info',
  '@wegwerpmailadres.nl',
  '@wegwerfemail.info',
  '@wg0.com',
  '@wegwerfmail.de',
  '@wegwerfmail.org',
  '@wegwerfmail.net',
  '@whatsaas.com',
  '@whatifanalytics.com',
  '@whatiaas.com',
  '@whyspam.me',
  '@wickmail.net',
  '@wh4f.org',
  '@widget.gg',
  '@wikidocuslava.ru',
  '@whiffles.org',
  '@widaryanto.info',
  '@wilemail.com',
  '@wimsg.com',
  '@willselfdestruct.com',
  '@willhackforfood.biz',
  '@winemaven.info',
  '@wins.com.br',
  '@wralawfirm.com',
  '@wmail.club',
  '@writeme.us',
  '@whatpaas.com',
  '@wudet.men',
  '@wronghead.com',
  '@wuespdj.xyz',
  '@whopy.com',
  '@wxnw.net',
  '@wwwnew.eu',
  '@wuzupmail.net',
  '@x24.com',
  '@xbaby69.top',
  '@xemne.com',
  '@xcompress.com',
  '@xcpy.com',
  '@xemaps.com',
  '@wuzup.net',
  '@xjoi.com',
  '@xents.com',
  '@xl.cx',
  '@xmail.com',
  '@xperiae5.com',
  '@xrho.com',
  '@xwaretech.net',
  '@xww.ro',
  '@xxi2.com',
  '@xxlocanto.us',
  '@xxqx3802.com',
  '@xxolocanto.us',
  '@xmaily.com',
  '@xwaretech.info',
  '@yahmail.top',
  '@xn--9kq967o.com',
  '@xwaretech.com',
  '@yamail.win',
  '@yaqp.com',
  '@yapped.net',
  '@yannmail.win',
  '@ycare.de',
  '@ye.vc',
  '@xzsok.com',
  '@ycn.ro',
  '@yep.it',
  '@yedi.org',
  '@yogamaven.com',
  '@yodx.ro',
  '@yoo.ro',
  '@yomail.info',
  '@yopmail.fr',
  '@yopmail.pp.ua',
  '@youmail.ga',
  '@yopmail.gq',
  '@you-spam.com',
  '@yopmail.net',
  '@yougotgoated.com',
  '@yourlms.biz',
  '@yspend.com',
  '@yourdomain.com',
  '@yourtube.ml',
  '@youmailr.com',
  '@youremail.cf',
  '@yui.it',
  '@ytpayy.com',
  '@yuurok.com',
  '@z0d.eu',
  '@yxzx.net',
  '@z1p.biz',
  '@zain.site',
  '@zasod.com',
  '@zebins.eu',
  '@z86.ru',
  '@zaktouni.fr',
  '@zebins.com',
  '@zehnminuten.de',
  '@zainmax.net',
  '@zfymail.com',
  '@zehnminutenmail.de',
  '@zepp.dk',
  '@zetmail.com',
  '@zhaoyuanedu.cn',
  '@zhcne.com',
  '@zhorachu.com',
  '@zik.dj',
  '@zhewei88.com',
  '@zoaxe.com',
  '@zoemail.net',
  '@zomg.info',
  '@zoemail.com',
  '@zippymail.info',
  '@zv68.com',
  '@zxcvbnm.com',
  '@zipcad.com',
  '@zzz.com',
  '@zymuying.com',
  '@mt2014.com',
  '@*.wupics.com',
  '@zzi.us',
  '@*.cfo2go.ro',
  '@*.onlysext.com',
  '@0mel.com',
  '@50mb.ml',
  '@tempr.email',
  '@*.spamtrap.ro',
  '@*.uu.gl',
  '@*.istii.ro',
  '@30minutemail.com',
  '@4warding.org',
  '@675hosting.org',
  '@675hosting.net',
  '@75hosting.com',
  '@75hosting.net',
  '@75hosting.org',
  '@amilegit.com',
  '@ajaxapp.net',
  '@amiriindustries.com',
  '@baxomale.ht.cx',
  '@antichef.net',
  '@antichef.com',
  '@bodhi.lawlita.com',
  '@chogmail.com',
  '@cool.fr.nf',
  '@correo.blogos.net',
  '@courriel.fr.nf',
  '@cosmorph.com',
  '@dandikmail.com',
  '@deadaddress.com',
  '@deadspam.com',
  '@dacoolest.com',
  '@despam.it',
  '@despammed.com',
  '@dfgh.net',
  '@Disposableemailaddresses:emailmiser.com',
  '@dm.w3internet.co.ukexample.com',
  '@dump-email.info',
  '@email60.com',
  '@emailmiser.com',
  '@emailx.at.hm',
  '@enterto.com',
  '@etranquil.com',
  '@etranquil.net',
  '@etranquil.org',
  '@fux0ringduh.com',
  '@girlsundertheinfluence.com',
  '@gowikibooks.com',
  '@gowikicampus.com',
  '@gowikicars.com',
  '@gowikifilms.com',
  '@gowikigames.com',
  '@gowikitravel.com',
  '@gowikinetwork.com',
  '@gowikimusic.com',
  '@great-host.in',
  '@guerillamail.biz',
  '@h.mintemail.com',
  '@ieatspam.eu',
  '@ihateyoualot.info',
  '@jetable.fr.nf',
  '@junk1e.com',
  '@klassmaster.net',
  '@lol.ovpn.to',
  '@lopl.co.cc',
  '@mail.mezimages.net',
  '@mega.zik.dj',
  '@monmail.fr.nf',
  '@msa.minsmail.com',
  '@monemail.fr.nf',
  '@mx0.wwwnew.eu',
  '@moncourrier.fr.nf',
  '@myspaceinc.org',
  '@myspaceinc.net',
  '@myspacepimpedup.com',
  '@neverbox.com',
  '@nogmailspam.info',
  '@nomail.xl.cx',
  '@nospam.ze.tc',
  '@nus.edu.sg',
  '@ourklips.com',
  '@poofy.org',
  '@pimpedupmyspace.com',
  '@qq.com',
  '@recursor.net',
  '@regbypass.com',
  '@regbypass.comsafe-mail.net',
  '@rklips.com',
  '@rejectmail.com',
  '@safe-mail.net',
  '@saynotospams.com',
  '@sibmail.com',
  '@sneakemail.com',
  '@spam.la',
  '@spambob.org',
  '@spamcannon.com',
  '@spamcannon.net',
  '@spambox.info',
  '@spamgourmet.org',
  '@spamgourmet.com',
  '@spamfree24.net',
  '@spamfree24.org',
  '@spamgourmet.net',
  '@SpamHereLots.com',
  '@spamkill.info',
  '@spamspot.com',
  '@spamthisplease.com',
  '@spamtrail.com',
  '@speed.1s.fr',
  '@supergreatmail.com',
  '@temporaryemail.net',
  '@temporaryforwarding.com',
  '@temporaryinbox.com',
  '@turual.com',
  '@uplipht.com',
  '@viewcastmedia.net',
  '@viewcastmedia.org',
  '@webm4il.info',
  '@wetrainbayarea.org',
  '@wetrainbayarea.com',
  '@viewcastmedia.com',
  '@www.gishpuppy.com',
  '@www.e4ward.com',
  '@www.mailinator.com',
  '@xagloo.com',
  '@xoxy.net',
  '@ypmail.webarnak.fr.eu.org',
  '@zoemail.org',
  '@1fsdfdsfsdf.tk',
  '@2fdgdfgdfgdf.tk',
  '@3trtretgfrfe.tk',
  '@5ghgfhfghfgh.tk',
  '@6hjgjhgkilkj.tk',
  '@4gfdsgfdgfd.tk',
  '@agedmail.com',
  '@antispammail.de',
  '@artman-conception.com',
  '@azmeil.tk',
  '@c2.hu',
  '@card.zp.ua',
  '@childsavetrust.org',
  '@delikkt.de',
  '@einmalmail.de',
  '@emailtemporar.ro',
  '@fansworldwide.de',
  '@fuckingduh.com',
  '@gehensiemirnichtaufdensack.de',
  '@goemailgo.com',
  '@gotti.otherinbox.com',
  '@hat-geld.de',
  '@mail.zp.ua',
  '@mail21.cc',
  '@mysamp.de',
  '@mycard.net.ua',
  '@noblepioneer.com',
  '@nospammail.net',
  '@poczta.onet.pl',
  '@pcusers.otherinbox.com',
  '@plexolan.de',
  '@services391.com',
  '@shmeriously.com',
  '@slapsfromlastnight.com',
  '@snkmail.com',
  '@spamail.de',
  '@spamgoes.in',
  '@spamtroll.net',
  '@tempemail.co.za',
  '@thc.st',
  '@wasteland.rfc822.org',
  '@webemail.me',
  '@x.ip6.li',
  '@za.com',
  '@mail.ru',
  '@parqueadero.work',
  '@zoho.com',
  '@gmx.es',
  '@gamil.com',
  '@outlouk.com',
  '@uul.pl',
  '@mailbox72.biz',
  '@autlook.com',
  '@homail.com',
  '@gamail.com',
  '@gmx.com',
  '@yomail.com',
  '@hotamil.com',
  '@outlok.com',
  '@oulook.com',
  '@gimail.com',
  '@hotmal.com',
  '@0ulook.com',
  '@outluo.com',
  '@yopmail.co',
  '@yotmail.com',
  '@outluk.com',
  '@gmai.com',
  '@autlook.es',
  '@htmail.com',
  '@outuok.com',
  '@outloo.com',
  '@bk.ru',
  '@yopail.com',
  '@yopmai.com',
  '@otmail.com',
  '@autluok.com',
  '@yandex.com',
  '@hotmaill.com',
  '@hotail.com',
  '@uotluok.com',
  '@gemail.com',
  '@ymail.com',
  '@otlook.es',
  '@7.fackme.gq',
  '@hornyalwary.top',
  '@latinchat.com',
  '@topmail.com',
  '@6.emailfake.ml',
  '@3.emailfake.ml',
  '@two.haddo.eu',
  '@7.emailfake.ml',
  '@x.fackme.gq',
  '@emailfake.com',
  '@riski.cf',
  '@emailtea.com',
  '@yop.ze.cx',
  '@sexyalwasmi.top',
  '@ggg.pp.ua',
  '@throwawaymail.uu.gl',
  '@hash.pp.ua',
  '@1000rebates.stream',
  '@pulpmail.us',
  '@yopmail.biz.st',
  '@qbfree.us',
  '@1.emailfake.ml',
  '@4.emailfake.ml',
  '@2.emailfake.ml',
  '@5.emailfake.ml',
  '@8.emailfake.ml',
  '@9.emailfake.ml',
  '@one.emailfake.ml',
  '@two.emailfake.ml',
  '@three.emailfake.ml',
  '@eight.emailfake.ml',
  '@five.emailfake.ml',
  '@four.emailfake.ml',
  '@nine.emailfake.ml',
  '@six.emailfake.ml',
  '@seven.emailfake.ml',
  '@three.fackme.gq',
  '@one.fackme.gq',
  '@two.fackme.gq',
  '@four.fackme.gq',
  '@five.fackme.gq',
  '@six.fackme.gq',
  '@nine.fackme.gq',
  '@eight.fackme.gq',
  '@seven.fackme.gq',
  '@x.emailfake.ml',
  '@latinmail.com',
  '@outluok.com',
  '@uu1.pl',
  '@fakemail.com',
  '@fakemail.net',
  '@1.fackme.gq',
  '@locanto1.club',
  '@2000rebates.stream',
  '@one.raikas77.eu',
  '@mailbox80.biz',
  '@8.fackme.gq',
  '@3.fackme.gq',
  '@2.fackme.gq',
  '@sezet.com',
  '@5.fackme.gq',
  '@6.fackme.gq',
  '@9.fackme.gq',
  '@4.fackme.gq',
  '@6-6-6.nut.cc',
  '@6-6-6.igg.biz',
  '@a.safe-mail.gq',
  '@6-6-6.usa.cc',
  '@affilikingz.de',
  '@btcmail.pwguerrillamail.net',
  '@cd.mintemail.com',
  '@emailfake.usa.cc',
  '@freelance-france.euposta.store',
  '@emailsy.info',
  '@kodorsex.cf',
  '@level3.flu.cc',
  '@level3.igg.biz',
  '@m5s.flu.cc',
  '@m5s.igg.biz',
  '@m5s.nut.cc',
  '@level3.nut.cc',
  '@level3.usa.cc',
  '@mbox.0x01.tk',
  '@my.safe-mail.gq',
  '@pw.j7.cloudns.cx',
  '@pw.mymy.cf',
  '@pw.mysafe.ml',
  '@rr.0x01.gq',
  '@s.0x01.gq',
  '@s-s.igg.biz',
  '@s-s.nut.cc',
  '@s-s.flu.cc',
  '@s-s.usa.cc',
  '@s0ny.igg.biz',
  '@s0ny.usa.cc',
  '@s0ny.nut.cc',
  '@spam.0x01.tk',
  '@ss.0x01.tk',
  '@queuem.com',
  '@txt.flu.cc',
  '@ti.igg.biz',
  '@txpwg.usa.cc',
  '@yop.0x01.gq',
  '@x.0x01.tk',
  '@s0ny.flu.cc',
  '@pay-mon.com',
  '@zep-hyr.com',
  '@travala10.com',
  '@zippiex.com',
  '@poly-swarm.com',
  '@1shivom.com',
  '@hubii-network.com',
  '@fidelium10.com',
  '@hurify1.com',
  '@bit-degree.com',
  '@0815.ry',
  '@0815.ru0clickemail.com',
  '@0845.ru',
  '@126.com',
  '@12minutemail.net',
  '@139.com',
  '@163.com',
  '@1mail.ml',
  '@24hourmail.com',
  '@420blaze.it',
  '@5mail.ga',
  '@6mail.cf',
  '@7days-printing.com',
  '@7mail.ga',
  '@8127ep.com',
  '@8mail.cf',
  '@8chan.co',
  '@8mail.ga',
  '@a.mailcker.com',
  '@a.vztc.com',
  '@ag.us.to',
  '@aktiefmail.nl',
  '@anonymousmail.org',
  '@anonymousspeech.com',
  '@atvclub.msk.ru',
  '@avpa.nl',
  '@b2cmail.de',
  '@big1.us',
  '@bio-muesli.info',
  '@blackmarket.to',
  '@bloatbox.com',
  '@blogos.com',
  '@bluebottle.com',
  '@br.mintemail.com',
  '@brainonfire.net',
  '@bu.mintemail.com',
  '@buffemail.com',
  '@bugmenever.com',
  '@buyusedlibrarybooks.org',
  '@cachedot.net',
  '@cc.liamria',
  '@chong-mail.org',
  '@cock.li',
  '@cumallover.me',
  '@deadfake.ga',
  '@deadfake.ml',
  '@deadfake.tk',
  '@dicksinmyan.us',
  '@dharmatel.net',
  '@dicksinhisan.us',
  '@discard.tk',
  '@disposable.ml',
  '@disposable.ga',
  '@disposable-email.ml',
  '@dm.w3internet.co.uk',
  '@docmail.com',
  '@droplister.com',
  '@dudmail.com',
  '@ee1.pl',
  '@edv.to',
  '@ee2.pl',
  '@einrot.de',
  '@emailage.gq',
  '@emailage.ga',
  '@emailage.tk',
  '@emailage.ml',
  '@email-fake.tk',
  '@emailspam.ga',
  '@emailspam.gq',
  '@emailspam.tk',
  '@emailz.cf',
  '@emailz.ga',
  '@emkei.cf',
  '@emkei.ga',
  '@emkei.gq',
  '@emkei.ml',
  '@emkei.tk',
  '@e-postkasten.de',
  '@e-postkasten.com',
  '@e-postkasten.info',
  '@e-postkasten.eu',
  '@facebook-email.cf',
  '@facebook-email.ga',
  '@facebookmail.gq',
  '@facebook-email.ml',
  '@facebookmail.ml',
  '@faecesmail.me',
  '@example.com',
  '@fakeinbox.cf',
  '@fakeinbox.ga',
  '@fakeinbox.ml',
  '@fake-mail.ml',
  '@fake-mail.ga',
  '@fastermail.com',
  '@fastmail.fm',
  '@fakeinbox.tk',
  '@fdfdsfds.com',
  '@fixmail.tk',
  '@fly-ts.de',
  '@freecoolemail.com',
  '@free-email.ga',
  '@freemail.ms',
  '@freemails.cf',
  '@freemails.ga',
  '@fuckmail.me',
  '@gawab.com',
  '@garrifulio.mailexpire.com',
  '@geschent.biz',
  '@getairmail.cf',
  '@getairmail.ga',
  '@getairmail.tk',
  '@getairmail.gq',
  '@get-mail.cf',
  '@getairmail.ml',
  '@get-mail.ga',
  '@get-mail.tk',
  '@goat.si',
  '@guerillamailblock.com',
  '@hooohush.ai',
  '@horsefucker.org',
  '@hot-mail.cf',
  '@hot-mail.ga',
  '@hot-mail.gq',
  '@hot-mail.tk',
  '@hot-mail.ml',
  '@huajiachem.cn',
  '@hush.ai',
  '@hushmail.com',
  '@hush.com',
  '@i2pmail.org',
  '@inbox2.info',
  '@hushmail.me',
  '@inboxed.im',
  '@iwantmyname.com',
  '@is.af',
  '@junkmail.ga',
  '@k2-herbal-incenses.com',
  '@junkmail.gq',
  '@l33r.eu',
  '@lavabit.com',
  '@linuxmail.so',
  '@login-email.cf',
  '@lol.com',
  '@login-email.tk',
  '@login-email.ga',
  '@login-email.ml',
  '@lovebitco.in',
  '@lr7.us',
  '@loves.dicksinmyan.us',
  '@loves.dicksinhisan.us',
  '@luv2.us',
  '@ma1l.bij.pl',
  '@mac.hush.com',
  '@mail.me',
  '@mail2world.com',
  '@maildrop.ga',
  '@maildrop.gq',
  '@mailinator.gq',
  '@mailita.tk',
  '@mailjunk.ga',
  '@mailjunk.tk',
  '@mailseal.de',
  '@mailwithyou.com',
  '@migumail.com',
  '@mmmmail.com',
  '@mjukglass.nu',
  '@mobi.web.id',
  '@ms9.mailslite.com',
  '@msg.mailslite.com',
  '@msb.minsmail.com',
  '@mythrashmail.net',
  '@national.shitposting.agency',
  '@nigge.rs',
  '@nmail.cf',
  '@nobugmail.com',
  '@noref.in',
  '@nospam.wins.com.br',
  '@o2.co.uk',
  '@o2.pl',
  '@phentermine-mortgages-texas-holdem.biz',
  '@prtz.eu',
  '@rcs.gaggle.net',
  '@redchan.it',
  '@schmeissweg.tk',
  '@selfdestructingmail.org',
  '@shut.ws',
  '@sina.cn',
  '@sina.com',
  '@sky-ts.de',
  '@smaakt.naar.gravel',
  '@smapfree24.eu',
  '@smapfree24.de',
  '@smapfree24.com',
  '@smapfree24.org',
  '@smapfree24.info',
  '@spambog.net',
  '@spamfighter.ga',
  '@spamfighter.tk',
  '@spamsalad.in',
  '@spamstack.net',
  '@sry.li',
  '@stop-my-spam.tk',
  '@temp.emeraldwebmail.com',
  '@tempimbox.com',
  '@temporaryemail.us',
  '@tfwno.gf',
  '@thecloudindex.com',
  '@tijdelijkmailadres.nl',
  '@topfreeemail.com',
  '@topcoolemail.com',
  '@tormail.net',
  '@trash2010.com',
  '@trash2011.com',
  '@trash-mail.ga',
  '@trash-mail.tk',
  '@trash-mail.ml',
  '@verticalscope.com',
  '@veryrealmail.com',
  '@vipmail.name',
  '@vistomail.com',
  '@walala.org',
  '@wants.dicksinmyan.us',
  '@wants.dicksinhisan.us',
  '@watchfull.net',
  '@watch-harry-potter.com',
  '@webuser.in',
  '@wegwerfemail.org',
  '@wegwerf-email-addressen.de',
  '@wegwerf-email-adressen.de',
  '@wegwrfmail.net',
  '@wegwrfmail.de',
  '@wegwrfmail.org',
  '@wmail.cf',
  '@wolfsmail.tk',
  '@xagloo.co',
  '@xoxox.cc',
  '@xxtreamcam.com',
  '@xyzfree.net',
  '@yanet.me',
  '@yeah.net'
];
