import { useEffect, useState, useContext, useCallback } from 'react';
import { Switch } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import SidebarLogo from '../assets/images/new-shoorah-logo.svg';
import {
  cleanLocalStorage,
  errorToast,
  getDeviceToken,
  getLocalStorageItem
} from '../utils/helper';
import Menu from './Menu';
import { Api } from '../api';
import dashboardIcon from '../assets/images/Dashboard.svg';
import subadminIcon from '../assets/images/SubAdmin.svg';
import userIcon from '../assets/images/Users.svg';
import expertIcon from '../assets/images/expert.svg';
import peapIcon from '../assets/images/peap.svg';
import activePeapIcon from '../assets/images/active-peap.svg';
import activeExpertIcon from '../assets/images/active-expert.svg';
import approvalIcon from '../assets/images/verify.svg';
import notiIcon from '../assets/images/Notification.svg';
import cmsIcon from '../assets/images/CMS.svg';
import earningIcon from '../assets/images/Ea5rning.svg';
import reportIcon from '../assets/images/reportIcon.svg';
import bagIcon from '../assets/images/bagIcon.svg';
import keyIcon from '../assets/images/keyIcon.svg';
import companyDashboardIcon from '../assets/images/companyDashboardIcon.svg';
import contentIcon from '../assets/images/Contentmanagement.svg';
import crownIcon from '../assets/images/crown.svg';
import moonIcon from '../assets/images/moon.svg';
import logOutIcon from '../assets/images/logout.svg';
import { SidebarContext } from '../context/SidebarContext';

import activeCrownIcon from '../assets/images/ActiveCrown.svg';
import activedashboardIcon from '../assets/images/ActiveDashboard.svg';
import activesubadminIcon from '../assets/images/ActiveSubAdmin.svg';
import activeuserIcon from '../assets/images/ActiveUsers.svg';
import activeapprovalIcon from '../assets/images/ActiveVerify.svg';
import activenotiIcon from '../assets/images/ActiveNotification.svg';
import activecmsIcon from '../assets/images/ActiveCMS.svg';
import activeearningIcon from '../assets/images/ActiveEa5rning.svg';
import activereportIcon from '../assets/images/ActivereportIcon.svg';
import activebagIcon from '../assets/images/ActivebagIcon.svg';
import activekeyIcon from '../assets/images/ActivekeyIcon.svg';
import activecompanyDashboardIcon from '../assets/images/ActivecompanyDashboardIcon.svg';
import activecontentIcon from '../assets/images/ActiveContentmanagement.svg';
import { useLocation } from 'react-router-dom';
import { CompanyApi } from '../api/companyApi';
import selfAssessmentActive from '../assets/images/selfAssessmentActiveIcon.svg';
import selfAssessment from '../assets/images/selfAssessmentIcon.svg';

import adminActive from '../assets/images/sidebar/active-admins.svg';
import admin from '../assets/images/sidebar/admins.svg';
import approvalActive from '../assets/images/sidebar/active-approval.svg';
import approval from '../assets/images/sidebar/approval.svg';
import b2bActive from '../assets/images/sidebar/active-b2b.svg';
import b2b from '../assets/images/sidebar/b2b.svg';
import cmsActive from '../assets/images/sidebar/active-cms.svg';
import cms from '../assets/images/sidebar/cms.svg';
import configActive from '../assets/images/sidebar/active-config.svg';
import config from '../assets/images/sidebar/config.svg';
import feedbackActive from '../assets/images/sidebar/active-feedback.svg';
import feedback from '../assets/images/sidebar/feedback.svg';
import partnersActive from '../assets/images/sidebar/active-partners.svg';
import partners from '../assets/images/sidebar/partners.svg';
import pushNotificationActive from '../assets/images/sidebar/active-push-notification.svg';
import pushNotification from '../assets/images/sidebar/push-notification.svg';
import companyActive from '../assets/images/sidebar/active-company.svg';
import company from '../assets/images/sidebar/company.svg';
import partnerActive from '../assets/images/sidebar/active-partner.svg';
import partner from '../assets/images/sidebar/partner.svg';
import usersActive from '../assets/images/sidebar/active-users.svg';
import users from '../assets/images/sidebar/users.svg';
import issuesActive from '../assets/images/sidebar/active-issues.svg';
import issues from '../assets/images/sidebar/issues.svg';
import affirmationActive from '../assets/images/sidebar/active-affirmation.svg';
import affirmation from '../assets/images/sidebar/affirmation.svg';
import meditationActive from '../assets/images/sidebar/active-meditation.svg';
import meditation from '../assets/images/sidebar/meditation.svg';
import focusActive from '../assets/images/sidebar/active-focus.svg';
import focus from '../assets/images/sidebar/focus.svg';
import ideaActive from '../assets/images/sidebar/active-idea.svg';
import idea from '../assets/images/sidebar/idea.svg';
import sleepActive from '../assets/images/sidebar/active-sleep.svg';
import sleep from '../assets/images/sidebar/sleep.svg';
import ritualActive from '../assets/images/sidebar/active-ritual.svg';
import ritual from '../assets/images/sidebar/ritual.svg';
import breathActive from '../assets/images/sidebar/active-breath.svg';
import breath from '../assets/images/sidebar/breath.svg';
import shoorahPods from '../assets/images/sidebar/shoorah-pods.svg';
import activeShoorahPods from '../assets/images/sidebar/active-shoorah-pods.svg';
import earning from '../assets/images/sidebar/earning.svg';
import activeEarning from '../assets/images/sidebar/active-earning.svg';
import activeDash from '../assets/images/sidebar/active-dashboard.svg';
import feed from '../assets/images/sidebar/feed.svg';
import activeFeed from '../assets/images/sidebar/active-feed.svg';

function Sidebar({ slide }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [subsData, setSubsData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [sidebarData, setSidebarData] = useState([]);
  const [activeIndexAlt, setActiveIndexAlt] = useState(0);
  const [openedSubMenuIndex, setOpenedSubMenuIndex] = useState(null);
  const { isDarkModeEnabled, setIsDarkModeEnabled } = useContext(SidebarContext);
  const userData = getLocalStorageItem('userData') && JSON.parse(getLocalStorageItem('userData'));

  useEffect(() => {
    if (userData?.id) {
      if (userData?.userType === 3) {
        setSidebarData([
          {
            name: 'Dashboard',
            href: `/${userData?.slug}/dashboard`,
            icon: companyDashboardIcon,
            activeIcon: activecompanyDashboardIcon,
            access: true
          },

          {
            name: 'Surveys',
            href: `/${userData?.slug}/pulse-survey`,
            icon: approvalIcon,
            activeIcon: activeapprovalIcon,
            access: true
          },
          {
            name: 'Content Approval',
            href: `/${userData?.slug}/content-approval`,
            icon: approval,
            activeIcon: approvalActive,
            access: true
          },
          {
            name: 'Sub Admins',
            href: `/${userData?.slug}/sub-admins`,
            icon: subadminIcon,
            activeIcon: activesubadminIcon,
            access: true,
            remove: subsData?.account !== 'Expired' ? false : true
          },
          {
            name: 'Solutions',
            href: `/${userData?.slug}/solution`,
            icon: keyIcon,
            activeIcon: activekeyIcon,
            access: true,
            remove: subsData?.account !== 'Expired' ? false : true
          },
          {
            name: 'My Company',
            href: `/${userData?.slug}/myCompany`,
            icon: bagIcon,
            activeIcon: activebagIcon,
            access: true
          },

          {
            name: 'Users',
            href: `/${userData?.slug}/users`,
            icon: userIcon,
            activeIcon: activeuserIcon,
            access: true,
            remove: subsData?.account !== 'Expired' ? false : true
          },
          {
            name: 'Notification',
            href: `/${userData?.slug}/notification`,
            icon: notiIcon,
            activeIcon: activenotiIcon,
            access: true,
            remove: subsData?.account !== 'Expired' ? false : true
          },
          {
            name: 'Report',
            href: `/${userData?.slug}/report`,
            icon: reportIcon,
            activeIcon: activereportIcon,
            access: true,
            remove: subsData?.account !== 'Expired' ? false : true
          },
          {
            name: 'Badges',
            href: `/${userData?.slug}/badges`,
            icon: crownIcon,
            activeIcon: activeCrownIcon,
            access: true,
            remove: subsData?.account !== 'Expired' ? false : true
          },
          {
            name: 'Integrations',
            href: `/${userData?.slug}/integrations`,
            icon: contentIcon,
            activeIcon: activecontentIcon,
            access: true,
            remove: subsData?.account !== 'Expired' ? false : true
          }
        ]);
      } else if (userData?.userType === 4) {
        setSidebarData([
          {
            name: 'Dashboard',
            href: `/${userData?.slug}/dashboard`,
            icon: companyDashboardIcon,
            activeIcon: activecompanyDashboardIcon,
            access: true
          },

          {
            name: 'Surveys',
            href: `/${userData?.slug}/pulse-survey`,
            icon: approvalIcon,
            activeIcon: activeapprovalIcon,
            access: true
          },
          {
            name: 'Solutions',
            href: `/${userData?.slug}/solution`,
            icon: keyIcon,
            activeIcon: activekeyIcon,
            access: true,
            remove: subsData?.account !== 'Expired' ? false : true
          },
          {
            name: 'My Company',
            href: `/${userData?.slug}/myCompany`,
            icon: bagIcon,
            activeIcon: activebagIcon,
            access: true
          },

          {
            name: 'Users',
            href: `/${userData?.slug}/users`,
            icon: userIcon,
            activeIcon: activeuserIcon,
            access: true,
            remove: subsData?.account !== 'Expired' ? false : true
          },
          {
            name: 'Notifications',
            href: `/${userData?.slug}/notification`,
            icon: notiIcon,
            activeIcon: activenotiIcon,
            access: true,
            remove: subsData?.account !== 'Expired' ? false : true
          },
          {
            name: 'Report',
            href: `/${userData?.slug}/report`,
            icon: reportIcon,
            activeIcon: activereportIcon,
            access: true,
            remove: subsData?.account !== 'Expired' ? false : true
          },
          {
            name: 'Badges',
            href: `/${userData?.slug}/badges`,
            icon: crownIcon,
            activeIcon: activeCrownIcon,
            access: true,
            remove: subsData?.account !== 'Expired' ? false : true
          },
          {
            name: 'Integrations',
            href: `/${userData?.slug}/integrations`,
            icon: contentIcon,
            activeIcon: activecontentIcon,
            access: true,
            remove: subsData?.account !== 'Expired' ? false : true
          }
        ]);
      } else if (userData?.userType === 5) {
        setSidebarData([
          {
            name: 'Dashboard',
            href: `/partner-dashboard`,
            icon: companyDashboardIcon,
            activeIcon: activecompanyDashboardIcon,
            access: true
          },
          {
            name: 'My Account',
            href: `/partner-account`,
            icon: userIcon,
            activeIcon: activeuserIcon,
            access: true
          },
          {
            name: 'Earnings',
            href: `/partner-earning`,
            icon: earningIcon,
            activeIcon: activeearningIcon,
            access: true
          },
          {
            name: 'Introduce Companies',
            href: `/introduce`,
            icon: contentIcon,
            activeIcon: activecontentIcon,
            access: true
          },
          {
            name: 'Notifications',
            href: `/partner-notification`,
            icon: notiIcon,
            activeIcon: activenotiIcon,
            access: true
          },
          {
            name: 'Legals',
            href: `/partner-legals`,
            icon: cmsIcon,
            activeIcon: activecmsIcon,
            access: true
          },
          {
            name: 'Assets',
            href: `/partner-assets`,
            icon: cmsIcon,
            activeIcon: activecmsIcon,
            access: true
          }
        ]);
      } else if (userData?.userType === 6) {
        setSidebarData([
          {
            name: 'Dashboard',
            href: `/  -dashboard`,
            icon: companyDashboardIcon,
            activeIcon: activecompanyDashboardIcon,
            access: true,
            isDisabled: userData?.expertApprove === 'false'
          },
          {
            name: 'Schedule',
            href: `/#`,
            icon: userIcon,
            activeIcon: activeuserIcon,
            access: true,
            isDisabled: userData?.expertApprove === 'false'
          },
          {
            name: 'Clients',
            href: `/expert-clients/my-client`,
            icon: earningIcon,
            activeIcon: activeearningIcon,
            access: true,
            isDisabled: userData?.expertApprove === 'false'
          },
          {
            name: 'Notifications',
            href: `/#`,
            icon: notiIcon,
            activeIcon: activenotiIcon,
            access: true,
            isDisabled: userData?.expertApprove === 'false'
          },
          {
            name: 'Me',
            href: `/expert-me`,
            icon: cmsIcon,
            activeIcon: activecmsIcon,
            access: true
          },
          {
            name: 'Payments',
            href: `/#`,
            icon: cmsIcon,
            activeIcon: activecmsIcon,
            access: true,
            isDisabled: userData?.expertApprove === 'false'
          }
        ]);
      } else if (userData?.userType === 1) {
        setSidebarData([
          {
            name: 'Dashboard',
            href: '/dashboard',
            icon: dashboardIcon,
            activeIcon: activedashboardIcon,
            access: true
          },
          {
            name: 'Our Experts',
            href: `/podExperts`,
            icon: expertIcon,
            activeIcon: activeExpertIcon,
            access: true
          },
          {
            name: 'Peap',
            href: '/peap-management',
            icon: peapIcon,
            activeIcon: activePeapIcon,
            access: true
          },
          {
            name: 'Survey',
            href: `/pulse-survey`,
            icon: approvalIcon,
            activeIcon: activeapprovalIcon,
            access: true
          },
          {
            name: 'Self Assessment',
            href: `/self-assessment`,
            icon: selfAssessment,
            activeIcon: selfAssessmentActive,
            access: true
          },
          {
            name: 'B2B',
            href: '/B2B-dashboard',
            dropDownHref: 'isDropDown',
            icon: b2b,
            activeIcon: b2bActive,
            access: true,
            isDropDown: true,
            subMenu: [
              {
                name: 'Dashboard',
                href: '/B2B-dashboard',
                icon: dashboardIcon,
                activeIcon: activeDash,
                access: true,
                show: true
              },
              {
                name: 'Company List',
                href: '/B2B-company-list',
                icon: company,
                activeIcon: companyActive,
                access: true,
                show: true
              },
              {
                name: 'Earnings',
                href: '/B2B-earning',
                icon: earningIcon,
                activeIcon: activeearningIcon,
                access: true,
                show: true
              }
            ]
          },
          {
            name: 'Partnership',
            href: 'partners',
            dropDownHref: 'isDropDown',
            icon: partners,
            activeIcon: partnersActive,
            access: true,
            isDropDown: true,
            subMenu: [
              {
                name: 'Partner List',
                href: '/partners',
                icon: company,
                activeIcon: companyActive,
                access: true,
                show: true
              },
              {
                name: 'Partner Introduced',
                href: '/partner-introduced',
                icon: users,
                activeIcon: usersActive,
                access: true,
                show: true
              }
            ]
          },
          {
            name: 'Users',
            href: '/users',
            icon: userIcon,
            activeIcon: activeuserIcon,
            access: true
          },
          {
            name: 'Earnings',
            href: '/earning',
            icon: earningIcon,
            activeIcon: activeearningIcon,
            access: userData?.moduleAccess?.earning_module_access
          },
          {
            name: 'Management',
            href: '/content-management/focus',
            dropDownHref: 'isDropDown',
            icon: contentIcon,
            activeIcon: activecontentIcon,
            access: true,
            isDropDown: true,
            subMenu: [
              {
                name: 'Focuses / Affirmation Focuses',
                href: '/content-management/focus',
                icon: focus,
                activeIcon: focusActive,
                access: true,
                show: true
              },
              {
                name: 'Focus',
                href: '/content-management/focus/add-edit',
                icon: focus,
                activeIcon: focusActive,
                access: true,
                show: false
              },
              {
                name: 'Focus',
                href: '/content-management/focus/view',
                icon: focus,
                activeIcon: focusActive,
                access: true,
                show: false
              },
              {
                name: 'Affirmation',
                href: '/content-management/affirmation',
                icon: affirmation,
                activeIcon: affirmationActive,
                access: true,
                show: true
              },
              {
                name: 'Affirmation',
                href: '/content-management/affirmation/add-edit',
                icon: affirmation,
                activeIcon: affirmationActive,
                access: true,
                show: false
              },
              {
                name: 'Affirmation',
                href: '/content-management/affirmation/view',
                icon: affirmation,
                activeIcon: affirmationActive,
                access: true,
                show: false
              },
              {
                name: 'Meditation',
                href: '/content-management/meditation',
                icon: meditation,
                activeIcon: meditationActive,
                access: true,
                show: true
              },
              {
                name: 'Meditation',
                href: '/content-management/meditation/add-edit',
                icon: meditation,
                activeIcon: meditationActive,
                access: true,
                show: false
              },
              {
                name: 'Sleep Sounds',
                href: '/content-management/sounds',
                icon: sleep,
                activeIcon: sleepActive,
                access: true,
                show: true
              },
              {
                name: 'Sleep Sounds',
                href: '/content-management/sounds/add-edit',
                icon: sleep,
                activeIcon: sleepActive,
                access: true,
                show: false
              },
              {
                name: 'Shoorah Rituals',
                href: '/content-management/rituals',
                icon: ritual,
                activeIcon: ritualActive,
                access: true,
                show: true
              },
              {
                name: 'Shoorah Rituals',
                href: '/content-management/rituals/add-edit',
                icon: ritual,
                activeIcon: ritualActive,
                access: true,
                show: false
              },
              // {
              //   name: 'BedTime Stories',
              //   href: '/content-management/bedtime-stories',
              //   icon: '',
              //   access: true,
              //   show: true,
              // },
              // {
              //   name: 'Gratitude',
              //   href: '/content-management/gratitude',
              //   icon: '',
              //   access: true,
              //   show: true,
              // },
              // {
              //   name: 'Gratitude',
              //   href: '/content-management/gratitude/add-edit',
              //   icon: '',
              //   access: true,
              //   show: false,
              // },
              {
                name: 'Shoorah Pods',
                href: '/content-management/shoorah-pods',
                icon: shoorahPods,
                activeIcon: activeShoorahPods,
                access: true,
                show: true
              },
              {
                name: 'Shoorah Pods',
                href: '/content-management/shoorah-pods/add-edit',
                icon: shoorahPods,
                activeIcon: activeShoorahPods,
                access: true,
                show: false
              },
              {
                name: 'Top Picks',
                href: '/content-management/top-picks',
                icon: '',
                activeIcon: '',
                access: true,
                show: false
              },
              {
                name: 'Top Picks',
                href: '/content-management/top-picks/add-edit',
                icon: '',
                activeIcon: '',
                access: true,
                show: false
              },
              // {
              //   name: 'Manifestation',
              //   href: '/content-management/manifestation',
              //   icon: '',
              //   access: true,
              //   show: true,
              // },
              {
                name: 'Vision Ideas',
                href: '/content-management/vision-ideas',
                icon: idea,
                activeIcon: ideaActive,
                access: true,
                show: true
              },
              {
                name: 'Vision Ideas',
                href: '/content-management/vision-ideas/add-edit',
                icon: idea,
                activeIcon: ideaActive,
                access: true,
                show: false
              },
              {
                name: 'Breathworks',
                href: '/content-management/breathwork',
                icon: breath,
                activeIcon: breathActive,
                access: true,
                show: true
              }
            ]
          },
          // {
          //   name: 'My Drafts',
          //   href: '/draft',
          //   icon: draftIcon,
          //   access: userData?.userType,
          // },
          {
            name: 'Push Notifications',
            href: '/push-notification',
            icon: pushNotification,
            activeIcon: pushNotificationActive,
            access: true
          },
          // { name: 'Article', href: '#', icon: articleIcon, access: true },
          {
            name: 'CMS',
            href: '/cms',
            icon: cms,
            activeIcon: cmsActive,
            access: true
          },
          {
            name: 'Config',
            href: '/config',
            icon: config,
            activeIcon: configActive,
            access: true
          },
          {
            name: 'App Feedback',
            href: '/app-issues',
            dropDownHref: 'isDropDown',
            icon: feedback,
            activeIcon: feedbackActive,
            access: true,
            isDropDown: true,
            subMenu: [
              {
                name: 'App Issues',
                href: '/app-issues',
                icon: issues,
                activeIcon: issuesActive,
                access: !userData?.userType,
                show: true
              },
              {
                name: 'Feedback',
                href: '/app-feedbacks',
                icon: feed,
                activeIcon: activeFeed,
                access: true,
                show: true
              }
            ]
          }
        ]);
      } else {
        setSidebarData([
          {
            name: 'Dashboard',
            href: '/dashboard',
            icon: dashboardIcon,
            activeIcon: activedashboardIcon,
            access: true
          },
          {
            name: 'Our Experts',
            href: `/podExperts`,
            icon: expertIcon,
            activeIcon: activeExpertIcon,
            access: true
          },
          {
            name: 'Peap',
            href: '/peap-management',
            icon: peapIcon,
            activeIcon: activePeapIcon,
            access: true
          },
          {
            name: 'Survey',
            href: `/pulse-survey`,
            icon: approvalIcon,
            activeIcon: activeapprovalIcon,
            access: true
          },
          {
            name: 'Self Assessment',
            href: `/self-assessment`,
            icon: selfAssessment,
            activeIcon: selfAssessmentActive,
            access: true
          },
          {
            name: 'Admins',
            href: '/sub-admins',
            icon: admin,
            activeIcon: adminActive,
            access: !userData?.userType
          },
          {
            name: 'B2B',
            href: '/B2B-dashboard',
            dropDownHref: 'isDropDown',
            icon: b2b,
            activeIcon: b2bActive,
            access: true,
            isDropDown: true,

            subMenu: [
              {
                name: 'Dashboard',
                href: '/B2B-dashboard',
                icon: dashboardIcon,
                activeIcon: activeDash,
                access: true,
                show: true
              },
              {
                name: 'Company List',
                href: '/B2B-company-list',
                icon: company,
                activeIcon: companyActive,
                access: true,
                show: true
              },
              {
                name: 'Earnings',
                href: '/B2B-earning',
                icon: earning,
                activeIcon: activeEarning,
                access: true,
                show: true
              }
            ]
          },
          {
            name: 'Partnership',
            href: 'partners',
            dropDownHref: 'isDropDown',
            icon: partners,
            activeIcon: partnersActive,
            access: true,
            isDropDown: true,
            subMenu: [
              {
                name: 'Partner List',
                href: '/partners',
                icon: company,
                activeIcon: companyActive,
                access: true,
                show: true
              },
              {
                name: 'Partner Introduced',
                href: '/partner-introduced',
                icon: users,
                activeIcon: usersActive,
                access: true,
                show: true
              }
            ]
          },
          {
            name: 'Users',
            href: '/users',
            icon: userIcon,
            activeIcon: activeuserIcon,
            access: true
          },
          {
            name: 'Content Approval',
            href: '/content-approval',
            icon: approval,
            activeIcon: approvalActive,
            access: !userData?.userType
          },
          {
            name: 'Earnings',
            href: '/earning',
            icon: earningIcon,
            activeIcon: activeearningIcon,
            access: userData?.moduleAccess?.earning_module_access
          },
          {
            name: 'Management',
            href: '/content-management/focus',
            dropDownHref: 'isDropDown',
            icon: contentIcon,
            activeIcon: activecontentIcon,
            access: true,
            isDropDown: true,
            subMenu: [
              {
                name: 'Focuses / Affirmation Focuses',
                href: '/content-management/focus',
                icon: focus,
                activeIcon: focusActive,
                access: true,
                show: true
              },
              {
                name: 'Focus',
                href: '/content-management/focus/add-edit',
                icon: approvalIcon,
                activeIcon: activeapprovalIcon,
                access: true,
                show: false
              },
              {
                name: 'Focus',
                href: '/content-management/focus/view',
                icon: approvalIcon,
                activeIcon: activeapprovalIcon,
                access: true,
                show: false
              },
              {
                name: 'Affirmation',
                href: '/content-management/affirmation',
                icon: affirmation,
                activeIcon: affirmationActive,
                access: true,
                show: true
              },
              {
                name: 'Affirmation',
                href: '/content-management/affirmation/add-edit',
                icon: affirmation,
                activeIcon: affirmationActive,
                access: true,
                show: false
              },
              {
                name: 'Affirmation',
                href: '/content-management/affirmation/view',
                icon: affirmation,
                activeIcon: affirmationActive,
                access: true,
                show: false
              },
              {
                name: 'Meditation',
                href: '/content-management/meditation',
                icon: meditation,
                activeIcon: meditationActive,
                access: true,
                show: true
              },
              {
                name: 'Meditation',
                href: '/content-management/meditation/add-edit',
                icon: meditation,
                activeIcon: meditationActive,
                access: true,
                show: false
              },
              {
                name: 'Sleep Sounds',
                href: '/content-management/sounds',
                icon: sleep,
                activeIcon: sleepActive,
                access: true,
                show: true
              },
              {
                name: 'Sleep Sounds',
                href: '/content-management/sounds/add-edit',
                icon: sleep,
                activeIcon: sleepActive,
                access: true,
                show: false
              },
              {
                name: 'Shoorah Rituals',
                href: '/content-management/rituals',
                icon: ritual,
                activeIcon: ritualActive,
                access: true,
                show: true
              },
              {
                name: 'Shoorah Rituals',
                href: '/content-management/rituals/add-edit',
                icon: ritual,
                activeIcon: ritualActive,
                access: true,
                show: false
              },
              // {
              //   name: 'BedTime Stories',
              //   href: '/content-management/bedtime-stories',
              //   icon: '',
              //   access: true,
              //   show: true,
              // },
              // {
              //   name: 'Gratitude',
              //   href: '/content-management/gratitude',
              //   icon: '',
              //   access: true,
              //   show: true,
              // },
              // {
              //   name: 'Gratitude',
              //   href: '/content-management/gratitude/add-edit',
              //   icon: '',
              //   access: true,
              //   show: false,
              // },
              {
                name: 'Shoorah Pods',
                href: '/content-management/shoorah-pods',
                icon: shoorahPods,
                activeIcon: activeShoorahPods,
                access: true,
                show: true
              },
              {
                name: 'Shoorah Pods',
                href: '/content-management/shoorah-pods/add-edit',
                icon: shoorahPods,
                activeIcon: activeShoorahPods,
                access: true,
                show: false
              },
              {
                name: 'Top Picks',
                href: '/content-management/top-picks',
                icon: approvalIcon,
                activeIcon: activeapprovalIcon,
                access: true,
                show: false
              },
              {
                name: 'Top Picks',
                href: '/content-management/top-picks/add-edit',
                icon: approvalIcon,
                activeIcon: activeapprovalIcon,
                access: true,
                show: false
              },
              // {
              //   name: 'Manifestation',
              //   href: '/content-management/manifestation',
              //   icon: '',
              //   access: true,
              //   show: true,
              // },
              {
                name: 'Vision Ideas',
                href: '/content-management/vision-ideas',
                icon: idea,
                activeIcon: ideaActive,
                access: true,
                show: true
              },
              {
                name: 'Vision Ideas',
                href: '/content-management/vision-ideas/add-edit',
                icon: idea,
                activeIcon: ideaActive,
                access: true,
                show: false
              },
              {
                name: 'Breathworks',
                href: '/content-management/breathwork',
                icon: breath,
                activeIcon: breathActive,
                access: true,
                show: true
              }
            ]
          },
          // {
          //   name: 'My Drafts',
          //   href: '/draft',
          //   icon: draftIcon,
          //   access: userData?.userType,
          // },
          {
            name: 'Push Notifications',
            href: '/push-notification',
            icon: pushNotification,
            activeIcon: pushNotificationActive,
            access: true
          },
          // { name: 'Article', href: '#', icon: articleIcon, access: true },
          {
            name: 'CMS',
            href: '/cms',
            icon: cms,
            activeIcon: cmsActive,
            access: true
          },
          {
            name: 'Config',
            href: '/config',
            icon: config,
            activeIcon: configActive,
            access: true
          },
          {
            name: 'App Feedback',
            href: '/app-issues',
            dropDownHref: 'isDropDown',
            icon: feedback,
            activeIcon: feedbackActive,
            access: true,
            isDropDown: true,
            subMenu: [
              {
                name: 'App Issues',
                href: '/app-issues',
                icon: issues,
                activeIcon: issuesActive,
                access: !userData?.userType,
                show: true
              },
              {
                name: 'Feedback',
                href: '/app-feedbacks',
                icon: feed,
                activeIcon: activeFeed,
                access: true,
                show: true
              }
            ]
          }
        ]);
      }
    }
  }, [
    subsData,
    setSubsData,
    userData?.expertApprove,
    userData?.id,
    userData?.moduleAccess?.earning_module_access,
    userData?.slug,
    userData?.userType
  ]);

  useEffect(() => {
    if (sidebarData.length) {
      let index = sidebarData.findIndex((data) => {
        if (data.isDropDown && data.subMenu) {
          return data.subMenu.some((subItem) => location?.pathname?.includes(subItem.href));
        }
        return location?.pathname?.includes(data.href);
      });
      if (index !== -1) {
        for (let i = 0; i <= index; i++) {
          if (sidebarData[i].access) {
            // index--;
          } else {
            index--;
          }
        }
      }
      if (index === -1) {
        setActiveIndexAlt(null);
        setActiveIndex(null);
      } else {
        let adjustedIndex = index;
        if (openedSubMenuIndex !== null && openedSubMenuIndex < index) {
          const visibleSubMenus = sidebarData[openedSubMenuIndex]?.subMenu.filter(
            (subItem) => subItem.show
          );
          adjustedIndex += visibleSubMenus.length + visibleSubMenus.length * 0.12;
        }
        setActiveIndexAlt(adjustedIndex);
      }
    }
  }, [location.pathname, sidebarData, openedSubMenuIndex]);

  const getCompanyPlanData = useCallback(() => {
    CompanyApi.getCompanyStatus(userData.companyId)
      .then((res) => {
        if (res?.data?.meta.code === 1) {
          setSubsData(res?.data?.data);
        } else {
          return errorToast('Something went wrong');
        }
      })
      .catch((err) => {
        return errorToast(err);
      });
  }, [userData?.companyId]);

  useEffect(() => {
    if (userData?.userType === 3 || userData?.userType === 4) {
      getCompanyPlanData();
    }
  }, [getCompanyPlanData, userData?.userType]);

  const handleLogout = async () => {
    if (getDeviceToken()) await Api.logoutUser();
    cleanLocalStorage();
    navigate('/login');
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div
      className={`flex h-full bg-white dark:bg-shoorah-darkBgTabColor lg:relative fixed lg:top-0 top-[89px] z-40 lg:border-[1.5px] border-r border-shoorah-borderGray lg:rounded-2xl overflow-y-auto hide-scroll flex-col lg:w-[250px] duration-300 ease-in-out ${
        slide ? 'w-[300px]' : 'w-[82px]'
      }`}
    >
      <div className="h-0 flex-1 relative overflow-y-auto sidebar-container hide-scroll">
        <div className="lg:flex hidden justify-center w-full sticky z-50 top-0 left-0 bg-white dark:bg-shoorah-darkBgTabColor items-center px-4 pt-6 pb-[56px]">
          <img className="h-[29px] w-auto dark:invert" src={SidebarLogo} alt="Your Company" />
        </div>
        <nav className="relative lg:mb-4">
          {sidebarData?.map((item, index) =>
            !item?.access ? (
              ''
            ) : (
              <Menu
                key={index}
                item={item}
                index={index}
                slide={slide}
                setActiveIndex={setActiveIndex}
                openedSubMenuIndex={openedSubMenuIndex}
                setOpenedSubMenuIndex={setOpenedSubMenuIndex}
              />
            )
          )}
          <div className="h-[1.5px] w-[calc(100%-48px)] lg:block hidden m-6 bg-shoorah-borderGray2"></div>
          <div className="lg:flex hidden flex-col justify-center items-center flex-shrink-0">
            <div className="group block w-full flex-shrink-0 cursor-pointer py-[18px] ps-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img
                    src={moonIcon}
                    alt="sidebar_icon"
                    className={`mr-3 ml-2 h-6 w-6 flex-shrink-0 dark:invert`}
                  />
                  <p className="text-sm text-shoorah-lightBlack dark:text-white/80 font-medium">
                    Darkmode
                  </p>
                </div>
                <Switch
                  checked={isDarkModeEnabled}
                  onChange={() => {
                    setIsDarkModeEnabled(!isDarkModeEnabled);
                  }}
                  className={classNames(
                    isDarkModeEnabled ? '' : '',
                    'mr-3 ml-2 relative border-shoorah-lightBlack dark:border-shoorah-darkBg inline-flex h-4 w-7 flex-shrink-0 cursor-pointer rounded-full border-2 duration-200 ease-in-out focus:outline-none'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      isDarkModeEnabled ? 'translate-x-3' : 'translate-x-0',
                      'pointer-events-none inline-block h-3 w-3 transform rounded-full bg-shoorah-lightBlack dark:bg-shoorah-darkBg border-shoorah-darkBg shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </div>
            </div>
            <div className="group block w-full flex-shrink-0 cursor-pointer py-[18px] ps-6">
              <div
                className="flex items-center"
                onClick={() => {
                  handleLogout();
                }}
              >
                <img
                  src={logOutIcon}
                  alt="sidebar_icon"
                  className={`mr-3 ml-2 h-6 w-6 flex-shrink-0 dark:invert`}
                />
                <p className="text-sm text-shoorah-lightBlack dark:text-white/80 font-medium">
                  Logout
                </p>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Sidebar;
