import axios from 'axios';
import Topbar from '../Topbar';
import Select from 'react-select';
import 'react-tooltip/dist/react-tooltip.css';
import { surveyApi } from '../../../api/surveyApi';
import React, { useEffect, useState } from 'react';
import { CompanyApi } from '../../../api/companyApi';
import { errorToast, isCopanySuperOrSubAdmin, isSuperAndSubAdmin, successToast } from '../../../utils/helper';
import {
  EditSurveyShareDataValidation,
  PublishSurveyValidation
} from '../../../validation/AddEditSurveyValidation';
import SurveySuccessModal from '../../../component/common/modals/SurveySuccessModal';
import SurveyApprovalModal from '../../../component/common/modals/SurveyApprovalModal';
import BulkAnswerModal from './BulkAnswerModal';
import Insights from './Insights';
import SurveyShare from './SurveyShare';
import AssessmentResultQuestion from '../../self-assessment/AssessmentResultQuestion';

const AddEditPulseSurvey = () => {
  const maxLength = 250; //Maximum character for description

  const surveyType = localStorage.getItem('surveyType'); // Survey or Template set from index.js according to popup selection

  // Extract the survey ID from the URL parameters
  const location = new URLSearchParams(window.location.search);
  const paramssurveyId = location.get('id');

  /**
   * Retrieves the user type from the local storage.
   * Assumes that the user data is stored in the local storage under the key 'userData'.
   * Parses the user data JSON string and extracts the userType property.
   *
   * @constant {string} userType - The type of the user retrieved from the local storage.
   */
  const { userType } = JSON.parse(localStorage.getItem('userData'));

  const [surveyLogo, setSurveyLogo] = useState(''); // This is the file object for logo of survey

  const [previewSurveyLogo, setPreviewSurveyLogo] = useState(''); // This is the preview URL for logo of survey

  /**
   * State hook to manage the survey categories.
   *
   * @type {Array}
   * @default []
   */
  const [surveyCategories, setSurveyCategories] = useState([]);

  //State for Survey Data for title description and category
  /**
   * useState hook to manage the state of survey data.
   *
   * @constant
   * @type {Array}
   * @default
   * @property {Object} 0 - The current state of the survey data.
   * @property {Function} 1 - Function to update the survey data state.
   *
   * @property {string} 0.title - The title of the survey.
   * @property {string} 0.description - The description of the survey.
   * @property {number} 0.type - The type of the survey.
   *                              2 if surveyType is 'Survey',
   *                              1 if surveyType is 'Template',
   *                              0 otherwise.
   * @property {string} 0.category - The category of the survey.
   */
  const [surveyData, setSurveyData] = useState({
    title: '',
    description: '',
    type: surveyType === 'Survey' ? 2 : surveyType === 'Template' ? 1 : 0,
    category: ''
  });

  /**
   * State hook for managing survey question data.
   *
   * @typedef {Object} SurveyQuestionData
   * @property {string} questionTitle - The title of the survey question.
   * @property {string} qType - The type of the survey question.
   * @property {boolean} skipable - Indicates if the question can be skipped.
   * @property {boolean} hasOther - Indicates if the question has an "Other" option.
   * @property {boolean} nonAbove - Indicates if the question has a "None of the above" option.
   * @property {string} image - The image associated with the survey question.
   *
   * @type {[SurveyQuestionData, Function]} surveyQuestionData - The state and setter function for survey question data.
   */
  const [surveyQuestionData, setSurveyQuestionData] = useState({
    questionTitle: '',
    qType: '',
    skipable: false,
    hasOther: false,
    nonAbove: false,
    image: ''
  });

  // State hook to manage the visibility of the question type dropdown.
  const [isOpenQuestionType, setIsOpenQuestionType] = useState(false);

  // State hook to manage the list of survey questions.
  const [surveyQuestions, setSurveyQuestions] = useState([]);

  // State hook to manage the answer choices for survey questions.
  const [answerChoices, setAnswerChoices] = useState([{ value: '' }]);

  // State hook to manage the count of survey questions.
  const [surveyQuestionCount, setSurveyQuestionCount] = useState(1);

  /**
   * State hook for managing bulk answers in the survey.
   * split by new line
   * @type {Array}
   * @default []
   */
  const [bulkAnswers, setBulkAnswers] = useState([]);

  /**
   * State hook for managing the input value of bulk answers.
   * Raw Data
   * @type {string}
   * @default ''
   */
  const [bulkAnswerInput, setBulkAnswerInput] = useState('');

  //state for errors
  const [errors, setErrors] = useState({});

  //State for draft or publish
  const [isDraft, setIsDraft] = useState(2);

  //State for show page edit or preview
  const [showPage, setShowPage] = useState('edit');

  const [surveyShareData, setSurveyShareData] = useState({
    endHeading: '',
    endBody: '',
    audience: '',
    shareSurveyCategory: '',
    approvalBy: ''
  });

  //For approval dialog Admin list
  const [superAdminList, setSuperAdminList] = useState([]);

  //For survey approval dialog
  const [isShowSurveyApprovalModal, setIsShowSurveyApprovalModal] = useState(false);

  //state for showing bluk answer modal
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);

  //State for open/close share tab team drop down
  const [isOpenShareSurveyTeamMenu, setIsOpenShareSurveyTeamMenu] = useState(false);

  //state for cover image
  const [coverImg, setCoverImgUrl] = useState('');
  const [surveyCoverImage, setSurveyCoverImage] = useState('');

  //state foe managing calendanr show and hide
  const [showCalendar, setShowCalendar] = useState(false);

  //state for publish date for share tab
  const [publishDate, setPublishDate] = useState('');

  // related to date picker
  const [isOpen, setIsOpen] = useState(false);

  //State related to time picker
  const [isOpenT, setIsOpenT] = useState(false);
  const [selectedTime, setSelectedTime] = useState('');

  const [addUserPopUpOpenSurvey, setAddUserPopUpOpenSurvey] = useState(false);

  const shareSurveyTeamOptions = ['IT', 'Marketing', 'Customer Service'];

  const [emailInput, setEmailInput] = useState('');

  const [emails, setEmails] = useState([]);

  const [text, setText] = useState('');
  const surveyId = localStorage.getItem('pulseSurveyId');

  const [dropDownIndex, setDropDownIndex] = useState();
  const [surveyQuestionImage, setSurveyQuestionImage] = useState({});
  const [surveyQuestionImagePreview, setSurveyQuestionImagePreview] = useState({});
  const [tempImagePreview, setTempImagePreview] = useState('');
  const [surveyQuestionImageIndex, setSurveyQuestionImageIndex] = useState(1);
  const [isShowSurveySuccessModal, setIsShowSurveySuccessModal] = useState(false);
  const [surveyQuestionResultList, setSurveyQuestionResultList] = useState([]);
  const [surveyStatus, setSurveyStatus] = useState('');
  let isAdmin;
  isAdmin =
    userType === 0 || userType === 1 ? true : userType === 3 || userType === 4 ? false : true;

    useEffect(() => {
      if (paramssurveyId) {
        if(surveyStatus == 5) {
          if(isSuperAndSubAdmin()) {
            if(showPage == 'results') {
              setShowPage('edit');
              return
            }
          } else  if(showPage == 'edit' || showPage == 'share') {
            setShowPage('results');
          }
        }
      }
    }, [showPage, surveyStatus]);

  useEffect(() => {
    if (paramssurveyId) {
      surveyApi.getByIdByAdmin(paramssurveyId, isAdmin).then((response) => {
        localStorage.setItem(
          'surveyType',
          response?.data?.data?.type === 2 || response?.data?.data?.type === 0
            ? 'Survey'
            : 'Template'
        );
        setSurveyStatus(response?.data?.data?.status);
        setPreviewSurveyLogo(response?.data?.data?.logo);
        setCoverImgUrl(response?.data?.data?.coverImage);
        setSurveyShareData({
          endHeading: response?.data?.data?.endHeading,
          endBody: response?.data?.data?.endBody,
          audience: response?.data?.data?.audience,
          shareSurveyCategory: response?.data?.data?.category,
          approvalBy: response?.data?.data?.approvalBy
        });
        const transformedData = response?.data?.data?.questions.map((question) => ({
          questionTitle: question.title,
          qType: question.type,
          skipable: question.skipable,
          hasOther: question.hasOther,
          nonAbove: false, // This property is added manually as per your format
          options: question.options.map((option) => ({
            value: option.value
          })),
          image: question.image
        }));

        setSurveyQuestions(transformedData);
        setSurveyData({
          title: response?.data?.data?.title,
          description: response?.data?.data?.description,
          type: response?.data?.data?.type,
          category: response?.data?.data?.category
        });
        if (response?.data?.meta?.code === 200) {
          successToast(response?.data?.meta?.message);
        } else if (response?.data?.meta?.code === 0) {
          errorToast(response?.data?.meta?.message);
        }
      });
    }
    localStorage.setItem('pulseSurveyId', paramssurveyId);
  }, []);

  const toggleDropdown = (index) => {
    setIsOpen(!isOpen);
    setDropDownIndex(index);
  };

  /**
   * Handles the upload of the survey logo.
   *
   * This function is triggered when a file is selected for the survey logo.
   * It sets the selected file as the survey logo and creates a preview URL for the logo.
   *
   * @param {Event} e - The event object from the file input change event.
   */

  const handleUploadSurveyLogo = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSurveyLogo(file);
      setPreviewSurveyLogo(URL.createObjectURL(file));
    }
  };

  /**
   * Handles the change event for survey data input fields.
   *
   * @param {Event} e - The event object from the input field.
   */
  const handleGetSurveyData = (e) => {
    setSurveyData({ ...surveyData, [e.target.name]: e.target.value });
  };

  /**
   * Fetches survey category data based on user type.
   * If userType is 0 or 1, it fetches survey categories for admin.
   * Otherwise, it fetches survey categories for the company.
   */
  const handleGetSurveyCategoryData = async () => {
    userType === 0 || userType === 1
      ? surveyApi.getSurveyCategory().then((response) => {
          if (response?.data?.meta?.code === 200) {
            setSurveyCategories(response?.data?.data);
          }
        })
      : CompanyApi.getSurveyCategoryCompany().then((response) => {
          if (response?.data?.meta?.code === 200) {
            setSurveyCategories(response?.data?.data);
          }
        });
  };

  /**
   * Transforms survey category data into options for a dropdown.
   * Each option contains a value (category ID) and a label (category name).
   */
  const surveyCategory = surveyCategories.map((surveyCategory) => {
    return {
      value: surveyCategory?._id,
      label: surveyCategory?.name?.charAt(0)?.toUpperCase() + surveyCategory?.name?.slice(1)
    };
  });

  /**
   * Handles the selection of a survey category.
   *
   * @param {Object} e - The event object.
   * @param {string} e.value - The selected category value.
   */
  const handleGetSurveyCategory = (e) => {
    setSurveyData({ ...surveyData, category: e.value });
  };

  /**
   * Handles the change event for survey question data input fields.
   *
   * @param {Event} e - The event object from the input field change event.
   */
  const handleGetQuestionData = (e) => {
    setSurveyQuestionData({ ...surveyQuestionData, [e.target.name]: e.target.value });
  };

  // Handle upload question images
  const handleUploadQuestionImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSurveyQuestionData((prev) => ({
        ...prev,
        image: file
      }));
    }
  };

  /**
   * Handles the saving of a survey question.
   *
   * Validates the survey question data and displays error toasts if validation fails.
   * If validation passes, it creates a new question object and adds it to the survey questions list.
   * Displays a success toast upon successful addition of the question.
   * Resets the temporary image preview, survey question image index, and form fields.
   *
   * @function handleSaveSurveyQuestion
   * @returns {void}
   */
  const handleSaveSurveyQuestion = () => {
    // if (editQuestionIndex === null) {
    if (!surveyQuestionData?.questionTitle?.trim()) {
      errorToast('Please add at least one question.');
      return;
    }
    if (!surveyQuestionData?.qType) {
      errorToast('Please select question type.');
      return;
    }
    if (
      surveyQuestionData.qType !== 3 &&
      answerChoices.length === 1 &&
      !answerChoices[0].value.trim()
    ) {
      errorToast('Please add at least one valid answer choice.');
      return;
    }

    const newObj = {
      ...surveyQuestionData,
      options: answerChoices
    };
    setSurveyQuestions([...surveyQuestions, newObj]);
    successToast('Question added successfully.');
    setTempImagePreview('');
    setSurveyQuestionImageIndex(surveyQuestionImageIndex + 1);

    setErrors('');
    setSurveyQuestionData({
      questionTitle: '',
      hasOther: false,
      skipable: false,
      nonAbove: false,
      image: ''
    });
    setTempImagePreview('');
    setAnswerChoices([{ value: '' }]);
  };

  // Handle get data from answer choice field
  /**
   * Updates the value of an answer choice at a specific index.
   *
   * @param {number} index - The index of the answer choice to update.
   * @param {Object} e - The event object containing the new value.
   * @param {Object} e.target - The target element of the event.
   * @param {string} e.target.value - The new value to set for the answer choice.
   */
  const handleGetQueAns = (index, e) => {
    let newAnswerChoices = [...answerChoices];
    newAnswerChoices[index].value = e.target.value;
    setAnswerChoices(newAnswerChoices);
  };

  /**
   * Removes an answer field from the answer choices.
   *
   * @param {number} index - The index of the answer field to remove.
   * @throws Will display an error toast if attempting to remove the last answer field.
   */
  const removeAnswerField = (index) => {
    if (answerChoices.length === 1) {
      errorToast('Cannot remove the last answer field');
      return; // Prevent removal
    }
    const newAnswerChoices = answerChoices.filter((_, i) => i !== index);
    setAnswerChoices(newAnswerChoices);
    setSurveyQuestionCount(surveyQuestionCount - 1);
  };

  /**
   * Adds a new answer field to the survey.
   * This function updates the state by adding a new answer choice with an empty value
   * and increments the survey question count by one.
   */
  const addAnswerField = () => {
    setAnswerChoices([...answerChoices, { value: '' }]);
    setSurveyQuestionCount(surveyQuestionCount + 1);
  };

  /**
   * Handles the input event for bulk answer data.
   *
   * This function updates the bulk answer input state with the value from the event target,
   * splits the input value by new lines to create an array of answers, and updates the bulk answers state.
   *
   * @param {Event} event - The input event triggered by the user.
   */
  const handleGetBulkAnswerData = (event) => {
    setBulkAnswerInput(event.target.value);
    const answersArray = event.target.value.split('\n');
    setBulkAnswers(answersArray);
  };

  /**
   * Handles saving bulk answers based on the provided type.
   *
   * @param {string} type - The type of action to perform. If 'save', the bulk answers are saved.
   *                        Otherwise, the input is reset and a default answer choice is set.
   */
  const handleSaveBulkAnswer = (type) => {
    if (type === 'save') {
      const bulkAnswer = bulkAnswers.map((answer) => ({ value: answer }));
      if (bulkAnswer.length > 0) {
        setAnswerChoices((prevAnswerChoices) => {
          const filteredChoices = prevAnswerChoices.filter((choice) => choice.value.trim() !== '');
          return [...filteredChoices, ...bulkAnswer];
        });
      }
      setIsBulkModalOpen(false);
      setBulkAnswerInput('');
      setErrors('');
    } else {
      setBulkAnswerInput('');
      setIsBulkModalOpen(false);
      setErrors('');
      setAnswerChoices([{ value: '' }]);
    }
  };

  /**
   * Handles the preview of the survey based on the provided type.
   * If the type is 'preview', it validates the survey data before proceeding.
   * If the validation fails, it sets the errors and stops further execution.
   * If the validation passes or the type is not 'preview', it sets the page to show accordingly.
   *
   * @param {string} type - The type of action to perform, e.g., 'preview'.
   */
  const handlePreviewSurvey = (type) => {
    if (type === 'preview') {
      const sendDataObj = {
        title: surveyData.title,
        description: surveyData.description,
        category: surveyData.category
      };
      const { errors, isValid } = PublishSurveyValidation(sendDataObj);
      if (!isValid) {
        setErrors(errors);
        return;
      } else {
        setShowPage(type);
      }
    } else {
      setShowPage(type);
    }
  };

  /**
   * Handles the submission of a survey. This function prepares the survey data,
   * including title, description, type, category, logo, and questions, and sends
   * it to the appropriate API endpoint for either creating a new survey or editing
   * an existing one. If the survey contains images, it uploads them to the specified
   * URLs provided by the API response.
   *
   * @function handleSubmitSurvey
   * @returns {void}
   */
  const handleSubmitSurvey = () => {
    let surveyFileObect = {};
    const sendDataObj = {
      title: surveyData.title,
      description: surveyData.description,
      ...(isAdmin && { type: surveyType === 'Survey' ? 2 : 1 }),
      // type: surveyType === 'Survey' ? 2 : 1,
      category: surveyData.category,
      ...(surveyLogo && { logo: surveyLogo.type }),

      questions: surveyQuestions?.map((surveyQuestion, index) => {
        if (surveyQuestion.image?.type) {
          surveyFileObect = { ...surveyFileObect, [`ques${index + 1}Image`]: surveyQuestion.image };
        }
        return {
          title: surveyQuestion?.questionTitle,
          qType: surveyQuestion?.qType,
          options: surveyQuestion?.options.map((o) => o.value),
          hasOther: surveyQuestion?.hasOther,
          skipable: surveyQuestion?.skipable,
          // ...(surveyQuestion?.image?.type && { image: surveyQuestion?.image?.type })
          image: surveyQuestion?.image?.type ? surveyQuestion?.image?.type : surveyQuestion?.image
          // ...(i?.image?.type && { image: i?.image?.type }),
        };
      })
    };

    if (paramssurveyId) {
      sendDataObj.id = paramssurveyId;
      surveyApi.editSurveyByAdmin(sendDataObj, isAdmin).then(async (response) => {
        if (response?.data?.meta?.code === 200) {
          const meta = response.data.meta;
          const uploadPromises = [];
          if (meta?.logo && meta?.logo?.uploadURL) {
            uploadPromises.push(
              uploadImage(surveyLogo, meta?.logo?.uploadURL, meta?.logo?.filename)
            );
          }

          for (const key in response?.data?.meta) {
            if (key.startsWith('ques') && key.endsWith('Image')) {
              const uploadPromise = uploadImage(
                surveyFileObect[key],
                response?.data?.meta[key]?.uploadURL,
                response?.data?.meta[key]?.filename
              );
              uploadPromises.push(uploadPromise);
            }
          }

          if (uploadPromises.length > 0) {
            try {
              const results = await Promise.all(uploadPromises);
              if (results.every((result) => result.status === 200)) {
                successToast('All images uploaded successfully');
                surveyFileObect = {};
                surveyType === 'Survey' ? setShowPage('share') : setIsShowSurveySuccessModal(true);
              } else {
                console.error('Some images failed to upload:', results);
              }
            } catch (error) {
              console.error('Error during uploads:', error);
              errorToast(error);
            }
          } else {
            surveyType === 'Survey' ? setShowPage('share') : setIsShowSurveySuccessModal(true);
          }
        }
      });
    } else {
      surveyApi.createSurveyByAdmin(sendDataObj, isAdmin).then(async (response) => {
        if (response?.data?.meta?.code === 200) {
          localStorage.setItem('pulseSurveyId', response?.data?.data?.id);
          const meta = response.data.meta;
          const uploadPromises = [];

          if (meta?.logo && meta?.logo?.uploadURL) {
            uploadPromises.push(
              uploadImage(surveyLogo, meta?.logo?.uploadURL, meta?.logo?.filename)
            );
          }

          for (const key in meta) {
            if (key.startsWith('ques') && key.endsWith('Image')) {
              const uploadPromise = uploadImage(
                surveyFileObect[key],
                meta[key]?.uploadURL,
                meta[key]?.filename
              );
              uploadPromises.push(uploadPromise);
            }
          }

          if (uploadPromises.length > 0) {
            try {
              const results = await Promise.all(uploadPromises);
              if (results.every((result) => result.status === 200)) {
                successToast('All images uploaded successfully');
                if (surveyType === 'Survey') {
                  setShowPage('share');
                } else {
                  if (userType === 0) {
                    setIsShowSurveyApprovalModal(true);
                  } else {
                    setIsShowSurveyApprovalModal(!isShowSurveyApprovalModal);
                    surveyApi.getAdminList(0).then(async (response) => {
                      if (response?.data?.meta?.code === 1) {
                        setSuperAdminList(response?.data?.data);
                      }
                    });
                  }
                }
              } else {
                console.error('Some images failed to upload:', results);
              }
            } catch (error) {
              console.error('Error during uploads:', error);
              errorToast(error);
            }
          } else {
            if (surveyType === 'Survey') {
              setShowPage('share');
            } else {
              if (userType === 0) {
                setIsShowSurveyApprovalModal(true);
              } else {
                setIsShowSurveyApprovalModal(!isShowSurveyApprovalModal);
                surveyApi.getAdminList(0).then(async (response) => {
                  if (response?.data?.meta?.code === 1) {
                    setSuperAdminList(response?.data?.data);
                  }
                });
              }
            }
          }
        }
      });
    }
  };

  const getSurveyResult = () => {
    if (isCopanySuperOrSubAdmin()) {
      const id = localStorage.getItem('pulseSurveyId')
      surveyApi.getSurveyResult(id, false).then((response) => {
        const formatData = response.data.questions.map(question => {
          return {
            questionId: question.questionId,
            title: question.questionTitle,  // Renamed 'questionTitle' to 'title'
            totalResponses: question.totalResponses,
            options: question.optionCounts.map(option => {
              return {
                optionId: option.optionId,
                value: option.optionValue,  // Renamed 'optionValue' to 'value'
                count: option.count,
                percentage: option.percentage
              };
            })
          };
        });
        setSurveyQuestionResultList(formatData)
      })
    }
  }

  useEffect(() => {
    if(showPage === 'results') {
      getSurveyResult()
    }
  },[showPage])

  const handleToggleCalendar = () => {
    setShowCalendar((prevShowCalendar) => !prevShowCalendar);
  };

  const handleChangeC = (e) => {
    const date = new Date(e);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    setPublishDate(formattedDate);
    setShowCalendar(false);
  };

  const handleSelectT = (time) => {
    setSelectedTime(time);
    setIsOpenT(false);
  };

  const convertToISO = (dateTimeString) => {
    const formattedDateTimeString = dateTimeString.replace('AM', ' AM').replace('PM', ' PM');
    const date = new Date(formattedDateTimeString);
    const isoDate = date.toISOString();
    return isoDate;
  };

  const getFilePreviewUrl = (file) => {
    if (!file) {
      throw new Error('No file provided');
    }

    // Ensure the input is a File object
    if (!(file instanceof File)) {
      throw new Error('Input must be a File object');
    }

    // Generate and return the preview URL
    return URL.createObjectURL(file);
  };

  const isUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  // Upload logo and question images function
  const uploadImage = async (imageFile, uploadURL, fileName) => {
    try {
      const response = await axios.put(uploadURL, imageFile, {
        headers: {
          'Content-Type': imageFile.type
        }
      });
      if (response.status === 200) {
        return response;
      } else {
        throw new Error('Upload failed with status: ' + response);
      }
    } catch (error) {
      return error;
    }
  };

  // Handle save draft functionality
  const handleSaveDraft = () => {
    if (!surveyData.title) {
      setErrors({ title: '*Title is required' });
    } else {
      setErrors({});
      let surveyFileObect = {};
      const sendDataObj = {
        title: surveyData.title,
        description: surveyData.description,
        ...(isAdmin && { type: surveyType === 'Survey' ? 2 : 1 }),
        status: 6,
        category: surveyData.category,
        ...(surveyLogo && { logo: surveyLogo.type }),
        questions: surveyQuestions?.length
          ? surveyQuestions.map((surveyQuestion, index) => {
              if (surveyQuestion.image?.type) {
                surveyFileObect = {
                  ...surveyFileObect,
                  [`ques${index + 1}Image`]: surveyQuestion.image
                };
              }
              return {
                title: surveyQuestion?.questionTitle,
                qType: surveyQuestion?.qType,
                options: surveyQuestion?.options.map((o) => o.value),
                hasOther: surveyQuestion?.hasOther,
                skipable: surveyQuestion?.skipable,
                // ...(surveyQuestion?.image?.type && { image: surveyQuestion?.image?.type })
                image: surveyQuestion?.image?.type
                  ? surveyQuestion?.image?.type
                  : surveyQuestion?.image
              };
            })
          : undefined // Will remove 'questions' if the array is empty
      };

      // Remove keys with undefined, null, or empty values
      const cleanedDataObj = JSON.parse(
        JSON.stringify(sendDataObj, (key, value) => {
          return value === undefined || value === null || value === '' ? undefined : value;
        })
      );

      if (paramssurveyId) {
        sendDataObj.id = paramssurveyId;
        surveyApi.editSurveyByAdmin(sendDataObj, isAdmin).then(async (response) => {
          if (response?.data?.meta?.code === 200) {
            const meta = response.data.meta;
            const uploadPromises = [];
            if (meta?.logo && meta?.logo?.uploadURL) {
              uploadPromises.push(
                uploadImage(surveyLogo, meta?.logo?.uploadURL, meta?.logo?.filename)
              );
            }

            for (const key in response?.data?.meta) {
              if (key.startsWith('ques') && key.endsWith('Image')) {
                const uploadPromise = uploadImage(
                  surveyFileObect[key],
                  response?.data?.meta[key]?.uploadURL,
                  response?.data?.meta[key]?.filename
                );
                uploadPromises.push(uploadPromise);
              }
            }

            if (uploadPromises.length > 0) {
              try {
                const results = await Promise.all(uploadPromises);
                if (results.every((result) => result.status === 200)) {
                  successToast('All images uploaded successfully');
                  surveyFileObect = {};
                  setIsDraft(0);
                  setIsShowSurveySuccessModal(true);
                } else {
                  console.error('Some images failed to upload:', results);
                }
              } catch (error) {
                console.error('Error during uploads:', error);
                errorToast(error);
              }
            } else {
              setIsDraft(0);
              setIsShowSurveySuccessModal(true);
            }
          }
        });
      } else {
        surveyApi.createSurveyByAdmin(sendDataObj, isAdmin).then(async (response) => {
          if (response?.data?.meta?.code === 200) {
            localStorage.setItem('pulseSurveyId', response?.data?.data?.id);
            const meta = response.data.meta;
            const uploadPromises = [];

            if (meta?.logo && meta?.logo?.uploadURL) {
              uploadPromises.push(
                uploadImage(surveyLogo, meta?.logo?.uploadURL, meta?.logo?.filename)
              );
            }

            for (const key in meta) {
              if (key.startsWith('ques') && key.endsWith('Image')) {
                const uploadPromise = uploadImage(
                  surveyFileObect[key],
                  meta[key]?.uploadURL,
                  meta[key]?.filename
                );
                uploadPromises.push(uploadPromise);
              }
            }

            if (uploadPromises.length > 0) {
              try {
                const results = await Promise.all(uploadPromises);
                if (results.every((result) => result.status === 200)) {
                  successToast('All images uploaded successfully');
                  setIsDraft(0);
                  setIsShowSurveySuccessModal(true);
                } else {
                  console.error('Some images failed to upload:', results);
                }
              } catch (error) {
                console.error('Error during uploads:', error);
                errorToast(error);
              }
            } else {
              setIsDraft(0);
              setIsShowSurveySuccessModal(true);
            }
          }
        });
      }
    }
  };

  // Edit, Delete and Duplicate Question Actions
  const handleQuestionAction = (type, questionIndex, data) => {
    if (type === 'delete') {
      const updatedObjectImage = {};
      const updatedObjectImagePreview = {};

      for (const key in surveyQuestionImage) {
        if (key !== `ques${questionIndex + 1}Image`) {
          const match = key.match(/ques(\d+)Image/);
          if (match) {
            const index = parseInt(match[1], 10);
            updatedObjectImage[`ques${index > questionIndex + 1 ? index - 1 : index}Image`] =
              surveyQuestionImage[key];
          }
        }
      }

      for (const key in surveyQuestionImagePreview) {
        if (key !== `ques${questionIndex + 1}Image`) {
          const match = key.match(/ques(\d+)Image/);
          if (match) {
            const index = parseInt(match[1], 10);
            updatedObjectImagePreview[`ques${index > questionIndex + 1 ? index - 1 : index}Image`] =
              surveyQuestionImagePreview[key];
          }
        }
      }

      const filteredSurveyQuestionDataObj = surveyQuestions.filter(
        (i, index) => index !== questionIndex
      );
      setSurveyQuestions(filteredSurveyQuestionDataObj);
      setSurveyQuestionImage(updatedObjectImage);
      setSurveyQuestionImagePreview(updatedObjectImagePreview);
    }

    if (type === 'edit') {
      setSurveyQuestionData({
        questionTitle: data?.questionTitle,
        hasOther: data?.hasOther,
        skipable: data?.skipable,
        nonAbove: data?.nonAbove,
        qType: data?.qType,
        image: data?.image
      });
      setTempImagePreview(data?.image);
      const newSurveyQuestions = surveyQuestions.filter((item, index) => index !== questionIndex);
      setSurveyQuestions(newSurveyQuestions);
      setAnswerChoices(data?.options);
      // setTempQuestion(data?.options);
    }

    if (type === 'duplicate') {
      const duplicateQuestion = surveyQuestions[questionIndex];
      setSurveyQuestions([...surveyQuestions, duplicateQuestion]);

      const originalImageKey = questionIndex + 1;
      const newImageKey = originalImageKey + 1;
      const duplicatedImage = surveyQuestionImagePreview[`ques${originalImageKey}Image`];

      if (duplicatedImage) {
        setSurveyQuestionImage((prev) => ({
          ...prev,
          [`ques${newImageKey}Image`]: surveyQuestionImage[`ques${originalImageKey}Image`]
        }));
        setSurveyQuestionImagePreview((prev) => ({
          ...prev,
          [`ques${newImageKey}Image`]: duplicatedImage
        }));
      }
    }
  };

  const handleGetEndPageData = ({ target: { name, value } }) => {
    setSurveyShareData({ ...surveyShareData, [name]: value });
  };
  // Send Survey data from Share Page with Edit Survey API
  const handleSendSurvey = () => {
    if (userType === 0 || userType === 1) {
      if (userType === 0) {
        const { errors, isValid } = EditSurveyShareDataValidation({
          audience: surveyShareData.audience,
          endHeading: surveyShareData.endHeading,
          endBody: surveyShareData.endBody
        });
        if (!isValid) {
          setErrors(errors);
        } else {
          let sendDataObj = {
            id: localStorage.getItem('pulseSurveyId'),
            audience: surveyShareData.audience,
            ...(surveyCoverImage && { coverImage: surveyCoverImage.type }),
            publishDateTime:
              publishDate && selectedTime ? convertToISO(`${publishDate} ${selectedTime}`) : '',
            endHeading: surveyShareData.endHeading,
            endBody: surveyShareData.endBody,
            ...(surveyData.category && { category: surveyData.category })
          };
          surveyApi.editSurveyByAdmin(sendDataObj, isAdmin).then(async (response) => {
            if (response?.data?.meta?.code === 200) {
              const meta = response.data.meta;
              setSurveyShareData({
                audience: '',
                endBody: '',
                endHeading: '',
                shareSurveyCategory: ''
              });
              setSurveyCoverImage('');
              setIsShowSurveySuccessModal(true);
              if (meta?.coverImage && meta?.coverImage?.uploadURL) {
                const uploadImageResponse = await uploadImage(
                  surveyCoverImage,
                  meta?.coverImage?.uploadURL,
                  meta?.coverImage?.filename
                );
                if (uploadImageResponse?.status === 200) {
                  successToast('Image uploaded successfully');
                  setIsShowSurveySuccessModal(true);
                }
              }
              localStorage.removeItem('surveyId');
            }
          });
          setErrors('');
        }
      } else {
        setIsShowSurveyApprovalModal(!isShowSurveyApprovalModal);
        surveyApi.getAdminList(0).then(async (response) => {
          if (response?.data?.meta?.code === 1) {
            setSuperAdminList(response?.data?.data);
          }
        });
      }
    } else {
      if (userType === 3 || userType === 4) {
        if (userType === 3) {
          const { errors, isValid } = EditSurveyShareDataValidation({
            audience: surveyShareData.audience,
            endHeading: surveyShareData.endHeading,
            endBody: surveyShareData.endBody
          });
          if (!isValid) {
            setErrors(errors);
          } else {
            let sendDataObj = {
              id: localStorage.getItem('pulseSurveyId'),
              department: surveyShareData.audience,
              ...(emails.length > 0 && { emails: emails }),
              ...(surveyCoverImage && { coverImage: surveyCoverImage.type }),
              publishDateTime:
                publishDate && selectedTime ? convertToISO(`${publishDate} ${selectedTime}`) : '',
              endHeading: surveyShareData.endHeading,
              endBody: surveyShareData.endBody
            };
            CompanyApi.editSurveyCompany(sendDataObj).then(async (response) => {
              if (response?.data?.meta?.code === 200) {
                const meta = response.data.meta;
                setSurveyShareData({
                  audience: '',
                  endBody: '',
                  endHeading: '',
                  shareSurveyCategory: ''
                });
                setEmailInput('');
                setEmails('');
                setSurveyCoverImage('');
                setIsShowSurveySuccessModal(true);
                if (meta?.coverImage && meta?.coverImage?.uploadURL) {
                  const uploadImageResponse = await uploadImage(
                    surveyCoverImage,
                    meta?.coverImage?.uploadURL,
                    meta?.coverImage?.filename
                  );
                  if (uploadImageResponse?.status === 200) {
                    successToast('Image uploaded successfully');
                    setIsShowSurveySuccessModal(true);
                    // setTimeout(() => {
                    //   window.location.href = '/reckit/pulse-survey';
                    //   setIsShowSurveySuccessModal(false);
                    // }, 1200);
                  }
                }
                // setTimeout(() => {
                //   window.location.href = '/reckit/pulse-survey';
                //   setIsShowSurveySuccessModal(false);
                // }, 1200);
              }
            });
            setErrors('');
          }
        } else {
          setIsShowSurveyApprovalModal(!isShowSurveyApprovalModal);
          CompanyApi.getAdminListCompany(3).then(async (response) => {
            if (response?.data?.meta?.code === 1) {
              setSuperAdminList(response?.data?.data);
            }
          });
        }
      }
    }
  };

  const handleSendSurveyWithApproval = () => {
    setIsShowSurveyApprovalModal(false);
    const { errors, isValid } = EditSurveyShareDataValidation({
      audience: surveyShareData.audience,
      endHeading: surveyShareData.endHeading,
      endBody: surveyShareData.endBody
    });
    if (userType === 1) {
      if (!isValid && surveyType !== 'Template') {
        setErrors(errors);
      } else {
        let sendDataObj = {
          id: localStorage.getItem('pulseSurveyId'),
          audience: surveyShareData.audience,
          ...(surveyCoverImage && { coverImage: surveyCoverImage.type }),
          publishDateTime:
            publishDate && selectedTime ? convertToISO(`${publishDate} ${selectedTime}`) : '',
          endHeading: surveyShareData.endHeading,
          endBody: surveyShareData.endBody,
          approvalBy: surveyShareData.approvalBy
        };

        if (surveyType === 'Template') {
          sendDataObj = {
            id: localStorage.getItem('pulseSurveyId'),
            publishDateTime: new Date().toISOString(),
            approvalBy: surveyShareData.approvalBy
          };
        }

        surveyApi.editSurveyByAdmin(sendDataObj, isAdmin).then(async (response) => {
          if (response?.data?.meta?.code === 200) {
            const meta = response.data.meta;
            setSurveyShareData({
              audience: '',
              endBody: '',
              endHeading: '',
              shareSurveyCategory: ''
            });
            setSurveyCoverImage('');
            setIsShowSurveyApprovalModal(false);
            setIsShowSurveySuccessModal(true);
            // setTimeout(() => {
            //   window.location.href = '/pulse-survey';
            //   setIsShowSurveySuccessModal(false);
            // }, 1200);
            if (meta?.coverImage && meta?.coverImage?.uploadURL) {
              const uploadImageResponse = await uploadImage(
                surveyCoverImage,
                meta?.coverImage?.uploadURL,
                meta?.coverImage?.filename
              );
              if (uploadImageResponse?.status === 200) {
                successToast('Image uploaded successfully');
                setIsShowSurveySuccessModal(true);
                // setTimeout(() => {
                //   window.location.href = '/pulse-survey';
                //   setIsShowSurveySuccessModal(false);
                // }, 1200);
              }
            }
          }
        });
        setErrors('');
      }
    } else {
      if (!isValid) {
        setErrors(errors);
      } else {
        let sendDataObj = {
          id: localStorage.getItem('pulseSurveyId'),
          department: surveyShareData.audience,
          ...(emails.length > 0 && { emails: emails }),
          ...(surveyCoverImage && { coverImage: surveyCoverImage.type }),
          publishDateTime:
            publishDate && selectedTime ? convertToISO(`${publishDate} ${selectedTime}`) : '',
          endHeading: surveyShareData.endHeading,
          endBody: surveyShareData.endBody,
          approvalBy: surveyShareData.approvalBy
        };
        CompanyApi.editSurveyCompany(sendDataObj).then(async (response) => {
          if (response?.data?.meta?.code === 200) {
            const meta = response.data.meta;
            setSurveyShareData({
              audience: '',
              endBody: '',
              endHeading: '',
              shareSurveyCategory: ''
            });
            setEmailInput('');
            setEmails('');
            setSurveyCoverImage('');
            setIsShowSurveySuccessModal(true);
            if (meta?.coverImage && meta?.coverImage?.uploadURL) {
              const uploadImageResponse = await uploadImage(
                surveyCoverImage,
                meta?.coverImage?.uploadURL,
                meta?.coverImage?.filename
              );
              if (uploadImageResponse?.status === 200) {
                successToast('Image uploaded successfully');
                setIsShowSurveySuccessModal(true);
                // setTimeout(() => {
                //   window.location.href = '/reckit/pulse-survey';
                //   setIsShowSurveySuccessModal(false);
                // }, 1200);
              }
            }
            // setTimeout(() => {
            //   window.location.href = '/reckit/pulse-survey';
            //   setIsShowSurveySuccessModal(false);
            // }, 1200);
          }
        });
        setErrors('');
      }
    }
  };

  useEffect(() => {
    handleGetSurveyCategoryData();
    // surveyId && fetchData();
  }, [surveyId]);

  return (
    <>
      <Topbar />
      <div className="gird font-work-sans add-survey-part lg:pl-5">
        <div className="grid-cols-12 flex justify-between sm:items-center mt-32 lg:mt-10">
          <div className="sm:col-span-6 col-span-12 flex gap-8 sm:gap-12 sm:mt-0 mt-5">
            <div
              className={`text-[20px] font-medium leading-9 text-shoorah-lightBlack ${
                showPage === 'edit' ? 'opacity-100' : 'opacity-50'
              }`}
            >
              Edit
            </div>
            <div
              className={`text-[20px] font-medium leading-9 text-shoorah-lightBlack ${
                showPage === 'share' ? 'opacity-100' : 'opacity-50'
              }`}
            >
              Share
            </div>
            {surveyId && isCopanySuperOrSubAdmin() && (
            <>
              <div
                className={`text-[20px] font-medium leading-9 text-shoorah-lightBlack ${
                  showPage === 'results' ? 'opacity-100' : 'opacity-50'
                }`}
              >
                <button onClick={() => setShowPage('results')} disabled={surveyStatus != 5}>Results</button>
              </div>
              <div
                className={`text-[20px] font-medium leading-9 text-shoorah-lightBlack ${
                  showPage === 'insights' ? 'opacity-100' : 'opacity-50'
                }`}
              >
                <button  onClick={() => setShowPage('insights')} disabled={surveyStatus != 5}>Insights</button>
              </div>
            </>
          )}
          </div>
          <div className="sm:col-span-6 col-span-12 relative flex justify-end text-[1rem]">
            <button
              className="border border-black rounded-[30px] px-2 sm:px-4 sm:py-[8px] truncate text-[14px]"
              onClick={() => handlePreviewSurvey(showPage === 'edit' ? 'preview' : 'edit')}
            >
              {showPage === 'preview' ? 'Edit' : 'Preview'}{' '}
              {surveyType === 'Survey' ? 'Survey' : 'Template'}
            </button>
          </div>
        </div>

        {showPage === 'edit' && (
          <div>
            <div className="sm:mt-9 mt-5 rounded-2xl border-[1.5px] border-shoorah-borderGray bg-[#FFFFFF] sm:p-6 p-4">
              <div
                className={`flex flex-row gap-3 justify-center bg-[#FFDC43] cursor-pointer ${
                  previewSurveyLogo
                    ? 'w-24 h-24 rounded-full p-2'
                    : 'w-full max-w-[122px] h-full px-6 py-2 rounded-3xl'
                }`}
              >
                {previewSurveyLogo ? (
                  <div className="relative inline-block w-20 h-20">
                    {/* Logo Image */}
                    <img
                      src={previewSurveyLogo}
                      alt="logo-image"
                      className="rounded-full w-full h-full border border-gray-300"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      id="survey-log"
                      hidden
                      onChange={handleUploadSurveyLogo}
                    />
                    {/* Edit Icon */}
                    <button
                      type="button"
                      className="absolute top-0 right-0 bg-white p-1 rounded-full shadow hover:shadow-md hover:bg-gray-100"
                      onClick={() => document.getElementById('survey-log').click()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M12 20h9" />
                        <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19.001 3 20l.999-4 12.5-12.5z" />
                      </svg>
                    </button>
                  </div>
                ) : (
                  <>
                    <label
                      htmlFor="survey-log"
                      className="cursor-pointer flex items-center gap-[10px] font-medium text-[16px] leading-6"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                          stroke="#0B1D2E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 12H16"
                          stroke="#0B1D2E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 16V8"
                          stroke="#0B1D2E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Logo
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      id="survey-log"
                      hidden
                      onChange={handleUploadSurveyLogo}
                      disabled={surveyLogo ? true : false}
                    />
                  </>
                )}
              </div>
              <div className="sm:grid sm:grid-cols-12 text-[16px] text-shoorah-lightBlack gap-5 mt-6 sm:w-[80%] add-survey-card">
                <div className="col-span-7">
                  <label htmlFor="title" className="font-medium text-[16px] leading-9 mb-1">
                    {surveyType === 'Survey' ? 'Survey' : 'Template'} Title
                  </label>
                  <div className="input-field">
                    <input
                      className="w-full h-10 bg-shoorah-normanGray border-[1.5px] text-[14px] border-shoorah-borderGray rounded-[8px] px-4 flex flex-col justify-center items-start relative"
                      type="text"
                      name="title"
                      id="title"
                      value={surveyData.title}
                      onChange={handleGetSurveyData}
                      placeholder="Enter title: e.g Social Anxiety Assessment"
                    />
                    {errors.title && <p className="text-red-500 text-sm">{errors.title}</p>}
                  </div>
                </div>
                <div className="col-span-5 sm:mt-0 text-[14px] text-shoorah-lightBlack mt-4">
                  <label className="font-medium text-[16px] leading-9 mb-1">
                    {surveyType === 'Survey' ? 'Survey' : 'Template'} Category
                  </label>
                  <div className="input-field">
                    <Select
                      options={surveyCategory}
                      name="category"
                      value={surveyCategory.find((option) => option.value === surveyData.category)}
                      onChange={handleGetSurveyCategory}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          height: '40px',
                          border: '1.5px solid #E7E8EA',
                          backgroundColor: '#F4F4F4',
                          borderRadius: '0.5rem',
                          color: '#0B1D2E'
                        }),
                        menu: (baseStyles) => ({
                          ...baseStyles,
                          backgroundColor: '#fff',
                          borderRadius: '0.5rem'
                        }),
                        option: (baseStyles, { isFocused, isSelected }) => ({
                          ...baseStyles,
                          backgroundColor: isSelected ? '' : isFocused ? '#EEEEEE' : '#fff',
                          color: '#0B1D2E',
                          ':hover': {
                            backgroundColor: '#EEEEEE'
                          }
                        }),
                        singleValue: (baseStyles) => ({
                          ...baseStyles,
                          color: '#0B1D2E'
                        })
                      }}
                    />
                    {errors.category && <p className="text-red-500 text-sm">{errors.category}</p>}
                  </div>
                </div>
              </div>
              <div className="text-[16px] text-shoorah-lightBlack mt-6 sm:w-[80%]">
                <label htmlFor="description" className="font-medium text-[16px] leading-9 mb-1">
                  {surveyType === 'Survey' ? 'Survey' : 'Template'} Description
                </label>
                <div className="input-field">
                  <textarea
                    className="w-full block rounded-lg px-4 py-2 text-[14px] bg-shoorah-normanGray border-[1.5px] border-shoorah-borderGray min-h-[100px] resize-none mb-0"
                    value={surveyData.description}
                    name="description"
                    id="description"
                    placeholder="Enter description: e.g Take this assessment to find out if you may have symptoms of social anxiety."
                    onChange={handleGetSurveyData}
                    maxLength={maxLength}
                  ></textarea>
                  {errors.description && (
                    <p className="text-red-500 text-sm">{errors.description}</p>
                  )}
                </div>
              </div>
            </div>
            {surveyQuestions?.length > 0 &&
              surveyQuestions?.map((surveyQuestion, index) => (
                <div
                  key={index}
                  className="px-6 py-10 sm:px-7 sm:py-10 border border-shoorah-borderGray bg-[#FFFFFF] rounded-xl mt-5"
                >
                  <div className="flex justify-center">
                    <div className="flex w-full z-0">
                      <div className="p-4 h-10 w-[60px] text-[14px] font-medium leading-9 rounded-lg mr-4 text-shoorah-lightBlack border-[1.5px] border-shoorah-borderGray bg-shoorah-normanGray flex items-center justify-center">
                        Q{index + 1}
                      </div>
                      <div className="question-title flex flex-col">
                        <h2 className="tracking-none sm:tracking-normal h-fit w-auto font-medium leading-9 text-[18.5px] sm:text-[24px] mb-6 text-black">
                          {surveyQuestion?.questionTitle}
                        </h2>
                        {surveyQuestion.image && (
                          <img
                            src={
                              isUrl(surveyQuestion.image)
                                ? surveyQuestion.image
                                : getFilePreviewUrl(surveyQuestion.image)
                            }
                            alt="preview-image"
                            className="w-[400px] h-[200px] object-cover mb-6"
                            onError={(e) => {
                              e.target.style.display = 'none'; // Hide image if loading fails
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="relative inline-block">
                      <div
                        className="dropdown-toggle justify-end text-shoorah-lightBlack text-[16px] flex items-center rounded-xl cursor-pointer"
                        onClick={() => toggleDropdown(index)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                            stroke="#0B1D2E"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                            stroke="#0B1D2E"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                            stroke="#0B1D2E"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        {isOpen && index === dropDownIndex && (
                          <div
                            className="w-40 top-[13px] left-[-131px] z-10 bg-[#FFFFFF] absolute mt-2 border-[1.5px] py-[14px] border-[#DDDDDD] rounded-lg"
                            style={{
                              zIndex: 274
                            }}
                          >
                            <div
                              onClick={() => [
                                setIsOpen(!isOpen),
                                handleQuestionAction('edit', index, surveyQuestion)
                              ]}
                              className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer font-medium leading-6 text-[16px]"
                            >
                              Edit
                            </div>
                            <div
                              onClick={() => [
                                setIsOpen(!isOpen),
                                handleQuestionAction('duplicate', index, surveyQuestion)
                              ]}
                              className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer font-medium leading-6 text-[16px]"
                            >
                              Duplicate
                            </div>
                            <div className="border-b border-gray-400 w-full my-2"></div>
                            <div
                              onClick={() => [
                                setIsOpen(!isOpen),
                                handleQuestionAction('delete', index, surveyQuestion)
                              ]}
                              className="py-1 text-left px-5 hover:bg-[#E7E8EA] cursor-pointer text-red-700 font-medium leading-6 text-[16px]"
                            >
                              Delete
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {surveyQuestion?.options?.filter((opt) => opt?.value !== '')?.length > 0 && (
                    <div className="w-full flex flex-col items-center">
                      <div className="flex flex-col space-y-7 mb-4 w-[90%] justify-center">
                        {surveyQuestion?.options
                          ?.filter((opt) => opt?.value !== '')
                          ?.map((opt, index) => (
                            <label className="flex items-center" key={index}>
                              <input type="radio" name="answerRadio" className="w-5 h-5" disabled />
                              <p className="ml-4 text-black"> {opt?.value}</p>
                            </label>
                          ))}
                      </div>
                    </div>
                  )}
                  {surveyQuestion?.qType === 3 && (
                    <div className="relative w-full">
                      <div className="absolute left-4 top-[14px]">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="opacity-50"
                        >
                          <g clipPath="url(#clip0_302_19223)">
                            <path
                              d="M16.4384 7.76362C16.4027 6.95987 16.3344 6.16644 16.2547 5.38522C16.0529 3.40836 14.456 1.81088 12.4801 1.60011C11.3187 1.47622 10.1303 1.37729 8.92123 1.37729C7.71212 1.37729 6.52378 1.47622 5.36234 1.60011C3.38644 1.81088 1.78957 3.40836 1.58781 5.38522C1.47002 6.53927 1.37732 7.71996 1.37732 8.9212C1.37732 10.1224 1.47002 11.3031 1.58781 12.4572C1.78957 14.4341 3.38656 16.0315 5.36247 16.2423C6.32421 16.3449 7.3044 16.4303 8.29954 16.4567"
                              stroke="#0B1D2E"
                              strokeWidth="1.428"
                              strokeLinecap="round"
                            />
                            <path
                              d="M5.20227 6.73435V6.18918C5.20227 5.54864 5.65954 4.99484 6.29462 4.91143C8.05762 4.67983 9.77632 4.67983 11.5393 4.91143C12.1744 4.99484 12.6317 5.54864 12.6317 6.18918V6.73435"
                              stroke="#0B1D2E"
                              strokeWidth="1.428"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.91711 4.73773V11.2177"
                              stroke="#0B1D2E"
                              strokeWidth="1.428"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.4455 11.1897L11.6435 15.5036L11.1227 17.9533C11.0389 18.3475 11.4498 18.7204 11.8345 18.5995L14.2724 17.8331L18.1993 13.701C18.8516 13.0147 18.7378 11.8669 17.9482 11.1686C17.1773 10.4866 16.0568 10.4961 15.4455 11.1897Z"
                              stroke="#0B1D2E"
                              strokeWidth="1.428"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_302_19223">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <textarea
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        maxLength={maxLength}
                        readOnly
                        placeholder="Enter Multiple Choice Question"
                        className="w-full h-[120px] md:w-[89%] lg:w-[80%] rounded-xl p-3 pt-[10px] pl-[40px] bg-shoorah-normanGray border-[1.5px] border-shoorah-borderGray resize-none"
                      />
                      <div className="absolute bottom-5 right-0 md:right-[80px] lg:right-[315px] text-gray-400 text-sm">
                        *{maxLength} Character limit
                      </div>
                    </div>
                  )}
                </div>
              ))}
            <div className="mt-5 rounded-2xl border-[1.5px] border-shoorah-borderGray p-6 bg-[#FFFFFF] mb-10">
              <div className="block w-full h-full">
                <h3 className="text-[20px] leading-9 text-shoorah-lightBlack">Add Question</h3>
                <div className="sm:grid sm:grid-cols-12 gap-2 mt-4 items-start w-full">
                  <div className="col-span-1 sm:col-span-7">
                    <div className="input-field">
                      <input
                        className="mb-5 sm:mb-0 w-full h-10 bg-shoorah-normanGray border-[1.5px] text-[14px] border-shoorah-borderGray rounded-[8px] px-4 flex flex-col justify-center items-start relative"
                        type="text"
                        placeholder="Enter your question"
                        name="questionTitle"
                        value={surveyQuestionData.questionTitle}
                        onChange={handleGetQuestionData}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-4 flex gap-5 items-start">
                    <div className="w-30 h-30">
                      {surveyQuestionData.image ? (
                        <img
                          src={
                            surveyQuestionData.image instanceof File
                              ? getFilePreviewUrl(surveyQuestionData.image)
                              : surveyQuestionData.image
                          }
                          alt="question-image"
                          className="w-30 h-30 object-cover rounded-lg cursor-pointer"
                        />
                      ) : (
                        <>
                          <label
                            htmlFor="question-image"
                            className="cursor-pointer mb-5 sm:mb-0 flex items-center justify-center border-[1.5px] bg-shoorah-normanGray border-shoorah-borderGray h-full rounded-lg"
                          >
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.75"
                                y="0.75"
                                width="38.5"
                                height="38.5"
                                rx="7.25"
                                fill="#F4F4F4"
                                stroke="#E7E8EA"
                                stroke-width="1.5"
                              />
                              <path
                                d="M17 30H23C28 30 30 28 30 23V17C30 12 28 10 23 10H17C12 10 10 12 10 17V23C10 28 12 30 17 30Z"
                                stroke="#0B1D2E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M17 18C18.1046 18 19 17.1046 19 16C19 14.8954 18.1046 14 17 14C15.8954 14 15 14.8954 15 16C15 17.1046 15.8954 18 17 18Z"
                                stroke="#0B1D2E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M10.6699 26.9496L15.5999 23.6396C16.3899 23.1096 17.5299 23.1696 18.2399 23.7796L18.5699 24.0696C19.3499 24.7396 20.6099 24.7396 21.3899 24.0696L25.5499 20.4996C26.3299 19.8296 27.5899 19.8296 28.3699 20.4996L29.9999 21.8996"
                                stroke="#0B1D2E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </label>
                          <input
                            type="file"
                            id="question-image"
                            hidden
                            accept=".jpg, .jpeg, .png"
                            onChange={handleUploadQuestionImage}
                            disabled={!!tempImagePreview} // Disable input if an image is already uploaded
                          />
                        </>
                      )}
                    </div>
                    <div className="whitespace-nowrap relative inline-block w-full sm:w-[160px]">
                      <div
                        className="dropdown-toggle border w-full sm:max-w-[160px] bg-shoorah-normanGray text-shoorah-lightBlack text-[16px] border-shoorah-borderGray flex items-center h-10 rounded-[8px] cursor-pointer justify-between px-4"
                        onClick={() => setIsOpenQuestionType(!isOpenQuestionType)}
                      >
                        <span className="text-shoorah-lightBlack text-[12px]">
                          {surveyQuestionData.qType === 1
                            ? 'Single Choice'
                            : surveyQuestionData.qType === 2
                            ? 'Multiple Choice'
                            : surveyQuestionData.qType === 3
                            ? 'Input'
                            : 'Select Type'}
                        </span>
                        <svg
                          className={`w-4 h-4 transform ${isOpen ? 'rotate-180' : ''}`}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                      {isOpenQuestionType && (
                        <div className="whitespace-nowrap absolute top-full z-40 right-0 w-full bg-white border-[1.5px] border-shoorah-borderGray rounded-xl mt-2 py-3">
                          <div
                            className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer text-[14px] leading-6"
                            onClick={() => [
                              setSurveyQuestionData({ ...surveyQuestionData, qType: '' }),
                              setIsOpenQuestionType(!isOpenQuestionType)
                            ]}
                          >
                            Select Type
                          </div>
                          <div
                            className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer text-[14px] leading-6"
                            onClick={() => [
                              setSurveyQuestionData({ ...surveyQuestionData, qType: 1 }),
                              setIsOpenQuestionType(!isOpenQuestionType)
                            ]}
                          >
                            Single Choice
                          </div>
                          <div
                            className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer text-[14px] leading-6"
                            onClick={() => [
                              setSurveyQuestionData({ ...surveyQuestionData, qType: 2 }),
                              setIsOpenQuestionType(!isOpenQuestionType)
                            ]}
                          >
                            Multiple Choice
                          </div>
                          <div
                            className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer text-[14px] leading-6"
                            onClick={() => [
                              setSurveyQuestionData({ ...surveyQuestionData, qType: 3 }),
                              setIsOpenQuestionType(!isOpenQuestionType)
                            ]}
                          >
                            Input Field
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {(surveyQuestionData.qType === 1 || surveyQuestionData.qType === 2) && (
                <>
                  <div className="w-full sm:w-[59%] flex justify-between">
                    <label className="block text-[16px] leading-9 font-medium text-shoorah-lightBlack mt-[45px] mb-[10px]">
                      Answers Choices
                    </label>
                  </div>
                  <div className="grid grid-cols-12 sm:gap-4 sm:w-[80%] w-full mb-6">
                    {answerChoices?.map((choice, index) => (
                      <div key={index} className="col-span-12 sm:col-span-10 mb-3 sm:mb-0">
                        <div className="flex items-center justify-between gap-5">
                          <div className="input-field w-full">
                            <input
                              className="w-full h-10 bg-shoorah-normanGray border-[1.5px] text-[14px] border-shoorah-borderGray rounded-[8px] px-4 flex flex-col justify-center items-start relative"
                              type="text"
                              placeholder="Enter your answer choice"
                              name="options"
                              value={choice.value || ''}
                              onChange={(e) => handleGetQueAns(index, e)}
                              readOnly={
                                surveyQuestionData.hasOther === choice.value ||
                                surveyQuestionData.nonAbove === choice.value
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <div className="flex gap-[10px]">
                            <button
                              className="flex justify-center items-center"
                              onClick={addAnswerField}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                  stroke="#A1A1A1"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M8 12H16"
                                  stroke="#A1A1A1"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M12 16V8"
                                  stroke="#A1A1A1"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                            <button
                              className="flex justify-center items-center"
                              onClick={() => {
                                // if (surveyQuestionCount > 1) {
                                removeAnswerField(index);
                                // }
                              }}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.92 22C17.42 22 21.92 17.5 21.92 12C21.92 6.5 17.42 2 11.92 2C6.41998 2 1.91998 6.5 1.91998 12C1.91998 17.5 6.41998 22 11.92 22Z"
                                  stroke="#A1A1A1"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.91998 12H15.92"
                                  stroke="#A1A1A1"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex sm:justify-start rounded-xl flex-col justify-start mb-10">
                    <button
                      className="flex gap-2 justify-center items-center rounded-[25px] border border-[#A1A1A1] px-6 py-[7px] w-fit"
                      onClick={() => {
                        setIsBulkModalOpen(true);
                      }}
                    >
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.50004 14.6666C12.1667 14.6666 15.1667 11.6666 15.1667 7.99998C15.1667 4.33331 12.1667 1.33331 8.50004 1.33331C4.83337 1.33331 1.83337 4.33331 1.83337 7.99998C1.83337 11.6666 4.83337 14.6666 8.50004 14.6666Z"
                          stroke="#0B1D2E"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.83337 8H11.1667"
                          stroke="#0B1D2E"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.5 10.6666V5.33331"
                          stroke="#0B1D2E"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className="text-[14px] text-shoorah-lightBlack font-medium leading-6">
                        Bulk Answers
                      </p>
                    </button>
                  </div>
                </>
              )}

              {surveyQuestionData.qType !== '' && surveyQuestionData.qType !== 3 && (
                <>
                  <label className="block text-[16px] leading-9 font-medium text-shoorah-lightBlack mt-5">
                    Answers Choices
                  </label>
                  <div className="w-full px-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-2">
                      <div className="flex items-center px-3 py-2 border border-[#CED2D5] rounded-[25px]">
                        <input
                          id="other-ans-choice-1"
                          type="checkbox"
                          className="rounded-answer-choice"
                          checked={surveyQuestionData.hasOther}
                          value={surveyQuestionData.hasOther}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setSurveyQuestionData((prevData) => ({
                              ...prevData,
                              hasOther: isChecked
                            }));
                            setAnswerChoices((prevChoices) =>
                              isChecked
                                ? [...prevChoices, { value: 'Other' }]
                                : prevChoices.filter((choice) => choice.value !== 'Other')
                            );
                          }}
                        />
                        <label
                          htmlFor="other-ans-choice-1"
                          className="ml-3 text-sm font-medium leading-6 text-gray-900"
                        >
                          Add "Other" Answers Choice
                        </label>
                      </div>

                      <div className="flex items-center px-3 py-2 border border-[#CED2D5] rounded-[25px]">
                        <input
                          id="other-ans-choice-2"
                          type="checkbox"
                          className="rounded-answer-choice"
                          checked={surveyQuestionData.skipable}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setSurveyQuestionData((prevData) => ({
                              ...prevData,
                              skipable: !prevData.skipable
                            }));
                            setAnswerChoices((prevChoices) =>
                              isChecked
                                ? [...prevChoices, { value: 'Skipable' }]
                                : prevChoices.filter((choice) => choice.value !== 'Skipable')
                            );
                          }}
                        />
                        <label
                          htmlFor="other-ans-choice-2"
                          className="ml-3 text-sm font-medium leading-6 text-gray-900"
                        >
                          "Skipable" Question
                        </label>
                      </div>

                      <div className="flex items-center px-3 py-2 border border-[#CED2D5] rounded-[25px]">
                        <input
                          id="other-ans-choice-3"
                          type="checkbox"
                          className="rounded-answer-choice"
                          checked={surveyQuestionData.nonAbove}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setSurveyQuestionData((prevData) => ({
                              ...prevData,
                              nonAbove: isChecked
                            }));
                            setAnswerChoices((prevChoices) =>
                              isChecked
                                ? [...prevChoices, { value: 'None of the above' }]
                                : prevChoices.filter(
                                    (choice) => choice.value !== 'None of the above'
                                  )
                            );
                          }}
                        />
                        <label
                          htmlFor="other-ans-choice-3"
                          className="ml-3 text-sm font-medium leading-6 text-gray-900"
                        >
                          "None of the Above" Options
                        </label>
                      </div>
                    </div>

                    {errors?.questions && (
                      <p className="text-red-500 text-sm mt-2">{errors?.questions}</p>
                    )}
                  </div>
                </>
              )}

              <div className="mt-10 flex justify-end">
                <button
                  className="bg-shoorah-secondary text-white px-10 py-2 rounded-full text-[16px] font-medium leading-6"
                  onClick={handleSaveSurveyQuestion}
                >
                  Save Question
                </button>
              </div>
            </div>
          </div>
        )}

        {showPage === 'share' && (
          <SurveyShare
            openTeamDropDown={() => setIsOpenShareSurveyTeamMenu((prev) => !prev)}
            surveyShareData={surveyShareData}
            isOpenShareSurveyTeamMenu={isOpenShareSurveyTeamMenu}
            userType={userType}
            setAudienceType={(value) =>
              setSurveyShareData({
                ...surveyShareData,
                audience: value
              })
            }
            errors={errors}
            surveyCategory={surveyCategory}
            handleGetSurveyCategory={(e) => handleGetSurveyCategory(e)}
            emailInput={emailInput}
            coverImg={coverImg}
            handleCoverImage={(e) => {
              setSurveyCoverImage(e.target.files[0]);
              setCoverImgUrl(URL.createObjectURL(e.target.files[0]));
            }}
            handleToggleCalendar={handleToggleCalendar}
            publishDate={publishDate}
            showCalendar={showCalendar}
            handleChangeC={(e) => handleChangeC(e)}
            handleTimeToggle={() => setIsOpenT(!isOpenT)}
            selectedTime={selectedTime}
            isOpenT={isOpenT}
            handleSelectT={(time) => handleSelectT(time)}
            handleGetEndPageData={(e) => handleGetEndPageData(e)}
            handleSendSurvey={() => handleSendSurvey()}
            addUserPopUpOpenSurvey={addUserPopUpOpenSurvey}
            handleUserPopUpOenSurvey={() => setAddUserPopUpOpenSurvey(!addUserPopUpOpenSurvey)}
            shareSurveyTeamOptions={shareSurveyTeamOptions}
            handleShareSurveyTeamOptions={(e, data) =>
              setSurveyShareData({
                ...surveyShareData,
                audience: e.target.value,
                selectedLabel: data
              })
            }
          />
        )}

        {showPage === 'preview' && (
          <div>
            <div className="sm:mt-9 mt-5 rounded-2xl border-[1.5px] border-shoorah-borderGray bg-[#FFFFFF] sm:p-6 p-4">
              <div
                className={`flex flex-row gap-3 justify-center bg-[#FFDC43] ${
                  previewSurveyLogo
                    ? 'w-20 h-20 rounded-full p-2'
                    : 'w-full max-w-[122px] h-full px-6 py-2 rounded-3xl'
                }`}
              >
                {previewSurveyLogo ? (
                  <img
                    src={previewSurveyLogo}
                    alt="logo-image"
                    className="rounded-full w-full h-full"
                  />
                ) : (
                  <label className="flex items-center gap-[10px] font-medium text-[16px] leading-6">
                    Logo
                  </label>
                )}
              </div>
              <div className="sm:grid sm:grid-cols-12 text-[16px] text-shoorah-lightBlack gap-5 mt-6 sm:w-[80%] add-survey-card">
                <div className="col-span-7">
                  <label htmlFor="title" className="font-medium text-[16px] leading-9 mb-1">
                    {surveyType === 'Survey' ? 'Survey' : 'Template'} Title
                  </label>
                  <div className="input-field">
                    <input
                      className="w-full h-10 bg-shoorah-normanGray border-[1.5px] text-[14px] border-shoorah-borderGray rounded-[8px] px-4 flex flex-col justify-center items-start relative"
                      type="text"
                      name="title"
                      readOnly
                      id="title"
                      value={surveyData?.title || ''}
                    />
                  </div>
                </div>
                <div className="col-span-5 sm:mt-0 text-[14px] text-shoorah-lightBlack mt-4">
                  <label className="font-medium text-[16px] leading-9 mb-1">
                    {surveyType === 'Survey' ? 'Survey' : 'Template'} Category
                  </label>
                  <div className="input-field">
                    <Select
                      options={surveyCategory}
                      name="category"
                      isDisabled={true}
                      value={surveyCategory.find((option) => option.value === surveyData.category)}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          height: '40px',
                          border: '1.5px solid #E7E8EA',
                          backgroundColor: '#F4F4F4',
                          borderRadius: '0.5rem',
                          color: '#0B1D2E'
                        }),
                        menu: (baseStyles) => ({
                          ...baseStyles,
                          backgroundColor: '#fff',
                          borderRadius: '0.5rem'
                        }),
                        option: (baseStyles, { isFocused, isSelected }) => ({
                          ...baseStyles,
                          backgroundColor: isSelected ? '' : isFocused ? '#EEEEEE' : '#fff',
                          color: '#0B1D2E',
                          ':hover': {
                            backgroundColor: '#EEEEEE'
                          }
                        }),
                        singleValue: (baseStyles) => ({
                          ...baseStyles,
                          color: '#0B1D2E'
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="text-[16px] text-shoorah-lightBlack mt-6 sm:w-[80%]">
                <label htmlFor="description" className="font-medium text-[16px] leading-9 mb-1">
                  {surveyType === 'Survey' ? 'Survey' : 'Template'} Description
                </label>
                <div className="input-field">
                  <textarea
                    className="w-full block rounded-lg px-4 py-2 text-[14px] bg-shoorah-normanGray border-[1.5px] border-shoorah-borderGray min-h-[100px] resize-none mb-0"
                    value={surveyData?.description || ''}
                    name="description"
                    id="description"
                    readOnly
                  ></textarea>
                </div>
              </div>
            </div>
            {surveyQuestions?.length > 0 &&
              surveyQuestions?.map((surveyQuestion, index) => (
                <div
                  key={index}
                  className="px-6 py-10 sm:px-7 sm:py-10 border border-shoorah-borderGray bg-[#FFFFFF] rounded-xl mt-5"
                >
                  <div className="flex justify-center">
                    <div className="flex w-full z-0">
                      <div className="p-4 h-10 w-[60px] text-[14px] font-medium leading-9 rounded-lg mr-4 text-shoorah-lightBlack border-[1.5px] border-shoorah-borderGray bg-shoorah-normanGray flex items-center justify-center">
                        Q{index + 1}
                      </div>
                      <div className="question-title flex flex-col">
                        <h2 className="tracking-none sm:tracking-normal h-fit w-auto font-medium leading-9 text-[18.5px] sm:text-[24px] mb-6 text-black">
                          {surveyQuestion?.questionTitle}
                        </h2>
                        {surveyQuestion.image && (
                          <img
                            src={
                              isUrl(surveyQuestion.image)
                                ? surveyQuestion.image
                                : getFilePreviewUrl(surveyQuestion.image)
                            }
                            alt="preview-image"
                            className="w-[400px] h-[200px] object-cover mb-6"
                            onError={(e) => {
                              e.target.style.display = 'none'; // Hide image if loading fails
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {surveyQuestion?.options?.filter((opt) => opt?.value !== '')?.length > 0 && (
                    <div className="w-full flex flex-col items-center">
                      <div className="flex flex-col space-y-7 mb-4 w-[90%] justify-center">
                        {surveyQuestion?.options
                          ?.filter((opt) => opt?.value !== '')
                          ?.map((opt, index) => (
                            <label className="flex items-center" key={index}>
                              <input type="radio" name="answerRadio" className="w-5 h-5" disabled />
                              <p className="ml-4 text-black"> {opt?.value}</p>
                            </label>
                          ))}
                      </div>
                    </div>
                  )}
                  {surveyQuestion?.qType === 3 && (
                    <div className="relative w-full">
                      <div className="absolute left-4 top-[14px]">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="opacity-50"
                        >
                          <g clipPath="url(#clip0_302_19223)">
                            <path
                              d="M16.4384 7.76362C16.4027 6.95987 16.3344 6.16644 16.2547 5.38522C16.0529 3.40836 14.456 1.81088 12.4801 1.60011C11.3187 1.47622 10.1303 1.37729 8.92123 1.37729C7.71212 1.37729 6.52378 1.47622 5.36234 1.60011C3.38644 1.81088 1.78957 3.40836 1.58781 5.38522C1.47002 6.53927 1.37732 7.71996 1.37732 8.9212C1.37732 10.1224 1.47002 11.3031 1.58781 12.4572C1.78957 14.4341 3.38656 16.0315 5.36247 16.2423C6.32421 16.3449 7.3044 16.4303 8.29954 16.4567"
                              stroke="#0B1D2E"
                              strokeWidth="1.428"
                              strokeLinecap="round"
                            />
                            <path
                              d="M5.20227 6.73435V6.18918C5.20227 5.54864 5.65954 4.99484 6.29462 4.91143C8.05762 4.67983 9.77632 4.67983 11.5393 4.91143C12.1744 4.99484 12.6317 5.54864 12.6317 6.18918V6.73435"
                              stroke="#0B1D2E"
                              strokeWidth="1.428"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.91711 4.73773V11.2177"
                              stroke="#0B1D2E"
                              strokeWidth="1.428"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.4455 11.1897L11.6435 15.5036L11.1227 17.9533C11.0389 18.3475 11.4498 18.7204 11.8345 18.5995L14.2724 17.8331L18.1993 13.701C18.8516 13.0147 18.7378 11.8669 17.9482 11.1686C17.1773 10.4866 16.0568 10.4961 15.4455 11.1897Z"
                              stroke="#0B1D2E"
                              strokeWidth="1.428"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_302_19223">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <textarea
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        maxLength={maxLength}
                        readOnly
                        placeholder="Enter Multiple Choice Question"
                        className="w-full h-[120px] md:w-[89%] lg:w-[80%] rounded-xl p-3 pt-[10px] pl-[40px] bg-shoorah-normanGray border-[1.5px] border-shoorah-borderGray resize-none"
                      />
                      <div className="absolute bottom-5 right-0 md:right-[80px] lg:right-[315px] text-gray-400 text-sm">
                        *{maxLength} Character limit
                      </div>
                    </div>
                  )}
                </div>
              ))}
            {/* {showPage !== 'preview' && ( */}
            <div className="w-full mb-10">
              <div className="flex flex-wrap mt-[20px] gap-2 sm:gap-5">
                <div className="col-span-12 sm:col-span-6">
                  <button
                    className="whitespace-nowrap bg-shoorah-secondary text-white px-6 py-2 rounded-full text-[16px] font-medium leading-6 flex gap-3 items-center"
                    onClick={handleSubmitSurvey}
                  >
                    Submit {surveyType === 'Survey' ? 'Survey' : 'Template'}
                    <div className="sm:hidden lg:flex">
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.44006 13.2801L10.7867 8.9334C11.3001 8.42006 11.3001 7.58006 10.7867 7.06673L6.44006 2.72006"
                          stroke="white"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
                <div className=" lg:w-[160px]  md:col-span-6">
                  <button
                    className="whitespace-nowrap p-4 w-full text-black border border-black py-[8px] rounded-full"
                    onClick={handleSaveDraft}
                  >
                    Save as draft
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {showPage === 'insights' && isCopanySuperOrSubAdmin() && <Insights />}

        {showPage === 'results' && isCopanySuperOrSubAdmin() && <>{surveyQuestionResultList && surveyQuestionResultList.length > 0 &&  surveyQuestionResultList.map((data, index) => (
          <AssessmentResultQuestion questionsList={data} index={index} key={index} />
        ) )}</>}

        {isBulkModalOpen && (
          <BulkAnswerModal
            bulkAnswerInput={bulkAnswerInput}
            handleGetBulkAnswerData={(e) => handleGetBulkAnswerData(e)}
            maxLength={maxLength}
            handleSaveBulkAnswer={(type) => handleSaveBulkAnswer(type)}
          />
        )}

        {isShowSurveyApprovalModal && (
          <SurveyApprovalModal
            adminList={superAdminList}
            handleChange={(e) => setSurveyShareData({ ...surveyShareData, approvalBy: e.value })}
            handleSendSurveyWithApproval={() => handleSendSurveyWithApproval()}
          />
        )}
        {isShowSurveySuccessModal && (
          <SurveySuccessModal isDraft={isDraft} surveyType={surveyType} />
        )}
      </div>
    </>
  );
};
export default AddEditPulseSurvey;
