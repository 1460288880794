import React, { useState } from 'react';
import DonutChartContainer from '../../component/Graph/DonutChart';
import PrimaryButton from '../../component/common/Buttons/PrimaryButton';
const AssessmentResultQuestion = ({ questionsList, index }) => {
  const [showChart, setShowChart] = useState(false);
  const color = ['#0D2535','#F05289','#20BDAD','#4A57DA','#FF6F61','#6A1B9A','#FFB74D','#4CAF50','#00BCD4','#FFC107' ];
  
  const data = questionsList.options.map(element => {    
    return {
        lable : element.value,
        value : element.count
    }
  });
  return (
    <div
      key={index}
      className="px-3 py-6 sm:px-7 sm:py-10 border mt-[25px] border-[#E7E8EA] bg-[#FFFFFF] rounded-2xl"
    >
      <div className="flex justify-center mb-4 sm:mb-[30px] border-b pb-4 sm:pb-[30px] border-[#CED2D5] sm:flex-row flex-col">
        <div className="flex w-full z-0">
          <div className="h-fit rounded-lg px-4 py-1 sm:px-6 sm:py-3 mr-3 sm:mr-[1.5%] text-black bg-gray-200">
            Q{index + 1}
          </div>
          <h2 className="sm:leading-normal tracking-none sm:tracking-normal h-fit w-full md:w-full lg:max-w-[46%] leading-9 text-lg sm:text-[24px] mb-5 sm:mb-0 flex items-center">
            {questionsList.title}
          </h2>
        </div>
        <div className='whitespace-nowrap text-md font-normal leading-9 text-shoorah-textBlack'>
        {questionsList.totalResponses ? questionsList.totalResponses : 0} Respondent
        </div>
      </div>
      <div className="w-full flex flex-col items-end">
        <div className="flex flex-col space-y-7 mb-[48px] w-full sm:w-[calc(100%-91px)] justify-center">
          {questionsList.options.map((option, optIndex) => (
            <label
              className="flex items-center justify-between sm:flex-row flex-col"
              key={optIndex}
            >
              <div className="flex items-center w-full sm:w-auto">
                <input
                  type="radio"
                  className="mr-2"
                  style={{ transform: 'scale(1.75)' }}
                  disabled
                />
                <p className="sm:ml-6 ml-4">{option.value}</p>
              </div>
              <div className="flex items-center gap-2 sm:gap-6">
                <div className="w-[90px] sm:w-[156px] flex justify-end">
                  <div className={`h-[15px]`} style={{ width: `${option.percentage}` ,backgroundColor: `${color[optIndex]}`  }}></div>
                </div>
                |<div>{option.percentage}</div>|<div>{option.count}</div>
              </div>
            </label>
          ))}
        </div>
      </div>
      {showChart && (
        <div className="w-full flex items-center justify-center">
          <DonutChartContainer color={color} data={data} />
        </div>
      )}
      <div className="w-full flex justify-end">
        {showChart ? (
          <button
            className="text-[14px] leading-9 font-semibold text-shoorah-primary ms-auto w-full text-right"
            onClick={() => setShowChart(false)}
          >
            Hide Chart
          </button>
        ) : (
          <PrimaryButton btnText="Show Chart" onClick={() => setShowChart(true)} />
        )}
      </div>
    </div>
  );
};
export default AssessmentResultQuestion;
