import React from 'react';
import Select from 'react-select';
import { Calendar } from 'react-calendar';

const generateTimes = () => {
  const times = [];
  const start = 0;
  const end = 12 * 60;
  for (let time = start; time <= end; time += 30) {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    const period = hours >= 12 ? 'pm' : 'am';
    const displayTime = `${hours % 12 || 12}:${minutes === 0 ? '00' : minutes} ${period}`;
    times.push(displayTime);
  }
  return times;
};

const SurveyShare = ({
  openTeamDropDown,
  surveyShareData,
  isOpenShareSurveyTeamMenu,
  userType,
  setAudienceType,
  errors,
  surveyCategory,
  handleGetSurveyCategory,
  emailInput,
  handleEmailChange,
  coverImg,
  handleCoverImage,
  handleToggleCalendar,
  publishDate,
  showCalendar,
  isOpen,
  handleChangeC,
  handleTimeToggle,
  selectedTime,
  isOpenT,
  handleSelectT,
  handleGetEndPageData,
  handleSendSurvey,
  addUserPopUpOpenSurvey,
  handleUserPopUpOenSurvey,
  shareSurveyTeamOptions,
  handleShareSurveyTeamOptions
}) => {
  return (
    <div>
      <div className="rounded-[16px] bg-white border-[1.5px] border-shoorah-borderGray sm:mt-9 mt-5 sm:p-6 p-4">
        <h3 className="text-[20px] leading-9 text-shoorah-lightBlack">Share Survey</h3>
        <div className="team-dropdown mt-6">
          <div className="team-dropdown-menu">
            <label className="text-[16px] leading-9 font-medium text-shoorah-lightBlack mb-1 block">
              Select Team
            </label>
            <div
              className="relative flex flex-col justify-between mx-0 items-center px-4 dropdown-menu text-[14px] border-[1.5px] border-shoorah-borderGray w-[80%] sm:w-[40%] h-10 bg-shoorah-normanGray rounded-lg cursor-pointer"
              onClick={() => openTeamDropDown()}
            >
              <div className="flex flex-row justify-between w-full h-full items-center">
                <span>
                  {typeof surveyShareData.audience === 'number'
                    ? surveyShareData.audience === 1
                      ? 'Business to Customer (B2B)'
                      : surveyShareData.audience === 2
                      ? 'Business to Customer (B2C)'
                      : 'Unknown Audience'
                    : typeof surveyShareData.audience === 'string' &&
                      surveyShareData.audience.length > 0
                    ? surveyShareData.audience
                    : 'All Team Members'}
                </span>
                <svg
                  className={`w-4 h-4 transform ${isOpenShareSurveyTeamMenu ? 'rotate-180' : ''}`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              {isOpenShareSurveyTeamMenu && (userType === 0 || userType === 1) && (
                <div className="absolute top-full z-40 right-0 w-full sm:w-64 bg-white border-[1.5px] border-shoorah-borderGray rounded-[8px] mt-2 py-3">
                  <div className="flex items-center gap-x-3 px-4 py-2 hover:bg-[#E7E8EA] cursor-pointer text-sm">
                    <input
                      id="B2C"
                      name="audience"
                      type="radio"
                      checked={surveyShareData.audience === 2}
                      className="h-4 w-4 border-black cursor-pointer"
                      value={surveyShareData.audience || 2}
                      onChange={(e) => {
                        const label = 'Business to Customer (B2C)';
                        return setAudienceType(2);
                        // setSurveyShareData({
                        //   ...surveyShareData,
                        //   audience: 2
                        // });
                      }}
                    />
                    <label
                      htmlFor="B2C"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Business to Customer (B2C)
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3 px-4 py-2 hover:bg-[#E7E8EA] cursor-pointer text-sm">
                    <input
                      id="B2B"
                      name="audience"
                      type="radio"
                      checked={surveyShareData.audience === 1}
                      className="h-4 w-4 border-black cursor-pointer"
                      value={surveyShareData.audience || 1}
                      onChange={(e) => {
                        const label = 'Business to Customer (B2B)';
                        return setAudienceType(1);
                      }}
                    />
                    <label
                      htmlFor="B2B"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Business to Customer (B2B)
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3 px-4 py-2 hover:bg-[#E7E8EA] cursor-pointer text-sm">
                    <input
                      name="audience"
                      type="radio"
                      id="all"
                      className="h-4 w-4"
                      checked={surveyShareData.audience === 3}
                      value={userType === 0 || userType === 1 ? 3 : 'all'}
                      onChange={(e) => {
                        const label =
                          userType === 0 || userType === 1
                            ? 'All Team Members'
                            : 'All Staff Members';
                        return setAudienceType(3);
                      }}
                    />
                    <label className="text-[16px] cursor-pointer" htmlFor="all">
                      All {userType === 0 || userType === 1 ? 'Team' : 'Staff'} Members
                    </label>
                  </div>
                </div>
              )}
              {isOpenShareSurveyTeamMenu && (userType === 3 || userType === 4) && (
                <div className="absolute top-full z-40 right-0 w-full sm:w-64 bg-white border-[1.5px] border-shoorah-borderGray rounded-xl mt-2 py-3">
                  {shareSurveyTeamOptions?.map((data, index) => (
                    <div
                      key={index}
                      className="flex items-center gap-x-3 px-4 py-2 hover:bg-[#E7E8EA] cursor-pointer text-sm"
                    >
                      <input
                        id={data}
                        name="audience"
                        type="radio"
                        className="h-4 w-4 border-black cursor-pointer"
                        value={data}
                        checked={surveyShareData.audience === data}
                        onChange={(e) => handleShareSurveyTeamOptions(e, data)}
                      />
                      <label
                        htmlFor={data}
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        {data}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <p className="text-red-500 text-sm">{errors.audience && errors.audience}</p>
        </div>

        {(userType === 0 || userType === 1) && (
          <div className="input-field mt-6 w-[80%] sm:w-[40%]">
            <span>Select Category</span>
            <Select
              options={surveyCategory}
              name="category"
              onChange={(e) => handleGetSurveyCategory(e)}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  height: '40px',
                  border: '1.5px solid #E7E8EA',
                  backgroundColor: '#F4F4F4',
                  borderRadius: '0.5rem',
                  color: '#0B1D2E'
                }),
                menu: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: '#F4F4F4',
                  borderRadius: '0.5rem'
                }),
                option: (baseStyles, { isFocused, isSelected }) => ({
                  ...baseStyles,
                  backgroundColor: isSelected ? '' : isFocused ? '' : '#F4F4F4',
                  color: '#0B1D2E',
                  ':hover': {
                    backgroundColor: '#EEEEEE'
                  }
                }),
                singleValue: (baseStyles) => ({
                  ...baseStyles,
                  color: '#0B1D2E'
                })
              }}
            />
          </div>
        )}
        {(userType === 3 || userType === 4) && (
          <div className="flex flex-col w-4/5 sm:w-2/5 mt-6">
            <label
              htmlFor="email"
              className="text-[16px] leading-9 font-medium text-shoorah-lightBlack mb-1"
            >
              Add Email
            </label>
            <textarea
              placeholder="Enter email address"
              id="email"
              value={emailInput}
              onChange={handleEmailChange}
              className="w-full px-4 py-2 h-[80px] text-[14px] resize-none border border-shoorah-borderGray rounded-lg bg-shoorah-normanGray"
            />
          </div>
        )}
        <div className="mt-6">
          <label className="text-[16px] leading-9 font-medium text-shoorah-lightBlack mb-1 block">
            Add cover image <span className="font-normal">(Optional)</span>
          </label>
          <div className="flex sm:justify-start justify-center">
            {coverImg ? (
              <div className="relative inline-block">
                {/* Logo Image */}
                <img
                  src={coverImg}
                  alt="cover-image"
                  className="w-[100%] h-[188px] rounded-[9px]"
                />
                <input
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => handleCoverImage(e)}
                />
                {/* Edit Icon */}
                <button
                  type="button"
                  className="absolute top-0 right-0 bg-white p-1 rounded-full shadow hover:shadow-md hover:bg-gray-100"
                  onClick={() => document.getElementById('file-upload').click()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M12 20h9" />
                    <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19.001 3 20l.999-4 12.5-12.5z" />
                  </svg>
                </button>
              </div>
            ) : (
              <div className="w-[98%] sm:w-[45.5%] max-w-[360px] h-[188px] border-[1.5px] border-indigo-300 rounded-[9px] bg-indigo-50 flex flex-col justify-center items-center relative">
                <div className="w-12 h-12 mb-4">
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 44H30C40 44 44 40 44 30V18C44 8 40 4 30 4H18C8 4 4 8 4 18V30C4 40 8 44 18 44Z"
                      fill="#B6C0F3"
                      stroke="#4A56DC"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18 20C20.2091 20 22 18.2091 22 16C22 13.7909 20.2091 12 18 12C15.7909 12 14 13.7909 14 16C14 18.2091 15.7909 20 18 20Z"
                      fill="#B6C0F3"
                      stroke="#4A56DC"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.33984 37.9L15.1998 31.28C16.7798 30.22 19.0598 30.34 20.4798 31.56L21.1398 32.14C22.6998 33.48 25.2198 33.48 26.7798 32.14L35.0998 25C36.6598 23.66 39.1798 23.66 40.7398 25L43.9998 27.8"
                      fill="#B6C0F3"
                    />
                    <path
                      d="M5.33984 37.9L15.1998 31.28C16.7798 30.22 19.0598 30.34 20.4798 31.56L21.1398 32.14C22.6998 33.48 25.2198 33.48 26.7798 32.14L35.0998 25C36.6598 23.66 39.1798 23.66 40.7398 25L43.9998 27.8"
                      stroke="#4A56DC"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="text-shoorah-lightBlack text-sm leading-[18px] font-normal text-center">
                  Drop your image here, or
                  <label
                    htmlFor="file-upload"
                    className="text-shoorah-gradient cursor-pointer ml-1"
                  >
                    browse
                  </label>
                </p>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => handleCoverImage(e)}
                />
                <p className="text-[8px] leading-3 font-normal text-[#969696] mt-2">
                  Maximum file size: 5MB
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="justify-start mt-[20px] sm:p-6 p-4 bg-white rounded-[16px] border-[1.5px] border-shoorah-borderGray">
        <h3 className="text-[20px] leading-9 text-shoorah-lightBlack">
          Publish Time and area <span className="font-light">(Optional)</span>
        </h3>
        <div className="flex flex-col sm:flex-row w-full lg:w-1/2 gap-4 mt-6">
          <div className="w-full sm:w-1/2">
            <label className="text-[16px] leading-9 font-medium text-shoorah-lightBlack mb-1 block">
              Add Date
            </label>
            <div className="w-[98%] sm:w-[90.5%] max-w-[281px] h-10 border-[1.5px] border-shoorah-borderGray rounded-lg px-4 bg-shoorah-normanGray flex flex-col justify-center items-start relative">
              <p
                className="flex flex-row justify-between items-center text-[14px] h-fit w-full bg-transparent border-none cursor-pointer text-dark"
                onClick={handleToggleCalendar}
              >
                {publishDate ? publishDate : 'Set Date'}
                <svg
                  className={`w-4 h-4 transform ${isOpen ? 'rotate-180' : ''}`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </p>

              {showCalendar && (
                <div className="absolute top-[-13px] -left-[40px] md:-left-[22%] lg:left-[150%] xl:-left-[21%] mt-1 bg-white z-50 border-[1.5px] border-shoorah-borderGray rounded-2xl px-2 transform scale-75 text-[14px]">
                  <Calendar
                    className={`calendar dark:!text-white text-sm sm:text-lg`}
                    onChange={(e) => handleChangeC(e)}
                    nextLabel={
                      <button className="custom-next-button">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 8 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.375 0.75C1.375 0.75 6.625 3.858 6.625 6C6.625 8.14125 1.375 11.25 1.375 11.25"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="dark:text-white"
                          ></path>
                        </svg>
                      </button>
                    }
                    prevLabel={
                      <button className="custom-prev-button">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 8 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="rotate-180"
                        >
                          <path
                            d="M1.375 0.75C1.375 0.75 6.625 3.858 6.625 6C6.625 8.14125 1.375 11.25 1.375 11.25"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="dark:text-white"
                          ></path>
                        </svg>
                      </button>
                    }
                    defaultValue={new Date()}
                    navigationLabel={({ date }) => (
                      <button
                        style={{
                          cursor: 'default',
                          pointerEvents: 'none'
                        }}
                      >
                        {date.toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long'
                        })}
                      </button>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="w-full sm:w-1/2">
            <label className="text-[16px] leading-9 font-medium text-shoorah-lightBlack mb-1 block">
              Add Time
            </label>
            <div className="w-[98%] sm:w-[90.5%] max-w-[281px] h-10 border-[1.5px] border-shoorah-borderGray rounded-lg px-4 bg-shoorah-normanGray flex flex-col justify-center items-start relative cursor-pointer">
              <div
                className="w-full h-full flex items-center justify-between text-[14px]"
                onClick={() => handleTimeToggle()}
              >
                <p className="flex flex-row justify-between items-center text-[14px] h-fit w-full bg-transparent border-none cursor-pointer text-dark">
                  {selectedTime ? selectedTime : 'Set Time'}
                  <svg
                    className={`w-4 h-4 transform ${isOpenT ? 'rotate-180' : ''}`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </p>
              </div>

              {isOpenT && (
                <div className="absolute top-[38px] z-20 w-full mt-2 text-[14px] sm:w-[71%] left-0 sm:left-[29%] overflow-hidden bg-white border-[1.5px] border-shoorah-borderGray rounded-xl overflow-y-auto max-h-[200px]">
                  {generateTimes().map((time) => (
                    <div
                      key={time}
                      className=" px-4 sm:px-6 text-[14px] py-2 hover:bg-[#E7E8EA] cursor-pointer"
                      onClick={() => handleSelectT(time)}
                    >
                      {time}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#FFFFFF] mt-5 rounded-[16px] border-[1.5px] border-shoorah-borderGray sm:p-6 p-4">
        <h3 className="text-[20px] leading-9 text-shoorah-lightBlack"> End Page</h3>
        <div className="mt-6">
          <label className="block text-[16px] leading-9 font-medium text-shoorah-lightBlack mb-1">
            Heading Text
          </label>
          <div className="input-field">
            <input
              className="dropdown-menu w-full sm:w-1/2 max-w-[580px] h-10 bg-shoorah-normanGray border-[1.5px] text-[14px] border-shoorah-borderGray rounded-[8px] px-4 flex flex-col justify-center items-start relative"
              placeholder="You have completed this survey."
              name="endHeading"
              value={surveyShareData.endHeading}
              onChange={(e) => handleGetEndPageData(e)}
            ></input>
            <p className="text-red-500 text-sm">{errors.endHeading && errors.endHeading}</p>
          </div>
          <label className="block text-[16px] leading-9 font-medium text-shoorah-lightBlack mb-1 mt-6">
            Body Text
          </label>
          <div className="input-field">
            <textarea
              className="block dropdown-menu w-full sm:w-1/2 max-w-[580px] bg-shoorah-normanGray border-[1.5px] text-[14px] resize-none border-shoorah-borderGray rounded-[8px] px-4 py-2 relative"
              placeholder="Thank you for taking time to answer this survey."
              name="endBody"
              value={surveyShareData.endBody}
              onChange={(e) => handleGetEndPageData(e)}
            ></textarea>
            <p className="text-red-500 text-sm">{errors.endBody && errors.endBody}</p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 sm:flex justify-end mt-4 gap-2 mb-20">
        <div className="col-span-6">
          <button
            className="whitespace-nowrap  text-[16px] text-white w-full rounded-3xl py-3 px-6 sm:min-w-[150px] h-[40px] bg-shoorah-secondary flex justify-center items-center"
            onClick={() => handleSendSurvey()}
          >
            Send Survey
          </button>
        </div>
      </div>

      {addUserPopUpOpenSurvey && (
        <div
          className="flex justify-center items-center z-50 fixed h-screen w-screen top-0 left-0 bg-black/15 px-10"
          onClick={() => handleUserPopUpOenSurvey()}
        >
          <div className=" h-fit w-[695px] rounded-[24px] px-12 py-4 bg-[#FFFFFF] sm:m-0 m-2">
            <div className="text-4xl mt-[45px] flex justify-center">
              <svg
                width="96"
                height="96"
                viewBox="0 0 96 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="48" cy="48" r="48" fill="#E5EAFA" />
                <path
                  d="M44.6667 22.5345C46.5067 20.9612 49.52 20.9612 51.3866 22.5345L55.6 26.1614C56.4 26.8548 57.8933 27.4147 58.96 27.4147H63.4933C66.32 27.4147 68.64 29.7345 68.64 32.5612V37.0947C68.64 38.1347 69.2 39.6547 69.8933 40.4547L73.52 44.668C75.0934 46.508 75.0934 49.5213 73.52 51.388L69.8933 55.6012C69.2 56.4012 68.64 57.8946 68.64 58.9612V63.4948C68.64 66.3214 66.32 68.6413 63.4933 68.6413H58.96C57.92 68.6413 56.4 69.2012 55.6 69.8945L51.3866 73.5211C49.5466 75.0945 46.5333 75.0945 44.6667 73.5211L40.4534 69.8945C39.6534 69.2012 38.16 68.6413 37.0933 68.6413H32.48C29.6533 68.6413 27.3333 66.3214 27.3333 63.4948V58.9346C27.3333 57.8946 26.7734 56.4012 26.1067 55.6012L22.5067 51.3613C20.96 49.5213 20.96 46.5346 22.5067 44.6946L26.1067 40.4547C26.7734 39.6547 27.3333 38.1614 27.3333 37.1214V32.5612C27.3333 29.7345 29.6533 27.4147 32.48 27.4147H37.0933C38.1334 27.4147 39.6534 26.8548 40.4534 26.1614L44.6667 22.5345Z"
                  fill="#B6C0F3"
                  stroke="#4A57DA"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M42.3737 55.5193L46.2403 58.506C46.747 59.0126 47.867 59.2527 48.6137 59.2527H53.3337C54.827 59.2527 56.4537 58.1328 56.827 56.6394L59.8136 47.5193C60.427 45.786 59.307 44.2661 57.4403 44.2661H52.4537C51.707 44.2661 51.0937 43.6529 51.2004 42.7729L51.8136 38.7729C52.0536 37.6529 51.307 36.3995 50.187 36.0261C49.2003 35.6528 47.947 36.1594 47.4403 36.906L42.3203 44.506"
                  stroke="#4A57DA"
                  strokeWidth="3"
                  strokeMiterlimit="10"
                />
                <path
                  d="M36 55.5182V43.305C36 41.545 36.7467 40.9316 38.5067 40.9316H39.76C41.4933 40.9316 42.2667 41.545 42.2667 43.305V55.5182C42.2667 57.2516 41.52 57.8916 39.76 57.8916H38.5067C36.7467 57.8916 36 57.2782 36 55.5182Z"
                  stroke="#4A57DA"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="text-shoorah-lightBlack text-[24px]  mt-[30px] flex justify-center">
              <div className="w-[75%] text-center">
                Congratulations on successfully creating your first survey template.
              </div>
            </div>
            <div className="flex justify-center mt-[18px]">
              <div className="relative w-[50%] text-[16px] text-shoorah-lightBlack flex justify-center text-center">
                <p className="font-thin">
                  {' '}
                  You can now manage this survey and access valuable insights
                </p>
              </div>
            </div>
            <div className="flex justify-center mt-[40px] mb-[50px] gap-6">
              <button
                className="text-[#FFFFFF] bg-shoorah-secondary text-[16px] border border-black px-10 rounded-full p-3"
                // onClick={() => {
                //   setAddUserPopUpOpen(false);
                // }}
              >
                Template
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SurveyShare;
