import { useEffect, useState } from 'react';

import { EXPERT_PROFILE_STATUS, PER_PAGE } from '../../../utils/constants';
import Table from '../../../component/common/Table';
import Pagination from '../../../component/common/Pagination/Pagination';
import PeapProfileDailog from './peapProfileDialog';

import { Api } from '../../../api';
import { errorToast, successToast } from '../../../utils/helper';
import ConfirmPopup from '../../../component/common/modals/ConfirmPopup';
import Select from '../../../component/common/Select';
import RejectExpertPopup from '../../../component/common/modals/RejectExpertPopup';
import Loader from '../../../component/common/Loader';

const actionOptions = [
  { value: EXPERT_PROFILE_STATUS[2].value, label: EXPERT_PROFILE_STATUS[2].name },
  { value: EXPERT_PROFILE_STATUS[3].value, label: EXPERT_PROFILE_STATUS[3].name },
  { value: EXPERT_PROFILE_STATUS[4].value, label: EXPERT_PROFILE_STATUS[4].name }
  // { value: 1, label: 'View' },
  // { value: 5, label: 'Join interview' }
];

function PendingSpecialist(params) {
  const [usersList, setUsersList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedPerPage, setSelectedPerPage] = useState(PER_PAGE[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [payload, setPayload] = useState({});
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [selectedExpertId, setSelectedExpertId] = useState(null);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);

  const handleNameClick = (expertId) => {
    setSelectedExpertId(expertId); // Set the selected expert ID
    setIsDialogOpen(true); // Open the dialog
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  //const functions
  const handlePagination = (pageNumber, pageSize, userId) => {
    // fetchUsers(pageNumber, pageSize, userId);
    fetchExperts(pageNumber, pageSize);
  };
  //handle dropdown actions in table
  // const handleDropdownChange = (value, rowData) => {
  //   // Handle the change event here
  //   console.log('Selected value:', value);
  //   const expertId = rowData?.id;
  //   const payload = {
  //     expertId,
  //     profileAction: value
  //   };
  //   console.log('Payload:', payload);
  //   switch (value + '') {
  //     case '3':
  //       console.log('Invite to interview');
  //       setOpenConfirmModal(true);
  //       setPayload(payload);
  //       // Api.inviteToInterview(payload).then(handleApiResponse);
  //       break;
  //     case '1':
  //       Api.inviteToInterview(payload).then(handleApiResponse);
  //       console.log('Approve');
  //       break;
  //     case '2':
  //       Api.inviteToInterview(payload).then(handleApiResponse);
  //       console.log('Decline');
  //       break;
  //     case '4':
  //       Api.inviteToInterview();
  //       console.log('Join Interview');

  //     default:
  //       break;
  //   }
  // };

  const handleDropdownChange = (value, rowData) => {
    const expertId = rowData?.id;

    setSelectedExpertId(expertId);
    const payload = {
      id: rowData.expert._id,
      profile_status: value
    };

    // Define the confirmation messages
    const confirmationMessages = {
      2: `Are you sure you want to invite this expert?`,
      3: `Are you sure you want to approve this expert?`,
      4: `Are you sure you want to decline this expert?`,
      5: `Are you sure you want to join the interview?`
    };

    switch (value + '') {
      case '3':
      case '2':
        setConfirmationMessage(confirmationMessages[value]);
        setOpenConfirmModal(true);
        setPayload(payload);
        break;
      case '4':
        setOpenRejectModal(true);
        setPayload(payload);
      default:
        break;
    }
  };

  const handleSubmitConfirmation = () => {
    setIsLoadingUpdate(true);
    Api.updatePeapExpertProfileStatus(payload)
      .then((response) => {
        setIsLoadingUpdate(false);
        if (response?.data?.meta?.code === 1) {
          successToast(response?.data?.meta?.message);
          setOpenConfirmModal(false);
          fetchExperts(currentPage, selectedPerPage.value);
        } else {
          errorToast(response?.data?.meta?.message);
        }
      })
      .catch((error) => {
        setIsLoadingUpdate(false);
        errorToast(error);
      });
  };

  const handleReject = (reason) => {
    const reqBody = { ...payload, reject_reason: reason };
    setIsLoadingUpdate(true);
    Api.updatePeapExpertProfileStatus(reqBody)
      .then((response) => {
        setIsLoadingUpdate(false);
        if (response?.data?.meta?.code === 1) {
          successToast(response?.data?.meta?.message);
          setOpenRejectModal(false);
          fetchExperts(currentPage, selectedPerPage.value);
        } else {
          errorToast(response?.data?.meta?.message);
        }
      })
      .catch((error) => {
        setIsLoadingUpdate(false);
        errorToast(error);
      });
  };

  // const handleDropdownChange = (value, expertId) => {
  //   let apiCall;
  //   switch (value) {
  //     case '1': // Invite to interview
  //       apiCall = Api.inviteToInterview(expertId);
  //       break;
  //     case '2': // Approve
  //       apiCall = Api.inviteToInterview(expertId);
  //       break;
  //     case '3': // Decline
  //       apiCall = Api.inviteToInterview(expertId);
  //       break;
  //     case '4': // Join Interview
  //       apiCall = Api.inviteToInterview(expertId);
  //       break;
  //     default:
  //       return;
  //   }
  //   apiCall.then(response => {
  //     if (response?.data?.meta?.code === 1) {
  //       console.log(`${value} action performed successfully`);
  //       fetchExperts(currentPage, selectedPerPage);
  //     } else {
  //       console.error(`Failed to perform ${value} action`);
  //     }
  //   });
  // }

  const handleApiResponse = (response) => {
    console.log('hello');

    try {
      if (response?.data?.meta?.code === 1) {
        fetchExperts(currentPage, selectedPerPage.value);
        console.log('selectedPerPage.value', selectedPerPage.value);
      } else {
        const message = response?.data?.meta?.message;
        console.log('🚀 ~ handleApiResponse ~ message:', message);
        // errorToast(message);
      }
    } catch (error) {
      errorToast(error);
    }
  };

  const columns = [
    {
      title: 'Name',
      key: 'name',
      type: 'text3',
      align: 'left',
      longText: true,
      darkText: true
    },

    {
      title: 'Email',
      key: 'email',
      type: 'text',
      align: 'left',
      style: {
        opacity: 'opacity-100',
        align: 'text-left'
      }
    },

    {
      title: 'Date Joined',
      key: 'dateJoined',
      type: 'date',
      align: 'left'
    },
    {
      title: 'Status',
      type: 'status',
      key: 'status',
      style: {
        align: 'text-center',
        textColor: 'text-black',
        opacity: 'opacity-100'
      }
    },

    {
      title: 'Actions',
      type: 'text',
      key: 'action',
      align: 'center',
      style: {
        align: 'flex justify-center'
      }
    }
  ];

  useEffect(() => {
    fetchExperts(currentPage, selectedPerPage.value);
  }, [currentPage, selectedPerPage.value]);

  const fetchExperts = (pageNumber, pageSize) => {
    setLoader(true);
    const status = EXPERT_PROFILE_STATUS.find((item) => item.tag === 'pending_list').value;
    Api.getSpecialistData(pageNumber, pageSize, '', '', status)
      .then((response) => {
        setLoader(false);
        if (response?.data?.meta?.code === 1) {
          setUsersList(response?.data?.data);
          setCurrentPage(pageNumber);
          setTotalCount(response.data.meta.totalRecords);
        } else {
          setUsersList([]);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error('API call error: ', error);
      });
  };

  const tableData = usersList.map((user) => {
    return {
      name: (
        <span
          onClick={() => handleNameClick(user.id)} // Set expert ID on click
          className="cursor-pointer text-blue-600 hover:underline"
        >
          {user.name}
        </span>
      ),
      email: user.email,
      dateJoined: user.createdAt,
      status:
        user.expert.profile_status === 1
          ? EXPERT_PROFILE_STATUS.find((x) => x.tag === 'pending').name
          : EXPERT_PROFILE_STATUS.find((x) => x.value === user.expert.profile_status).name,
      action: (
        <div className="w-[150px]">
          <Select
            options={actionOptions.filter((option) => option.value !== user.expert.profile_status)}
            onChange={(val) => handleDropdownChange(val, user)}
          />
        </div>
      )
    };
  });

  return (
    <div>
      <div>
        <div className="flex justify-center"></div>
          {loader && <Loader />}
        <div className="mt-2">
          <div className="pt-3">
            <Table
              columns={columns}
              data={tableData}
              // data={usersList}
              name={'users_table'}
              bottomBorder={totalCount > selectedPerPage?.value}
              refreshTable={() => handlePagination(currentPage, selectedPerPage?.value, '')}
              // loader={loader}
            />
            {isDialogOpen && (
              <PeapProfileDailog
                onClose={handleCloseDialog}
                open={isDialogOpen}
                expertId={selectedExpertId}
              />
            )}
          </div>
          <div></div>
        </div>
      </div>
      <div>
        {/* {usersList?.length >= 10 ? ( */}
        {totalCount > selectedPerPage?.value ? (
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={selectedPerPage?.value}
            onPageChange={(page) => handlePagination(page, selectedPerPage?.value, '')}
          />
        ) : (
          <span />
        )}
      </div>
      {openConfirmModal && (
        <ConfirmPopup
          open={openConfirmModal}
          setOpen={setOpenConfirmModal}
          message={confirmationMessage}
          setAccepted={handleSubmitConfirmation}
          handleNo={() => {
            setOpenConfirmModal(false);
          }}
        />
      )}
      <RejectExpertPopup
        open={openRejectModal}
        onClose={() => setOpenRejectModal(false)}
        onSubmit={handleReject}
        isLoading={isLoadingUpdate}
      />
    </div>
  );
}

export default PendingSpecialist;
