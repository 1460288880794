import React from 'react';
import Like from '../../../assets/images/SurveyThumbUp.svg';
import { getPathForSurvey } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';

const SurveySuccessModal = ({ isDraft, surveyType }) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center items-center z-50 fixed h-screen w-screen top-0 left-0 bg-black/15 px-10">
      <div className="h-fit w-[692px] rounded-[24px] px-24 py-16 bg-shoorah-normanGray border-[1.5px] border-shoorah-borderGray">
        <div className="flex justify-center">
          <img src={Like} alt="Like" />
        </div>
        <h1 className="text-2xl font-medium text-center mt-6">
          {surveyType === 'Survey'
            ? isDraft !== 0
              ? 'Congratulation on successfully sending you survey to your staff!'
              : 'Your draft has been saved successfully!'
            : 'Congratulation on successfully saving your Template!'}
        </h1>
        {isDraft && (
          <p className="text-base font-normal text-center mt-4">
            You can now manage this survey and access valuable insights
          </p>
        )}
        <button
          className="bg-[#4A57DA] rounded-full px-10 py-3 text-white text-lg block mx-auto mt-8"
          onClick={() => navigate(getPathForSurvey('/pulse-survey'))}
        >
          My {surveyType === 'Survey' ? 'Surveys' : 'Template'}
        </button>
      </div>
    </div>
  );
};

export default SurveySuccessModal;
