import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { useState } from 'react';
export default function Select({ options, onChange, value }) {
  const [selected, setSelected] = useState(value || '');
  const selectedValue = options.find((item) => item.value === selected);
  return (
    <div className="w-full">
      <Listbox
        value={selected}
        onChange={(val) => {
          setSelected(val);
          onChange(val);
        }}
      >
        <ListboxButton
          className={clsx(
            'relative flex justify-between text-black items-center w-full rounded-lg py-1.5 px-3 text-left text-sm/6 max-w-full truncate'
          )}
        >
          {selectedValue?.label || 'Select'}
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M7 10l5 5 5-5H7z" />
          </svg>
        </ListboxButton>
        <ListboxOptions
          anchor="bottom"
          transition
          className={clsx(
            'w-auto shadow-md border rounded-xl bg-white p-1 focus:outline-none',
            'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
          )}
        >
          {options.map((option) => (
            <ListboxOption
              key={option.value}
              value={option.value}
              className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-shoorah-secondary/15 text-shoorah-secondary"
            >
              <div className="text-sm/6 text-black">{option.label}</div>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </Listbox>
    </div>
  );
}
