import React from 'react';
import { Doughnut } from 'react-chartjs-2';
const DonutChartContainer = ({ color, data }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    }
  };
  const dataD = {
    maintainAspectRatio: false,
    responsive: false,
    labels: data.map(item => item.label),
    datasets: [
      {
        data: data.map(item => item.value),
        backgroundColor: color.slice(0, data.length),
        borderColor: color.slice(0, data.length),
        borderWidth: 1
      }
    ]
  };
  return (
    <>
      <div className="max-w-[180px] sm:max-w-[247px]">
        <Doughnut data={dataD} options={options} />
      </div>
    </>
  );
};
export default DonutChartContainer;