import { Api } from '../../api';
import { Switch } from '@headlessui/react';
import moonIcon from '../../assets/images/moon.svg';
import { Menu, Transition } from '@headlessui/react';
import Setting from '../../assets/images/setting.svg';
import logOutIcon from '../../assets/images/logout.svg';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import Notification from '../../assets/images/Notification.svg';
import Filter from '../../assets/images/filter.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { cleanLocalStorage, isSuperAdmin, useOutsideClick } from '../../utils/helper';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { AGERANGE, DATECREITERIA, GENDER } from '../../utils/constants';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { SidebarContext } from '../../context/SidebarContext';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Topbar = ({
  title,
  isFilterOpen,
  setIsFilterOpen,
  parameters,
  handleChange,
  filterData,
  setDateCalander,
  dateCalender,
  handleSelectRange,
  model,
  filterModalRef,
  wrapperRef
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState({});
  const [notificationCounts, setNotificationCounts] = useState(0);
  const { isDarkModeEnabled, setIsDarkModeEnabled } = useContext(SidebarContext);
  const userData = JSON.parse(localStorage.getItem('userData'));
  const name = userData?.name?.split(' ');

  useEffect(() => {
    if (userData?.userType === 3 || userData?.userType === 4) {
      Api.getCompanyData(userData?.companyId)
        .then((res) => {
          if (res.data?.meta?.code === 1) {
            const company = res.data?.data;
            setCompanyData({
              company_logo: company.company_logo,
              company_name: company.company_name,
              company_email: company.company_email,
              company_address: company.company_address,
              contact_person: company.contact_person,
              contact_number: company.contact_number,
              no_of_seat_bought: company.no_of_seat_bought,
              seat_price: company.seat_price,
              seat_active: company.seat_active,
              contract_progress: company.contract_progress,
              b2b_interest_via: company.b2b_interest_via,
              terms_agreed: company.terms_agreed,
              contract_sent: company.contract_sent,
              contract_signed: company.contract_signed,
              invoice_raised: company.invoice_raised,
              payment_complete: company.payment_complete,
              restrict_company: company.restrict_company,
              previousSeat: company.no_of_seat_bought,
              company_type: company.company_type,
              activeUsersCount: company.activeUsersCount,
              inactiveUsersCount: company.inactiveUsersCount
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const getUnreadNotificationCount = () => {
    Api.getUnreadNotificationCount()
      .then((res) => {
        setNotificationCounts(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      window.location.pathname !== '/login' &&
      (userData?.userType === 0 || userData?.userType === 1)
    )
      getUnreadNotificationCount();
  }, [window.location.pathname]);

  const handleLogout = async () => {
    cleanLocalStorage();
    navigate('/login');
  };

  return (
    <div
      className={`w-full flex lg:relative fixed lg:bg-shoorah-normanGray bg-white dark:bg-shoorah-darkBgColor top-0 left-0 items-center justify-between gap-x-2 lg:p-0 py-5 px-6 lg:border-none border-b-[1.5px] border-shoorah-borderGray lg:pl-0 z-[38] ${
        !location.pathname.includes('/dashboard') ? '' : 'lg:mt-[22px]'
      }`}
    >
      <h1 className="font-medium leading-9 text-shoorah-lightBlack dark:text-shoorah-darkBg text-2xl lg:block hidden">
        {title ? title : `${name}`}
      </h1>
      <div className="flex gap-x-2 items-center">
        <div className="flex lg:gap-x-4 gap-x-6 items-center">
          {(userData?.userType === 3 || userData?.userType === 4) &&
            location.pathname.includes('/dashboard') && (
              <button
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                className="w-[30px] h-[30px] lg:w-10 lg:h-10 lg:flex hidden items-center justify-center relative bg-white dark:bg-shoorah-darkBg border border-shoorah-borderGray rounded-full cursor-pointer"
              >
                <img src={Filter} alt="filter" className="w-4 h-4 lg:w-5 lg:h-5" />
              </button>
            )}
          {(userData?.userType === 3 || userData?.userType === 4) &&
            location.pathname.includes('/dashboard') && (
              <Transition
                show={isFilterOpen}
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div
                  ref={filterModalRef}
                  className="grid fixed w-full md:w-[60%] z-[999] shadow-md bg-white dark:bg-shoorah-darkBgTabColor right-1/2 translate-x-1/2 top-12 p-4 rounded-2xl gap-4 lg:grid-cols-4 sm:grid-cols-2"
                >
                  <div className="flex  items-center gap-2 justify-between">
                    <select
                      className={`border text-[#666666] dark:text-white bg-white dark:bg-shoorah-darkBgColor appearance-none dark:border-none  rounded-md ${
                        parameters.dateCriteria == '4' ? 'w-4/5' : 'w-full'
                      } h-10 px-2`}
                      value={parameters.dateCriteria}
                      onChange={handleChange}
                      id="dateCriteria"
                      name="dateCriteria"
                    >
                      <option value={''} disabled>
                        Select Date Criteria
                      </option>
                      {DATECREITERIA.map((e, i) => (
                        <option key={i} value={e.value}>
                          {e.name}
                        </option>
                      ))}
                    </select>
                    {model.dateButtonVisible && (
                      <button
                        className={`inline-flex items-center justify-center rounded-3xl border dark:bg-shoorah-darkBgColor dark:border-none text-black ${
                          parameters.dateCriteria == '4' ? 'lg:w-1/5' : ''
                        } px-2 py-1 text-xs font-medium shadow-sm hover:shoorah-primary focus:outline-none sm:w-auto`}
                        onClick={() => {
                          // setModel({
                          //   ...model,
                          //   datePickerVisible: !model.datePickerVisible
                          // });
                          setDateCalander(!dateCalender);
                        }}
                      >
                        <CalendarIcon className="w-[18px] h-[20px] dark:text-white inline" />
                      </button>
                    )}
                  </div>
                  <Transition
                    show={dateCalender}
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div
                      className="absolute lg:right-[2rem] sm:right-[2rem] z-[2] mt-2 mx-auto lg:left-0 origin-top-right rounded-md bg-white dark:bg-shoorah-darkBgTabColor  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-fit"
                      ref={wrapperRef}
                    >
                      <DateRangePicker
                        ranges={[
                          {
                            startDate: parameters?.startDate ? parameters?.startDate : new Date(),
                            endDate: parameters?.endDate ? parameters?.endDate : new Date(),
                            key: 'selection'
                          }
                        ]}
                        onChange={handleSelectRange}
                        maxDate={new Date()}
                      />
                    </div>
                  </Transition>
                  <select
                    className="border text-[#666666] dark:text-white bg-white dark:bg-shoorah-darkBgColor  dark:border-none appearance-none rounded-md h-10 px-2"
                    value={parameters.location}
                    onChange={handleChange}
                    id="location"
                    name="location"
                  >
                    <option value={''}>All Locations</option>
                    {filterData?.countries.map((e, i) => (
                      <option key={i} value={e.toLowerCase()}>
                        {e}
                      </option>
                    ))}
                  </select>
                  <select
                    className="border text-[#666666] dark:text-white dark:border-none bg-white dark:bg-shoorah-darkBgColor  appearance-none rounded-md h-10 px-2"
                    value={parameters.department}
                    onChange={handleChange}
                    id="department"
                    name="department"
                  >
                    <option value={''}>All Department</option>
                    {filterData?.departments.map((e, i) => (
                      <option key={i} value={e.toLowerCase()}>
                        {e}
                      </option>
                    ))}
                  </select>
                  <select
                    className="border text-[#666666] dark:text-white dark:border-none bg-white dark:bg-shoorah-darkBgColor  appearance-none rounded-md h-10 px-2"
                    value={parameters.dataType}
                    onChange={handleChange}
                    id="dataType"
                    name="dataType"
                  >
                    <option value={3}>All Emotions</option>
                    <option value={1}>Personal Emotions</option>
                    <option value={2}>Professional Emotions</option>
                  </select>
                  <select
                    className="border text-[#666666] dark:text-white dark:border-none bg-white dark:bg-shoorah-darkBgColor  appearance-none rounded-md h-10 px-2"
                    value={parameters.age}
                    onChange={handleChange}
                    id="age"
                    name="age"
                  >
                    <option value={''}>All Ages</option>
                    {AGERANGE.map((e, i) => (
                      <option key={i} value={e.value}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                  <select
                    className="border text-[#666666] dark:text-white dark:border-none bg-white dark:bg-shoorah-darkBgColor  appearance-none rounded-md h-10 px-2"
                    value={parameters.ethinicity}
                    onChange={handleChange}
                    id="ethinicity"
                    name="ethinicity"
                  >
                    <option value={''}>All Ethinicities</option>
                    {filterData?.ethnicities.map((e, i) => (
                      <option key={i} value={e.toLowerCase()}>
                        {e}
                      </option>
                    ))}
                  </select>
                  <select
                    className="border text-[#666666] dark:text-white dark:border-none bg-white dark:bg-shoorah-darkBgColor  appearance-none rounded-md h-10 px-2"
                    value={parameters.gender}
                    onChange={handleChange}
                    id="gender"
                    name="gender"
                  >
                    <option value={''}>All Genders</option>
                    {GENDER.map((e, i) => (
                      <option key={i} value={e.value}>
                        {e.name}
                      </option>
                    ))}
                  </select>

                  <button
                    onClick={() => setIsFilterOpen(false)}
                    className="border text-white dark:text-white dark:border-none bg-shoorah-secondary dark:bg-shoorah-darkBgColor  appearance-none rounded-md h-10 px-2"
                  >
                    Done
                  </button>
                </div>
              </Transition>
            )}
          <Link
            to={
              userData?.userType === 3 || userData?.userType === 4
                ? '/' + userData?.slug + '/notifications'
                : '/notifications'
            }
            data-tooltip-id="notification-tip"
            data-tooltip-content="Notifications"
            className="w-[30px] h-[30px] lg:w-10 lg:h-10 flex items-center justify-center relative bg-shoorah-normanGray lg:bg-white lg:border-[1.5px] border-shoorah-borderGray dark:bg-shoorah-darkBg rounded-full cursor-pointer"
          >
            <img src={Notification} alt="notification" className="w-4 h-4 lg:w-6 lg:h-6" />
            {notificationCounts > 0 && (
              <div className="absolute -right-2 shadow-2xl -top-2 rounded-[50%] text-white lg:text-[12px] text-[10px] flex justify-center align-middle text-center bg-[red] lg:h-[20px] lg:w-[20px] w-4 h-4">
                <p className="self-center">
                  {notificationCounts > 99 ? '99+' : notificationCounts}
                </p>
              </div>
            )}
          </Link>
          {!location.pathname.includes('/dashboard') && (
            <div className="lg:flex hidden justify-between items-start w-[250px] bg-white dark:bg-shoorah-darkBg border px-6 py-3 rounded-xl">
              <div className="flex gap-4 items-center w-full relative">
                {companyData?.company_logo && !companyData.company_logo.includes('null') ? (
                  <img
                    loading="lazy"
                    src={companyData.company_logo}
                    className="w-12 aspect-square object-cover rounded-full bg-blue-300"
                  />
                ) : (
                  <div className="self-center dark:bg-shoorah-darkBgColor bg-black flex justify-center items-center w-12 aspect-square rounded-full">
                    <span className="text-white font-medium">
                      {name && name[0]?.charAt(0).toUpperCase()}
                    </span>
                    <span className="text-white font-medium">
                      {name && name[1]?.charAt(0).toUpperCase()}
                    </span>
                  </div>
                )}
                <div>
                  <span className="font-bold text-base leading-5 text-shoorah-lightBlack">
                    {userData?.name}
                  </span>
                  <div className="flex leading-none text-shoorah-gray3 gap-1 text-[12px] text-left font-medium">
                    <h6 className="leading-[14px]">
                      {' '}
                      {userData?.userType === 0 || userData?.userType === 3
                        ? 'Super Admin'
                        : 'Sub Admin'}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="text-base flex justify-center gap-x-1 items-center text-shoorah-newDashboardBlue2 dark:text-shoorah-darkBg">
                <Menu className="relative" as="div">
                  <Menu.Button className="flex items-center justify-center text-sm font-medium focus:outline-none w-[18px] h-[18px] rounded-full border border-black mt-2">
                    <ChevronDownIcon className="w-[14px] text-shoorah-lightBlack" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute w-[150px] shadow-lg bottom-0 left-[2rem] -translate-x-full translate-y-full z-10 mt-2 rounded-md dark:bg-shoorah-darkBgColor bg-white ring-1 ring-shoorah-primary ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={
                                !isSuperAdmin()
                                  ? `/${userData.slug}/change-password`
                                  : '/change-password'
                              }
                              className={classNames(
                                active
                                  ? 'bg-gray-100 dark:text-shoorah-darkBg text-shoorah-sidebarBackground'
                                  : ' dark:text-shoorah-darkBg text-gray-700',
                                'block px-4 py-2 whitespace-nowrap text-sm hover:bg-inherit'
                              )}
                            >
                              Change Password
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          )}
          <div className="flex lg:hidden">
            <div
              onClick={() =>
                navigate(!isSuperAdmin() ? `/${userData.slug}/change-password` : '/change-password')
              }
              className="flex gap-4 items-center w-full relative"
            >
              {companyData?.company_logo && !companyData.company_logo.includes('null') ? (
                <img
                  loading="lazy"
                  src={companyData.company_logo}
                  className="w-12 aspect-square object-cover rounded-full bg-blue-300"
                />
              ) : (
                <div className="self-center dark:bg-shoorah-gradient bg-black flex justify-center items-center w-12 aspect-square rounded-full">
                  <span className="text-white">{name && name[0]?.charAt(0)}</span>
                  <span className="text-white">{name && name[1]?.charAt(0)}</span>
                </div>
              )}
              <div>
                <span className="font-bold text-[14px] dark:text-white">{userData?.name}</span>
                <h6 className="text-[12px] lg:text-sm dark:text-white">
                  {' '}
                  {userData?.userType === 3 || userData?.userType === 0
                    ? 'Super Admin'
                    : 'Sub Admin'}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex lg:hidden gap-x-2">
        <Menu className="relative" as="div">
          <Menu.Button className="w-[30px] h-[30px] lg:w-10 lg:h-10 flex items-center justify-center relative bg-shoorah-normanGray dark:bg-shoorah-darkBg rounded-full cursor-pointer">
            <img src={Setting} alt="setting" className="w-4 h-4 lg:w-6 lg:h-6" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Menu.Items className="absolute shadow-lg -bottom-2 left-[3rem] -translate-x-full translate-y-full z-[100] rounded-md dark:bg-shoorah-darkBgColor bg-white ring-1 ring-shoorah-primary ring-opacity-5 focus:outline-none">
              <div className="py-5 px-3 dark:bg-shoorah-darkBgTabColor rounded-md">
                <Menu.Item>
                  {({ active }) => (
                    <div className="group block w-full flex-shrink-0 cursor-pointer mb-4">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <img
                            src={moonIcon}
                            alt="sidebar_icon"
                            className={`mr-3 lg:h-6 lg:w-6 w-4 h-4 flex-shrink-0`}
                          />
                          <p className="text-[12px] text-shoorah-lightBlack dark:text-shoorah-darkBg/80 font-medium">
                            Darkmode
                          </p>
                        </div>
                        <Switch
                          checked={isDarkModeEnabled}
                          onChange={() => {
                            setIsDarkModeEnabled(!isDarkModeEnabled);
                          }}
                          className={classNames(
                            isDarkModeEnabled ? '' : '',
                            'ml-[45px] relative bg-shoorah-lightBlack dark:bg-shoorah-darkBg border-shoorah-lightBlack dark:border-shoorah-darkBg border-2 inline-flex h-4 w-7 flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none'
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              isDarkModeEnabled ? 'translate-x-3' : 'translate-x-0',
                              'pointer-events-none inline-block h-3 w-3 transform rounded-full dark:bg-shoorah-lightBlack bg-white shadow ring-0 transition duration-200 ease-in-out'
                            )}
                          />
                        </Switch>
                      </div>
                    </div>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <div className="group block w-full flex-shrink-0 cursor-pointer mb-4">
                      <div
                        className="flex items-center"
                        onClick={() => {
                          handleLogout();
                        }}
                      >
                        <img
                          src={logOutIcon}
                          alt="sidebar_icon"
                          className={`mr-3 lg:h-6 lg:w-6 w-4 h-4 flex-shrink-0`}
                        />
                        <p className="text-[12px] text-shoorah-lightBlack dark:text-shoorah-darkBg/80 font-medium">
                          Logout
                        </p>
                      </div>
                    </div>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={
                        !isSuperAdmin() ? `/${userData.slug}/change-password` : '/change-password'
                      }
                      className="text-[12px] text-shoorah-lightBlack dark:text-shoorah-darkBg/80 font-medium group block w-full flex-shrink-0 cursor-pointer"
                    >
                      Change Password
                    </Link>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default Topbar;
