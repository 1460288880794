import React from 'react';
import FileIcon from '../../../assets/images/FileTimesIcon.svg';

const SpecialistAttachments = ({ data }) => {
  const cvDocs = data.expertAttachments.filter((item) => item.docType === 'cv');
  const insuranceDocs = data.expertAttachments.filter((item) => item.docType === 'insurance');
  const certificationDocs = data.expertAttachments.filter(
    (item) => item.docType === 'certification'
  );
  return (
    <div className="border-2 relative  w-full border-gray-300 rounded-3xl px-6 lg:px-10 pt-3 lg:pt-6 pb-6 lg:pb-12 bg-white dark:bg-shoorah-darkBgTabColor dark:border-none dark:text-white ">
      <div className="text-black dark:text-white h-full font-medium w-full flex flex-col gap-[1rem]">
        <div className="flex flex-col gap-[1rem] ">
          <h4 className="text-lg ">Attachments</h4>
          <p className="text-sm font-medium text-shoorah-gray2">
            Efficiently manage & approve specialist attachments with just a few clicks. Dont't
            forget to review and take action on the attachment.
          </p>
        </div>

        <div className="w-full mt-4 flex gap-5 rounded-2xl border-2 border-gray-300 py-2 ">
          <div className="w-full flex flex-col">
            <p className="text-sm ml-3 text-shoorah-gray2">C.V. Document</p>

            {cvDocs.map((item) => {
              return (
                <div key={item.id} className="w-full flex  ">
                  <div className="flex  pl-3 w-[29px] justify-center  items-center ">
                    <img src={FileIcon} alt="File Icon" />
                  </div>
                  <a
                    href={item.fileUrl}
                    download={item.fileName}
                    target="_blank"
                    className="block pr-3 ml-3 placeholder:text-blue-950 text-gray-1000 w-full  h-fit appearance-none outline-none text-sm lg:text-base"
                  >
                    {item.fileName}
                  </a>
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-full flex gap-5 rounded-2xl border-2 border-gray-300 py-2 ">
          <div className="w-full flex flex-col">
            <p className="text-sm ml-3 text-shoorah-gray2">
              Insurance Documents ( no minimum selection )
            </p>

            {insuranceDocs.map((item) => {
              return (
                <div key={item.id} className="w-full flex  ">
                  <div className="flex shrink-0 pl-3 w-[29px] justify-center items-center ">
                    <img src={FileIcon} alt="File Icon" />
                  </div>
                  <a
                    href={item.fileUrl}
                    download={item.fileName}
                    target="_blank"
                    className=" pr-3 ml-3 block placeholder:text-blue-950 text-gray-1000 w-full  h-fit appearance-none outline-none text-sm lg:text-base"
                  >
                    {item.fileName}
                  </a>
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-full flex gap-5 rounded-2xl border-2 border-gray-300 py-2 ">
          <div className="w-full flex flex-col">
            <p className="text-sm ml-3 text-shoorah-gray2">
              Certification Documents ( no minimum selection )
            </p>

            {certificationDocs.map((item) => {
              return (
                <div key={item.id} className="w-full flex  ">
                  <div className="flex  pl-3 w-[29px] justify-center items-center ">
                    <img src={FileIcon} alt="File Icon" />
                  </div>
                  <a
                    href={item.fileUrl}
                    download={item.fileName}
                    target="_blank"
                    className="block pr-3 ml-3 placeholder:text-blue-950 text-gray-1000 w-full  h-fit appearance-none outline-none text-sm lg:text-base"
                  >
                    {item.fileName}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialistAttachments;
