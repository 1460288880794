export const SurveyAssessmentUpdateDialog = ({ onButtonClick, title, buttonTitle }) => {
  return (
    <div className="flex justify-center items-center z-50 fixed h-screen w-screen top-0 left-0 bg-black/15 px-10">
      <div className=" h-fit w-[692px] rounded-[24px] px-24 py-16 bg-shoorah-normanGray border-[1.5px] border-shoorah-borderGray">
        <svg
          width={96}
          height={96}
          viewBox="0 0 96 96"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto"
        >
          <circle cx={48} cy={48} r={48} fill="#E5EAFA" />
          <path
            d="M44.6667 22.5341C46.5067 20.9608 49.52 20.9608 51.3866 22.5341L55.6 26.1611C56.4 26.8544 57.8933 27.4143 58.96 27.4143H63.4933C66.32 27.4143 68.64 29.7342 68.64 32.5608V37.0943C68.64 38.1343 69.2 39.6544 69.8933 40.4544L73.52 44.6676C75.0934 46.5076 75.0934 49.521 73.52 51.3876L69.8933 55.6009C69.2 56.4009 68.64 57.8942 68.64 58.9609V63.4944C68.64 66.3211 66.32 68.6409 63.4933 68.6409H58.96C57.92 68.6409 56.4 69.2008 55.6 69.8941L51.3866 73.5208C49.5466 75.0941 46.5333 75.0941 44.6667 73.5208L40.4534 69.8941C39.6534 69.2008 38.16 68.6409 37.0933 68.6409H32.48C29.6533 68.6409 27.3333 66.3211 27.3333 63.4944V58.9342C27.3333 57.8942 26.7734 56.4009 26.1067 55.6009L22.5067 51.3609C20.96 49.5209 20.96 46.5343 22.5067 44.6943L26.1067 40.4544C26.7734 39.6544 27.3333 38.161 27.3333 37.121V32.5608C27.3333 29.7342 29.6533 27.4143 32.48 27.4143H37.0933C38.1334 27.4143 39.6534 26.8544 40.4534 26.1611L44.6667 22.5341Z"
            fill="#B6C0F3"
            stroke="#4A57DA"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M42.3737 55.5196L46.2403 58.5062C46.747 59.0129 47.867 59.253 48.6137 59.253H53.3337C54.827 59.253 56.4537 58.133 56.827 56.6397L59.8136 47.5196C60.427 45.7862 59.307 44.2663 57.4403 44.2663H52.4537C51.707 44.2663 51.0937 43.6531 51.2004 42.7731L51.8136 38.7731C52.0536 37.6531 51.307 36.3997 50.187 36.0264C49.2003 35.6531 47.947 36.1596 47.4403 36.9063L42.3203 44.5062"
            stroke="#4A57DA"
            strokeWidth={3}
            strokeMiterlimit={10}
          />
          <path
            d="M36 55.5185V43.3053C36 41.5453 36.7467 40.9319 38.5067 40.9319H39.76C41.4933 40.9319 42.2667 41.5453 42.2667 43.3053V55.5185C42.2667 57.2518 41.52 57.8918 39.76 57.8918H38.5067C36.7467 57.8918 36 57.2785 36 55.5185Z"
            stroke="#4A57DA"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <h1 className="text-2xl font-medium text-center mt-6">
          {title}
        </h1>
        <button
          className="bg-[#4A57DA] rounded-full px-10 py-3 text-white text-lg block mx-auto mt-8"
          onClick={onButtonClick}
        >
          {buttonTitle}
        </button>
      </div>
    </div>
  );
};
