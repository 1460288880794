import { Textarea } from '@headlessui/react';
import React, { useEffect, useRef, useState } from 'react';

function PulseSurvey() {
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedOption, setSelectedOption] = useState('Multiple Choice');

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  const [addUserPopUpOpen, setAddUserPopUpOpen] = useState(false);
  const [text, setText] = useState('');
  const maxLength = 250;
  const [selectedOptiona, setSelectedOptionb] = useState('');

  const [isOpenA, setIsOpenB] = useState(false); // State for dropdown visibility

  const [selectedOption, setSelectedOption] = useState('Multiple Choice');

  const toggleDropdown = () => setIsOpen(!isOpen);

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  return (
    <div className=" gird font-serif m-5 gap-10 ">
      <div className="grid grid-cols-12 justify-around items-center mb-[35px] ">
        <div className="col-span-6 grid gap-5 sm:flex  sm:gap-16 ">
          <div className="text-2xl">
            <button>Edit</button>
          </div>
          <div className="text-2xl opacity-50">
            <button>Share</button>
          </div>
        </div>
        <div className="col-span-6 flex justify-end text-[1rem]">
          <button className="border border-black rounded-[30px] px-4 py-2 sm:px-10 sm:py-[8px] truncate">
            Preview Assesment
          </button>
        </div>
      </div>

      <div className="flex flex-col bg-white rounded-2xl  border px-2 sm:pl-[25px]  mt-[41px] mb-[22px] border-gray-300">
        <h1 className="mt-[40px] mb-[15px] text-[16px] font-medium ">Assesment Title</h1>
        <div className="grid grid-cols-12 w-[68.5%] h-[40px]  mb-[36px]">
          <input
            className="col-span-12  bg-[#E7E8EA] rounded-xl pl-[15px] border-2 border-gray-300"
            placeholder=" Enter title: e.g Social Anxiety Assessment"
          ></input>
        </div>
        <h1 className=" mb-[15px] text-[16px] font-medium ">Assesment Description</h1>
        <div className="grid grid-cols-12 w-[68.5%] h-[100px]  mb-[36px]">
          <textarea
            className="col-span-12 bg-[#E7E8EA] border-2 h-[100px] border-gray-300 rounded-xl  pl-[18px] pb-[30px] pt-[10px] mb-[35px]  relative resize-none"
            placeholder="Thank you for taking time to answer this survey."
          ></textarea>
        </div>
      </div>

      <div className="bg-white  rounded-2xl w-full p-5">
        <div className=" grid grid-cols-12 gap-5 mt-[35px]">
          <div className="col-span-6 flex flex-col ">
            <h1>About Assesment</h1>
            <textarea
              className="dropdown-menu w-full  bg-[#E7E8EA] border-2 resize-none border-gray-300 rounded-xl pl-[18px] pb-[30px] pt-[10px] mb-[35px]  relative"
              placeholder="Enter some copy covering what this assessment is  about:"
            ></textarea>
          </div>
          <div className="col-span-6 flex flex-col ">
            <h1>Who is this for?</h1>
            <textarea
              className="dropdown-menu w-full  bg-[#E7E8EA] border-2 resize-none border-gray-300 rounded-xl pl-[18px] pb-[30px] pt-[10px] mb-[35px]  relative"
              placeholder="Enter some details of who may take this assessment:"
            ></textarea>
          </div>
        </div>

        <div className="">
          <h1>Is it accurate ?</h1>
          <textarea
            className="dropdown-menu w-full sm:w-1/2 max-w-[580px] bg-[#E7E8EA] border-2  resize-none border-gray-300 rounded-xl pl-[18px] pb-[30px] pt-[10px] mb-[15px]  relative"
            placeholder="Enter some copy covering the accuracy of this assessment:"
          ></textarea>
        </div>
      </div>
      <div className="mt-[25px] bg-white rounded-xl   p-[1px] ">
        <h1 className="mt-[20px] mb-[15px] text-[16px] font-medium  ml-6 ">Reviewed By</h1>

        <div className="flex flex-col sm:flex-row sm:ml-[25px]  align-middle m-[30px]">
          <div className="flex justify-center items-center rounded-3xl border border-gray-300 w-[80px] h-[80px]">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.85 45.5H31.15C41.4 45.5 45.5 41.4 45.5 31.15V18.85C45.5 8.6 41.4 4.5 31.15 4.5H18.85C8.6 4.5 4.5 8.6 4.5 18.85V31.15C4.5 41.4 8.6 45.5 18.85 45.5Z"
                stroke="#0B1D2E"
                strokeWidth="3.075"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.85 20.9002C21.1144 20.9002 22.95 19.0646 22.95 16.8002C22.95 14.5358 21.1144 12.7002 18.85 12.7002C16.5856 12.7002 14.75 14.5358 14.75 16.8002C14.75 19.0646 16.5856 20.9002 18.85 20.9002Z"
                stroke="#0B1D2E"
                strokeWidth="3.075"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.87366 39.2468L15.9802 32.4613C17.5997 31.3748 19.9367 31.4978 21.3922 32.7483L22.0687 33.3428C23.6677 34.7163 26.2507 34.7163 27.8497 33.3428L36.3777 26.0243C37.9767 24.6508 40.5597 24.6508 42.1587 26.0243L45.5002 28.8943"
                stroke="#0B1D2E"
                strokeWidth="3.075"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <div className=" w-full xl:w-[42%]  flex flex-col sm:flex-row  gap-[25px] sm:mt-[0px] mt-[20px]  ml-[0px] sm:ml-[25px]">
            <div className="w-full sm:w-1/2 gap-8">
              <div className="flex flex-col">
                <h1 className="text-[16px] mb-[16px]">Medical Professional</h1>
                <lable className="dropdown-toggle bg-[#E7E8EA] text-[14px] max-w-[280px] h-[40px] flex items-center justify-start pl-5 w-full border-2 border-gray-300 rounded-xl   relative">
                  Dr.Marc Bouji
                </lable>
              </div>
            </div>
            <div className=" w-full sm:w-1/2 gap-8">
              <div className="flex flex-col">
                <h1 className="text-[16px] mb-[16px]">Medical Professional</h1>
                <lable className="dropdown-toggle text-[14px] bg-[#E7E8EA] max-w-[280px] h-[40px] flex items-center justify-start pl-5 w-full border-2 border-gray-300 rounded-xl   relative">
                  Dr.Marc Bouji
                </lable>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[30px] rounded-3xl border-2 border-gray-300  px-6 py-8 bg-white">
        <div className="block w-full h-full">
          <h1 className="text-2xl">Add Question</h1>
          <div className="sm:grid sm:grid-cols-12 gap-4  mt-[20px] sm:w-[80%] w-full">
            <input
              className=" col-span-1 sm:col-span-8 w-full  mb-10 sm:mb-0 rounded-xl px-5 py-3  bg-[#E7E8EA] border-2 border-gray-300"
              type="text"
              placeholder="Enter your question"
            />
            <div className="col-span-1 sm:col-span-1  grid justify-center items-center  gap-3 border-2  border-gray-300  rounded-2xl bg-gray-300">
              <button>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="#0B1D2E"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"
                    stroke="#0B1D2E"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.66998 18.9501L7.59998 15.6401C8.38998 15.1101 9.52998 15.1701 10.24 15.7801L10.57 16.0701C11.35 16.7401 12.61 16.7401 13.39 16.0701L17.55 12.5001C18.33 11.8301 19.59 11.8301 20.37 12.5001L22 13.9001"
                    stroke="#0B1D2E"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className="relative inline-block w-64">
              <div
                className="dropdown-toggle border border-gray-300 flex justify-between items-center p-4 bg-gray-100 rounded-xl cursor-pointer "
                onClick={toggleDropdown}
              >
                <span>{selectedOption}</span>
                <svg
                  className={`transform transition-transform ${
                    isOpen ? 'rotate-180' : 'rotate-0'
                  } w-5 h-5`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 10.6l3.71-3.37a.75.75 0 111.06 1.06l-4 3.75a.75.75 0 01-1.06 0l-4-3.75a.75.75 0 01.02-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>

              {isOpen && (
                <div className="dropdown-menu overflow-hidden absolute w-full mt-1 border border-gray-300 bg-gray-200 rounded-xl  z-10">
                  <div
                    className="dropdown-item p-2 hover:bg-gray-300 cursor-pointer"
                    onClick={() => selectOption('Single Choice')}
                  >
                    Single Choice
                  </div>
                  <div
                    className="dropdown-item p-2 hover:bg-gray-300 cursor-pointer"
                    onClick={() => selectOption('Multiple Choice')}
                  >
                    Multiple Choice
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <h1 className=" text-xl mt-[45px] mb-[10px]">Answer Choice</h1>

        <div className="grid grid-cols-12 gap-6 sm:w-[80%] w-full mb-[20px]">
          <div className=" col-span-12 sm:col-span-9 mb-3 sm:mb-0 rounded-xl px-5 py-3 bg-[#E7E8EA] border-2 border-gray-300">
            <input
              className=" bg-[#E7E8EA] border-none w-full outline-none rounded-xl px-4 "
              type="text"
              placeholder="Enter your answer choice"
            />
          </div>
          <div className="grid grid-cols-12 sm:grid-cols-4 col-span-3 gap-5 sm:gap-4 sm:justify-items-end">
            <div className=" col-span-6 sm:col-span-1 justify-center items-center flex text-gray-400">
              <button className="  flex justify-center items-center">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="#A1A1A1"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 12H16"
                    stroke="#A1A1A1"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 16V8"
                    stroke="#A1A1A1"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className=" col-span-6 sm:col-span-1 justify-center items-center flex text-gray-400">
              <button className="  flex justify-center items-center">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.92 22C17.42 22 21.92 17.5 21.92 12C21.92 6.5 17.42 2 11.92 2C6.41998 2 1.91998 6.5 1.91998 12C1.91998 17.5 6.41998 22 11.92 22Z"
                    stroke="#A1A1A1"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.91998 12H15.92"
                    stroke="#A1A1A1"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6 sm:w-[80%] w-full mb-[20px]">
          <div className=" col-span-12 sm:col-span-9 mb-3 sm:mb-0 rounded-xl px-5 py-3 bg-[#E7E8EA] border-2 border-gray-300">
            <input
              className=" bg-[#E7E8EA] border-none w-full outline-none rounded-xl px-4 truncate "
              type="text"
              placeholder="Enter your answer choice"
            />
          </div>
          <div className="grid grid-cols-12 sm:grid-cols-4 col-span-3 gap-5 sm:gap-4 sm:justify-items-end">
            <div className=" col-span-6 sm:col-span-1 justify-center items-center flex text-gray-400">
              <button className="  flex justify-center items-center">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="#A1A1A1"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 12H16"
                    stroke="#A1A1A1"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 16V8"
                    stroke="#A1A1A1"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className=" col-span-6 sm:col-span-1 justify-center items-center flex text-gray-400">
              <button className="  flex justify-center items-center">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.92 22C17.42 22 21.92 17.5 21.92 12C21.92 6.5 17.42 2 11.92 2C6.41998 2 1.91998 6.5 1.91998 12C1.91998 17.5 6.41998 22 11.92 22Z"
                    stroke="#A1A1A1"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.91998 12H15.92"
                    stroke="#A1A1A1"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6 sm:w-[80%] w-full mb-[20px]">
          <div className=" col-span-12 sm:col-span-9 mb-3 sm:mb-0 rounded-xl px-5 py-3 bg-[#E7E8EA] border-2 border-gray-300">
            <input
              className=" bg-[#E7E8EA] border-none w-full outline-none rounded-xl px-4 "
              type="text"
              placeholder="Enter your answer choice"
            />
          </div>
          <div className="grid grid-cols-12 sm:grid-cols-4 col-span-3 gap-5 sm:gap-4 sm:justify-items-end">
            <div className=" col-span-6 sm:col-span-1 justify-center items-center flex text-gray-400">
              <button className="  flex justify-center items-center">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="#A1A1A1"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 12H16"
                    stroke="#A1A1A1"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 16V8"
                    stroke="#A1A1A1"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className=" col-span-6 sm:col-span-1 justify-center items-center flex text-gray-400">
              <button className="  flex justify-center items-center">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.92 22C17.42 22 21.92 17.5 21.92 12C21.92 6.5 17.42 2 11.92 2C6.41998 2 1.91998 6.5 1.91998 12C1.91998 17.5 6.41998 22 11.92 22Z"
                    stroke="#A1A1A1"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.91998 12H15.92"
                    stroke="#A1A1A1"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className=" flex  sm:justify-start  rounded-xl px-0 py-3 justify-start ">
          <button
            className=" flex gap-5 justify-center items-center rounded-[25px] border border-black px-11 py-2"
            onClick={() => {
              setAddUserPopUpOpen(true);
            }}
          >
            <svg
              width="21"
              height="20"
              viewBox="0 0 18 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.50004 14.6666C12.1667 14.6666 15.1667 11.6666 15.1667 7.99998C15.1667 4.33331 12.1667 1.33331 8.50004 1.33331C4.83337 1.33331 1.83337 4.33331 1.83337 7.99998C1.83337 11.6666 4.83337 14.6666 8.50004 14.6666Z"
                stroke="#0B1D2E"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.83337 8H11.1667"
                stroke="#0B1D2E"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.5 10.6666V5.33331"
                stroke="#0B1D2E"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <p>Bulk Answers</p>
          </button>
        </div>
        <h1 className=" text-xl mt-[20px]">Answers Choices</h1>

        <div className="grid grid-cols-12 w-full sm:w-[70%] lg:w-[70%] gap-4 sm:gap-6 lg:gap-10 mt-5 sm:mt-[20px]">
          <div className="col-span-12 sm:col-span-4 px-3 py-4  border border-gray-300 rounded-[25px]  items-center">
            <div className="flex items-center">
              <div className="p-[8px] border border-gray-300 rounded-full"></div>
              <p className="ml-5 truncate">Add "Other" Answers choice</p>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-4 px-3 py-4  border border-gray-300 rounded-[25px]  items-center">
            <div className="flex items-center">
              <div className="p-[8px] border border-gray-300 rounded-full"></div>
              <p className="ml-5 truncate">"Skipable" Question</p>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-4 px-3 py-4  border border-gray-300 rounded-[25px]  items-center">
            <div className="flex items-center">
              <div className="p-[8px] border border-gray-300 rounded-full"></div>
              <p className="ml-5 truncate">"None of the Above" Options</p>
            </div>
          </div>
        </div>
        <div className=" mt-[20px] flex justify-end">
          <button className="bg-shoorah-secondary text-white px-10 py-3 rounded-full p-5">
            Save Question
          </button>
        </div>
      </div>
      {isOpen && (
        <div
          className="dropdown-menu w-64 z-10 bg-white overflow-hidden absolute mt-1 border-2 py-4 border-gray-300 rounded-xl"
          style={{
            top: '23px',
            zIndex: 274,
            left: '-189px'
          }}
        >
          <div
            className="dropdown-item p-2 hover:bg-gray-300 cursor-pointer"
            onClick={() => selectOption('Single Choice')}
          >
            Edit
          </div>
          <div
            className="dropdown-item p-2 hover:bg-gray-300 cursor-pointer"
            onClick={() => selectOption('Multiple Choice')}
          >
            Duplicate
          </div>
          <div className="border-b border-gray-400 w-full"></div>
          <div
            className="dropdown-item p-2 hover:bg-gray-300 cursor-pointer text-red-700"
            onClick={() => selectOption('Multiple Choice')}
          >
            Delete
          </div>
        </div>
      )}
      {addUserPopUpOpen && (
        <div className="flex justify-center items-center z-50 fixed h-screen w-screen top-0 left-0 bg-black/15  px-10">
          <div className=" h-fit w-[702px] rounded-3xl px-16 py-4 bg-gray-100">
            <div className="text-4xl mt-[20px]">Add Answers in Bulk</div>
            <div className="text-gray-400 mt-[20px]">
              Enter each answer choice on a seperate line
            </div>
            <div className="flex justify-center mt-[30px]">
              <div className="relative w-full">
                <textarea
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  maxLength={maxLength}
                  placeholder="Enter reason here..."
                  className="w-full h-[240px] p-4 border rounded-xl text-[#0B1D2E] border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-200"
                />
                <div className="absolute bottom-2 right-4  py-5 px-6 text-gray-400 text-sm">
                  *{maxLength} Character limit
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-[40px] mb-[10px] gap-6">
              <button
                className="text-black border border-black px-10  rounded-full p-3"
                onClick={() => {
                  setAddUserPopUpOpen(false);
                }}
              >
                Cancel
              </button>
              <button className=" border bg-shoorah-primary text-white border-shoorah-primary px-14 py-2 rounded-full p-5">
                Save
              </button>
              <button className=" "></button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PulseSurvey;
