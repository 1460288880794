/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { Calendar } from 'react-calendar';
import { errorToast, successToast } from '../../utils/helper';
import {
  AddSelfAssessmentValidation,
  DraftSelfAssessmentValidation,
  EditQuestionValidation,
  EditSelfAssessmentValidation
} from '../../validation/AddSelfAssessmentValidation';
import Topbar from '../../container/PulseSurvey/Topbar';
import axios from 'axios';
import { Api } from '../../api';
import {
  SELF_ASSESSMENT_ATTACHMENT_WEIGHTS,
  SELF_ASSESSMENT_STATUS,
  SELF_ASSESSMENT_TYPE
} from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import ValueCards from '../../component/common/cards/valueCard';
import editIcon from '../../assets/images/edit-icon.svg';
import { SurveyAssessmentUpdateDialog } from '../../component/common/dialogs/surveyAssessmentUpdateDialog';
import AssessmentResultQuestion from './AssessmentResultQuestion';
import Loader from '../../component/common/Loader';
import SelfAssesmentResult from './SelfAssesmentResult';

const AddEditSelfAssessment = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState();
  const [isOpenT, setIsOpenT] = useState(false);
  const [selectedTime, setSelectedTime] = useState('');
  const maxLength = 250;
  const [isShowAssessmentApprovalModal, setIsShowAssessmentApprovalModal] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [tempQuestion, setTempQuestion] = useState({
    title: '',
    options: [{ value: '', weight: '' }]
  });
  const [assessmentType, setAssessmentType] = useState(0);
  const [contentApproval, setContentApproval] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  //search query params
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  //type 1 for percentage and 0 for Attachment
  useEffect(() => {
    const type = parseInt(searchParams.get('type')) ?? 0;
    if (type == 0 || type == 1) {
      setAssessmentType(
        type == 0 ? SELF_ASSESSMENT_TYPE.PERCENTAGE : SELF_ASSESSMENT_TYPE.ATTACHMENT
      );
    }
    const id = searchParams.get('id') ?? '';
    if (id !== '') {
      fetchAssessmentInfo(id);
    }
    const tab = parseInt(searchParams.get('view')) ?? 0;
    if (tab == 4) {
      setCurrentTab(4);
    }
    const content = searchParams.get('contentApproval') ?? 0;
    if (content == 1) {
      setContentApproval(1);
    }
  }, []);

  const convertWeightToString = (questions) => {
    return questions.map((question) => ({
      ...question,
      options: question.options.map((option) => ({
        ...option,
        weight: option.weight.toString()
      }))
    }));
  };
  const mapWeightsToLabels = (questions) => {
    const weightLabels = Object.entries(SELF_ASSESSMENT_ATTACHMENT_WEIGHTS).reduce(
      (acc, [key, value]) => {
        acc[value] = key;
        return acc;
      },
      {}
    );

    return questions.map((question) => ({
      ...question,
      options: question.options.map((option) => ({
        ...option,
        weight: weightLabels[option.weight] // Replace the integer weight with the corresponding label
      }))
    }));
  };
  const fetchAssessmentInfo = (id) => {
    Api.getAssessment(null, null, null, null, id).then((response) => {
      if (response?.data?.meta?.code === 1) {
        const assessmentData = response?.data?.data;
        setAssessmentType(response?.data?.data.type);

        if (assessmentData != null) {
          if (assessmentType === 0) {
            const questionsWithStringWeights = convertWeightToString(assessmentData?.questions);
            setQuestionsList(questionsWithStringWeights);
            setResultMessage(
              assessmentData?.result_message
                ? assessmentData?.result_message
                : { 0: '', 1: '', 2: '', 3: '' }
            );
          }
          if (assessmentType === 1) {
            const questionsWithMappedWeights = mapWeightsToLabels(assessmentData?.questions);

            setQuestionsList(questionsWithMappedWeights);
          }

          setAssessmentData({
            title: assessmentData?.title,
            description: assessmentData?.description,
            aboutAssessment: assessmentData?.aboutAssessment,
            whoIsThisFor: assessmentData?.whoIsThisFor,
            isItAccurate: assessmentData?.isItAccurate
          });
          medicalExpertData.filter((data) => data?.name === assessmentData?.medicalExpert);
          //old data
          setReviewedByData({
            medicalExpert: assessmentData?.medicalExpertId,
            publishedBy: assessmentData?.publishedBy
          });
          setReviewByMedicalCategory({
            medicalProfessionalCategory: assessmentData?.medicalExpert,
            publishedByCategory: assessmentData?.publishedByCategory
          });
          setShareAssessmentData({
            endHeading: assessmentData?.endHeading,
            endBody: assessmentData?.endBody,
            approvalBy: assessmentData?.approvalBy
          });
          setCoverImagePreview(assessmentData?.coverImage);
          setStartDate(assessmentData?.publishDateTime);
        } else {
          errorToast('Unable to load the data');
        }
      }
    });
  };
  const handleCurrentTab = (tab) => {
    setCurrentTab(tab);
  };
  const isPreview = searchParams.get('preview');

  // ------------------------- Parthiv
  const isExistAssessmentData = JSON.parse(localStorage.getItem('assessmentData'));

  //state to handle selected tab preview 1, share2 or edit 0,
  const [currentTab, setCurrentTab] = useState(0);

  const [errors, setErrors] = useState({});
  const [assessmentData, setAssessmentData] = useState({
    title: '',
    description: '',
    aboutAssessment: '',
    whoIsThisFor: '',
    isItAccurate: '',
    type: assessmentType === 0 ? 0 : 1
  });
  const [reviewedByData, setReviewedByData] = useState({
    medicalExpert: '',
    publishedBy: ''
  });
  const [medicalExpertData, setMedicalExpertData] = useState([]);
  const [reviewByDropdown, setReviewByDropdown] = useState({
    medicalProfessionalMenu: false,
    publishedByMenu: false
  });
  const [reviewByCategory, setReviewByMedicalCategory] = useState({
    medicalProfessionalCategory: '',
    publishedByCategory: ''
  });

  const [dropdownIndex, setDropdownIndex] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isShowPreviewData, setIsShowPreviewData] = useState();
  const [bulkAnswerInput, setBulkAnswerInput] = useState('');
  const [reviewedByImage, setReviewedByImage] = useState('');
  const [isShowBulkAnswerModal, setIsShowBulkAnswerModal] = useState(false);
  const [shareAssessmentData, setShareAssessmentData] = useState({
    endHeading: '',
    endBody: '',
    approvalBy: ''
  });
  const [resultMessage, setResultMessage] = useState({ 0: '', 1: '', 2: '', 3: '' });
  const [coverImage, setCoverImage] = useState('');
  const [coverImagePreview, setCoverImagePreview] = useState('');
  const [isShowApprovalModal, setIsShowApprovalModal] = useState(false);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);
  const [superAdminList, setSuperAdminList] = useState([]);
  const [dropDownIndex, setDropDownIndex] = useState();
  const [isOpenAnswerChoiceMenu, setIsOpenAnswerChoiceMenu] = useState(false);
  // isDraft 1 for save as draft and 0 as publisht
  const [isDraft, setIsDraft] = useState(0);
  const [bulkAnswers, setBulkAnswers] = useState([]);
  const fileInputRef = useRef(null);

  const tileContent = ({ date, view }) => {
    if (view === 'month' && isDateMarked(date)) {
      return <span className="mark" />;
    }
    return null;
  };

  useEffect(() => {
    setAssessmentData({
      ...assessmentData,
      type: assessmentType
    });
  }, [assessmentType]);

  const tileClassName = ({ date }) => {
    return isDateMarked(date) ? 'marked-tile' : null;
  };

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const markedDates = [new Date('2024-05-05'), new Date('2024-05-10'), new Date('2024-05-15')];

  const isDateMarked = (date) => {
    return markedDates.some((markedDate) => isSameDay(date, markedDate));
  };

  const handleToggleCalendar = () => {
    setShowCalendar((prevShowCalendar) => !prevShowCalendar);
  };

  const handleChangeC = (e) => {
    const date = new Date(e);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    setStartDate(formattedDate);
    setShowCalendar(false);
  };

  const generateTimes = () => {
    const times = [];
    const start = 0;
    const end = 12 * 60;
    for (let time = start; time <= end; time += 30) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      const period = hours >= 12 ? 'pm' : 'am';
      const displayTime = `${hours % 12 || 12}:${minutes === 0 ? '00' : minutes} ${period}`;
      times.push(displayTime);
    }
    return times;
  };

  const handleSelectT = (time) => {
    setSelectedTime(time);
    setIsOpenT(false);
  };

  const handleGetAssessmentData = ({ target: { name, value } }) => {
    setAssessmentData({ ...assessmentData, [name]: value });
  };

  const handlePreviewCoverImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setErrorMessage('File size must be less than 5 MB.');
        return;
      }
      setErrorMessage('');
      const reader = new FileReader();
      reader.onloadend = () => {
        setCoverImagePreview(reader.result);
      };
      setCoverImage(file);
      reader.readAsDataURL(file);
    }
  };
  //add Answer Option
  const addAnswer = (type) => {
    setTempQuestion({
      ...tempQuestion,
      options: [...tempQuestion.options, { value: '', weight: '' }]
    });
  };
  //remove Answer Option
  const removeAnswer = (index) => {
    if (tempQuestion.options.length > 1) {
      setTempQuestion({
        ...tempQuestion,
        options: tempQuestion.options.filter((_, i) => i !== index)
      });
    }
  };

  const handleAnswerchange = (index, value) => {
    // Clone the tempQuestion object
    const updatedQuestion = { ...tempQuestion };

    // Update the specific option value at the given index
    updatedQuestion.options[index].value = value;

    // Set the updated question back to the state
    setTempQuestion(updatedQuestion);
    console.log('tempQuestion', tempQuestion);
  };
  const handleQuestionUpdate = (value) => {
    const updatedQuestion = { ...tempQuestion };
    updatedQuestion.title = value;
    setTempQuestion(updatedQuestion);
  };

  const selectOption = (textValue, index) => {
    tempQuestion.options[index].weight = textValue;

    setTempQuestion(tempQuestion);
    setIsOpenAnswerChoiceMenu(false);
  };

  const convertToISO = (dateTimeString) => {
    const formattedDateTimeString = dateTimeString.replace('AM', ' AM').replace('PM', ' PM');
    const date = new Date(formattedDateTimeString);
    const isoDate = date.toISOString();
    return isoDate;
  };

  const handleGetBulkAnswerData = (event) => {
    const inputValue = event.target.value;
    setBulkAnswerInput(inputValue);
    const answersArray = inputValue.split('\n');
    setBulkAnswers(answersArray);
  };

  const handleBulkAnswer = (type) => {
    if (type === 'save') {
      const bulkAnswer = bulkAnswers.map((answer) => ({ value: answer, weight: '' }));
      if (bulkAnswer.length > 0) {
        setTempQuestion((prevTempQuestion) => ({
          ...prevTempQuestion,
          options: [...prevTempQuestion.options, ...bulkAnswer]
        }));
      }
      setIsShowBulkAnswerModal(false);
      setBulkAnswerInput('');
      setBulkAnswers([]);
      setErrors('');
    } else {
      setBulkAnswerInput('');
      setIsShowBulkAnswerModal(false);
      setBulkAnswers([]);
      setErrors('');
    }
  };

  // Save Question in Local Storage
  const handleSaveQuestion = () => {
    const { isValid, errors } = EditQuestionValidation(tempQuestion);
    if (!isValid) {
      setErrors(errors);
    } else {
      setQuestionsList((prevQuestionsList) => {
        const updatedQuestionsList = [...prevQuestionsList, tempQuestion];

        return updatedQuestionsList;
      });

      setTempQuestion({ title: '', options: [{ value: '', weight: '' }] });
      setErrors('');
    }
  };

  const handleSubmitAssessment = () => {
    try {
      // Validate the assessment data
      const { isValid, errors } = AddSelfAssessmentValidation(
        {
          ...assessmentData,
          medicalExpert: reviewedByData.medicalExpert ? reviewedByData.medicalExpert : '',
          publishedBy: reviewedByData.publishedBy,
          resultMessage: resultMessage
        },
        assessmentType
      );
      // If validation fails, set errors and return
      if (!isValid) {
        setErrors(errors);
        return;
      }

      // Ensure there is at least one question in the list
      if (questionsList.length === 0) {
        errorToast('Please add at least one question');
        return;
      }

      console.log(assessmentType);
      console.log(questionsList);

      // If assessment type is 1 and there are questions, process the weights
      if (assessmentType === 1 && questionsList.length > 0) {
        questionsList.forEach((firstQuestion) => {
          const hasWeightOneOrSecure = firstQuestion.options.some(
            (option) => option.weight.length > 1
          );
          if (hasWeightOneOrSecure) {
            questionsList.forEach((question) => {
              question.options.forEach((option) => {
                option.weight = SELF_ASSESSMENT_ATTACHMENT_WEIGHTS[option.weight].toString();
              });
            });
          }
        });
      }

      // Set the assessment object with the necessary data
      let newObj = {
        ...assessmentData,
        ...(reviewedByData.medicalExpert && { medicalExpert: reviewedByData.medicalExpert }),
        ...(reviewedByData.publishedBy && { publishedBy: reviewedByData.publishedBy }),
        ...(assessmentType === 0 && resultMessage && { result_message: resultMessage }),
        ...(questionsList.length > 0 && { questions: questionsList })
      };

      // Clear any existing errors
      setErrors('');

      // Check if the assessment is being updated or created
      if (searchParams.get('id') ?? '' !== '') {
        delete newObj.type
        newObj = {
          ...newObj,
          id: searchParams.get('id')
        };
        // Update the existing assessment
        updateAssessmentHandle(newObj, 0);
      } else {
        // Create a new assessment
        createAssessmentHandle(newObj, 0);
      }
    } catch (error) {
      // Handle any errors that occur during submission
      errorToast('Something went wrong');
    }
  };

  const handleDraft = () => {
    // Validate the draft assessment data
    const { isValid, errors } = DraftSelfAssessmentValidation({
      title: assessmentData.title,
      description: assessmentData.description
    });
    // If validation fails, set errors and return
    if (!isValid) {
      setErrors(errors);
      return;
    }

    // If assessment type is 1 and there are questions, process the weights
    if (assessmentType === 1 && questionsList.length > 0) {
      questionsList.forEach((firstQuestion) => {
        const hasWeightOneOrSecure = firstQuestion.options.some(
          (option) => option.weight.length > 1
        );
        if (hasWeightOneOrSecure) {
          questionsList.forEach((question) => {
            question.options.forEach((option) => {
              option.weight = SELF_ASSESSMENT_ATTACHMENT_WEIGHTS[option.weight].toString();
            });
          });
        }
      });
    }

    // Set the assessment object with the necessary data
    let newObj = {
      ...assessmentData,
      ...(reviewedByData.medicalExpert && { medicalExpert: reviewedByData.medicalExpert }),
      ...(reviewedByData.publishedBy && { publishedBy: reviewedByData.publishedBy }),
      ...(resultMessage && { result_message: resultMessage }),
      ...(questionsList.length > 0 && { questions: questionsList })
    };
    // Clear any existing errors
    setErrors('');

    // Prepare the new assessment object with status set to draft (0)
    newObj = {
      ...newObj,
      status: SELF_ASSESSMENT_STATUS.DRAFT
    };

    // Check if the draft is being updated or created
    if (searchParams.get('id') ?? '' !== '') {
      delete newObj.type
      newObj = {
        ...newObj,
        id: searchParams.get('id')
      };
      // Update the existing draft assessment
      updateAssessmentHandle(newObj, 1);
    } else {
      // Create a new draft assessment
      createAssessmentHandle(newObj, 1);
    }
  };

  const updateAssessmentHandle = async (newObj, isDraft) => {
    const { userType } = JSON.parse(localStorage.getItem('userData'));
    if (userType === 0 || userType === 1) {
      Api.editAssessment(newObj).then((response) => {
        console.log('🚀 ~ Api.editAssessment ~ response:', response, isDraft);
        if (response?.data?.meta?.code === 1) {
          //store assessment id in local storage
          if (response?.data?.data?.id) {
            localStorage.setItem('assessmentId', response?.data?.data?.id);
          } else {
            localStorage.setItem('assessmentId', searchParams.get('id'));
          }
          setAssessmentData({
            title: '',
            description: '',
            aboutAssessment: '',
            isItAccurate: '',
            whoIsThisFor: ''
          });
          setReviewByMedicalCategory({
            medicalProfessionalCategory: '',
            publishedByCategory: ''
          });
          setReviewedByData({ medicalExpert: '', publishedBy: '' });
          setReviewedByImage('');
          setErrors('');
          successToast(response?.data?.meta?.message);

          if (isDraft === 1) {
            navigate('/self-assessment/view-all-assessment');
          }
          if (isDraft === 0) {
            setCurrentTab(2);
          }
        }
        if (response?.data?.meta?.code === 0) {
          errorToast(response?.data?.meta?.message);
        }
      });
    } else {
      errorToast('You are not authorized to create the assessment');
    }
  };
  const createAssessmentHandle = async (newObj, isDraft) => {
    const { userType } = JSON.parse(localStorage.getItem('userData'));
    if (userType === 0 || userType === 1) {
      Api.createAssessment(newObj).then((response) => {
        console.log('🚀 ~ Api.createAssessment ~ response:', response);
        if (response?.data?.meta?.code === 1) {
          //store assessment id in local storage
          localStorage.setItem('assessmentId', response?.data?.data?.id);
          setAssessmentData({
            title: '',
            description: '',
            aboutAssessment: '',
            isItAccurate: '',
            whoIsThisFor: ''
          });
          setReviewByMedicalCategory({
            medicalProfessionalCategory: '',
            publishedByCategory: ''
          });
          setReviewedByData({ medicalExpert: '', publishedBy: '' });
          setReviewedByImage('');
          setErrors('');
          successToast(response?.data?.meta?.message);

          if (isDraft === 1) {
            setIsShowAssessmentApprovalModal(true);
          }
          if (isDraft === 0) {
            setCurrentTab(2);
          }
        }
      });
    } else {
      errorToast('You are not authorized to create the assessment');
    }
  };
  const handleApproval = () => {
    const id = searchParams.get('id') ?? '';
    if (id != '') {
      Api.approveAssessment({ status: 1, id: id }).then((response) => {
        if (response?.data?.meta?.code === 1) {
          successToast(response?.data?.meta?.message);
          navigate('/self-assessment/view-all-assessment');
        }
      });
    }
  };
  const handleReject = () => {
    const id = searchParams.get('id') ?? '';
    if (id != '') {
      Api.approveAssessment({ status: 2, id: id, comment: 'Assessment rejected' }).then(
        (response) => {
          if (response?.data?.meta?.code === 1) {
            successToast(response?.data?.meta?.message);
            navigate('/self-assessment/view-all-assessment');
          }
        }
      );
    }
  };

  // Share page
  const uploadImage = async (imageFile, uploadURL, fileName) => {
    try {
      const response = await axios.put(uploadURL, imageFile, {
        headers: {
          'Content-Type': imageFile.type
        }
      });
      if (response.status === 200) {
        return response;
      } else {
        throw new Error('Upload failed with status: ' + response);
      }
    } catch (error) {
      return error;
    }
  };

  const handleSendAssessment = () => {
    setIsLoading(true);
    const { userType } = JSON.parse(localStorage.getItem('userData'));
    let newObj = {
      id: localStorage.getItem('assessmentId'),
      ...shareAssessmentData,
      ...(coverImage && { coverImage: coverImage.type }),
      publishDateTime: startDate && selectedTime ? convertToISO(`${startDate} ${selectedTime}`) : ''
    };

    const { errors, isValid } = EditSelfAssessmentValidation({
      endHeading: shareAssessmentData.endHeading,
      endBody: shareAssessmentData.endBody
    });
    if (!isValid) {
      setErrors(errors);
      return;
    }
    if (userType === 0) {
      Api.editAssessment(newObj).then(async (response) => {
        if (response?.data?.meta?.code === 1) {
          const meta = response?.data?.meta;
          if (meta?.coverImage && meta?.coverImage?.uploadURL) {
            const uploadImageResponse = await uploadImage(
              coverImage,
              meta?.coverImage?.uploadURL,
              meta?.coverImage?.filename
            );
            if (uploadImageResponse?.status === 200) {
              successToast('Image uploaded successfully');
            }
          }
          setIsLoading(false);
          setShareAssessmentData({ endHeading: '', endBody: '' });
          setCoverImage('');
          setCoverImagePreview('');
          setSelectedTime('');
          setStartDate('');
          localStorage.removeItem('assessmentData');
          localStorage.removeItem('assessmentId');
          localStorage.removeItem('assessmentType');
          setIsShowSuccessModal(true);
        }
      });
    } else if (userType === 1) {
      Api.getAdminList().then(async (response) => {
        if (response?.data?.meta?.code === 1) {
          setSuperAdminList(response?.data?.data);
          setIsShowApprovalModal(true);
          setIsLoading(false);
        }
      });
    } else {
      errorToast('You are not authorized to share the assessment');
      setIsLoading(false);
    }
  };

  const superAdminListOptions = superAdminList.map((admin) => {
    return {
      value: admin?.id,
      label: admin?.name?.charAt(0)?.toUpperCase() + admin?.name?.slice(1)
    };
  });

  const handleSendSurveyWithApproval = () => {
    const newObj = {
      id: localStorage.getItem('assessmentId'),
      ...shareAssessmentData,
      ...(coverImage && { coverImage: coverImage.type }),
      publishDateTime: startDate && selectedTime ? convertToISO(`${startDate} ${selectedTime}`) : ''
    };
    const { errors, isValid } = EditSelfAssessmentValidation({
      endHeading: shareAssessmentData.endHeading,
      endBody: shareAssessmentData.endBody
    });
    if (!isValid) {
      setErrors(errors);
    } else {
      setIsShowApprovalModal(false);
      Api.editAssessment(newObj).then(async (response) => {
        if (response?.data?.meta?.code === 1) {
          const meta = response?.data?.meta;
          if (meta?.coverImage && meta?.coverImage?.uploadURL) {
            const uploadImageResponse = await uploadImage(
              coverImage,
              meta?.coverImage?.uploadURL,
              meta?.coverImage?.filename
            );
            if (uploadImageResponse?.status === 200) {
              successToast('Image uploaded successfully');
            }
          }
          setShareAssessmentData({ endHeading: '', endBody: '' });
          setCoverImage('');
          setCoverImagePreview('');
          setSelectedTime('');
          setStartDate('');
          localStorage.removeItem('assessmentData');
          localStorage.removeItem('assessmentId');
          localStorage.removeItem('assessmentType');
          setIsShowSuccessModal(true);
        }
      });
    }
  };

  const getMedicalExpertsListData = () => {
    Api.getAllProfessionals().then((response) => {
      if (response?.data?.meta?.code === 1) {
        setMedicalExpertData(response?.data?.data);
      }
    });
  };

  // Delete / Edit Question
  const handleQuestionAction = (type, questionIndex, data) => {
    if (type === 'delete') {
      const updatedQuestionsList = questionsList.filter((_, index) => index !== questionIndex);
      setQuestionsList(updatedQuestionsList);
    }

    if (type === 'edit') {
      const tempData = questionsList[questionIndex];
      setTempQuestion(tempData);
      const updatedQuestionsList = questionsList.filter((_, index) => index !== questionIndex);
      setQuestionsList(updatedQuestionsList);
    }
    if (type === 'duplicate') {
      const tempData = questionsList[questionIndex];
      setTempQuestion(tempData);
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsShowPreviewData(isExistAssessmentData);
    setQuestionsList([]);
  }, []);

  useEffect(() => {
    if (currentTab === 0) {
      if (localStorage.getItem('assessmentId')) {
        localStorage.removeItem('assessmentData');
        setIsShowPreviewData();
      }
      localStorage.removeItem('assessmentId');
      localStorage.removeItem('assessmentType');
    }
  }, [currentTab]);

  useEffect(() => {
    getMedicalExpertsListData();
    localStorage.removeItem('assessmentData');
    localStorage.removeItem('assessmentType');
    setIsShowPreviewData();
  }, []);

  const showCard =
    assessmentData?.aboutAssessment ||
    isShowPreviewData?.aboutAssessment ||
    assessmentData?.whoIsThisFor ||
    isShowPreviewData?.whoIsThisFor ||
    assessmentData?.isItAccurate ||
    isShowPreviewData?.isItAccurate;
  const showReviewedBySection =
    reviewedByImage ||
    reviewByCategory?.medicalProfessionalCategory ||
    isShowPreviewData?.medicalProfessionalCategory ||
    reviewByCategory?.publishedByCategory ||
    isShowPreviewData?.publishedByCategory;

  // preview assessment and Edit assessment
  const ActionButton = ({ onClick, children }) => (
    <div className="col-span-6 flex justify-end text-[1rem]">
      <button
        className="border border-black rounded-[30px] px-4 py-2 sm:px-10 sm:py-[8px] truncate text-[14px]"
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );

  const handleClearCoverImage = () => {
    setCoverImage('');
    setCoverImagePreview('');
    setErrorMessage('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className="gird font-serif mx-5 mb-9 gap-10">
      <Topbar title="Self Assessment" />
      {/* Edit, Share and preview */}
      <div className="grid grid-cols-12 justify-around items-center  my-6 max-w-full">
        <div className="col-span-6 grid gap-5 sm:flex sm:gap-10">
          {(currentTab === 0 || currentTab === 1 || currentTab === 2) && (
            <div
              className={`text-[20px] ${
                currentTab === 0 || currentTab === 1 ? 'opacity-100' : 'opacity-50'
              }`}
            >
              <p>Edit</p>
            </div>
          )}

          {(currentTab === 4 || currentTab === 3) && (
            <div
              className={`text-[20px] ${currentTab === 4 ? 'opacity-100' : 'opacity-50'}`}
              onClick={() => setCurrentTab(4)}
            >
              <p>View</p>
            </div>
          )}
          {(currentTab === 3 || (currentTab === 4 && contentApproval === 0)) && (
            <div
              className={`text-[20px] ${currentTab === 3 ? 'opacity-100' : 'opacity-50'}`}
              onClick={() => handleCurrentTab(3)}
            >
              <p>Result</p>
            </div>
          )}
          {(currentTab === 0 || currentTab === 1 || currentTab === 2) && (
            <div className={`text-[20px] ${currentTab === 2 ? 'opacity-100' : 'opacity-50'}`}>
              <p>Share</p>
            </div>
          )}
        </div>

        {currentTab === 0 && <ActionButton onClick={() => setCurrentTab(1)}>Preview</ActionButton>}
        {currentTab === 1 && <ActionButton onClick={() => setCurrentTab(0)}>Edit</ActionButton>}
      </div>
      {/* Title and discription card*/}
      {(currentTab === 0 || currentTab === 1 || currentTab === 4) && (
        <div className="flex flex-col bg-white rounded-2xl border px-5 sm:pl-[25px] mt-2  border-[#E7E8EA]">
          <h1 className="mt-4 mb-2 text-[16px] font-medium">Assessment Title</h1>
          <div className="grid relative grid-cols-12 w-full sm:w-[68.5%] h-[40px] mb-[36px]">
            <input
              className="col-span-12 bg-[#F4F4F4] rounded-lg pl-[15px] border-1 border-[#E7E8EA] truncate py-1.5"
              placeholder="Enter title: e.g Social Anxiety Assessment"
              name="title"
              value={assessmentData.title}
              onChange={handleGetAssessmentData}
              readOnly={currentTab !== 0}
            />
            {currentTab === 1 && (
              <div className="col-span-12">
                <p className="text-red-500 text-sm">{errors.title && errors.title}</p>
              </div>
            )}
          </div>
          <h1 className="mb-2 text-[16px] font-medium">Assessment Description</h1>
          <div className="relative grid grid-cols-12 w-full sm:w-[68.5%] h-[100px] mb-6">
            <textarea
              className="col-span-12 bg-[#F4F4F4] border-2 h-[100px] border-[#E7E8EA] rounded-lg pl-[18px] pb-[30px] pt-[10px] relative resize-none"
              placeholder="Thank you for taking time to answer this Assessment."
              name="description"
              value={assessmentData.description}
              onChange={handleGetAssessmentData}
              readOnly={currentTab !== 0}
            />
            {currentTab === 1 && (
              <div className="col-span-12">
                <p className="text-red-500 text-sm">{errors.description && errors.description}</p>
              </div>
            )}
          </div>
        </div>
      )}
      {/* about assessment, who is this for and is it accurate */}
      {(currentTab === 0 || currentTab === 1 || currentTab === 4) && (
        <div className="bg-white rounded-2xl w-full p-5 mt-5">
          <div className="flex flex-col sm:grid sm:grid-cols-12 gap-5 mt-4 mb-2">
            <div className="col-span-6 flex flex-col">
              <div className="input-field ">
                <h1 className="mb-2">About Assessment</h1>
                <textarea
                  className="dropdown-menu w-full text-[14px] bg-[#F4F4F4] border-2 resize-none border-[#E7E8EA] rounded-lg pl-[18px] pb-[30px] pt-[10px] relative"
                  placeholder="Enter some copy covering what this assessment is about:"
                  name="aboutAssessment"
                  value={assessmentData.aboutAssessment}
                  onChange={handleGetAssessmentData}
                  readOnly={currentTab !== 0}
                ></textarea>
                {currentTab === 1 && (
                  <p className="text-red-500 text-sm">
                    {errors.aboutAssessment && errors.aboutAssessment}
                  </p>
                )}
              </div>
            </div>
            <div className="col-span-6 flex flex-col">
              <div className="input-field">
                <h1 className="mb-2">Who is this for?</h1>
                <textarea
                  className="dropdown-menu w-full text-[14px] bg-[#F4F4F4] border-2 resize-none border-[#E7E8EA] rounded-lg pl-[18px] pb-[30px] pt-[10px] relative"
                  placeholder="Enter some details of who may take this assessment:"
                  name="whoIsThisFor"
                  value={assessmentData.whoIsThisFor}
                  onChange={handleGetAssessmentData}
                  readOnly={currentTab !== 0}
                ></textarea>
                {currentTab === 1 && (
                  <p className="text-red-500 text-sm">
                    {errors.aboutAssessment && errors.aboutAssessment}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="input-field ">
            <h1 className="mb-2">Is it accurate ?</h1>
            <textarea
              className="dropdown-menu w-full sm:w-1/2 max-w-[580px] bg-[#F4F4F4] border-2 resize-none border-[#E7E8EA] rounded-lg pl-[18px] pb-[30px] pt-[10px] relative"
              placeholder="Enter some copy covering the accuracy of this assessment:"
              name="isItAccurate"
              value={assessmentData.isItAccurate}
              onChange={handleGetAssessmentData}
              readOnly={currentTab !== 0}
            ></textarea>
            {currentTab === 1 && (
              <p className="text-red-500 text-sm">
                {' '}
                {errors.aboutAssessment && errors.aboutAssessment}{' '}
              </p>
            )}
          </div>
        </div>
      )}
      {/* Result messages section if percentage */}
      {(currentTab === 0 || currentTab === 1 || currentTab === 4) && assessmentType === 0 && (
        <div className="bg-white rounded-2xl w-full p-5 mt-4">
          <div className="flex flex-col sm:grid sm:grid-cols-12 gap-5 mt-4">
            <div className="col-span-6 flex flex-col">
              <div className="input-field mb-[35px]">
                <h1 className="mb-2">Result Message for 0-25%</h1>
                <textarea
                  className="dropdown-menu w-full text-[14px] bg-[#F4F4F4] border-2 resize-none border-[#E7E8EA] rounded-lg pl-[18px] pb-[30px] pt-[10px] relative"
                  placeholder="Add your message here"
                  name="0"
                  value={resultMessage[0]}
                  onChange={(e) => setResultMessage({ ...resultMessage, 0: e.target.value })}
                  readOnly={currentTab !== 0}
                ></textarea>
                {currentTab === 1 && (
                  <p className="text-red-500 text-sm">
                    {errors.message0To25 && errors.message0To25}
                  </p>
                )}
              </div>
            </div>
            <div className="col-span-6 flex flex-col">
              <div className="input-field mb-[35px]">
                <h1 className="mb-2">Result Message for 26-50%</h1>
                <textarea
                  className="dropdown-menu w-full text-[14px] bg-[#F4F4F4] border-2 resize-none border-[#E7E8EA] rounded-lg pl-[18px] pb-[30px] pt-[10px] relative"
                  placeholder="Add your message here"
                  name="1"
                  value={resultMessage[1]}
                  onChange={(e) => setResultMessage({ ...resultMessage, 1: e.target.value })}
                  readOnly={currentTab !== 0}
                ></textarea>
                {currentTab === 1 && (
                  <p className="text-red-500 text-sm">
                    {errors.message26To50 && errors.message26To50}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:grid sm:grid-cols-12 gap-5 mt-4">
            <div className="col-span-6 flex flex-col">
              <div className="input-field mb-[35px]">
                <h1 className="mb-2">Result Message for 51-75%</h1>
                <textarea
                  className="dropdown-menu w-full text-[14px] bg-[#F4F4F4] border-2 resize-none border-[#E7E8EA] rounded-lg pl-[18px] pb-[30px] pt-[10px] relative"
                  placeholder="Add your message here"
                  name="2"
                  value={resultMessage[2]}
                  onChange={(e) => setResultMessage({ ...resultMessage, 2: e.target.value })}
                  readOnly={currentTab !== 0}
                ></textarea>
                {currentTab === 1 && (
                  <p className="text-red-500 text-sm">
                    {errors.message51To75 && errors.message51To75}
                  </p>
                )}
              </div>
            </div>
            <div className="col-span-6 flex flex-col">
              <div className="input-field mb-[35px]">
                <h1 className="mb-2">Result Message for 76-100%</h1>
                <textarea
                  className="dropdown-menu w-full text-[14px] bg-[#F4F4F4] border-2 resize-none border-[#E7E8EA] rounded-lg pl-[18px] pb-[30px] pt-[10px] relative"
                  placeholder="Add your message here"
                  name="3"
                  value={resultMessage[3]}
                  readOnly={currentTab !== 0}
                  onChange={(e) => setResultMessage({ ...resultMessage, 3: e.target.value })}
                ></textarea>
                {currentTab === 1 && (
                  <p className="text-red-500 text-sm">
                    {errors.message76To100 && errors.message76To100}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Reviewed by section */}
      {(currentTab === 0 || currentTab === 1 || currentTab === 4) && (
        <div className="mt-[25px] bg-white rounded-lg p-[1px]">
          <h1 className="mt-[20px] mb-[15px] text-[16px] font-medium ml-6">Reviewed By</h1>
          <div className="flex flex-col sm:flex-row sm:ml-[25px] align-middle m-[30px]">
            <div className="flex flex-col items-center justify-center">
              <div className="flex justify-center items-center rounded-3xl border border-[#E7E8EA] w-[80px] h-[80px] relative">
                {reviewedByImage ? (
                  <img
                    src={reviewedByImage ? reviewedByImage : 'Review Image'}
                    alt="user-image"
                    className="rounded-3xl w-full h-full object-cover"
                  />
                ) : (
                  <label htmlFor="reviewImage" className="cursor-pointer">
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.85 45.5H31.15C41.4 45.5 45.5 41.4 45.5 31.15V18.85C45.5 8.6 41.4 4.5 31.15 4.5H18.85C8.6 4.5 4.5 8.6 4.5 18.85V31.15C4.5 41.4 8.6 45.5 18.85 45.5Z"
                        stroke="#0B1D2E"
                        strokeWidth="3.075"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.85 20.9002C21.1144 20.9002 22.95 19.0646 22.95 16.8002C22.95 14.5358 21.1144 12.7002 18.85 12.7002C16.5856 12.7002 14.75 14.5358 14.75 16.8002C14.75 19.0646 16.5856 20.9002 18.85 20.9002Z"
                        stroke="#0B1D2E"
                        strokeWidth="3.075"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.87366 39.2468L15.9802 32.4613C17.5997 31.3748 19.9367 31.4978 21.3922 32.7483L22.0687 33.3428C23.6677 34.7163 26.2507 34.7163 27.8497 33.3428L36.3777 26.0243C37.9767 24.6508 40.5597 24.6508 42.1587 26.0243L45.5002 28.8943"
                        stroke="#0B1D2E"
                        strokeWidth="3.075"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </label>
                )}
              </div>
            </div>
            <div className="w-full xl:w-[42%] flex flex-col sm:flex-row gap-[25px] sm:mt-[0px] mt-[20px] ml-[0px] sm:ml-[25px]">
              <div className="w-full sm:w-1/2 gap-8 mr-12">
                <div className="flex flex-col">
                  <h1 className="text-[16px] mb-[16px]">Medical Professional</h1>
                  <div
                    className="relative flex flex-col justify-between items-center px-7 border border-[#E7E8EA] h-[40px] bg-[#F4F4F4] rounded-lg cursor-pointer"
                    onClick={() => {
                      if (currentTab === 0) {
                        setReviewByDropdown({
                          ...reviewByDropdown,
                          medicalProfessionalMenu: !reviewByDropdown.medicalProfessionalMenu
                        });
                      }
                    }}
                    style={{ width: '250px' }}
                  >
                    <div className="flex flex-row justify-between w-full h-full items-center">
                      <span>
                        {reviewByCategory.medicalProfessionalCategory || 'Select professional'}
                      </span>
                      <svg
                        className={`w-4 h-4 transform transition-transform duration-200 ${
                          reviewByDropdown?.medicalProfessionalMenu ? 'rotate-180' : ''
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                    {reviewByDropdown?.medicalProfessionalMenu && (
                      <ul className="absolute top-full left-0 w-full h-fit overflow-y-auto border bg-[#FFFFFF] border-[#E7E8EA] rounded-lg mt-2 z-10">
                        {medicalExpertData?.map((data, index) => (
                          <li
                            key={index}
                            className="px-4 py-2 hover:bg-[#E7E8EA] cursor-pointer"
                            onClick={() => [
                              setReviewedByImage(data?.image),
                              setReviewedByData({ ...reviewedByData, medicalExpert: data?.id }),
                              setReviewByMedicalCategory({
                                ...reviewByCategory,
                                medicalProfessionalCategory: data?.name
                              }),
                              setReviewByDropdown({
                                ...reviewByDropdown,
                                medicalProfessionalMenu: !reviewByDropdown.medicalProfessionalMenu
                              })
                            ]}
                          >
                            {data?.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <p className="text-red-500 text-sm">
                    {errors.medicalExpert && errors.medicalExpert}
                  </p>
                </div>
              </div>
              <div className="w-full sm:w-96 gap-8">
                <div className="flex flex-col">
                  <h1 className="text-base mb-4">Published By</h1>
                  <div className="relative dropdown-container">
                    <div className="grid grid-cols-12 w-full h-10">
                      <div className="col-span-12">
                        <input
                          className="col-span-12 bg-[#F4F4F4] rounded-lg pl-[15px] border-2 border-[#E7E8EA] truncate py-1.5 w-full sm:w-[250px]"
                          placeholder="Enter publisher name"
                          name="publishedBy"
                          value={reviewedByData.publishedBy}
                          readOnly={currentTab !== 0}
                          onChange={(e) => [
                            setReviewedByData({ ...reviewedByData, publishedBy: e.target.value }),
                            setReviewByMedicalCategory({
                              ...reviewByCategory,
                              publishedByCategory: e.target.value
                            })
                          ]}
                        ></input>
                      </div>
                    </div>
                    <p className="text-red-500 text-sm">
                      {errors.publishedBy && errors.publishedBy}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Questions list page */}
      {(currentTab === 0 || currentTab === 1 || currentTab === 4) &&
        questionsList.map((data, index) => (
          <div
            key={index}
            className="px-3 py-10 sm:px-7 sm:py-10 border mt-[25px] border-[#E7E8EA] bg-[#FFFFFF] rounded-2xl"
          >
            <div className="flex justify-center mb-9">
              <div className="flex w-full z-0">
                <div className="h-fit rounded-lg px-4 py-1 sm:px-6 sm:py-3 mr-5 sm:mr-[1.5%] text-black bg-gray-200">
                  Q{index + 1}
                </div>
                <h2 className="sm:leading-normal tracking-none sm:tracking-normal h-fit w-full md:w-full lg:max-w-[46%] leading-9 text-lg sm:text-[24px] mb-5 sm:mb-0 flex items-center">
                  {questionsList[index].title}
                </h2>
              </div>
              {currentTab === 0 && (
                <div className="relative inline-block">
                  <div
                    className="dropdown-toggle relative sm:left-0 left-[-10px] sm:top-0 top-[-20px] justify-end flex items-center rounded-lg cursor-pointer"
                    onClick={() => [setIsOpen(!isOpen), setDropDownIndex(index)]}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                        stroke="#0B1D2E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  {isOpen && dropDownIndex === index && (
                    <div
                      className="dropdown-menu w-40 top-[13px] left-[-131px] z-10 bg-[#FFFFFF] overflow-hidden absolute mt-1 border-[1.5px] py-4 border-[#DDDDDD] rounded-lg"
                      style={{
                        zIndex: 274
                      }}
                    >
                      <div
                        className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer"
                        onClick={() => [handleQuestionAction('edit', index, data)]}
                      >
                        Edit
                      </div>
                      <div
                        className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer"
                        onClick={() => [
                          setIsOpen(!isOpen),
                          handleQuestionAction('duplicate', index)
                        ]}
                      >
                        Duplicate
                      </div>
                      <div className="border-b border-gray-400 w-full"></div>
                      <div
                        className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer text-red-700"
                        onClick={() => [setIsOpen(!isOpen), handleQuestionAction('delete', index)]}
                      >
                        Delete
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="w-full flex flex-col items-center">
              <div className="flex flex-col space-y-7 mb-4 w-[87%] justify-center">
                {questionsList[index].options.map((option, optIndex) => (
                  <label className="flex items-center" key={optIndex}>
                    <input
                      type="radio"
                      className="mr-2"
                      style={{ transform: 'scale(1.75)' }}
                      disabled
                    />
                    <p className="ml-6">{option.value}</p>
                  </label>
                ))}
              </div>
            </div>
          </div>
        ))}

      {/* Add question button */}
      {currentTab === 0 && (
        <div className="mt-[30px] rounded-3xl border-[1.5px] border-[#E7E8EA] px-6 py-4 bg-[#FFFFFF]">
          <div className="block w-full h-full">
            <h1 className="text-2xl">Add Question</h1>
            <div className="sm:grid sm:grid-cols-12 gap-2 mt-[20px] sm:w-[100%] w-full">
              <div className="col-span-1 sm:col-span-7">
                <input
                  className="w-full sm:mb-0 rounded-lg px-5 py-1.5 bg-[#F4F4F4] border-[1.5px] border-[#E7E8EA]"
                  type="text"
                  placeholder="Enter your question"
                  value={tempQuestion.title}
                  onChange={(e) => handleQuestionUpdate(e.target.value)}
                />
                <p className="text-red-500 text-sm">
                  {errors.questionTitle && errors.questionTitle}
                </p>
              </div>
            </div>
          </div>
          <h1 className="text-xl mt-[45px] mb-[10px]">Answer Choice</h1>
          <div className="grid grid-cols-12 sm:gap-6 w-full mb-5">
            {tempQuestion.options?.map((answer, index) => (
              <div
                key={index}
                className="col-span-12 sm:col-span-9 mb-3 sm:mb-0 rounded-lg flex flex-col sm:flex-row align-top"
              >
                <div className="flex flex-col items-start w-full align-top">
                  <input
                    className="w-full mb-3 sm:mb-0 rounded-lg px-5 py-1.5 bg-[#F4F4F4] border-[1.5px] border-[#E7E8EA]"
                    type="text"
                    placeholder="Enter answer choices"
                    value={tempQuestion.options[index]?.value}
                    onChange={(e) => handleAnswerchange(index, e.target.value)}
                  />
                  {errors[`option${index}`] && (
                    <p className="text-red-500 text-sm">{errors[`option${index}`]}</p>
                  )}
                </div>
                <div className="flex items-center mt-2 sm:mt-0 sm:ml-2 align-top">
                  <div className="menu">
                    <div className="relative inline-block w-full sm:w-[160px]">
                      <div
                        className="dropdown-toggle border w-full sm:max-w-[160px] bg-[#F4F4F4] border-[#E7E8EA] flex justify-around items-center h-10 rounded-lg cursor-pointer"
                        onClick={() => [setIsOpenAnswerChoiceMenu(true), setDropdownIndex(index)]}
                      >
                        <span className="pl-1 text-sm">
                          {(typeof tempQuestion.options[index].weight === 'number' ||
                            typeof tempQuestion.options[index].weight === 'string') &&
                          tempQuestion.options[index].weight.trim() !== ''
                            ? tempQuestion.options[index].weight
                            : 'Select Weight'}
                        </span>
                        <svg
                          className={`w-4 h-4 transform ${
                            isOpen && index === dropdownIndex ? 'rotate-180' : ''
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                      {isOpenAnswerChoiceMenu && (
                        <div className="dropdown-menu overflow-hidden absolute w-full mt-1 bg-[#F4F4F4] rounded-lg z-10">
                          {assessmentType === 1 && index === dropdownIndex && (
                            <>
                              <div
                                className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer text-sm"
                                onClick={() => selectOption('SECURE', index)}
                              >
                                SECURE
                              </div>
                              <div
                                className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer text-sm"
                                onClick={() => selectOption('AVOIDANT', index)}
                              >
                                AVOIDANT
                              </div>
                              <div
                                className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer text-sm"
                                onClick={() => selectOption('ANXIOUS', index)}
                              >
                                ANXIOUS
                              </div>
                              <div
                                className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer text-sm"
                                onClick={() => selectOption('DISORGANISED', index)}
                              >
                                DISORGANISED
                              </div>
                            </>
                          )}
                          {assessmentType === 0 && index === dropdownIndex && (
                            <>
                              <div
                                className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer"
                                onClick={() => selectOption('0', index)}
                              >
                                0
                              </div>
                              <div
                                className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer"
                                onClick={() => selectOption('1', index)}
                              >
                                1
                              </div>
                              <div
                                className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer"
                                onClick={() => selectOption('2', index)}
                              >
                                2
                              </div>
                              <div
                                className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer"
                                onClick={() => selectOption('3', index)}
                              >
                                3
                              </div>
                              <div
                                className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer"
                                onClick={() => selectOption('4', index)}
                              >
                                4
                              </div>
                              <div
                                className="dropdown-item p-2 hover:bg-[#E7E8EA] cursor-pointer"
                                onClick={() => selectOption('5', index)}
                              >
                                5
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    {errors[`weight${index}`] && (
                      <p className="text-red-500 text-sm">{errors[`weight${index}`]}</p>
                    )}
                  </div>

                  {/* Add Button */}
                  <button
                    className="flex justify-center items-center text-gray-400 ml-2"
                    onClick={() => addAnswer('choiceSelect')}
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                        stroke="#A1A1A1"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 12H16"
                        stroke="#A1A1A1"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 16V8"
                        stroke="#A1A1A1"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>

                  {/* Remove Button */}
                  <button
                    className="flex justify-center items-center text-gray-400 ml-2"
                    onClick={() => removeAnswer(index, 'choiceSelect')}
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.92 22C17.42 22 21.92 17.5 21.92 12C21.92 6.5 17.42 2 11.92 2C6.41998 2 1.91998 6.5 1.91998 12C1.91998 17.5 6.41998 22 11.92 22Z"
                        stroke="#A1A1A1"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.91998 12H15.92"
                        stroke="#A1A1A1"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="flex sm:justify-start rounded-lg px-0 py-3 justify-start">
            <button
              className="flex gap-5 justify-center items-center rounded-[25px] border border-[##A1A1A1] px-11 py-2"
              onClick={() => {
                setIsShowBulkAnswerModal(true);
              }}
            >
              <svg
                width="21"
                height="20"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.50004 14.6666C12.1667 14.6666 15.1667 11.6666 15.1667 7.99998C15.1667 4.33331 12.1667 1.33331 8.50004 1.33331C4.83337 1.33331 1.83337 4.33331 1.83337 7.99998C1.83337 11.6666 4.83337 14.6666 8.50004 14.6666Z"
                  stroke="#0B1D2E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.83337 8H11.1667"
                  stroke="#0B1D2E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.5 10.6666V5.33331"
                  stroke="#0B1D2E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p>Bulk Answers</p>
            </button>
          </div>
          <button
            className="bg-shoorah-secondary text-white px-10 py-2 rounded-full mt-[20px] ms-auto block"
            onClick={handleSaveQuestion}
          >
            Save Question
          </button>
        </div>
      )}

      {/* If user click on bluk answer */}
      {isShowBulkAnswerModal && (
        <div className="flex justify-center items-center z-50 fixed h-screen w-screen top-0 left-0 bg-black/15 px-10">
          <div className="h-fit w-[702px] rounded-3xl px-16 py-4 bg-gray-100">
            <div className="text-4xl mt-[20px]">Add Answers in Bulk</div>
            <div className="text-gray-400 mt-[20px]">
              Enter each answer choice on a separate line
            </div>
            <div className="flex justify-center mt-[30px]">
              <div className="relative w-full">
                <textarea
                  value={bulkAnswerInput}
                  onChange={handleGetBulkAnswerData}
                  maxLength={maxLength}
                  placeholder="Enter reason here..."
                  className="w-full h-[240px] p-4 border rounded-lg text-[#0B1D2E] border-[#E7E8EA]300 focus:outline-none focus:ring-2 focus:ring-gray-200"
                />
                <div className="absolute bottom-2 right-4 py-5 px-6 text-gray-400 text-sm">
                  *{maxLength} Character limit
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-[40px] mb-[10px] gap-6">
              <button
                className="text-black border border-black px-10 rounded-full p-3"
                onClick={() => handleBulkAnswer('cancel')}
              >
                Cancel
              </button>
              <button
                className="border bg-shoorah-primary text-white border-shoorah-primary px-14 py-2 rounded-full p-5"
                onClick={() => handleBulkAnswer('save')}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Share Assessment Page  */}
      {currentTab === 2 && (
        <div className="max-w-[100%] mx-auto">
          {isLoading && <Loader />}
          <div className="mt-[50px] rounded-2xl bg-[#FFFFFF]">
            <div className="text-[20px] p-7 text-black">Share Assessment</div>
            <div className="heading-part flex items-center gap-5 px-7 w-full">
              <h6 className="text-[16px]"> Add cover image (Optional) </h6>
              {coverImagePreview && (
                <button onClick={handleClearCoverImage}>
                  <img src={editIcon} alt="edit-icon" className="w-6" />
                </button>
              )}
            </div>
            <div className="flex sm:justify-start justify-center mt-[12px] px-7">
              <div
                className="w-[98%] sm:w-[45.5%] max-w-[360px] h-[188px] border-[1.5px] border-indigo-300 rounded-lg mb-[34px] bg-indigo-50 flex flex-col justify-center items-center relative"
                style={{
                  backgroundImage: coverImagePreview ? `url(${coverImagePreview})` : 'none',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              >
                <div className="w-12 h-12 mb-4">
                  {!coverImagePreview && (
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 44H30C40 44 44 40 44 30V18C44 8 40 4 30 4H18C8 4 4 8 4 18V30C4 40 8 44 18 44Z"
                        fill="#B6C0F3"
                        stroke="#4A56DC"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18 20C20.2091 20 22 18.2091 22 16C22 13.7909 20.2091 12 18 12C15.7909 12 14 13.7909 14 16C14 18.2091 15.7909 20 18 20Z"
                        fill="#B6C0F3"
                        stroke="#4A56DC"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.33984 37.9L15.1998 31.28C16.7798 30.22 19.0598 30.34 20.4798 31.56L21.1398 32.14C22.6998 33.48 25.2198 33.48 26.7798 32.14L35.0998 25C36.6598 23.66 39.1798 23.66 40.7398 25L43.9998 27.8"
                        fill="#B6C0F3"
                      />
                      <path
                        d="M5.33984 37.9L15.1998 31.28C16.7798 30.22 19.0598 30.34 20.4798 31.56L21.1398 32.14C22.6998 33.48 25.2198 33.48 26.7798 32.14L35.0998 25C36.6598 23.66 39.1798 23.66 40.7398 25L43.9998 27.8"
                        stroke="#4A56DC"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
                {!coverImagePreview && (
                  <p className="text-gray-700 text-[18px] text-center">
                    Drop your image here, or
                    <label htmlFor="file-upload" className="text-blue-600 cursor-pointer ml-1">
                      browse
                    </label>
                  </p>
                )}
                <input
                  id="file-upload"
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={handlePreviewCoverImage}
                  ref={fileInputRef}
                />
                {!coverImagePreview && (
                  <p className="text-[8px] text-[#969696] mt-2">Maximum file size: 5MB</p>
                )}
                {/* {coverImagePreview && (
                  <button className="mt-2 text-red-600 text-xs" onClick={handleClearCoverImage}>
                    Remove Image
                  </button>
                )} */}
                {errorMessage && <p className="text-red-600 text-xs">{errorMessage}</p>}
              </div>
            </div>
          </div>
          <div className="justify-start mt-[20px] pt-[30px] bg-[#FFFFFF] rounded-lg">
            <div>
              <p className="flex text-[20px] px-7 mb-[48px] font-normal">
                Publish Time <span style={{ fontWeight: 200 }}>(Optional)</span>
              </p>
            </div>
            <div className="flex flex-col sm:flex-row w-full  lg:w-1/2 gap-4 px-4 sm:px-7 ">
              <div className="w-full sm:w-1/2">
                <div className="flex items-center justify-start sm:items-start">
                  <h1 className="mb-[20px] text-[16px]">Add Date</h1>
                </div>
                <div className="w-[98%] sm:w-[90.5%] max-w-[281px] h-[40px] border-[1.5px] border-[#E7E8EA] rounded-lg pl-[10px] sm:pl-[18px] mb-[34px] bg-[#F4F4F4] flex flex-col justify-center items-start relative">
                  <p
                    className="flex flex-row justify-between items-center text-[14px] h-fit w-full bg-transparent border-none cursor-pointer text-dark pr-2 sm:pr-5"
                    onClick={handleToggleCalendar}
                  >
                    {startDate ? startDate : 'Set Date'}
                    <svg
                      className={`w-4 h-4 transform ${isOpen ? 'rotate-180' : ''}`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </p>
                  {showCalendar && (
                    <div className="absolute top-[-13px] left-[-43px] mt-1 bg-white z-50 border-[1.5px] border-[#E7E8EA] rounded-2xl px-2 transform scale-75 text-[14px]">
                      <Calendar
                        className={`calendar dark:!text-white text-sm sm:text-lg`}
                        onChange={handleChangeC}
                        tileContent={tileContent}
                        tileClassName={tileClassName}
                        nextLabel={
                          <button className="custom-next-button">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 8 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.375 0.75C1.375 0.75 6.625 3.858 6.625 6C6.625 8.14125 1.375 11.25 1.375 11.25"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="dark:text-white"
                              ></path>
                            </svg>
                          </button>
                        }
                        prevLabel={
                          <button className="custom-prev-button">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 8 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="rotate-180"
                            >
                              <path
                                d="M1.375 0.75C1.375 0.75 6.625 3.858 6.625 6C6.625 8.14125 1.375 11.25 1.375 11.25"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="dark:text-white"
                              ></path>
                            </svg>
                          </button>
                        }
                        defaultValue={new Date()}
                        minDate={new Date()}
                        navigationLabel={({ date }) => (
                          <button
                            style={{
                              cursor: 'default',
                              pointerEvents: 'none'
                            }}
                          >
                            {date.toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'long'
                            })}
                          </button>
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full sm:w-1/2">
                <div className="flex items-center justify-start sm:items-start cursor-pointer">
                  <h1 className="mb-[20px] text-[16px]">Add Time</h1>
                </div>
                <div className="w-[98%] sm:w-[90.5%] max-w-[281px] h-[40px] border-[1.5px] border-[#E7E8EA] rounded-lg pl-[10px] sm:pl-[18px] mb-[34px] bg-[#F4F4F4] flex flex-col justify-center items-start relative cursor-pointer">
                  <div
                    className="w-full h-full flex items-center justify-between text-[14px]"
                    onClick={() => setIsOpenT(!isOpenT)}
                  >
                    <p className="flex flex-row justify-between items-center text-[14px] h-fit w-full bg-transparent border-none cursor-pointer text-dark pr-1 sm:pr-5">
                      {selectedTime ? selectedTime : 'Set Time'}
                      <svg
                        className={`w-4 h-4 transform ${isOpen ? 'rotate-180' : ''}`}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </p>
                  </div>
                  {isOpenT && (
                    <div className="absolute top-[38px] z-10 w-[110%] text-[14px] sm:w-[71%] left-0 sm:left-[29%] overflow-hidden bg-white border-[1.5px] border-[#E7E8EA] rounded-lg overflow-y-auto max-h-[200px] shadow-md">
                      {generateTimes().map((time) => (
                        <div
                          key={time}
                          className="px-4 sm:px-6 text-[14px] py-2 hover:bg-[#E7E8EA] cursor-pointer"
                          onClick={() => handleSelectT(time)}
                        >
                          {time}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#FFFFFF] pt-[30px] mt-[20px] rounded-3xl p-5 sm:pl-[40px]">
            <h1 className="text-[20px] mb-[45px]">End Page</h1>
            <div className="input-field mb-[35px]">
              <h1 className="mb-[15px] text-[1rem]">Heading Text</h1>
              <input
                className="dropdown-menu w-full sm:w-1/2 max-w-[580px] h-[40px] bg-[#F4F4F4] border-[1.5px] text-[14px] border-[#E7E8EA] rounded-lg pl-[18px] flex flex-col justify-center items-start relative"
                placeholder="You have completed this Assessment."
                name="endHeading"
                value={shareAssessmentData.endHeading}
                onChange={(e) =>
                  setShareAssessmentData({ ...shareAssessmentData, endHeading: e.target.value })
                }
              ></input>
              <p className="text-red-500 text-sm">{errors.endHeading && errors.endHeading}</p>
            </div>
            <div className="input-field mb-[35px]">
              <h1 className="text-[1rem] mb-[15px]"> Body Text</h1>
              <textarea
                className="dropdown-menu w-full sm:w-1/2 max-w-[580px] bg-[#F4F4F4] border-[1.5px] text-[14px] resize-none border-[#E7E8EA] rounded-lg pl-[18px] pb-[30px] pt-[10px] relative"
                placeholder="Thank you for taking time to answer this Assessment."
                name="endBody"
                value={shareAssessmentData.endBody}
                onChange={(e) =>
                  setShareAssessmentData({ ...shareAssessmentData, endBody: e.target.value })
                }
              ></textarea>
              <p className="text-red-500 text-sm">{errors.endBody && errors.endBody}</p>
            </div>
          </div>
          <div className="grid grid-cols-12 sm:flex justify-end mt-[30px] p-5 gap-6">
            <div className="col-span-6 rounded-3xl p-3 w-full sm:max-w-[145px] h-[40px] bg-shoorah-secondary flex justify-center items-center">
              <button className="text-[14px] text-white w-full" onClick={handleSendAssessment}>
                Send Assessment
              </button>
            </div>
          </div>
        </div>
      )}

      {isShowApprovalModal && (
        <div className="flex justify-center items-center z-50 fixed h-screen w-screen top-0 left-0 bg-black/15 px-10">
          <div className=" h-fit w-[692px] rounded-[24px] px-24 py-16 bg-[#F4F4F4] border-[1.5px] border-[#E7E8EA]">
            <svg
              width={96}
              height={96}
              viewBox="0 0 96 96"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mx-auto"
            >
              <circle cx={48} cy={48} r={48} fill="#E5EAFA" />
              <path
                d="M44.6667 22.5341C46.5067 20.9608 49.52 20.9608 51.3866 22.5341L55.6 26.1611C56.4 26.8544 57.8933 27.4143 58.96 27.4143H63.4933C66.32 27.4143 68.64 29.7342 68.64 32.5608V37.0943C68.64 38.1343 69.2 39.6544 69.8933 40.4544L73.52 44.6676C75.0934 46.5076 75.0934 49.521 73.52 51.3876L69.8933 55.6009C69.2 56.4009 68.64 57.8942 68.64 58.9609V63.4944C68.64 66.3211 66.32 68.6409 63.4933 68.6409H58.96C57.92 68.6409 56.4 69.2008 55.6 69.8941L51.3866 73.5208C49.5466 75.0941 46.5333 75.0941 44.6667 73.5208L40.4534 69.8941C39.6534 69.2008 38.16 68.6409 37.0933 68.6409H32.48C29.6533 68.6409 27.3333 66.3211 27.3333 63.4944V58.9342C27.3333 57.8942 26.7734 56.4009 26.1067 55.6009L22.5067 51.3609C20.96 49.5209 20.96 46.5343 22.5067 44.6943L26.1067 40.4544C26.7734 39.6544 27.3333 38.161 27.3333 37.121V32.5608C27.3333 29.7342 29.6533 27.4143 32.48 27.4143H37.0933C38.1334 27.4143 39.6534 26.8544 40.4534 26.1611L44.6667 22.5341Z"
                fill="#B6C0F3"
                stroke="#4A57DA"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M42.3737 55.5196L46.2403 58.5062C46.747 59.0129 47.867 59.253 48.6137 59.253H53.3337C54.827 59.253 56.4537 58.133 56.827 56.6397L59.8136 47.5196C60.427 45.7862 59.307 44.2663 57.4403 44.2663H52.4537C51.707 44.2663 51.0937 43.6531 51.2004 42.7731L51.8136 38.7731C52.0536 37.6531 51.307 36.3997 50.187 36.0264C49.2003 35.6531 47.947 36.1596 47.4403 36.9063L42.3203 44.5062"
                stroke="#4A57DA"
                strokeWidth={3}
                strokeMiterlimit={10}
              />
              <path
                d="M36 55.5185V43.3053C36 41.5453 36.7467 40.9319 38.5067 40.9319H39.76C41.4933 40.9319 42.2667 41.5453 42.2667 43.3053V55.5185C42.2667 57.2518 41.52 57.8918 39.76 57.8918H38.5067C36.7467 57.8918 36 57.2785 36 55.5185Z"
                stroke="#4A57DA"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h1 className="text-2xl font-medium text-center mt-6">
              You're currently unable to share this assessment with users
            </h1>
            <p className="text-base font-normal text-center mt-4">
              Send the assessment to an admin to get approval
            </p>
            <Select
              options={superAdminListOptions}
              className="mt-4 w-96 mx-auto"
              placeholder="Select Admin..."
              onChange={(e) =>
                setShareAssessmentData({ ...shareAssessmentData, approvalBy: e.value })
              }
            />
            <button
              className="bg-[#4A57DA] rounded-full px-10 py-2 text-white text-base block mx-auto mt-8"
              onClick={handleSendSurveyWithApproval}
            >
              Send for Approval
            </button>
          </div>
        </div>
      )}
      {isShowSuccessModal && (
        <div className="flex justify-center items-center z-50 fixed h-screen w-screen top-0 left-0 bg-black/15 px-10">
          <div className="h-fit w-[692px] rounded-[24px] p-10 bg-[#F4F4F4] border-[1.5px] border-[#E7E8EA]">
            <svg
              width={96}
              height={96}
              viewBox="0 0 96 96"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mx-auto"
            >
              <circle cx={48} cy={48} r={48} fill="#E5EAFA" />
              <path
                d="M44.6667 22.5341C46.5067 20.9608 49.52 20.9608 51.3866 22.5341L55.6 26.1611C56.4 26.8544 57.8933 27.4143 58.96 27.4143H63.4933C66.32 27.4143 68.64 29.7342 68.64 32.5608V37.0943C68.64 38.1343 69.2 39.6544 69.8933 40.4544L73.52 44.6676C75.0934 46.5076 75.0934 49.521 73.52 51.3876L69.8933 55.6009C69.2 56.4009 68.64 57.8942 68.64 58.9609V63.4944C68.64 66.3211 66.32 68.6409 63.4933 68.6409H58.96C57.92 68.6409 56.4 69.2008 55.6 69.8941L51.3866 73.5208C49.5466 75.0941 46.5333 75.0941 44.6667 73.5208L40.4534 69.8941C39.6534 69.2008 38.16 68.6409 37.0933 68.6409H32.48C29.6533 68.6409 27.3333 66.3211 27.3333 63.4944V58.9342C27.3333 57.8942 26.7734 56.4009 26.1067 55.6009L22.5067 51.3609C20.96 49.5209 20.96 46.5343 22.5067 44.6943L26.1067 40.4544C26.7734 39.6544 27.3333 38.161 27.3333 37.121V32.5608C27.3333 29.7342 29.6533 27.4143 32.48 27.4143H37.0933C38.1334 27.4143 39.6534 26.8544 40.4534 26.1611L44.6667 22.5341Z"
                fill="#B6C0F3"
                stroke="#4A57DA"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M42.3737 55.5196L46.2403 58.5062C46.747 59.0129 47.867 59.253 48.6137 59.253H53.3337C54.827 59.253 56.4537 58.133 56.827 56.6397L59.8136 47.5196C60.427 45.7862 59.307 44.2663 57.4403 44.2663H52.4537C51.707 44.2663 51.0937 43.6531 51.2004 42.7731L51.8136 38.7731C52.0536 37.6531 51.307 36.3997 50.187 36.0264C49.2003 35.6531 47.947 36.1596 47.4403 36.9063L42.3203 44.5062"
                stroke="#4A57DA"
                strokeWidth={3}
                strokeMiterlimit={10}
              />
              <path
                d="M36 55.5185V43.3053C36 41.5453 36.7467 40.9319 38.5067 40.9319H39.76C41.4933 40.9319 42.2667 41.5453 42.2667 43.3053V55.5185C42.2667 57.2518 41.52 57.8918 39.76 57.8918H38.5067C36.7467 57.8918 36 57.2785 36 55.5185Z"
                stroke="#4A57DA"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h1 className="text-2xl font-medium text-center mt-6 w-3/4 mx-auto">
              Congratulation on successfully creating Self Assessment
            </h1>
            <p className="text-base font-normal text-center mt-4 w-72 mx-auto">
              You can now manage this assessment and access valuable insights
            </p>
            <button
              className="bg-[#4A57DA] rounded-full px-10 py-3 text-white text-lg block mx-auto mt-8"
              onClick={() => {
                setIsShowSuccessModal(false);
                navigate('/self-assessment/view-all-assessment');
              }}
            >
              View All Assessments
            </button>
          </div>
        </div>
      )}

      {currentTab === 1 && (
        <div className="w-full sm:w-[45%] mt-12 md:w-[100%] lg:w-[40%]">
          <div className="flex flex-row sm:grid sm:grid-cols-12  gap-2 sm:gap-5">
            <div className="col-span-12 w-full sm:col-span-4">
              <button
                className="w-full h-full sm:py-0 bg-shoorah-secondary text-white rounded-full flex flex-row gap-4 sm:gap-4 md:gap-8 lg:gap-3 justify-center items-center text-base"
                onClick={handleSubmitAssessment}
              >
                Submit
              </button>
            </div>
            <div className="col-span-12 w-full sm:col-span-4 md:col-span-6">
              <button
                className="w-full text-black border border-black py-2 rounded-full"
                onClick={handleDraft}
              >
                Save as draft
              </button>
            </div>
          </div>
        </div>
      )}
      {currentTab === 4 && contentApproval === 1 && (
        <div className="w-full sm:w-[45%] mt-12 md:w-[100%] lg:w-[40%]">
          <div className="flex flex-row sm:grid sm:grid-cols-12 gap-2 sm:gap-5">
            <div className="col-span-12 w-full sm:col-span-4">
              <button
                className="w-full h-full my-2 sm:py-0 bg-shoorah-secondary text-white rounded-full flex flex-row gap-4 sm:gap-4 md:gap-8 lg:gap-3 justify-center items-center text-base"
                onClick={handleApproval}
              >
                Approve
              </button>
            </div>
            <div className="col-span-12 w-full sm:col-span-4">
              <button
                className="w-full h-full my-2 sm:py-0 bg-shoorah-darkRed text-white rounded-full flex flex-row gap-4 sm:gap-4 md:gap-8 lg:gap-3 justify-center items-center text-base"
                onClick={handleReject}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      )}

      {isShowAssessmentApprovalModal && (
        <SurveyAssessmentUpdateDialog
          title={'Draft assessment saved successfully.'}
          onButtonClick={() => navigate('/self-assessment/view-all-assessment')}
          buttonTitle={'View All Assessments'}
        />
      )}

      {currentTab === 3 && (
        <SelfAssesmentResult questionsList={questionsList}/>
      )}
    </div>
  );
};

export default AddEditSelfAssessment;
