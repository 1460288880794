import React from 'react';

const SpecialistPersonalBio = ({ data }) => {
  return (
    <div className="border-2 relative h-full border-gray-300 rounded-3xl px-6 lg:px-10 pt-3 lg:pt-6 pb-6 lg:pb-12 bg-white dark:bg-shoorah-darkBgTabColor dark:border-none dark:text-white text-black text-lg font-medium w-full">
      <h4 className="mb-3">Personal Bio</h4>
      <p className="text-sm font-medium text-shoorah-gray2">
        Explore specialists bio to gain a deeper understanding of their professional journey. While
        these sections cannot be edited directly admins can request changes through messaging
        feature.
      </p>

      <div className="w-full mt-[2rem]">
        <textarea
          readOnly
          className="p-2 md:p-5 text-sm  flex h-40 overflow-y-auto no-scrollbar  rounded-2xl border-2 border-gray-300 placeholder:text-gray-400 text-gray-700  w-full appearance-none outline-none resize-none break-all "
          value={data?.bio}
        ></textarea>
      </div>
    </div>
  );
};

export default SpecialistPersonalBio;
