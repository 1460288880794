import React from 'react';
import { Helmet } from 'react-helmet';
import ProfileCard from './profileCard';
import { useLocation, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Api } from '../../../api';
import Loader from '../../../component/common/Loader';
import Topbar from '../../PulseSurvey/Topbar';
import SpecialistInformation from './SpecialistInformation';
import Specification from './SpecialistSpecification';
import SpecialistAttachments from './SpecialistAttachments';
import SpecialistPersonalBio from './SpecialistPersonalBio';
import SpecialistStatusDbsInfo from './SpecialistStatusDbsInfo';
import SpecialistComplaints from './SpecialistComplaints';
import SpecialistPricing from './SpecialistPricing';
const MySpecialistProfile = () => {
  const location = useLocation();
  const { userId } = useParams();
  const [loader, setLoader] = useState(true);
  const [expertProfileData, setExpertProfileData] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, [location]);

  const fetchUsers = () => {
    setLoader(true);
    Api.getPeapAccountInfo(userId)
      .then((response) => {
        setLoader(false);
        if (response?.data?.meta?.code === 200) {
          setExpertProfileData(response?.data?.data);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error('API call error: ', error);
      });
  };

  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isApprove, setIsApprove] = useState(false);

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };
  const togglePopup1 = () => {
    setIsApprove(!isApprove);
  };

  return (
    <div className="lg:pl-8 pb-10">
      {loader && <Loader />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Peap Profile | Shoorah Admin</title>
      </Helmet>
      <Topbar title={'Specialist Profile'} />
      {!loader && (
        <div className="lg:mt-8 w-full">
          <ProfileCard data={expertProfileData} />

          <div className="mt-6 mb-5 flex flex-col lg:flex-row xl:gap-x-14 gap-6 w-full">
            {/* left */}
            <div className="flex flex-col lg:h-fit gap-6 lg:gap-12 w-full lg:w-1/2 relative ">
              <SpecialistInformation data={expertProfileData} />
              <Specification data={expertProfileData} />
              <SpecialistAttachments data={expertProfileData} />
            </div>

            {/* right */}
            <div className="flex flex-col gap-6 lg:gap-12 w-full lg:w-1/2 lg:h-fit">
              <SpecialistPersonalBio data={expertProfileData} />
              <SpecialistStatusDbsInfo data={expertProfileData} />
              <SpecialistPricing data={expertProfileData} refetch={fetchUsers} />
              {/* <SpecialistComplaints data={expertProfileData} /> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MySpecialistProfile;
