import React from 'react';
import UserIcon from '../../../assets/images/UserShieldIcon.svg';
import IdentityIcon from '../../../assets/images/IdentityIcon.svg';

const SpecialistStatusDbsInfo = ({ data }) => {
  return (
    <div className="border-2 relative  border-gray-300 rounded-3xl px-6 lg:px-10 pt-3 lg:pt-6 pb-6 lg:pb-12 bg-white dark:bg-shoorah-darkBgTabColor dark:border-none dark:text-white ">
      <div className="text-black h-full dark:text-white text-lg font-medium w-full flex flex-col gap-[1rem]">
        <h4>ID & DBS Check</h4>
        <p className="text-sm font-medium text-shoorah-gray2">
          Specialists conveniently upload their DBS and ID checks and verifications. While the DBS
          checks require approval from a Shoorah Admin member. The ID verification is efficiently
          handled by a reliable third-party software.
        </p>

        <div className="w-full flex md:flex-row xl:flex-row flex-col lg:flex-col gap-6 xl:gap-16 md:gap-16 lg:gap-6 justify-center mt-[1rem] h-full ">
          <div className="w-full border-2  border-gray-300 pt-4 py-5  rounded-3xl flex flex-col justify-start items-center gap-2 md:gap-4 ">
            <div className="w-full flex justify-center items-center gap-2 md:gap-4 rounded-3xl ">
              <img src={UserIcon} alt="User Icon" className="w-6 h-6 md:w-auto md:h-auto" />
              <p className="text-lg">DBS Check</p>
            </div>
            {/* relative left-5 */}
            <div className=" flex justify-evenly ">
              <a
                href={data?.fileName}
                download
                target="_blank"
                rel="noreferrer nopener"
                className="text-sm underline text-center text-shoorah-gray3"
              >
                View file{' '}
              </a>
            </div>
          </div>

          <div className="w-full border-2   border-gray-300 pt-4 py-5 rounded-3xl flex flex-col justify-start items-center gap-2 md:gap-4 ">
            <div className="w-full flex justify-center gap-2 md:gap-4 items-center rounded-3xl ">
              <img src={IdentityIcon} alt="Identity Icon" className="w-6 h-6 md:w-auto md:h-auto" />
              <p className="text-lg">Identity</p>
            </div>
            {/* relative left-5 */}
            <div className=" flex justify-evenly  ">
              <p
                className={`text-sm text-center text-gray-300 ${
                  data?.workflowSuccess === 1 ? 'text-green-500' : 'text-red-500'
                }`}
              >
                {data?.workflowSuccess === 1 ? 'Verified' : 'Not Verified'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialistStatusDbsInfo;
