import React, { useEffect, useState } from 'react';
import AssessmentResultQuestion from './AssessmentResultQuestion';
import ValueCards from '../../component/common/cards/valueCard';
import { Api } from '../../api';
import { useNavigate, useNavigation, useSearchParams } from 'react-router-dom';

const SelfAssesmentResult = ({ questionsList }) => {
    const [resultData, setResultData] = useState({});
    const [questionData, setQuestionData] = useState([]);
    console.log("🚀 ~ SelfAssesmentResult ~ resultData:", resultData.questions)
    const [params] = useSearchParams();
    const id = params.get('id');
    const fetchSelfAssesmentResult = async () => {
        try {
            Api.getAssessmentResult(id).then((response) => {
                setResultData(response?.data);
                const data = response?.data?.questions && response?.data?.questions.map((question) => {
                        return {
                            questionId: question.questionId,
                            title: question.questionTitle,  // Renamed 'questionTitle' to 'title'
                            totalResponses: question.totalResponses,
                            options: question.optionCounts.map(option => {
                              return {
                                optionId: option.optionId,
                                value: option.optionValue,  // Renamed 'optionValue' to 'value'
                                count: option.count,
                                percentage: option.percentage
                              };
                            })
                          };
                })
                setQuestionData(data)
            }).catch((error) => {
                console.error('Error fetching SelfAssesmentResult:', error);
            })
        } catch (error) {
            console.error('Error fetching SelfAssesmentResult:', error);
        }
    }

    useEffect(() => {
        fetchSelfAssesmentResult();
    },[])
  return (
    <>
      <ValueCards resultData={resultData} />
      {questionData && questionData.length > 0 && questionData.map((data, index) => (
        <AssessmentResultQuestion questionsList={data} index={index} key={index} />
      ))}
    </>
  );
};

export default SelfAssesmentResult;
