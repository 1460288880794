import validator from 'validator';

export const PublishSurveyValidation = (data) => {
  const errors = {};

  if (validator.isEmpty(data?.title?.trim())) {
    errors.title = '*Title is required';
  }

  if (validator.isEmpty(data?.description?.trim())) {
    errors.description = '*Description is required';
  }

  if (validator.isEmpty(data?.category?.trim())) {
    errors.category = '*Category is required';
  }

  return { errors, isValid: Object.keys(errors).length <= 0 };
};

export const EditSurveyShareDataValidation = (data) => {
  const errors = {};

  if (!data?.audience) {
    errors.audience = '*Survey team is required';
  }

  if (validator.isEmpty(data?.endHeading.trim())) {
    errors.endHeading = '*Survey end heading is required';
  }

  if (validator.isEmpty(data?.endBody.trim())) {
    errors.endBody = '*Survey end body is required';
  }

  return { errors, isValid: Object.keys(errors).length <= 0 };
};
