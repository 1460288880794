import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import slack from '../../../assets/images/slack.png';
import { surveyApi } from '../../../api/surveyApi';
import { isCopanySuperOrSubAdmin } from '../../../utils/helper';

const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June'],
  datasets: [
    {
      label: 'Survey Monthly Analysis',
      data: [2, 9, 8, 2, 6, 7],
      backgroundColor: '#3a47ab',
      borderColor: ' #4a56db',
      borderWidth: 1
    }
  ]
};

const options2 = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: 'Survey Analysis'
    }
  }
};

const dataD = {
  labels: ['Responses', 'No Response'],
  datasets: [
    {
      label: 'Overall Responses',
      data: [1135, 365],
      backgroundColor: ['#D97F56', '#ECC09E'],
      hoverBackgroundColor: ['#db2777', '#f472b6'],
      borderWidth: 1,
      borderRadius: 10,
      borderJoinStyle: 'round'
    }
  ]
};

const optionsD = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    }
  },
  cutout: '90%',
  radius: '70%'
};

const ProgressBar = ({ label, percentage }) => (
  <div className="mb-4">
    <div className="flex justify-between items-center mb-1">
      <span className="text-lg font-normal">{label}</span>
      <span className="text-sm text-green-600">{percentage}%</span>
    </div>
    <div className="w-full bg-green-100 rounded-full h-2">
      <div className="bg-green-500 h-2 rounded-full" style={{ width: `${percentage}%` }}></div>
    </div>
  </div>
);

const Insights = () => {
  const [InsightsData, setInsightsData] = useState();
  const [userAgeRangeData, setUserAgeRangeData] = useState();
  // console.log('userAgeRangeData.slice(0, 2)', userAgeRangeData.slice(0, 2));
  

  const AgeRangeData = userAgeRangeData?.length > 0 && {
    labels: userAgeRangeData.slice(0, 2).map(item => `Age ${item.range}`),
    datasets: [
    {
      label: 'Overall Responses',
      data: userAgeRangeData.slice(0, 2).map(item => item.percentage),
      backgroundColor: ['#67A14A', '#C2EC97'],
      hoverBackgroundColor: ['#db2777', '#f472b6'],
      borderWidth: 1,
      borderRadius: 10,
      borderJoinStyle: 'round'
    }
  ]
  }

  const CompletedAssigsmentData = userAgeRangeData?.length > 0 && {
    labels: ['Completed', 'Not Completed'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [userAgeRangeData.completionRate, userAgeRangeData.percentageIncomplete],
        backgroundColor: ['#D97F56', '#ECC09E'],
        hoverBackgroundColor: ['#db2777', '#f472b6'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  }

  console.log('AgeRangeData', AgeRangeData);
  

  const fetchInsightsData = () => {
    if (isCopanySuperOrSubAdmin()) {
      const id = localStorage.getItem('pulseSurveyId');
      const companyId = JSON.parse(localStorage.getItem('userData')).companyId;
      console.log('🚀 ~ fetchInsightsData ~ companyId:', companyId);
      surveyApi.getSurveyInsights(id, companyId, false).then((response) => {
        setInsightsData(response.data.data);
        setUserAgeRangeData(response.data.data.ageRangeDistribution);
      });
    }
  };

  useEffect(() => {
    fetchInsightsData();
  }, []);
  return (
    <div className="w-full mb-10 font-work-sans">
      <div className="flex sm:flex-row flex-col h-auto gap-4 my-5">
        <div className="w-full sm:w-3/12 md:w-full h-[96px] bg-[#FFFFFF] rounded-[8px] border-[1.5px] border-[#E7E8EA] flex items-center p-5">
          <div className="flex flex-row items-center w-full">
            <div className="flex justify-center items-center">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="#4A57DA" />
                <path
                  d="M33.98 26.79V30.79C33.98 31.05 33.97 31.3 33.94 31.54C33.71 34.24 32.12 35.58 29.19 35.58H28.79C28.54 35.58 28.3 35.7 28.15 35.9L26.95 37.5C26.42 38.21 25.56 38.21 25.03 37.5L23.83 35.9C23.7 35.73 23.41 35.58 23.19 35.58H22.79C19.6 35.58 18 34.79 18 30.79V26.79C18 23.86 19.35 22.27 22.04 22.04C22.28 22.01 22.53 22 22.79 22H29.19C32.38 22 33.98 23.6 33.98 26.79Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M37.98 22.79V26.79C37.98 29.73 36.63 31.31 33.94 31.54C33.97 31.3 33.98 31.05 33.98 30.79V26.79C33.98 23.6 32.38 22 29.19 22H22.79C22.53 22 22.28 22.01 22.04 22.04C22.27 19.35 23.86 18 26.79 18H33.19C36.38 18 37.98 19.6 37.98 22.79Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M29.4955 29.25H29.5045"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25.9955 29.25H26.0045"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22.4955 29.25H22.5045"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="flex flex-col text-start ml-4">
              <p className="text-[14px] tracking-tight">Total Response</p>
              <p className="text-[24px] font-medium">
                {InsightsData?.totalResponses ? InsightsData?.totalResponses : 0}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-3/12 md:w-full h-[96px] bg-[#FFFFFF] rounded-[8px] border-[1.5px] flex items-center pl-3 border-[#E7E8EA]">
          <div className="flex flex-row items-center w-full">
            <div className="flex justify-center items-center">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="#F05289" />
                <path
                  d="M34.32 28.0002C36.92 28.0002 38 27.0002 37.04 23.7202C36.39 21.5102 34.49 19.6102 32.28 18.9602C29 18.0002 28 19.0802 28 21.6802V24.5602C28 27.0002 29 28.0002 31 28.0002H34.32Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M36.0004 30.6998C35.0704 35.3298 30.6304 38.6898 25.5804 37.8698C21.7904 37.2598 18.7404 34.2098 18.1204 30.4198C17.3104 25.3898 20.6504 20.9498 25.2604 20.0098"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className=" flex flex-col text-start ml-4">
              <p className="text-[14px] tracking-tight">Completion rate</p>
              <p className="text-[24px] font-medium">
                {InsightsData?.completionRate ? InsightsData.completionRate : 0}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-3/12 md:w-full h-[96px] bg-[#FFFFFF] rounded-[8px] border-[1.5px] flex items-center pl-3 border-[#E7E8EA]">
          <div className="flex flex-row items-center w-full">
            <div className="flex justify-center items-center">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="#0B1D2E" />
                <path
                  d="M36.75 29.25C36.75 34.08 32.83 38 28 38C23.17 38 19.25 34.08 19.25 29.25C19.25 24.42 23.17 20.5 28 20.5C32.83 20.5 36.75 24.42 36.75 29.25Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M28 24V29"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25 18H31"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="flex flex-col text-start ml-4">
              <p className="text-[14px] tracking-tight">Time Spent</p>
              <p className="text-[24px] font-medium">00</p>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-3/12 md:w-full h-[96px] bg-[#FFFFFF] rounded-[8px] border-[1.5px] flex items-center pl-3 border-[#E7E8EA]">
          <div className="flex flex-row items-center w-full">
            <div className="flex justify-center items-center">
              <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill="#20BDAD" />
                <path
                  d="M33 34.4297H29L24.55 37.3897C23.89 37.8297 23 37.3598 23 36.5598V34.4297C20 34.4297 18 32.4297 18 29.4297V23.4297C18 20.4297 20 18.4297 23 18.4297H33C36 18.4297 38 20.4297 38 23.4297V29.4297C38 32.4297 36 34.4297 33 34.4297Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M27.9998 27.3604V27.1504C27.9998 26.4704 28.4198 26.1104 28.8398 25.8204C29.2498 25.5404 29.6598 25.1804 29.6598 24.5204C29.6598 23.6004 28.9198 22.8604 27.9998 22.8604C27.0798 22.8604 26.3398 23.6004 26.3398 24.5204"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M27.9955 29.75H28.0045"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="flex flex-col text-start ml-4">
              <p className="text-[14px] tracking-tight"> Skipped Questions</p>
              <p className="text-[24px] font-medium">
                {InsightsData?.skippedQuestions ? InsightsData?.skippedQuestions : 0}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row md:flex-col gap-10 w-full overflow-x-auto horizontal-card-scrollbar pb-2">
        <div className="md:flex md:flex-row gap-10 w-full">
          <div className="px-3 py-6 w-full mb-5 sm:mb-0 md:w-1/2 lg:w-1/5 min-w-[250px] bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
            <div className="flex flex-row gap-2 px-1 justify-between">
              <div>
                <h2 className="text-[20px] tracking-tight flex items-start font-medium text-[#0B0F18] text-left pl-[0px] md:pl-[0px]">
                  Complete Assessment
                </h2>
              </div>
              <div className="flex items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="relative flex max-h-[180px] justify-center items-center">
              <div className="absolute text-center">
                <p className="text-sm text-[#606060]">Total</p>
                <p className="text-2xl text-[#0B0F18]">0</p>
              </div>
              <Doughnut data={CompletedAssigsmentData || dataD} options={optionsD} />
            </div>
            <div className="flex flex-col md:flex-col lg:flex-col md:justify-between md:gap-5 lg:gap-3 gap-4 lg:pl-5 lg:pr-10 md:pl-2 md:pr-2">
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#D97F56] rounded-full mr-2"></span>
                <p className=" flex flex-row items-center gap-2 text-[16px] text-[#606060]">
                  Complete: <p className="text-[#0B0F18] text-[20px]">{userAgeRangeData?.completionRate} </p>
                </p>
              </div>
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#ECC09E] rounded-full mr-2"></span>
                <p className="flex flex-row items-center gap-2 text-[16px] text-[#606060]">
                  Incomplete: <p className="text-[#0B0F18] text-[20px]">{userAgeRangeData?.percentageIncomplete}</p>
                </p>
              </div>
            </div>
          </div>
          <div className="px-3 py-6 w-full mb-5 sm:mb-0  md:w-1/2 lg:w-1/5 min-w-[250px] bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
            <div className="flex flex-row gap-2 px-1 justify-between">
              <div>
                <h2 className="text-[20px] tracking-tight flex items-start font-medium text-[#0B0F18] text-left pl-[0px] md:pl-[0px] ">
                  User Age Range
                </h2>
              </div>
              <div className="flex items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="relative flex max-h-[180px] justify-center items-center">
              <div className="absolute text-center">
                <p className="text-sm text-[#606060]">Total</p>
                <p className="text-2xl text-[#0B0F18] ">0</p>
              </div>
              <Doughnut data={AgeRangeData || dataD} options={optionsD} />
            </div>
            <div className="flex flex-col md:flex-col lg:flex-col md:justify-between md:gap-5 lg:gap-3 gap-4 lg:pl-5 lg:pr-10 md:pl-2 md:pr-2">
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#67A14A] rounded-full mr-2"></span>
                <p className="flex flex-row items-center gap-2 text-[16px] text-[#606060]">
                  Age 18-25: <p className="text-[#0B0F18] text-[20px] ">{userAgeRangeData?.[0].percentage}</p>
                </p>
              </div>
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#C2EC97] rounded-full mr-2"></span>
                <p className="flex flex-row items-center gap-2 text-[16px] text-[#606060]">
                  Age 26-32: <p className="text-[#0B0F18] text-[20px]">{userAgeRangeData?.[1].percentage}</p>
                </p>
              </div>
            </div>
          </div>
          <div className="px-3 py-6 w-full mb-5 sm:mb-0 md:w-1/2 lg:w-1/5 min-w-[250px] bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
            <div className="flex flex-row gap-2 px-1 justify-between">
              <div>
                <h2 className="text-[20px] tracking-tight flex items-start font-medium text-[#0B0F18] text-left pl-[0px] md:pl-[0px]">
                  Common Outcome
                </h2>
              </div>
              <div className="flex items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="relative flex max-h-[180px] justify-center items-center">
              <div className="absolute text-center">
                <p className="text-sm text-[#606060]">Total</p>
                <p className="text-2xl text-[#0B0F18] ">0</p>
              </div>
              <Doughnut data={dataD} options={optionsD} />
            </div>
            <div className="flex flex-col md:flex-col lg:flex-col md:justify-between md:gap-5 lg:gap-3 gap-4 lg:pl-5 lg:pr-10 md:pl-2 md:pr-2">
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#21BDAD] rounded-full mr-2"></span>
                <p className="flex flex-row gap-2 items-center text-[16px] text-[#606060]">
                  Anxiety: <p className="text-[#0B0F18] text-[20px]">0 </p>
                </p>
              </div>
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#6BE0BD] rounded-full mr-2"></span>
                <p className="flex flex-row gap-2 items-center text-[16px] text-[#606060]">
                  Depression: <p className="text-[#0B0F18] text-[20px]">0</p>
                </p>
              </div>
            </div>
          </div>
          <div className="px-3 py-6 w-full mb-5 sm:mb-0 md:w-1/2 lg:w-1/5 min-w-[250px] bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
            <div className="flex flex-row gap-2 px-1 justify-between">
              <div>
                <h2 className="text-[20px] tracking-tight flex items-start font-medium text-[#0B0F18] text-left pl-[0px] md:pl-[0px]">
                  Top Usage Times
                </h2>
              </div>
              <div className="flex items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="relative flex max-h-[180px] justify-center items-center">
              <div className="absolute text-center">
                <p className="text-sm text-[#606060]">Total</p>
                <p className="text-2xl text-[#0B0F18] ">0</p>
              </div>
              <Doughnut data={dataD} options={optionsD} />
            </div>
            <div className="flex flex-col md:flex-col lg:flex-col md:justify-between md:gap-5 lg:gap-3 gap-4 lg:pl-5 lg:pr-10 md:pl-2 md:pr-2">
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#EDCC12] rounded-full mr-2"></span>
                <p className=" flex flex-row gap-2 items-center text-[16px] text-[#606060]">
                  9-10pm: <p className="text-[#0B0F18] text-[20px]">0</p>
                </p>
              </div>
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#FFE873] rounded-full mr-2"></span>
                <p className=" flex flex-row gap-2 text-[16px] items-center text-[#606060]">
                  7-8am: <p className="text-[#0B0F18] text-[20px]">0</p>
                </p>
              </div>
            </div>
          </div>
          <div className="px-3 py-6 w-full mb-5 sm:mb-0 md:w-1/2 lg:w-1/5 min-w-[250px] bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
            <div className="flex flex-row gap-2 px-1 justify-between">
              <div>
                <h2 className="text-[20px] tracking-tight flex items-start font-medium text-[#0B0F18] text-left pl-[0px] md:pl-[0px]">
                  Common Outcome
                </h2>
              </div>
              <div className="flex items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="relative flex max-h-[180px] justify-center items-center">
              <div className="absolute text-center">
                <p className="text-sm text-[#606060]">Total</p>
                <p className="text-2xl text-[#0B0F18] ">0</p>
              </div>
              <Doughnut data={dataD} options={optionsD} />
            </div>
            <div className="flex flex-col md:flex-col lg:flex-col md:justify-between md:gap-5 lg:gap-3 gap-4 lg:pl-5 lg:pr-10 md:pl-2 md:pr-2">
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#21BDAD] rounded-full mr-2"></span>
                <p className="flex flex-row gap-2 items-center text-[16px] text-[#606060]">
                  Anxiety: <p className="text-[#0B0F18] text-[20px]">0 </p>
                </p>
              </div>
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#6BE0BD] rounded-full mr-2"></span>
                <p className="flex flex-row gap-2 items-center text-[16px] text-[#606060]">
                  Depression: <p className="text-[#0B0F18] text-[20px]">0</p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col  lg:flex-row md:flex-col gap-6 sm:w-full mt-[20px]">
        <div className=" flex flex-col justify-between w-full md:w-full  lg:max-w-[265px] h-[200px] md:h-[200px] lg:h-[344px] bg-white rounded-xl border-[1.5px] border-[#E7E8EA] p-5">
          <p className=" text-[20px] lg:text-[20px]  md:text-[25px] leading-tight h-[66px]  font-medium  w-[80%] justify-start text-[#0B0F18]">
            Percentage of incomplete Assessments
          </p>
          <p className=" text-[48px] text-[#0B0F18] font-work-sans">{InsightsData?.percentageIncomplete ? InsightsData?.percentageIncomplete : 0}</p>
        </div>
        <div className="  flex flex-col justify-between  w-full md:w-full  lg:max-w-[265px]  h-[344px] bg-white rounded-xl border-[1.5px] border-[#E7E8EA] p-5">
          <p className="text-[#0B0F18] text-[20px]   lg:text-[20px]  md:text-[25px]">
            Drop-off point
          </p>
          <div>
            <div className=" max-w-md lg:max-w-md  mx-auto p-6 ">
              <ProgressBar label="Intro" percentage={7} />
              <ProgressBar label="About" percentage={55} />
              <ProgressBar label="Questions" percentage={89} />
            </div>
          </div>
        </div>
        <div className=" h-[344px]  w-full  bg-white rounded-xl border-[1.5px] border-[#E7E8EA] p-4">
          <Bar
            data={data}
            options={{
              ...options2,
              responsive: true,
              maintainAspectRatio: false
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Insights;
