import React, { useState } from 'react';
import PrimaryButton from '../../../component/common/Buttons/PrimaryButton';
import { Api } from '../../../api';
import { errorToast } from '../../../utils/helper';
import { useParams } from 'react-router-dom';

const SpecialistPricing = ({ data, refetch }) => {
  const [shoorahPrice, setShoorahPrice] = useState(data?.pricePerHour || 0);
  const [publicPrice, setPublicPrice] = useState(data?.pricePerHour || 0);
  const { userId } = useParams();

  const handleSubmit = async () => {
    try {
      const payload = {
        pricePerHour: +shoorahPrice
      };
      const res = await Api.updatePeapExpertProfilePrize(userId, payload);
      if (res.data.meta.code === 1) {
        refetch();
      }
    } catch (error) {
      console.log('Error:', error);
      errorToast(error?.response?.data?.message);
    }
  };

  return (
    <div className="border-2 relative border-gray-300 rounded-3xl px-6 lg:px-10 pt-3 lg:pt-6 pb-6 lg:pb-12 bg-white dark:bg-shoorah-darkBgTabColor dark:border-none dark:text-white text-black h-full text-lg  font-medium w-full space-y-4">
      <div>
        <h4 className="mb-3">Pricing Details</h4>
        <p className="text-sm font-medium text-shoorah-gray2">
          Specialists can suggest a ( 30 min ) sessions pricing which needs to approved here by the
          shoorah admin member.
        </p>
      </div>
      <div className="flex justify-between items-start gap-4">
        <div className="w-full">
          <h5 className="text-sm md:text-base mb-2">Shoorah Price</h5>
          <input
            type="text"
            value={shoorahPrice}
            onChange={(e) => setShoorahPrice(e.target.value)}
            className="border-2 border-gray-300 rounded-xl bg-white dark:bg-shoorah-darkBgColor px-3 py-2 w-full max-w-40"
          />
        </div>
        <div className="w-full">
          <h5 className="text-sm md:text-base mb-2">Public Price</h5>
          <input
            type="text"
            value={publicPrice}
            onChange={(e) => setPublicPrice(e.target.value)}
            className="border-2 border-gray-300 rounded-xl bg-white dark:bg-shoorah-darkBgColor px-3 py-2 w-full max-w-40"
          />
        </div>
      </div>

      <div className="text-end">
        <PrimaryButton btnText={'Submit'} onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default SpecialistPricing;
