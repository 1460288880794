import React, { useState } from 'react'
import { Bar } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import SurveyCrad from './surveyCrad';
import { isSuperAndSubAdmin } from '../../../utils/helper';

const ProgressBar = ({ label, percentage }) => (
    <div className="mb-4">
     {!!label && <div className="flex justify-between items-center mb-1">
        <span className="text-lg font-normal">{label}</span>
        <span className="text-sm text-green-600">{percentage}%</span>
      </div>}
      <div className="w-full bg-green-100 rounded-full h-2">
        <div className="bg-green-500 h-2 rounded-full" style={{ width: `${percentage}%` }}></div>
      </div>
    </div>
  );

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Survey Monthly Analysis',
        data: [2, 9, 8, 2, 6, 7],
        backgroundColor: '#3a47ab',
        borderColor: ' #4a56db',
        borderWidth: 1
      }
    ]
  };

  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: 'Survey Analysis'
      }
    }
  };

  const dataD = {
    labels: ['Responses', 'No Response'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [1135, 365],
        backgroundColor: ['#F05289', '#FF9BCB'],
        hoverBackgroundColor: ['#F05289', '#FF9BCB'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };

  const optionsD = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: '90%',
    radius: '70%'
  };

const AdminDashboardInsight = ({resultData}) => {
  const isAdmin = isSuperAndSubAdmin()
  const [Id, setId] = useState('');

  const OverAllResponseData = resultData && {
    labels: ['Responses', 'No Response'],
    datasets: [
      {
        label: 'Overall Responses',
        data: [resultData?.completedSurveys + resultData?.openSurveys, resultData?.notAttemptedSurveys],
        backgroundColor: ['#F05289', '#FF9BCB'],
        hoverBackgroundColor: ['#F05289', '#FF9BCB'],
        borderWidth: 1,
        borderRadius: 10,
        borderJoinStyle: 'round'
      }
    ]
  };

  const handleQuestionAction = (id) => {
    if (Id === '') {
      setId(id);
    } else {
      setId('');
    }
  };
  return (
    <div>
        <div className='flex flex-col  lg:flex-row md:flex-col gap-6 sm:w-full'>
            <div className="px-3 py-6 w-full mb-5 sm:mb-0 md:w-1/2 lg:w-1/5 min-w-[250px] bg-[#FFFFFF] rounded-xl border border-[#E7E8EA]">
            <div className="flex flex-row gap-2 px-1 justify-between">
              <div>
                <h2 className="text-[20px] tracking-tight flex items-start font-medium text-[#0B0F18] text-left pl-[0px] md:pl-[0px]">
                  Complete Assessment
                </h2>
              </div>
              <div className="flex items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.25 9C2.25 8.172 2.922 7.5 3.75 7.5C4.578 7.5 5.25 8.172 5.25 9C5.25 9.828 4.578 10.5 3.75 10.5C2.922 10.5 2.25 9.828 2.25 9ZM9 7.5C8.172 7.5 7.5 8.172 7.5 9C7.5 9.828 8.172 10.5 9 10.5C9.828 10.5 10.5 9.828 10.5 9C10.5 8.172 9.828 7.5 9 7.5ZM14.25 7.5C13.422 7.5 12.75 8.172 12.75 9C12.75 9.828 13.422 10.5 14.25 10.5C15.078 10.5 15.75 9.828 15.75 9C15.75 8.172 15.078 7.5 14.25 7.5Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="relative flex max-h-[180px] justify-center items-center">
              <div className="absolute text-center">
                <p className="text-sm text-[#606060]">Total</p>
                <p className="text-2xl text-[#0B0F18]">{resultData?.completedSurveys + resultData?.openSurveys + resultData?.notAttemptedSurveys}</p>
              </div>
              <Doughnut data={OverAllResponseData || dataD} options={optionsD} />
            </div>
            <div className="flex flex-col md:flex-col lg:flex-col md:justify-between md:gap-5 lg:gap-3 gap-4 lg:pl-5 lg:pr-10 md:pl-2 md:pr-2">
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#F05289] rounded-full mr-2"></span>
                <p className=" flex flex-row items-center gap-2 text-[16px] text-[#606060]">
                  Responses: <p className="text-[#0B0F18] text-[20px]">{resultData?.completedSurveys + resultData?.openSurveys}</p>
                </p>
              </div>
              <div className="flex items-center justify-left">
                <span className="w-3 h-3 bg-[#FF9BCB] rounded-full mr-2"></span>
                <p className="flex flex-row items-center gap-2 text-[16px] text-[#606060]">
                  No Response: <p className="text-[#0B0F18] text-[20px]">{resultData?.notAttemptedSurveys}</p>
                </p>
              </div>
            </div>
            </div>
            <div className='flex flex-col gap-[30px] w-2/5'>
                <div className='px-5 py-6 bg-[#ffffff] border border-[#E7E8EA] rounded-xl'>
                    <div className='text-xl font-medium leading-[22px] text-shoorah-lightBlack mb-[22px]'>
                    Average Completion Time
                    </div>
                    <div className='text-[48px] leading-[36px] font-semibold text-shoorah-lightBlack mb-[18px]'>
                    8 <span className='text-[30px] leading-[36px] font-semibold italic'>minutes</span>
                    </div>
                    <div className='flex items-center justify-between w-full'>
                        <div className='text-xs leading-5 text-[#8FD460]'>
                        +25,32%
                        </div>
                        <div className='text-[rgba(41,45,50,0.38)] text-xs leading-5'>
                        Last 7 days
                        </div>
                    </div>
                </div>
                <div className='px-5 py-6 bg-[#ffffff] border border-[#E7E8EA] rounded-xl'>
                    <div className='flex items-center justify-between mb-[30px]'>
                        <div className='text-xl leading-[22px] font-medium text-shoorah-lightBlack'>
                            Completion Rate
                        </div>
                        <div className='text-[18px] leading-[21px] font-medium text-[#67A14A]'>
                            {resultData?.completionRate}
                        </div>
                    </div>
                    <div>
                        <ProgressBar percentage={resultData?.completionRate?.split('%')[0]} />
                    </div>
                </div>
            </div>
            <div className='w-2/5'>
                <div className='px-5 py-4 bg-[#FFFFFF] rounded-xl border border-[#E7E8EA] h-full'>
                  <div className='text-xl font-medium leading-[22px] text-shoorah-lightBlack mb-[25px]'>
                  Top Performing Template
                  </div>
                  {resultData && resultData?.topPerformingTemplate?.length > 0 ? <>
                    {resultData?.topPerformingTemplate?.map((item, index) => (
                      <SurveyCrad
                        item={item}
                        key={index}
                        handleQuestionAction={(id) => handleQuestionAction(id)}
                        Id={Id}
                        handleDeletePulseSurve={(deleteId) =>
                          console.log('deleteId', deleteId)
                        }
                        isAdmin={isAdmin}
                      />
                    ))}
                  </> : <p>You Don't have any top performing template</p>
                  }
                </div>
            </div>
        </div>
        <div className="flex flex-col  lg:flex-row md:flex-col gap-6 sm:w-full mt-[20px]">
        <div className=" flex flex-col justify-between w-full md:w-full  lg:max-w-[265px] h-[200px] md:h-[200px] lg:h-[344px] bg-white rounded-xl border-[1.5px] border-[#E7E8EA] p-5">
          <p className=" text-[20px] lg:text-[20px]  md:text-[25px] leading-tight h-[66px]  font-medium  w-[80%] justify-start text-[#0B0F18]">
            Percentage of incomplete Assessments
          </p>
          <p className=" text-[48px] text-[#0B0F18] font-work-sans">{resultData?.incompletePercentage ? resultData?.incompletePercentage : '0'}</p>
        </div>
        <div className="  flex flex-col justify-between  w-full md:w-full  lg:max-w-[265px]  h-[344px] bg-white rounded-xl border-[1.5px] border-[#E7E8EA] p-5">
          <p className="text-[#0B0F18] text-[20px] font-medium lg:text-[20px]  md:text-[25px]">
            Drop-off point
          </p>
          <div>
            <div className=" max-w-md lg:max-w-md  mx-auto p-6 ">
              <ProgressBar label="Intro" percentage={7} />
              <ProgressBar label="About" percentage={55} />
              <ProgressBar label="Questions" percentage={89} />
            </div>
          </div>
        </div>
        <div className=" h-[344px]  w-full  bg-white rounded-xl border-[1.5px] border-[#E7E8EA] p-4">
          <Bar
            data={data}
            options={{
              ...options2,
              responsive: true,
              maintainAspectRatio: false
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default AdminDashboardInsight
